import { MapRef } from 'react-map-gl';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MapState = {
  mapInstance: MapRef | null;
};

const initialState: MapState = { mapInstance: null };

const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapInstance(state, action: PayloadAction<MapState['mapInstance']>) {
      state.mapInstance = action.payload;
    },
  },
});

export const { setMapInstance } = slice.actions;
export const { reducer } = slice;
