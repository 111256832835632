import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MapView } from '../map-view/MapView';
import { MyFleetView } from '../my-fleet-view/MyFleet';
import { PortView } from '../port-view/PortView';
import { VesselDetailsView } from '../vessel-details-view/VesselDetailsView';
import VesselIdentification from '../vessel-indentification/VesselIdentification';

export const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/map" />} />
    <Route path="/map" element={<MapView />} />
    <Route path="/vessel/:imo" element={<VesselDetailsView />} />
    <Route path="/vessel/:imo/:eventType/:eventId" element={<VesselDetailsView />} />
    <Route path="/my-fleet" element={<MyFleetView />} />
    <Route path="/port/:portId" element={<PortView />} />
    <Route path="/port" element={<PortView />} />
    <Route path="/ipr" element={<VesselIdentification />} />
  </Routes>
);
