import { createListenerMiddleware } from '@reduxjs/toolkit';

import { RootState } from '../../store/store';
import { userFilterEmailOptions } from '../event-filters/filter-options';
import {
  resetForm,
  setEmailOption,
  setName,
  setSelectedUserFilterId,
  setShowForm,
  setUserFilterOptions,
} from './slice';

const resetFormChanges = (state: RootState, dispatch: any) => {
  const selectedUserFilter =
    state.userFilters.userFilterOptions?.find((option) => option.id === state.userFilters.selectedUserFilterOptionId) ||
    null;

  if (!selectedUserFilter) {
    dispatch(resetForm());
    return;
  }

  const emailOption =
    userFilterEmailOptions.find((option) => option.value === selectedUserFilter?.alert_schedule) ||
    userFilterEmailOptions[0];

  dispatch(setName(selectedUserFilter.name));
  dispatch(setEmailOption(emailOption));
};

export const userFilterListenerMiddleware = createListenerMiddleware();

userFilterListenerMiddleware.startListening({
  actionCreator: setSelectedUserFilterId,
  effect: async (_, listenerApi) => {
    resetFormChanges(listenerApi.getState() as RootState, listenerApi.dispatch);
  },
});

userFilterListenerMiddleware.startListening({
  actionCreator: setShowForm,
  effect: async (action, listenerApi) => {
    if (action.payload) {
      // Form got opened, no side effects.
      return;
    }

    // Form got closed, reset any changes
    resetFormChanges(listenerApi.getState() as RootState, listenerApi.dispatch);
  },
});

userFilterListenerMiddleware.startListening({
  actionCreator: setUserFilterOptions,
  effect: async (_, listenerApi) => {
    // If we have a filter ID set in the URL, we need to setup the initial form values when the user filters have been fetched.
    resetFormChanges(listenerApi.getState() as RootState, listenerApi.dispatch);
  },
});
