import './styles.css';

import React from 'react';

import { VesselSearch } from '../vessel-search/VesselSearch';
import { CurrentUser } from './components/CurrentUser';
import { Link } from './components/Link';

export const Header: React.FC = () => (
  <header className="Header bg-secondary text-secondary-content flex justify-between flex-col lg:flex-row lg:items-center">
    <div className="Header__left flex items-center h-full">
      <div className="bg-white h-full flex items-center px-0 md:px-3 lg:px-6">
        <img src="/assets/images/wartsila-logo.png" alt="Wärtsilä logo" className="w-16 min-w-[3rem]" />
      </div>
      <div className="Header__links pl-8 md:pl-12 lg:pl-16 flex items-center h-full bg-dark overflow-auto">
        <Link to="/map">Events</Link>
        <Link to="/port">Ports</Link>
        <Link to="/my-fleet">My fleet</Link>
      </div>
    </div>
    <div className="Header__right mr-2 flex flex-wrap flex-col justify-between sm:flex-row sm:items-center p-2 lg:py-0">
      <VesselSearch className="w-[290px] mr-1 order-2 lg:order-1 py-1" />
      <CurrentUser className="order-3 lg:order-3 mb-1 sm:mb-0" />
    </div>
  </header>
);
