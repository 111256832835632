import React from 'react';

type Props = {
  className?: string;
};

export const DownloadIcon: React.FC<Props> = ({ className = 'h-5 w-5' }) => (
  <svg
    id="bounding-box-48x48-export-me"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    className={className}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path d="m34.3,24.29l-9.29,9.22V4h-2v29.51l-9.3-9.22-1.41,1.42,11,10.91c.19.19.45.29.7.29s.51-.1.7-.29l11-10.91-1.41-1.42Z" />
    <rect x="8.01" y="42" width="31.99" height="2" />
  </svg>
);
