import React from 'react';
import ReactSelect, { CSSObjectWithLabel, GetOptionLabel, StylesConfig } from 'react-select';

import { themeColors } from '../utils';

const defaultStyles: CSSObjectWithLabel = {
  backgroundColor: themeColors.primary,
  color: themeColors.primaryContent,
  borderColor: themeColors.secondary,
  fontSize: '14px',
};

export const defaultStyleConfig: StylesConfig = {
  control: (provided) => ({
    ...provided,
    ...defaultStyles,
    boxShadow: 'none',
    '&:hover': {
      borderColor: themeColors.secondary,
    },
  }),
  clearIndicator: (provided, state) =>
    state.isMulti
      ? {
          display: 'none',
        }
      : {
          ...provided,
          color: themeColors.neutral,
          '&:hover': {
            color: themeColors.error,
            cursor: 'pointer',
          },
        },
  dropdownIndicator: (provided) => ({
    ...provided,
    color: themeColors.primaryContent,
    '&:hover': {
      color: themeColors.primaryContent,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: themeColors.secondary,
  }),
  option: (provided) => ({
    ...provided,
    ...defaultStyles,
    '&:hover': {
      backgroundColor: themeColors.secondary,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    ...defaultStyles,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: themeColors.secondary,
    color: themeColors.base100,
    borderColor: themeColors.primaryContent,
    fontSize: '12px',
    width: 'calc(100% - 12px)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: themeColors.secondary,
    color: themeColors.base100,
    borderColor: themeColors.primaryContent,
    fontSize: '12px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: themeColors.primary,
    },
  }),
  valueContainer: (provided, state) =>
    state.isMulti
      ? {
          ...provided,
          paddingRight: 0,
        }
      : provided,
  menu: (provided) => ({
    ...provided,
    ...defaultStyles,
    border: '0.5px solid rgb(8 67 95)',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 15,
  }),
  input: (provided) => ({
    ...provided,
    color: themeColors.primaryContent,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#808080',
  }),
};

type Props = {
  options: any[];
  value: any;
  onChange: (value: any) => void;
  onInputChange?: (value: string) => void;
  isClearable?: boolean;
  isMulti?: boolean;
  isLoading?: boolean;
  className?: string;
  placeholder?: string;
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode;
  menuPortalTarget?: HTMLElement;
  getOptionLabel?: GetOptionLabel<any>;
  formatOptionLabel?: any;
  closeMenuOnSelect?: boolean;
  styleConfig?: StylesConfig;
};

export const Select: React.FC<Props> = ({
  value,
  onChange,
  onInputChange,
  options,
  isClearable,
  isMulti,
  isLoading,
  className,
  placeholder,
  noOptionsMessage,
  menuPortalTarget,
  getOptionLabel,
  formatOptionLabel,
  closeMenuOnSelect,
  styleConfig,
}) => (
  <ReactSelect
    value={value}
    onInputChange={onInputChange}
    onChange={onChange as (value: any) => void}
    options={options}
    styles={styleConfig || defaultStyleConfig}
    isClearable={isClearable}
    isMulti={isMulti}
    isLoading={isLoading}
    className={className}
    placeholder={placeholder}
    noOptionsMessage={noOptionsMessage}
    menuPortalTarget={menuPortalTarget}
    getOptionLabel={getOptionLabel}
    formatOptionLabel={formatOptionLabel}
    closeMenuOnSelect={closeMenuOnSelect}
  />
);
