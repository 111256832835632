import React from 'react';

import { useAppSelector } from '../../common/hooks';
import { EventFeed } from '../event-feed/EventFeed';
import { EventFilters } from '../event-filters/EventFilters';
import { Map } from '../map/Map';
import { Polling } from '../polling/Polling';
import { VesselMapZoom } from '../vessel-map-zoom/VesselMapZoom';
import { useClearSelectedEventOnFilterChange, useSetupFiltersForMapView } from './hooks';

export const MapView: React.FC = () => {
  const map = useAppSelector((state) => state.map.mapInstance);
  useSetupFiltersForMapView();
  useClearSelectedEventOnFilterChange();
  const { eventsPageEnablePolling } = useAppSelector((state) => state.polling);

  return (
    <div className="MapView relative h-full flex justify-between flex-col md:flex-row">
      <div className="absolute left-0 z-[11] w-[300px]">
        <EventFilters />
        <Polling
          className="bg-primary/90 text-primary-content w-full p-2 mt-1"
          minimizedClassName="bg-primary/90 text-primary-content w-10 p-2 mt-1"
        />
      </div>
      <div className="relative map flex-1 min-h-[50vh]">
        <div className="absolute md:left-0 z-[11] right-2 md:bottom-8">
          <div className="flex flex-col items-start ml-2 mt-2 text-primary-content">
            <VesselMapZoom map={map} />
          </div>
        </div>
        <Map />
      </div>
      <div className="md:absolute md:right-5 md:top-0 md:bottom-0 md:z-10 md:my-10 md:w-[28rem]">
        <EventFeed pollingEnabled={eventsPageEnablePolling} />
      </div>
    </div>
  );
};
