import React from 'react';

type Props = {
  color?: string;
  highlightPosition?: boolean | null;
  hasDirection?: boolean;
};

export const PositionMarker: React.FC<Props> = ({ color, highlightPosition, hasDirection }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      className={`w-[16px] cursor-pointer ${highlightPosition ? 'border-black border-2 rounded-full w-[25px]' : ''}`}
    >
      <circle style={{ fill: color }} cx="39" cy="38.99" r="39" />
      {hasDirection && (
        <path
          style={{ fill: '#fff' }}
          d="M20.86,54c-.76.45-1.07.16-.71-.63l18.18-40c.36-.8,1-.79,1.33,0L58.17,53.23c.37.8,0,1.09-.71.64L39,43.09Z"
          transform="translate(0 -0.01)"
        />
      )}
    </svg>
  );
};
