import dayjs from 'dayjs';
import React from 'react';

import { AnomalyRating } from '../../../event-feed-rating/AnomalyRating';
import { Anomalies } from '../../../../store/apis/vessel-event-api';
import {
  getCommandText,
  getDriftingText,
  getWaitingForOrdersText,
  getWaitingForPortText,
  getConfidenceText,
  getDestinationChangedText,
  getDurationText,
  getInvolvedTugText,
  getSpeedChangedText,
  getStatusChangedText,
} from '../../utils';
import { EventAttribute } from '../EventAttribute';
import { ListItem } from '../ListItem';

type Props = {
  anomaly: Anomalies;
  selected: boolean;
};

export const AnomalyItem: React.FC<Props> = ({ anomaly, selected }) => (
  <ListItem
    key={anomaly.id}
    item={anomaly}
    type="anomaly"
    className={selected ? 'bg-secondary/60' : ''}
    date={`${dayjs.utc(anomaly.start_datetime).format('YYYY-MM-DD HH:mm')} UTC`}
  >
    <>
      <div className="py-1">
        {getSpeedChangedText(anomaly) && <EventAttribute>{getSpeedChangedText(anomaly)}</EventAttribute>}
        {getDestinationChangedText(anomaly) && <EventAttribute>{getDestinationChangedText(anomaly)}</EventAttribute>}
        {getStatusChangedText(anomaly) && <EventAttribute>{getStatusChangedText(anomaly)}</EventAttribute>}
        {anomaly.casualty_id && (
          <EventAttribute>
            <a
              className="link font-medium hover:text-accent no-underline"
              href={`/vessel/${anomaly.imo_number}/casualties/${anomaly.casualty_id}`}
            >
              Casualty reported
            </a>
          </EventAttribute>
        )}
        {getCommandText(anomaly) && <EventAttribute>{getCommandText(anomaly)}</EventAttribute>}
        {getDriftingText(anomaly) && <EventAttribute>{getDriftingText(anomaly)}</EventAttribute>}
        {getWaitingForOrdersText(anomaly) && <EventAttribute>{getWaitingForOrdersText(anomaly)}</EventAttribute>}
        {getWaitingForPortText(anomaly) && <EventAttribute>{getWaitingForPortText(anomaly)}</EventAttribute>}
        {getInvolvedTugText(anomaly) && <EventAttribute>{getInvolvedTugText(anomaly)}</EventAttribute>}
      </div>
      {getDurationText(anomaly) && (
        <div className="flex items-center">
          <span className="text-sm">
            {getDurationText(anomaly)} | {getConfidenceText(anomaly)}
          </span>
        </div>
      )}
      <AnomalyRating anomalyId={anomaly.id as number} className="mt-2 mb-1" />
    </>
  </ListItem>
);
