import dayjs from 'dayjs';
import React from 'react';

import { Drydockings } from '../../../../store/apis/vessel-event-api';
import { getDurationAndAge, getLocationFlagClassNames } from '../../utils';
import { ListItem } from '../ListItem';

type Props = {
  dryDocking: Drydockings;
  selected: boolean;
};

export const DryDockingItem: React.FC<Props> = ({ dryDocking, selected }) => (
  <ListItem
    key={dryDocking.id}
    item={dryDocking}
    type="dry-dock"
    date={`${dayjs.utc(dryDocking.update_datetime).format('YYYY-MM-DD HH:mm')} UTC`}
    className={selected ? 'bg-secondary/60' : ''}
  >
    <div className="mb-0.5">
      <span className={getLocationFlagClassNames(dryDocking)}></span>
      <span className="ml-2">{dryDocking.location}</span>
    </div>
    <span>{getDurationAndAge(dryDocking)}</span>
  </ListItem>
);
