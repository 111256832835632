import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ContactState = {
  showForm: boolean;
  subject: string;
  message: string;
};

const initialState: ContactState = { showForm: false, subject: '', message: '' };

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setShowForm(state, action: PayloadAction<boolean>) {
      state.showForm = action.payload;
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    reset(state) {
      state.showForm = false;
      state.subject = '';
      state.message = '';
    },
  },
});

export const { setShowForm, setSubject, setMessage, reset } = contactSlice.actions;
export const { reducer } = contactSlice;
