const formatLatitude = (latitude: number) => {
  const positiveLatitude = Math.abs(latitude);
  const degrees = Math.floor(positiveLatitude);
  const minutes = (positiveLatitude - degrees) * 60;
  return [degrees, minutes.toFixed(2), latitude > 0 ? 'N' : 'S'];
};

const formatLongitude = (longitude: number) => {
  const positiveLongitude = Math.abs(longitude);
  const degrees = Math.floor(positiveLongitude);
  const minutes = (positiveLongitude - degrees) * 60;
  return [degrees, minutes.toFixed(2), longitude > 0 ? 'E' : 'W'];
};

const filterConsequtiveSameCoordinates = (data: any) => {
  const filteredData = data.filter((position: any, index: number, array: any) => {
    if (index === 0) {
      return true;
    }
    return position.latitude !== array[index - 1].latitude || position.longitude !== array[index - 1].longitude;
  });
  return filteredData;
};

export const formatPositionData = (positions: any) => {
  const data = filterConsequtiveSameCoordinates(positions)
    .toReversed()
    .map((position: any, index: number) => {
      return [
        index + 1,
        ...formatLatitude(position.latitude),
        ...formatLongitude(position.longitude),
        '', // PORT[NM]
        '', // STBD[NM]
        '', // Arr.Rad[NM]
        position.speed,
        '', // Sail(RL/GC)
        '', // ROT[deg/min]
        '', // Turn Rad [NM]
        '', // Wind Dir[deg]
        '', // Time Zone
        position.timestamp?.split('T')[1].substring(0, 8),
        '', // Name
      ];
    });
  return data;
};
