import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';

import { setUserLocation, setIsFetching, setIsSearching, setShowUserLocation } from './slice';
import { Coordinates, setCoordinatesAndUpdateUrl } from '../port-view/slice';

export const useSetUserLocation = () => {
  const dispatch = useAppDispatch();
  const storedLocation: Coordinates | null = useAppSelector((state) => state.location.userLocation);
  const isFetching: boolean = useAppSelector((state) => state.location.isFetching);
  const isSearching: boolean = useAppSelector((state) => state.location.isSearching);

  useEffect(() => {
    if (storedLocation) return;
    if (isFetching) return;
    if (!isSearching) return;
    dispatch(setIsFetching(true));

    const fetchingLocationSuccessful = (pos: GeolocationPosition) => {
      dispatch(setUserLocation({ latitude: pos.coords.latitude, longitude: pos.coords.longitude }));
      dispatch(setCoordinatesAndUpdateUrl({ latitude: pos.coords.latitude, longitude: pos.coords.longitude }));
      dispatch(setShowUserLocation(true));
      dispatch(setIsFetching(false));
    };

    const fetchingLocationFailed = () => {
      toast.error('Please share your location with browser if you want to see vessels near you.');
      dispatch(setShowUserLocation(false));
      dispatch(setIsFetching(false));
    };

    navigator.geolocation.getCurrentPosition(fetchingLocationSuccessful, fetchingLocationFailed, { timeout: 8000 });
    dispatch(setIsSearching(false));
  }, [dispatch, storedLocation, isFetching, isSearching]);
};
