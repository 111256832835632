import dayjs from 'dayjs';

import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import { EventType } from '../features/event-feed/slice';
import { EventFiltersState, EventOptionValue, ProductOption } from '../features/event-filters/slice';
import { GetAnomaliesApiArg, GetCasualtiesApiArg, GetDrydockingsApiArg } from '../store/apis/vessel-event-api';
import { CommonState } from './slice';
import { MapStyle, TimeRangeOption, TimeRangeUnit } from './types';

export const getCssVariable = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name);

export const themeColors = {
  primary: '#0f172b',
  primaryContent: '#FFFFFF',
  secondary: '#003347',
  secondaryContent: '#FFFFFF',
  accent: '#F97316',
  accentContent: '#00abf1',
  neutral: '#3D4451',
  neutralContent: '#FFFFFF',
  base100: '#FFFFFF',
  info: '#3ABFF8',
  success: '#008000',
  successContent: '#FFFFFF',
  warning: '#FBBD23',
  error: '#DE350B',
  errorContent: '#FFFFFF',
};

export const customColors = {
  mapOrange: '#f5a623',
  secondaryLight: '#08435f',
  infoLight: '#9fd9f3',
};

const devTeam = ['dennis.grebasch', 'lotta.merisaari', 'marina.massoud', 'thomas.dewilde'];

const addOrOperatorValueToAndOperator = (params: Record<string, string>, value: string) => {
  params.and = !params.and ? `(or(${value})` : `${params.and},or(${value})`;
};

const isDevTeam = (user: string) => {
  return devTeam.includes(user);
};

const groupProductOptionsByType = (options: ProductOption[]) => {
  const groupedOptions = options.reduce((groupedOptions: Record<string, ProductOption[]>, option) => {
    const { type } = option;
    if (!type) return groupedOptions;
    groupedOptions[type] = groupedOptions[type] ?? [];
    groupedOptions[type].push(option);
    return groupedOptions;
  }, {});

  return groupedOptions;
};

const getProductOperatorValue = (productList: ProductOption[]) => {
  const groupedProducts: ProductOption[][] = Object.values(groupProductOptionsByType(productList));
  const operatorValue = groupedProducts
    .map((typeGroup) => {
      const typeGroupString = typeGroup.map((option) => `products.cs.{${option.value}}`).join(',');
      return typeGroup.length > 1 ? `or(${typeGroupString})` : typeGroupString;
    })
    .join(',');
  return groupedProducts.length > 1 ? `and(${operatorValue})` : operatorValue;
};

export const buildQueryFilter = (filter: EventFiltersState, currentUser: AccountInfo) => {
  // Add shared params
  const sharedParams: Partial<GetAnomaliesApiArg> & Partial<GetCasualtiesApiArg> & Partial<GetDrydockingsApiArg> = {};
  const selectedFleetOption = filter?.selectedFleetOption?.value;
  let fleetFilterSet = false;
  if (!filter.selectedImo) {
    switch (selectedFleetOption) {
      case 'wartsila-fleet':
        sharedParams['installationId'] = 'not.is.null';
        fleetFilterSet = true;
        break;
      case 'imo-fleet':
        sharedParams['imoNumber'] = 'not.is.null';
        fleetFilterSet = true;
        break;
      case 'my-fleet':
        const currentEmployee = isDevTeam(currentUser.username.split('@')[0])
          ? 'claus.sibbesen'
          : currentUser.username.split('@')[0];
        sharedParams['employees'] = `cs.{${currentEmployee}}`;
        fleetFilterSet = true;
        break;
    }

    if (selectedFleetOption && !fleetFilterSet) {
      // Check if fleet filter is set to a custom employee value
      if (selectedFleetOption.split('.')?.length > 1) {
        sharedParams['employees'] = `cs.{${selectedFleetOption}}`;
      }
    }
  }

  const { selectedVesselTypeOptions } = filter;
  if (selectedVesselTypeOptions?.length) {
    const operatorValue = selectedVesselTypeOptions.map((option) => `vessel_type_code.like.${option.value}*`).join(',');
    addOrOperatorValueToAndOperator(sharedParams, operatorValue);
  }

  const { selectedProductOptions } = filter;
  if (selectedProductOptions?.length) {
    const operatorValue = getProductOperatorValue(selectedProductOptions);
    addOrOperatorValueToAndOperator(sharedParams, operatorValue);
  }

  const { selectedCustomerOption } = filter;
  if (selectedCustomerOption) {
    const operatorValue = selectedCustomerOption
      .split(',')
      .filter((customer) => customer)
      .map((customer) => `customers.cs.\{${customer}\}`)
      .join(',');
    addOrOperatorValueToAndOperator(sharedParams, operatorValue);
  }

  const { selectedLocationOption } = filter;
  if (selectedLocationOption) {
    const operatorValue = selectedLocationOption
      .split(',')
      .filter((location) => location)
      .map((location) =>
        location.length < 5
          ? `location.like.${location}*,destination.like.${location}`
          : `location.eq.${location},destination.eq.${location}`
      )
      .join(',');
    addOrOperatorValueToAndOperator(sharedParams, operatorValue);
  }

  if (filter.selectedImo) {
    sharedParams['imoNumber'] = `eq.${filter.selectedImo}`;
  }

  // Add type specific params.
  // Note: only modify the "sharedParams" object before adding type specific params.

  const anomalyParams: GetAnomaliesApiArg = { order: 'start_datetime.desc', ...sharedParams };
  const casualtyParams: GetCasualtiesApiArg = { order: 'casualty_date.desc', ...sharedParams };
  const dryDockParams: GetDrydockingsApiArg = {
    order: 'update_datetime.desc',
    imoNumber: 'not.is.null',
    ...sharedParams,
  };

  if (filter.selectedTimeRangeOption && !filter.selectedImo) {
    const { amount, unit } = filter.selectedTimeRangeOption.value;
    const date = dayjs.utc().subtract(amount, unit);

    anomalyParams['startDatetime'] = `gte.${date.format('YYYY-MM-DDThh:mm')}`;
    casualtyParams['casualtyDate'] = `gte.${date.format('YYYY-MM-DD')}`;
  }

  const { selectedCharacteristicOptions } = filter;
  if (selectedCharacteristicOptions?.length) {
    const operatorValue = selectedCharacteristicOptions.map((option) => option.value).join(',');
    addOrOperatorValueToAndOperator(anomalyParams, operatorValue);
  }

  if (!filter.selectedImo) {
    const { selectedConfidenceMin } = filter;
    if (selectedConfidenceMin?.minQuery) {
      addOrOperatorValueToAndOperator(anomalyParams, selectedConfidenceMin.minQuery);
    }

    const { selectedConfidenceMax } = filter;
    if (selectedConfidenceMax?.maxQuery) {
      addOrOperatorValueToAndOperator(anomalyParams, selectedConfidenceMax.maxQuery);
    }
  }

  const { selectedCauseOptions } = filter;
  if (selectedCauseOptions?.length) {
    const operatorValue = selectedCauseOptions.map((option) => `casualty_causes.like.*${option.value}*`).join(',');
    addOrOperatorValueToAndOperator(casualtyParams, operatorValue);
  }

  const { selectedCrmLeadOptions } = filter;
  if (selectedCrmLeadOptions?.length) {
    const operatorValue = selectedCrmLeadOptions.map((option) => `crm_link->>status.eq.${option.value}`).join(',');
    addOrOperatorValueToAndOperator(casualtyParams, operatorValue);
  }

  const { selectedDescriptionOption } = filter;
  if (selectedDescriptionOption) {
    const operatorValue = selectedDescriptionOption
      .split(',')
      .filter((word) => word)
      .map((word) => `casualty_description.ilike.*${word}*`)
      .join(',');
    addOrOperatorValueToAndOperator(casualtyParams, operatorValue);
  }

  const { selectedDrydockingStatusOption } = filter;
  if (selectedDrydockingStatusOption) {
    const setting = selectedDrydockingStatusOption.value;
    if (setting === 'cid') {
      const operatorValue = 'end_datetime.is.null';
      addOrOperatorValueToAndOperator(dryDockParams, operatorValue);
    } else if (filter.selectedTimeRangeOption && !filter.selectedImo) {
      const { amount, unit } = filter.selectedTimeRangeOption.value;
      const date = dayjs.utc().subtract(amount, unit);
      dryDockParams['updateDatetime'] = `gte.${date.format('YYYY-MM-DDThh:mm')}`;
    }
  }

  // Add the closing parenthesis to the AND operators

  if (anomalyParams['and']) {
    anomalyParams['and'] = `${anomalyParams['and']})`;
  }

  if (casualtyParams['and']) {
    casualtyParams['and'] = `${casualtyParams['and']})`;
  }

  if (dryDockParams['and']) {
    dryDockParams['and'] = `${dryDockParams['and']})`;
  }

  return {
    anomalyParams,
    casualtyParams,
    dryDockParams,
  };
};

export type CasualtyCauseKey =
  | 'AR'
  | 'CN'
  | 'CT'
  | 'FD'
  | 'FX'
  | 'HL'
  | 'LD'
  | 'LT'
  | 'MG'
  | 'MY'
  | 'PS'
  | 'PY'
  | 'WS'
  | 'XX';
export const casualtyCauses: Record<CasualtyCauseKey, string> = {
  AR: 'Arrests/seizures',
  CN: 'Collision',
  CT: 'Contact',
  FD: 'Foundered (sunk, submerged)',
  FX: 'Fire/explosion',
  HL: 'Hull damage',
  LD: 'Labour dispute',
  LT: 'War loss/damage during hostilities',
  MG: 'Missing/overdue',
  MY: 'Machinery damage/failure',
  PS: 'Vessel detained',
  PY: 'Piracy',
  WS: 'Wrecked/stranded (aground)',
  XX: 'Miscellaneous',
};

export const debounce = (callback: Function, delay: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: any) => {
    const later = () => {
      clearTimeout(timeout);
      callback(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
  };
};

export const acquireTokenForApiResourceSilent = async (msalInstance: IPublicClientApplication, appId: string) => {
  const [account] = msalInstance.getAllAccounts();
  return (
    await msalInstance.acquireTokenSilent({
      account,
      scopes: [`${appId}/.default`],
    })
  ).accessToken;
};

export const acquireTokenForApiResourceRedirect = async (msalInstance: IPublicClientApplication, appId: string) => {
  await msalInstance.acquireTokenRedirect({
    scopes: [`${appId}/.default`],
  });
};

type CommonUrlParams = {
  ais?: 'dev';
};

export const getStateFromUrl = (initialState: CommonState): CommonState => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<CommonUrlParams> = Object.fromEntries(urlSearchParams.entries());

  if (params.ais === 'dev') {
    return { ...initialState, useDevAisApi: true };
  }

  return initialState;
};

export const getEventTypeFromEventOptionValue = (eventOptionValue: EventOptionValue): EventType => {
  let type: EventType;
  switch (eventOptionValue) {
    case 'anomalies':
      type = 'anomaly';
      break;
    case 'casualties':
      type = 'casualty';
      break;
    case 'dry-docking':
      type = 'dry-dock';
      break;
    case 'port-calls':
      type = 'port-calls';
      break;
  }
  return type;
};

export const getEventOptionValueFromEventType = (eventType: EventType): EventOptionValue | undefined => {
  let eventOptionValue: EventOptionValue | undefined = undefined;
  switch (eventType) {
    case 'anomaly':
      eventOptionValue = 'anomalies';
      break;
    case 'casualty':
      eventOptionValue = 'casualties';
      break;
    case 'dry-dock':
      eventOptionValue = 'dry-docking';
      break;
    case 'port-calls':
      eventOptionValue = 'port-calls';
      break;
  }
  return eventOptionValue;
};

export const addSearchParamsToUrl = (params: URLSearchParams) => {
  const queryStringDecoded = decodeURIComponent(params.toString());
  const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const url = queryStringDecoded ? `${baseUrl}?${queryStringDecoded}` : baseUrl;
  window.history.replaceState({}, window.document.title, url);
};

type TimeRangeUrlKey = 'm' | 'h' | 'd' | 'w' | 'mo';

const timeUnits: Record<TimeRangeUrlKey, TimeRangeUnit> = {
  m: 'minutes',
  h: 'hours',
  d: 'days',
  w: 'weeks',
  mo: 'months',
};

export const parseUrlTimeRange = (urlTimeRange: string) => {
  if (!urlTimeRange) {
    return undefined;
  }

  const digitPattern = /[0-9]/g;
  const amount = urlTimeRange.match(digitPattern)?.join('');
  const letterPattern = /[a-zA-Z]/g;
  const unit = urlTimeRange.match(letterPattern)?.join('') as TimeRangeUrlKey | undefined;

  if (!amount || !unit) {
    return undefined;
  }

  return { amount, unit: timeUnits[unit] };
};

export const formatTimeRangeOptionForUrl = (timeRangeOption: TimeRangeOption | null) => {
  if (!timeRangeOption?.value) {
    return undefined;
  }

  let [shortUnit] =
    Object.entries(timeUnits).find((entry) => {
      const [_, longUnit] = entry;
      return longUnit === timeRangeOption.value.unit;
    }) || [];

  return `${timeRangeOption.value.amount}${shortUnit}`;
};

const STORAGE_KEY = 'csi-client-config';

type CsiClientConfig = {
  vesselPageMapStyleName: MapStyle;
  portPageMapStyleName: MapStyle;
  eventsPageEnablePolling: boolean;
};

export const storeConfig = (updatedFields: Partial<CsiClientConfig>) => {
  const config = getStoredConfig();
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...config,
      ...updatedFields,
    })
  );
};

export const getStoredConfig = (): CsiClientConfig | null => {
  const configJson = localStorage.getItem(STORAGE_KEY);
  return configJson ? JSON.parse(configJson) : null;
};
