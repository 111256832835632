import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import MapGL, { MapLayerMouseEvent, MapRef, Marker, Popup, ScaleControl } from 'react-map-gl';

import { Spinner } from '../../../common/components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { VesselPosition } from '../../../store/apis/ais-api';
import { zoomMapOverMarkers } from '../../port-view/utils';
import { useGetFilteredVesselLocations, useGetMapProps } from '../hooks';
import { setMapInstance as setMapInstanceAction, setSelectedVessel } from '../slice';

export const MyFleetMap: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.myFleet);
  const [mapInstance, setMapInstance] = useState<MapRef | null>(null);
  const { accessToken, mapStyle, maxZoom } = useGetMapProps();
  const vesselLocations = useGetFilteredVesselLocations();

  useEffect(() => {
    if (!mapInstance) return;
    dispatch(setMapInstanceAction(mapInstance));
  }, [dispatch, mapInstance]);

  useEffect(() => {
    const selectedVessel = vesselLocations.currentData.find((vessel) => vessel.imo === state.selected);
    if (!selectedVessel) return;
    mapInstance?.flyTo({
      center: [selectedVessel.positions?.[0].longitude as number, selectedVessel.positions?.[0].latitude as number],
    });
  }, [state.selected]);

  useEffect(() => {
    if (vesselLocations.isLoading) return;
    if (
      state.selectedTab === 'automatic' &&
      state.myAutomaticFleet &&
      state.myAutomaticFleet.length > 200 &&
      vesselLocations.currentData
    )
      toast.error(
        `Only showing ${vesselLocations.currentData.length} vessels out of ${state.myAutomaticFleet.length}`,
        {
          duration: 10000,
          id: 'automaticFleet',
        }
      );
    else if (
      state.selectedTab === 'manual' &&
      state.myManualFleet &&
      state.myManualFleet.length > 200 &&
      vesselLocations.currentData
    )
      toast.error(`Only showing ${vesselLocations.currentData.length} vessels out of ${state.myManualFleet.length}`, {
        duration: 10000,
        id: 'manualFleet',
      });
  }, [state.selectedTab, state.myAutomaticFleet, state.myManualFleet, vesselLocations.isLoading]);

  const handleSelectMarker = (originalEvent: MouseEvent, identifier?: number) => {
    originalEvent.stopPropagation();
    if (!identifier) return;
    if (identifier === state.selected) {
      dispatch(setSelectedVessel(undefined));
      return;
    }
    dispatch(setSelectedVessel(identifier));
    const listItem = document.querySelector(`div[data-event='fleet-${identifier}']`);
    listItem?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleMapClick = (e: MapLayerMouseEvent) => {
    e.originalEvent.stopPropagation();
    if (state.selected) {
      dispatch(setSelectedVessel());
    }
  };

  const vesselMarkers = useMemo(() => {
    const vesselsLocationData = vesselLocations.currentData;
    if (!vesselsLocationData) return;
    zoomMapOverMarkers(
      vesselsLocationData.map((location) => [
        location.positions?.[0].longitude as number,
        location.positions?.[0].latitude as number,
      ]),
      state.mapInstance
    );
    return vesselsLocationData.map((vesselLocation: VesselPosition) => {
      const identifier = vesselLocation.imo ? vesselLocation.imo : vesselLocation.mmsi;
      const selected = identifier === state.selected;
      const image = vesselLocation.positions?.[0].heading
        ? '/assets/images/positions/position-direction.svg'
        : '/assets/images/positions/position-no-direction.svg';
      if (vesselLocation.positions?.[0].latitude && vesselLocation.positions?.[0].longitude)
        return (
          <Marker
            longitude={vesselLocation.positions?.[0].longitude}
            latitude={vesselLocation.positions?.[0].latitude}
            anchor="center"
            rotation={vesselLocation.positions?.[0].heading}
            key={identifier}
            onClick={(e) => handleSelectMarker(e.originalEvent, identifier)}
          >
            <img src={image} className="w-[18px] mr-1 cursor-pointer" title={vesselLocation.vessel?.name}></img>
            {selected && (
              <Popup
                closeButton={false}
                closeOnClick={false}
                latitude={vesselLocation.positions?.[0].latitude as number}
                longitude={vesselLocation.positions?.[0].longitude as number}
                anchor="top"
                className="map__marker__popup"
              >
                {vesselLocation.vessel?.name}
              </Popup>
            )}
          </Marker>
        );
    });
  }, [state.selected, vesselLocations.currentData]);

  return (
    <MapGL
      mapboxAccessToken={accessToken}
      mapStyle={mapStyle}
      ref={setMapInstance}
      maxZoom={maxZoom}
      onClick={handleMapClick}
      dragRotate={false}
      touchZoomRotate={false}
    >
      {vesselLocations.isLoading && (
        <div className="absolute w-full h-full flex items-center justify-center">
          <div className="bg-primary/90 rounded-[4px] flex items-center justify-center p-10 z-50">
            <Spinner className="w-8 h-8" />
          </div>
        </div>
      )}
      {vesselMarkers}
      <ScaleControl position="bottom-right" maxWidth={200} unit="nautical" />
    </MapGL>
  );
};
