import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

import { ClockIcon } from '../../../common/components/icons/ClockIcon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { Port, VesselPosition } from '../../../store/apis/ais-api';
import { setSelectedVessel } from '../slice';

type Props = {
  vessel: VesselPosition;
  className?: string;
  status?: PortStatus;
};

enum PortStatus {
  InPort = 'inPort',
  Arriving = 'arriving',
  Departing = 'departing',
}

export const PortVesselItem: React.FC<Props> = ({ vessel, className, status }) => {
  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector((state) => state.portPage.selected);
  const mapInstance = useAppSelector((state) => state.portPage.mapInstance);

  const onClickMarkerIconHandler = () => {
    const identifier = vessel.imo ? vessel.imo : vessel.mmsi;
    if (identifier === selectedItem) {
      dispatch(setSelectedVessel(undefined));
      return;
    }
    mapInstance?.flyTo({
      center: [vessel.positions?.[0].longitude as number, vessel.positions?.[0].latitude as number],
    });
    dispatch(setSelectedVessel(identifier));
  };

  const renderPortLink = (port: Port) => {
    const portInformation = (
      <>
        <span title={port.country_name} className={`fi fi-${port.country_code?.toLowerCase()} mr-1`} />
        {port.name}
        {port.unlocode && ` (${port.unlocode})`}
      </>
    );

    if (!port.unlocode) {
      return <span className="text-gray-400">{portInformation}</span>;
    } else {
      return (
        <Link className="hover:text-info" title={port.unlocode} to={`/port/${port.unlocode}`}>
          {portInformation}
        </Link>
      );
    }
  };

  return (
    <div
      className="PortVesselItem border-b border-neutral w-full p-2 cursor-pointer"
      data-event={`port-${vessel.imo}`}
      onClick={onClickMarkerIconHandler}
    >
      <div className="flex justify-between w-full overflow-x-hidden">
        <div className="flex-1 flex flex-col">
          <span className="text-gray-400 flex items-center justify-between">
            <span className="flex items-center">
              <ClockIcon className="h-4 w-4" />
              <span className="ml-1">{dayjs.utc(vessel.positions?.[0].timestamp).format('YYYY-MM-DD HH:mm')} UTC</span>
            </span>
          </span>
          <h1 className={`text-lg font-medium ${!vessel.imo ? 'opacity-50' : ''}`}>
            {vessel.imo ? (
              <Link to={`/vessel/${vessel.imo}`} target="_blank" rel="noopener noreferrer" className="hover:underline">
                {vessel.vessel?.name}
              </Link>
            ) : (
              <span title="Vessel page is not available for vessels without an IMO number">{vessel.vessel?.name}</span>
            )}
          </h1>
          <span className="text-gray-400 text-xs">
            {`${vessel.vessel?.type_name} | ${vessel.imo ? 'IMO ' + vessel.imo : 'MMSI ' + vessel.mmsi}`}
            <a
              title="Link to CIB"
              hidden={!vessel.installation_id}
              href={`https://cib.data.wartsila.com/installations/${vessel.installation_id}`}
            >
              <span> | </span>
              <span className="hover:underline">ID {vessel.installation_id}</span>
            </a>
          </span>
        </div>
        <img
          alt="Vessel marker"
          src="/assets/images/positions/position-direction.svg"
          className="h-10 w-10 mr-2 mt-1 cursor-pointer"
          style={{ transform: `rotate(${vessel.positions?.[0].heading}deg)` }}
          title="Show on map"
        ></img>
      </div>
      {vessel.positions?.[0].status_id !== 15 && (
        <div className="flex items-center my-0.5">
          <img src="/assets/images/life-buoy.svg" alt="life buoy icon" className="w-4 h-4 mx-0.5" />
          <span className="ml-1.5 text-sm">{vessel.positions?.[0].status_desc}</span>
        </div>
      )}

      {status === PortStatus.Arriving && (
        <>
          {vessel.positions?.[0].voyage?.eta && (
            <p>ETA: {`${dayjs.utc(vessel.positions[0].voyage.eta).format('YYYY-MM-DD HH:mm')} UTC`}</p>
          )}
          {!!vessel.ports?.last_port && <div>Last port: {renderPortLink(vessel.ports?.last_port)}</div>}
        </>
      )}
      {status === PortStatus.Departing && !!vessel.ports?.next_port && (
        <div>Next port: {renderPortLink(vessel.ports?.next_port)}</div>
      )}
      {status === PortStatus.InPort && (
        <>
          {!!vessel.ports?.last_port && vessel.ports?.last_port?.id !== vessel.ports?.current_port?.id && (
            <div>Last port: {renderPortLink(vessel.ports?.last_port)}</div>
          )}
          {!!vessel.ports?.next_port && vessel.ports?.next_port?.id !== vessel.ports?.current_port?.id && (
            <div>Next port: {renderPortLink(vessel.ports?.next_port)}</div>
          )}
        </>
      )}
    </div>
  );
};
