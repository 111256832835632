import React from 'react';

type Props = {
  className?: string;
};

export const Star: React.FC<Props> = ({ className = 'h-5 w-5' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    className={className}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
  >
    <polygon points="42 19.96 29.56 18.15 24 6.88 18.44 18.15 6 19.96 15 28.73 12.88 41.12 24 35.27 35.13 41.12 33 28.73 42 19.96" />
  </svg>
);
