import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ChevronLeft } from '../../common/components/icons/ChevronLeft';
import { ChevronRight } from '../../common/components/icons/ChevronRight';
import { ExclamationTriangleIcon } from '../../common/components/icons/ExclamationTriangleIcon';
import { useAppSelector } from '../../common/hooks';
import { useGetVesselChangesIhsQuery } from '../../store/apis/vessel-event-api';
import { EventFeed } from '../event-feed/EventFeed';
import { EventOptionValue } from '../event-filters/slice';
import { LayersLegend } from '../map-layers/LayersLegend';
import { LayersMenuCompact } from '../map-layers/LayersMenuCompact';
import { SwitchMapStyle } from '../switch-map-style/SwitchMapStyle';
import { VesselMapDateSelect } from '../vessel-map-date-select/VesselMapDateSelect';
import { VesselMapZoom } from '../vessel-map-zoom/VesselMapZoom';
import { VesselMap } from '../vessel-map/VesselMap';
import { DataSources } from './components/DataSources';
import { Equipment } from './components/Equipment';
import { InstallationPartners } from './components/InstallationPartners';
import { InternalUseText } from './components/InternalUseText';
import { LastPosition } from './components/LastPosition';
import { RelatedCompanies } from './components/RelatedCompanies';
import { VesselDetails } from './components/VesselDetails';
import {
  useDatesFromLastPositionConditionally,
  useGetLatestVesselPositionQuery,
  useSetupFiltersForVesselDetailsView,
  useUpdateDatesWhenSelectedEventChanges,
  useUpdateUrlWhenSelectedEventChanges,
} from './hooks';
import { useInstallationsGetQuery } from '../../store/apis/installation-api';
import { DownloadPositions } from '../download-positions/DownloadPositions';

export const VesselDetailsView: React.FC = () => {
  const map = useAppSelector((state) => state.vesselMap.mapInstance);
  let { imo, eventType } = useParams<{ imo: string; eventType: EventOptionValue }>();
  useSetupFiltersForVesselDetailsView(Number(imo), eventType);
  useDatesFromLastPositionConditionally(Number(imo), eventType);
  useUpdateDatesWhenSelectedEventChanges();
  useUpdateUrlWhenSelectedEventChanges(imo as string);

  const [isOpen, setIsOpen] = useState(true);
  const lastPosition = useGetLatestVesselPositionQuery(Number(imo));
  const ihsEventsQuery = useGetVesselChangesIhsQuery({ imoNumber: `eq.${imo?.toString()}` }, { skip: !imo });
  const wartsilaDataQuery = useInstallationsGetQuery({ imoNumber: Number(imo), fields: 'id' });
  const installationId = wartsilaDataQuery.currentData?.[0]?.id;

  if (!imo) {
    return null;
  }

  return (
    <div
      className={`VesselDetailsView h-full bg-primary text-primary-content flex justify-between flex-col lg:flex-row ${
        isOpen ? 'p-3' : 'py-3 px-1'
      }`}
    >
      {isOpen ? (
        <div className="flex-grow lg:max-w-[26rem] flex flex-col">
          <div className="flex flex-row justify-between">
            <VesselDetails imo={imo} installationId={installationId} />
            <div
              className="cursor-pointer hover:bg-secondary rounded py-1 px-2 text-xs mb-1 w-fit h-fit"
              onClick={() => setIsOpen(!isOpen)}
              title="Hide vessel information"
            >
              <ChevronLeft />
            </div>
          </div>
          <div className="flex flex-col flex-grow justify-between overflow-auto mt-1">
            <div className="mr-2">
              <RelatedCompanies imo={imo} className="mt-3" />
              <InstallationPartners installationId={installationId} className="mt-3" />
              <Equipment installationId={installationId} className="mt-3" />
            </div>
            <div className="">
              <DataSources imo={imo} />
              <InternalUseText className="mt-3 mr-2 text-stone-400" />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer hover:bg-secondary rounded py-1 px-2 text-xs mb-1 w-fit h-fit"
          onClick={() => setIsOpen(!isOpen)}
          title="Show vessel information"
        >
          <ChevronRight />
        </div>
      )}
      {!lastPosition.isFetching &&
        (!!ihsEventsQuery.currentData?.length || !!lastPosition.data?.results?.length ? (
          <>
            {!!lastPosition.data?.results?.length && (
              <div id="map-container" className="flex-grow min-h-[35rem] lg:min-h-[unset] mx-2 my-2 lg:my-0 relative">
                <div className="absolute right-0 top-0 z-10">
                  <VesselMapDateSelect className="m-1" />
                </div>
                <div className="absolute left-0 top-0 z-10 m-1 hidden sm:block">
                  <div className="flex flex-col items-center">
                    <SwitchMapStyle className="flex mb-1" originPage="vessel" />
                    <LayersMenuCompact className="flex mb-1" />
                    <DownloadPositions className="flex mb-1" imo={imo} />
                  </div>
                </div>
                <div className="absolute right-0 bottom-0 z-10 m-1">
                  <LayersLegend className="mt-1 rounded-[4px]" />
                </div>
                <div className="absolute left-0 bottom-0 z-10 m-1">
                  <div className="flex flex-col items-center">
                    <SwitchMapStyle className="flex mb-1 sm:hidden" originPage="vessel" />
                    <LayersMenuCompact className="flex mb-1 sm:hidden" />
                    <DownloadPositions className="flex mb-1 sm:hidden" imo={imo} />
                    <VesselMapZoom map={map} />
                  </div>
                </div>
                <VesselMap imo={imo!} />
              </div>
            )}
            <div className="flex-grow basis-0 lg:max-w-[26rem] max-w-[unset] flex flex-col">
              <LastPosition imo={imo!} className="border-b border-secondary pb-2" />
              <EventFeed className="flex-grow overflow-auto min-h-[35rem] lg:min-h-[unset]" />
            </div>
          </>
        ) : (
          <div className="mt-20 w-full justify-center flex">
            <ExclamationTriangleIcon className="mr-2 h-6" /> No AIS location data is available for this vessel!
          </div>
        ))}
    </div>
  );
};
