import React, { useEffect } from 'react';

import { Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { fetchConfig } from '../../common/slice';

type Props = {
  children?: React.ReactNode;
};

export const AuthenticationWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const config = useAppSelector((state) => state.common.config);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  if (!config) {
    return null;
  }

  const authConfiguration: Configuration = {
    auth: {
      redirectUri: '/',
      clientId: config.CLIENT_ID,
      authority: `https://login.microsoftonline.com/${config.TENANT_ID}`,
    },
    cache: {
      cacheLocation: 'sessionStorage',
    },
    system: {
      tokenRenewalOffsetSeconds: 900,
      iframeHashTimeout: 15000,
    },
  };

  const msalInstance = new PublicClientApplication(authConfiguration);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
