import React, { useState } from 'react';

import { Checkbox } from '../../common/components/Checkbox';
import { ChevronLeft } from '../../common/components/icons/ChevronLeft';
import { ChevronRight } from '../../common/components/icons/ChevronRight';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { setEnabledInEventsPageAndSave } from './slice';

type Props = {
  className?: string;
  minimizedClassName?: string;
};

export const Polling: React.FC<Props> = ({ className, minimizedClassName }) => {
  const dispatch = useAppDispatch();
  const [minimized, setMinimized] = useState(false);
  const { eventsPageEnablePolling } = useAppSelector((state) => state.polling);

  if (minimized) {
    return (
      <div
        className={`Polling ${minimizedClassName ? minimizedClassName : ''}`}
        title={`Auto refresh ${eventsPageEnablePolling ? 'enabled' : 'disabled'}`}
      >
        <ChevronRight className="h-5 w-5 cursor-pointer" onClick={() => setMinimized(false)} />
      </div>
    );
  }

  return (
    <div className={`Polling ${className ? className : ''}`} title="Update map with latest data every 5 minutes">
      <Checkbox
        inputClassName="checkbox-secondary bg-primary"
        value={eventsPageEnablePolling}
        onChange={(value) => dispatch(setEnabledInEventsPageAndSave(value))}
      >
        <div className="w-full flex justify-between items-center">
          <p
            className="ml-2 cursor-pointer"
            onClick={() => dispatch(setEnabledInEventsPageAndSave(!eventsPageEnablePolling))}
          >
            Auto refresh
          </p>
          <ChevronLeft className="h-5 w-5 cursor-pointer" onClick={() => setMinimized(true)} />
        </div>
      </Checkbox>
    </div>
  );
};
