import Slider from 'rc-slider';
import React from 'react';

import { QuestionCircleIcon } from '../../../../common/components/icons/QuestionCircleIcon';
import { Select } from '../../../../common/components/Select';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { themeColors } from '../../../../common/utils';
import { ConfidenceOption, setState } from '../../../event-filters/slice';
import { anomalySortOptions, setAnomalySorting } from '../../slice';

const defaultStyles: React.CSSProperties = {
  backgroundColor: themeColors.primary,
  color: themeColors.primaryContent,
  borderColor: themeColors.secondary,
};

const sliderStyles: Record<string, React.CSSProperties> = {
  dotStyle: {
    display: 'none',
  },
  handleStyle: {
    ...defaultStyles,
    boxShadow: 'none',
    top: '8px',
    opacity: 1,
  },
  trackStyle: {
    ...defaultStyles,
    backgroundColor: themeColors.secondary,
    height: '10px',
  },
  railStyle: {
    ...defaultStyles,
    height: '10px',
  },
  activeDotStyle: {
    display: 'none',
  },
};

type Props = {
  className?: string;
};

export const AnomalyFilters: React.FC<Props> = ({ className }) => {
  const state = useAppSelector((state) => state.eventFilters);
  const anomalySorting = useAppSelector((state) => state.eventFeed.anomalySorting);
  const dispatch = useAppDispatch();

  const marks = state.confidenceOptions.reduce((marks, confidenceOption) => {
    return {
      ...marks,
      [confidenceOption.value]: {
        label: confidenceOption.label,
        style: { color: '#808080', whiteSpace: 'nowrap' },
      },
    };
  }, {});

  return (
    <div className={`AnomalyFilters ${className ? className : ''}`}>
      <Select
        value={state.selectedCharacteristicOptions}
        options={state.characteristicOptions}
        onChange={(value) => dispatch(setState({ ...state, selectedCharacteristicOptions: value }))}
        isClearable
        className="mb-2"
        placeholder="Filter by characteristic"
        isMulti
      />
      <div className="pb-10">
        <div className="flex items-start">
          <p className="text-sm ml-2 mr-1 mb-2 text-[#808080]">Filter by confidence</p>
          <span
            className="mb-5"
            title="Confidence represents how much the vessel activity resembles activity during previous casualties"
          >
            <QuestionCircleIcon className="h-3 w-3 fill-[#808080] hover:fill-slate-200" />
          </span>
        </div>

        <Slider
          key={`${state.selectedConfidenceMin.value}${state.selectedConfidenceMax.value}`}
          className="!w-[85%] mx-auto select-none"
          allowCross={false}
          dotStyle={sliderStyles.dotStyle}
          handleStyle={sliderStyles.handleStyle}
          trackStyle={sliderStyles.trackStyle}
          railStyle={sliderStyles.railStyle}
          activeDotStyle={sliderStyles.activeDotStyle}
          range
          dots
          marks={marks}
          defaultValue={[state.selectedConfidenceMin.value, state.selectedConfidenceMax.value]}
          min={state.confidenceOptions[0].value}
          max={state.confidenceOptions[state.confidenceOptions.length - 1].value}
          onAfterChange={(values) => {
            const [min, max] = values as number[];
            const selectedConfidenceMin = state.confidenceOptions.find(
              (option) => option.value === min
            ) as ConfidenceOption;
            const selectedConfidenceMax = state.confidenceOptions.find(
              (option) => option.value === max
            ) as ConfidenceOption;
            dispatch(setState({ ...state, selectedConfidenceMin, selectedConfidenceMax }));
          }}
        />
      </div>
      <div className="text-[#808080] flex items-center">
        <p className="mr-2 text-sm">Sort:</p>

        <Select
          value={anomalySorting}
          options={anomalySortOptions}
          onChange={(value) => dispatch(setAnomalySorting(value))}
        />
      </div>
    </div>
  );
};
