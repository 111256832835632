import { Config } from '../../common/slice';

export const setupFavicon = (config: Config) => {
  const faviconEl = document.querySelector<HTMLLinkElement>('link#favicon');
  if (!faviconEl) {
    return;
  }

  switch (config.ENVIRONMENT) {
    case 'local':
      faviconEl.href = '/assets/favicons/csi-dev.png';
      break;
    case 'dev':
      faviconEl.href = '/assets/favicons/csi-dev.png';
      break;
    case 'test':
      faviconEl.href = '/assets/favicons/csi-test.png';
      break;
    default:
      faviconEl.href = '/assets/favicons/csi-prod.png';
      break;
  }
};
