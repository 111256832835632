import 'mapbox-gl/dist/mapbox-gl.css';
import './styles.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MapGL, { MapLayerMouseEvent, MapRef, Marker, Popup, ViewState } from 'react-map-gl';

import { useAppDispatch, useAppSelector, useGetFilteredEvents } from '../../common/hooks';
import { Anomalies, Casualties, Drydockings } from '../../store/apis/vessel-event-api';
import { EventType, setSelectedEvent, setSelectedTab } from '../event-feed/slice';
import { scrollToEventInFeed } from '../event-feed/utils';
import { setTitleAndUpdate } from '../title/slice';
import { useGetPollingInterval } from './hooks';
import { setMapInstance as setMapInstanceAction } from './slice';

export const Map: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedEvent = useAppSelector((state) => state.eventFeed.selectedEvent);
  const pollingInterval = useGetPollingInterval();
  const { anomalies, casualties, drydockings } = useGetFilteredEvents(pollingInterval);

  const [mapInstance, setMapInstance] = useState<MapRef | null>(null);
  const { MAPBOX_STYLE: mapStyle, MAPBOX_ACCESS_TOKEN: mapboxAccessToken } = useAppSelector(
    (state) => state.common.config!
  );

  useEffect(() => {
    if (!mapInstance) return;
    dispatch(setMapInstanceAction(mapInstance));
  }, [dispatch, mapInstance]);

  useEffect(() => {
    dispatch(setTitleAndUpdate());
  }, [dispatch]);

  const initialMapState: Partial<ViewState> = useMemo(
    () => ({
      latitude: 35,
      longitude: 0,
      zoom: 1.5,
    }),
    []
  );

  const onMarkerClickHandler = useCallback(
    async (
      marker: Anomalies | Casualties | Drydockings,
      type: EventType,
      selected: boolean,
      originalEvent: MouseEvent
    ) => {
      originalEvent.stopPropagation();
      if (!selected) {
        await dispatch(setSelectedEvent({ id: marker.id as number, type }));
        await dispatch(setSelectedTab(type));
        scrollToEventInFeed(type, marker.id as number);
        return;
      }
      dispatch(setSelectedEvent(null));
    },
    [dispatch, selectedEvent]
  );

  const handleMapClick = useCallback(
    (e: MapLayerMouseEvent) => {
      e.originalEvent.stopPropagation();
      if (selectedEvent) {
        dispatch(setSelectedEvent(null));
      }
    },
    [dispatch, selectedEvent]
  );

  const getMarkers = useCallback(
    (items: Anomalies[] | Casualties[] | Drydockings[], type: EventType, imgSrc: string, zIndex: number) =>
      items.map((item) => {
        const selected = item.id === selectedEvent?.id && type === selectedEvent?.type;
        return (
          item.longitude !== null &&
          item.latitude &&
          item.longitude && (
            <Marker
              key={item.id}
              longitude={item.longitude}
              latitude={item.latitude}
              anchor="bottom"
              style={{ zIndex }}
              onClick={(e) => onMarkerClickHandler(item, type, selected, e.originalEvent)}
            >
              <img alt="marker" src={imgSrc} className={`w-[22px] cursor-pointer`} title={item.vessel_name} />
              {selected && (
                <Popup
                  closeButton={false}
                  closeOnClick={false}
                  latitude={item.latitude as number}
                  longitude={item.longitude as number}
                  anchor="top"
                  className="map__marker__popup"
                >
                  {item.vessel_name}
                </Popup>
              )}
            </Marker>
          )
        );
      }),
    [onMarkerClickHandler, selectedEvent?.id, selectedEvent?.type]
  );

  const anomalyMarkers = useMemo(() => {
    if (!anomalies.currentData || anomalies.isFetching) {
      return null;
    }
    return getMarkers(anomalies.currentData as Anomalies[], 'anomaly', '/assets/images/markers/anomaly.svg', 2);
  }, [anomalies.currentData, anomalies.isFetching, getMarkers]);

  const casualtyMarkers = useMemo(() => {
    if (!casualties.currentData || casualties.isFetching) {
      return null;
    }
    return getMarkers(casualties.currentData as Casualties[], 'casualty', '/assets/images/markers/casualty.svg', 3);
  }, [casualties.currentData, casualties.isFetching, getMarkers]);

  const drydockMarkers = useMemo(() => {
    if (!drydockings.currentData || drydockings.isFetching) {
      return null;
    }
    return getMarkers(drydockings.currentData as Drydockings[], 'dry-dock', '/assets/images/markers/drydock.svg', 1);
  }, [drydockings.currentData, drydockings.isFetching, getMarkers]);

  return (
    <MapGL
      initialViewState={initialMapState}
      ref={setMapInstance}
      onClick={handleMapClick}
      mapStyle={mapStyle}
      mapboxAccessToken={mapboxAccessToken}
      dragRotate={false}
      touchZoomRotate={false}
    >
      {anomalyMarkers}
      {casualtyMarkers}
      {drydockMarkers}
    </MapGL>
  );
};
