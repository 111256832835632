import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Star } from '../../../common/components/icons/Star';

import { Spinner } from '../../../common/components/Spinner';
import { useAppDispatch } from '../../../common/hooks';
import {
  useCreateVesselInFleetFleetPostMutation,
  useDeleteVesselFromFleetFleetImoNumberDeleteMutation,
  useGetVesselInFleetFleetImoNumberGetQuery,
} from '../../../store/apis/csi-api';
import { setSelectedEvent } from '../../event-feed/slice';
import { setSelectedTimeRangeOption, setDatesAndNullUrl } from '../../vessel-map-date-select/slice';
import { setSelectedPositionTimestamp } from '../../vessel-map/slice';
import { useGetDetailedShipdataQuery } from '../hooks';
import { getSearchCookies } from '../../vessel-search/slice';
import { setCookie } from '../utils';
import { setTitleAndUpdate } from '../../title/slice';

type Props = {
  imo: string;
  installationId?: number;
  className?: string;
};

export const VesselDetails: React.FC<Props> = ({ imo, installationId, className }) => {
  const shipDataQuery = useGetDetailedShipdataQuery(imo);
  const isVesselInFleetQuery = useGetVesselInFleetFleetImoNumberGetQuery({ imoNumber: Number(imo) });
  const [addToFleet] = useCreateVesselInFleetFleetPostMutation();
  const [deleteVessel] = useDeleteVesselFromFleetFleetImoNumberDeleteMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCookie(
      imo,
      shipDataQuery?.currentData?.shipname,
      shipDataQuery?.currentData?.shiptypelevel4,
      shipDataQuery?.currentData?.yearofbuild
    );
    dispatch(getSearchCookies());
  }, [shipDataQuery.currentData]);

  useEffect(() => {
    if (shipDataQuery.currentData && !shipDataQuery.isFetching && !shipDataQuery.currentData.shipname) return;
    dispatch(setTitleAndUpdate(shipDataQuery.currentData?.shipname!));
  }, [shipDataQuery.currentData, dispatch]);

  if (shipDataQuery.isFetching) {
    return <Spinner className="h-6 w-6" />;
  }

  const onCurrentLocationHandler = async () => {
    const currentDate = dayjs.utc(new Date()).format('YYYY-MM-DD');
    await dispatch(setSelectedEvent(null));
    dispatch(setSelectedTimeRangeOption(null));
    dispatch(setSelectedPositionTimestamp(null));
    dispatch(
      setDatesAndNullUrl({
        startDate: currentDate,
        endDate: currentDate,
      })
    );
  };

  const handleAddToFleet = async () => {
    if (isVesselInFleetQuery.currentData?.vessel_in_fleet) {
      await deleteVessel({ imoNumber: Number(imo) });
      toast('Vessel removed from fleet');
    } else {
      await addToFleet({ imoNumber: Number(imo) });
      toast.success('Vessel added to your fleet');
    }
    isVesselInFleetQuery.refetch();
  };

  const renderStatus = (value: string) => {
    if (value === 'Total Loss' || value === 'Broken Up') {
      return <span className="text-error">{value}</span>;
    } else {
      return <span>{value}</span>;
    }
  };

  const vessel = shipDataQuery?.currentData || null;

  if (!vessel) {
    return (
      <div className={`VesselDetails ${className ? className : ''}`}>
        <p>No vessel details available</p>
      </div>
    );
  }

  return (
    <div className={`VesselDetails ${className ? className : ''}`}>
      <div className="flex items-center">
        <h1 className="text-2xl cursor-pointer" onClick={onCurrentLocationHandler} title="Show current position">
          {vessel.shipname}
        </h1>
        {vessel.lrimoshipno &&
          (isVesselInFleetQuery.currentData?.fleet_type === 'automatic' ? (
            <span title="Vessel is in your automatically generated fleet">
              <Star className="stroke-primary-content h-5 w-5 ml-2 fill-yellow-500" />
            </span>
          ) : (
            (isVesselInFleetQuery.currentData?.fleet_type === 'manual' ||
              !isVesselInFleetQuery.currentData?.vessel_in_fleet) && (
              <span
                onClick={handleAddToFleet}
                title={
                  isVesselInFleetQuery.currentData?.vessel_in_fleet ? 'Remove vessel from My Fleet' : 'Add to My Fleet'
                }
              >
                <Star
                  className={`stroke-primary-content h-5 w-5 ml-2 cursor-pointer ${
                    isVesselInFleetQuery.currentData?.vessel_in_fleet && 'fill-yellow-500'
                  }`}
                />
              </span>
            )
          ))}
      </div>
      <div className="text-stone-400">
        {vessel.shiptypelevel4} |{' '}
        <span className={`fi fi-${vessel.flagcodes?.iso2?.toLowerCase()}`} title={vessel.flagname} /> {vessel.flagname}
      </div>
      <div className="text-stone-400">
        <>
          {vessel.shipstatus && renderStatus(vessel.shipstatus)} | {vessel.yearofbuild}
          {vessel.deathdate && ` - ${vessel.deathdate.substring(0, 4)}`}
        </>
      </div>
      <div className="text-stone-400">
        IMO {vessel.lrimoshipno}{' '}
        {vessel.maritimemobileserviceidentitymmsinumber && `| MMSI ${vessel.maritimemobileserviceidentitymmsinumber}`}{' '}
        {installationId && (
          <>
            |{' '}
            <a
              className="hover:underline"
              target="_blank"
              href={`https://cib.data.wartsila.com/installations/${installationId}`}
              rel="noreferrer"
              title="Link to CIB"
            >
              ID {installationId}
            </a>
          </>
        )}
      </div>
    </div>
  );
};
