import React from 'react';

import { Spinner } from '../../../../common/components/Spinner';
import { useAppSelector } from '../../../../common/hooks';
import { Anomalies } from '../../../../store/apis/vessel-event-api';
import { SelectedEvent } from '../../slice';
import { getAnomalySortingWeight } from '../../utils';
import { AnomalyItem } from './AnomalyItem';

type Props = {
  anomalies?: Anomalies[];
  isFetching: boolean;
  selectedEvent: SelectedEvent;
};

export const AnomalyList: React.FC<Props> = ({ anomalies, isFetching, selectedEvent }) => {
  const state = useAppSelector((state) => state.eventFeed);

  const sortAnomalies = (anomalies: Anomalies[] | undefined) => {
    if (!anomalies) return;
    if (state.anomalySorting.value === 'chronological') return anomalies;
    return [...anomalies]
      .map((anomaly) => {
        return { ...anomaly, weight: getAnomalySortingWeight(anomaly) };
      })
      .sort((a, b) => b.weight - a.weight);
  };

  return (
    <div className="AnomalyList">
      {isFetching ? (
        <div className="flex items-center justify-center h-full">
          <Spinner className="my-10 h-6 w-6" />
        </div>
      ) : (
        sortAnomalies(anomalies)?.map((anomaly) => {
          const selected = anomaly.id === selectedEvent?.id && selectedEvent?.type === 'anomaly';
          return <AnomalyItem key={anomaly.id} anomaly={anomaly} selected={selected} />;
        })
      )}
    </div>
  );
};
