import { GetShipdataApiArg, useGetShipdataQuery } from '../../store/apis/ihs-vessel-data-api';

const defaultQueryParams: Partial<GetShipdataApiArg> = {
  limit: '10',
  select:
    'lrimoshipno,shipname,shipstatuscode,shipstatus,yearofbuild,flagcode,shiptypelevel4,maritimemobileserviceidentitymmsinumber',
};

export const useSearchVesselsQuery = (input: string) => {
  const queryParams = !!parseInt(input)
    ? { ...defaultQueryParams, lrimoshipno: `ilike.*${input.trim()}*` }
    : { ...defaultQueryParams, shipname: `ilike.*${input}*` };
  if (!!!parseInt(input) || input.trim().length !== 7) {
    queryParams.shipstatuscode = 'not.in.(D,K,M)';
  }
  return useGetShipdataQuery({ ...queryParams }, { skip: !input });
};
