import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapRef } from 'react-map-gl';
import { addSearchParamsToUrl } from '../../common/utils';
import { getBoxCoordinatesFromURL, getCoordinatesFromURL, getPolygonUpdateState } from './utils';
import { Polygon } from 'geojson';
import { AccountInfo } from '@azure/msal-browser';
import { BaseSelectOption } from '../../common/types';

export const setCoordinatesAndUpdateUrl = createAsyncThunk<void, Coordinates>(
  'portView/setCoordinatesAndUpdateUrl',
  async (arg, { dispatch }) => {
    dispatch(setLocationCoordinates(arg));
    const params = new URLSearchParams();
    params.set('latitude', arg.latitude.toString());
    params.set('longitude', arg.longitude.toString());
    addSearchParamsToUrl(params);
  }
);

export const setBoxCoordinatesAndUpdateUrl = createAsyncThunk<void, BoxCoordinates>(
  'portView/setBoxCoordinatesAndUpdateUrl',
  async (arg, { dispatch }) => {
    dispatch(setLocationBoxCoordinates(arg));
    const params = new URLSearchParams();
    params.set('maxLatitude', arg.maxLat.toString());
    params.set('maxLongitude', arg.maxLon.toString());
    params.set('minLatitude', arg.minLat.toString());
    params.set('minLongitude', arg.minLon.toString());
    addSearchParamsToUrl(params);
  }
);

export enum PortStatus {
  InPort = 'inPort',
  Arriving = 'arriving',
  Departing = 'departing',
}

export enum PortVesselFilterOptions {
  Wartsila = 'wartsila',
  IMO = 'imo',
  Global = 'global',
}

export type Coordinates = {
  longitude: number;
  latitude: number;
};

type PolygonPermissionParams = {
  user: AccountInfo | null;
  polygonEditors?: string[];
};

export const portVesselFilterSelectOptions: BaseSelectOption[] = [
  {
    label: 'Global fleet',
    value: PortVesselFilterOptions.Global,
  },
  {
    label: 'IMO fleet',
    value: PortVesselFilterOptions.IMO,
  },
  {
    label: 'Wärtsilä fleet',
    value: PortVesselFilterOptions.Wartsila,
  },
];

export type BoxCoordinates = {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
};

export type PortPageState = {
  isSearchOpen: Boolean;
  mapInstance: MapRef | null;
  unlocode?: string;
  selected?: number;
  selectedTab: PortStatus;
  portVesselFilter: BaseSelectOption;
  locationCoordinates?: Coordinates;
  locationBoxCoordinates?: BoxCoordinates;
  portPolygon?: Polygon;
  updatePolygon: boolean;
};
const initialState: PortPageState = {
  isSearchOpen: false,
  mapInstance: null,
  selectedTab: PortStatus.InPort,
  portVesselFilter: portVesselFilterSelectOptions[1],
  locationCoordinates: getCoordinatesFromURL(),
  locationBoxCoordinates: getBoxCoordinatesFromURL(),
  updatePolygon: false,
};

const slice = createSlice({
  name: 'eventFilters',
  initialState,
  reducers: {
    setIsSearchOpen(state, action: PayloadAction<PortPageState['isSearchOpen']>) {
      state.isSearchOpen = action.payload;
    },
    setMapInstance(state, action: PayloadAction<PortPageState['mapInstance']>) {
      state.mapInstance = action.payload;
    },
    setUnlocode(state, action: PayloadAction<PortPageState['unlocode']>) {
      state.unlocode = action.payload;
    },
    setSelectedVessel(state, action: PayloadAction<PortPageState['selected']>) {
      state.selected = action.payload;
    },
    setSelectedTab(state, action: PayloadAction<PortPageState['selectedTab']>) {
      state.selectedTab = action.payload;
    },
    setPortVesselFilter(state, action: PayloadAction<PortPageState['portVesselFilter']>) {
      state.portVesselFilter = action.payload;
    },
    setLocationCoordinates(state, action: PayloadAction<PortPageState['locationCoordinates']>) {
      state.locationCoordinates = action.payload;
    },
    setLocationBoxCoordinates(state, action: PayloadAction<PortPageState['locationBoxCoordinates']>) {
      state.locationBoxCoordinates = action.payload;
    },
    setPortPolygon(state, action: PayloadAction<PortPageState['portPolygon']>) {
      state.portPolygon = action.payload;
    },
    setUpdatePortPolygonPermissions(state, action: PayloadAction<PolygonPermissionParams>) {
      state.updatePolygon = getPolygonUpdateState(action.payload.user, action.payload.polygonEditors);
    },
  },
});

export const {
  setIsSearchOpen,
  setMapInstance,
  setUnlocode,
  setSelectedVessel,
  setSelectedTab,
  setPortVesselFilter,
  setLocationCoordinates,
  setLocationBoxCoordinates,
  setPortPolygon,
  setUpdatePortPolygonPermissions,
} = slice.actions;
export const { reducer } = slice;
