import React from 'react';

type Props = {
  children: React.ReactNode;
  show: boolean;
  className?: string;
};

export const Modal: React.FC<Props> = ({ children, show, className }) => (
  <div className={`modal ${show ? 'modal-open' : ''}`}>
    <div className={`modal-box relative h-fit ${className ? className : ''}`}>{children}</div>
  </div>
);
