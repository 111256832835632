import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { setSelectedEvent, setShowFilters } from '../event-feed/slice';
import { resetStateFromUrl } from '../event-filters/slice';

export const useSetupFiltersForMapView = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetStateFromUrl());
    dispatch(setShowFilters(true));
  }, [dispatch]);
};

export const useClearSelectedEventOnFilterChange = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.eventFilters);

  useEffect(() => {
    dispatch(setSelectedEvent(null));
  }, [dispatch, state]);
};
