import React from 'react';

import { Spinner } from '../../../common/components/Spinner';
import { Anomalies, Casualties, Drydockings } from '../../../store/apis/vessel-event-api';
import { SelectedEvent } from '../slice';
import { scrollToEventInFeed } from '../utils';
import { AnomalyItem } from './anomaly-components/AnomalyItem';
import { CasualtyItem } from './casualty-components/CasualtyItem';
import { DryDockingItem } from './drydocking-components/DryDockingItem';
import { IhsEventItem } from './ihs-components/IhsEventItem';

type Props = {
  anomalies?: Anomalies[];
  dryDockings?: Drydockings[];
  casualties?: Casualties[];
  isFetching: boolean;
  selectedEvent: SelectedEvent;
  events: any[] | null;
};

export const VesselEventList: React.FC<Props> = ({ isFetching, events, selectedEvent }) => {
  selectedEvent && scrollToEventInFeed(selectedEvent.type, selectedEvent.id);

  return (
    <div className="VesselEventList">
      {isFetching ? (
        <div className="flex items-center justify-center h-full">
          <Spinner className="my-10 h-6 w-6" />
        </div>
      ) : (
        events
          ?.sort(
            (a, b) =>
              new Date(b.casualty_date || b.start_datetime || b.valid_from).valueOf() -
              new Date(a.casualty_date || a.start_datetime || a.valid_from).valueOf()
          )
          .map((event) => {
            if (event.valid_from) {
              return <IhsEventItem key={event.id} event={event} />;
            } else if (event.casualty_date) {
              const selected = event.id === selectedEvent?.id && selectedEvent?.type === 'casualty';
              return <CasualtyItem key={event.id} casualty={event} selected={selected} />;
            } else if (event.anomaly_confidence) {
              const selected = event.id === selectedEvent?.id && selectedEvent?.type === 'anomaly';
              return <AnomalyItem key={event.id} anomaly={event} selected={selected} />;
            } else {
              const selected = event.id === selectedEvent?.id && selectedEvent?.type === 'dry-dock';
              return <DryDockingItem key={event.id} dryDocking={event} selected={selected} />;
            }
          })
      )}
    </div>
  );
};
