import React from 'react';
import toast from 'react-hot-toast';

import { Checkbox } from '../../common/components/Checkbox';
import { Modal } from '../../common/components/Modal';
import { Select } from '../../common/components/Select';
import { Spinner } from '../../common/components/Spinner';
import { TextInput } from '../../common/components/TextInput';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { customColors } from '../../common/utils';
import {
  useCreateUserFilterUserFiltersPostMutation,
  useDeleteUserFilterUserFiltersFilterIdDeleteMutation,
  useGetUserFiltersUserFiltersGetQuery,
  useUpdateUserFilterUserFiltersFilterIdPutMutation,
} from '../../store/apis/csi-api';
import { userFilterEmailOptions } from '../event-filters/filter-options';
import { buildUserFilter } from '../event-filters/utils';
import {
  selectedUserFilterOptionSelector,
  setAllowFee,
  setEmailOption,
  setName,
  setSelectedUserFilterId,
  setShowForm,
} from './slice';
import { getFilterList } from './utils';

type Props = {
  className?: string;
};

export const UserFilterForm: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const eventFiltersState = useAppSelector((state) => state.eventFilters);
  const state = useAppSelector((state) => state.userFilters);
  const selectedUserFilterOption = useAppSelector(selectedUserFilterOptionSelector);
  const query = useGetUserFiltersUserFiltersGetQuery();
  const [createUserFilter, createUserFilterResult] = useCreateUserFilterUserFiltersPostMutation();
  const [updateUserFilter, updateUserFilterResult] = useUpdateUserFilterUserFiltersFilterIdPutMutation();
  const [deleteUserFilter, deleteUserFilterResult] = useDeleteUserFilterUserFiltersFilterIdDeleteMutation();
  const listOfSelectedFilters = getFilterList(eventFiltersState);

  const saveUserFilterHandler = async () => {
    try {
      if (!state.form.name) {
        return;
      }
      const userFilter = buildUserFilter(
        state.form.name,
        state.form.emailOption.value,
        eventFiltersState,
        selectedUserFilterOption?.id
      );

      if (selectedUserFilterOption?.id) {
        await updateUserFilter({ filterId: selectedUserFilterOption.id, userFilter }).unwrap();
      } else {
        const filter = await createUserFilter({
          userFilter,
        }).unwrap();
        dispatch(setSelectedUserFilterId(filter.id || null));
      }

      toast.success('Filter saved');
      query.refetch();
      dispatch(setShowForm(false));
    } catch (error) {
      toast.error('Could not save filter, please try again.');
    }
  };

  const deleteUserFilterHandler = async () => {
    if (!selectedUserFilterOption) {
      return;
    }

    if (!window.confirm(`Are your sure you want to delete "${selectedUserFilterOption.name}" filter?`)) {
      return;
    }

    try {
      await deleteUserFilter({ filterId: selectedUserFilterOption?.id as number }).unwrap();
      toast.success('Filter deleted');
      dispatch(setSelectedUserFilterId(null));
      query.refetch();
      dispatch(setShowForm(false));
    } catch (error) {
      toast.error('Could not delete filter, please try again.');
    }
  };

  const isSaveBtnDisabled =
    createUserFilterResult.isLoading ||
    updateUserFilterResult.isLoading ||
    !state.form.name ||
    (state.form.emailOption.value && !state.form.allowFee);

  return (
    <div className={`UserFilterForm ${className ? className : ''}`}>
      <Modal className="bg-primary/90" show={state.showForm}>
        <div>
          <h1 className="text-lg font-bold">Save filter and create email notifications</h1>
          <div className="flex flex-col bg-primary border border-neutral rounded p-2 mt-2 text-sm text-gray-400">
            {listOfSelectedFilters.map((i) => {
              return (
                <div key={i?.key}>
                  <span className="font-bold">{i?.key}: </span>
                  <span>{i?.value}</span>
                </div>
              );
            })}
          </div>
          <div className="mt-2 text-sm">Filter name</div>
          <TextInput
            className="mt-1"
            value={state.form.name}
            onChange={(value) => dispatch(setName(value))}
            placeholder="Filter name (required)"
          />
          <div className="mt-2 text-sm">Email alerts</div>
          <Select
            value={state.form.emailOption}
            options={userFilterEmailOptions}
            onChange={(value) => dispatch(setEmailOption(value))}
            // Apply 'hidden' class manually to prevent the select field from being partially visible while modal is fading out.
            className={`mt-1 ${state.showForm ? '' : 'hidden'}`}
            placeholder="Select email alert"
            menuPortalTarget={document.body}
          />

          {state.form.emailOption.value && (
            <Checkbox
              className="mt-2"
              inputClassName="checkbox-secondary bg-primary"
              value={state.form.allowFee}
              onChange={(value) => dispatch(setAllowFee(value))}
            >
              <p className="ml-2" onClick={() => dispatch(setAllowFee(!state.form.allowFee))}>
                I understand that starting from April 2022, there will be a total monthly fee of 4.50 EUR charged to my
                cost center for receiving e-mail alerts.
              </p>
            </Checkbox>
          )}

          <div className="flex mt-2 justify-between">
            <div>
              <button
                className={`btn btn-sm mr-2 bg-[#08435f] hover:bg-[#08435f]/70 disabled:text-gray-500`}
                style={{ borderColor: !isSaveBtnDisabled ? customColors.secondaryLight : undefined }}
                disabled={isSaveBtnDisabled}
                onClick={saveUserFilterHandler}
              >
                Save
                {(createUserFilterResult.isLoading || updateUserFilterResult.isLoading) && <Spinner className="ml-2" />}
              </button>
              <button className="btn btn-sm" onClick={() => dispatch(setShowForm(false))}>
                Cancel
              </button>
            </div>
            {!!selectedUserFilterOption?.id && (
              <button
                className="btn btn-sm btn-error disabled:text-gray-500"
                disabled={deleteUserFilterResult.isLoading}
                onClick={deleteUserFilterHandler}
              >
                Delete {deleteUserFilterResult.isLoading && <Spinner className="ml-2" />}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
