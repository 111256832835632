import React, { useEffect, useRef, useState } from 'react';

type Props = {
  className?: string;
  listClassName?: string;
  title?: string;
  icon: React.ReactNode;
  items: React.ReactNode;
};

export const IconDropdown: React.FC<Props> = ({ className, listClassName, title, icon, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeMenuIfClickedOutside = (e: MouseEvent) => {
      if (!(e.target instanceof Element)) {
        return;
      }
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', closeMenuIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', closeMenuIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} title={title}>
      <div className={`IconDropdown ${className ? className : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <div className="hover:bg-secondary bg-primary rounded-full p-1">{icon}</div>
      </div>
      {isOpen && (
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`absolute bg-primary rounded w-32 mt-1 ${listClassName ? listClassName : ''}`}
        >
          {items}
        </div>
      )}
    </div>
  );
};
