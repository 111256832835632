import React from 'react';
import toast from 'react-hot-toast';

import { Modal } from '../../common/components/Modal';
import { Spinner } from '../../common/components/Spinner';
import { TextAreaInput } from '../../common/components/TextAreaInput';
import { TextInput } from '../../common/components/TextInput';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { useCreateIssueIssuesPostMutation } from '../../store/apis/csi-api';
import { reset, setMessage, setShowForm, setSubject } from './slice';

type Props = {
  className?: string;
};

export const ReportProblemForm: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.reportProblem);
  const [createIssue, createIssueResult] = useCreateIssueIssuesPostMutation();

  const submitFormHandler = async () => {
    try {
      const url =
        window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search;
      await createIssue({
        issueRequest: { summary: `[Feedback] ${state.subject}`, text: state.message, url },
      }).unwrap();
      toast.success('Form submitted');
      dispatch(reset());
    } catch (error) {
      toast.error('Could not submit form, please try again.');
    }
  };

  return (
    <div className={`ReportProblemForm ${className ? className : ''}`}>
      <Modal className="bg-primary/90 text-white" show={state.showForm}>
        <div>
          <h1 className="text-lg font-bold">Report a problem</h1>
          <div className="mt-2 text-sm">Subject</div>
          <TextInput
            className="mt-1"
            value={state.subject}
            onChange={(value) => dispatch(setSubject(value))}
            placeholder="Summary"
          />
          <div className="mt-2 text-sm">Message</div>
          <TextAreaInput
            className="mt-1"
            value={state.message}
            onChange={(value) => dispatch(setMessage(value))}
            placeholder="Message"
            rows={3}
          />
          <div className="mt-2">
            <button
              className="btn btn-sm btn-secondary mr-2 disabled:text-gray-500"
              disabled={(!state.subject && !state.message) || createIssueResult.isLoading}
              onClick={submitFormHandler}
            >
              Send {createIssueResult.isLoading && <Spinner className="ml-2" />}
            </button>
            <button className="btn btn-sm" onClick={() => dispatch(setShowForm(false))}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
