import React from 'react';

import { BellIcon } from '../../common/components/icons/BellIcon';
import { ChevronDown } from '../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../common/components/icons/ChevronUp';
import { ExclamationTriangleIcon } from '../../common/components/icons/ExclamationTriangleIcon';
import { Select } from '../../common/components/Select';
import { TextInput } from '../../common/components/TextInput';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { useSetupUserFilterOptions } from '../user-filters/hooks';
import { selectedUserFilterOptionSelector, setSelectedUserFilterId, setShowForm } from '../user-filters/slice';
import { UserFilterForm } from '../user-filters/UserFilterForm';
import { useSetupMyFleetOption } from './hooks';
import { ProductOption, setState, toggleShowEventFilters } from './slice';
import { filteredTimeOptions, filteredVesselTypeOptions, vesselTypeStyleConfig } from './utils';

export const EventFilters: React.FC = () => {
  useSetupMyFleetOption();
  useSetupUserFilterOptions();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.eventFilters);
  const userFiltersState = useAppSelector((state) => state.userFilters);
  const selectedUserFilterOption = useAppSelector(selectedUserFilterOptionSelector);

  const showMyFilters = !!userFiltersState.userFilterOptions?.length;

  const formatOptionLabel = ({
    name,
    notification_type,
    settings,
  }: {
    name: string;
    notification_type: string;
    settings: any;
  }) => {
    let oldFilters;
    if (settings.products) {
      oldFilters = settings.products.filter((product: any) => product.startsWith('_'));
    }

    return (
      <div className="flex justify-between">
        <div
          title={oldFilters && !!oldFilters.length && `Outdated filters: ${oldFilters}`}
          className={`${oldFilters && !!oldFilters.length && 'text-error'} truncate flex flex-row`}
        >
          {name}
          {oldFilters && !!oldFilters.length && (
            <ExclamationTriangleIcon className="h-5 w-5 stroke-error self-center ml-2" />
          )}
        </div>
        {notification_type !== null && notification_type === 'email' && (
          <div title="Email notifications on" className="self-end">
            <BellIcon />
          </div>
        )}
      </div>
    );
  };

  const formatProductLabel = (filter: ProductOption) => (
    <>
      <p>
        {filter.label}{' '}
        {filter.count && (
          <>
            ({filter.count} {filter.count === 1 ? 'PRT' : 'PRTs'})
          </>
        )}{' '}
      </p>
      <p className="text-gray-500">{filter.type}</p>
    </>
  );

  return (
    <div className="EventFilters bg-primary/90 text-white p-2">
      <span
        className="flex items-center justify-between cursor-pointer"
        onClick={() => dispatch(toggleShowEventFilters())}
      >
        {showMyFilters ? 'My filters' : 'Filter criteria'} {state.isOpen ? <ChevronUp /> : <ChevronDown />}
      </span>
      {state.isOpen && showMyFilters && (
        <Select
          value={selectedUserFilterOption}
          options={userFiltersState.userFilterOptions || []}
          formatOptionLabel={formatOptionLabel}
          onChange={(value) => dispatch(setSelectedUserFilterId(value?.id || null))}
          isClearable
          className="mt-2"
          placeholder="Select filter"
        />
      )}
      {state.isOpen && (
        <div className="mt-2">
          {showMyFilters && <p className="mb-2">Filter criteria</p>}
          <Select
            value={state.selectedTimeRangeOption}
            options={filteredTimeOptions(state)}
            onChange={(value) => dispatch(setState({ ...state, selectedTimeRangeOption: value }))}
            className="mb-2"
            placeholder="Filter by time"
          />
          <Select
            value={state.selectedEventOption}
            options={state.eventOptions}
            onChange={(value) => dispatch(setState({ ...state, selectedEventOption: value }))}
            isClearable
            className="mb-2"
            placeholder="Filter by event"
          />
          <Select
            value={state.selectedFleetOption}
            options={state.fleetOptions}
            onChange={(value) => dispatch(setState({ ...state, selectedFleetOption: value }))}
            className="mb-2"
            placeholder="Filter by fleet"
          />
          <Select
            value={state.selectedVesselTypeOptions}
            options={filteredVesselTypeOptions(state)}
            closeMenuOnSelect={false}
            onChange={(value) => dispatch(setState({ ...state, selectedVesselTypeOptions: value }))}
            isMulti
            className="mb-2"
            placeholder="Filter by vessel type"
            styleConfig={vesselTypeStyleConfig}
          />
          <Select
            value={state.selectedProductOptions}
            options={state.productOptions}
            formatOptionLabel={formatProductLabel}
            onChange={(value) => dispatch(setState({ ...state, selectedProductOptions: value }))}
            isMulti
            className="mb-2"
            placeholder="Filter by product"
          />
          <TextInput
            value={state.selectedCustomerOption}
            onChange={(value) => dispatch(setState({ ...state, selectedCustomerOption: value }))}
            placeholder="Filter by customers (e.g. 1035,4410,AGR)"
            className="mb-2"
            useDebounce
          />
          <TextInput
            value={state.selectedLocationOption}
            transformInput={(value) => {
              let transformedValue = value.replace(/^,+|(,)+|[^a-zA-Z,]+/g, '$1');
              return transformedValue.toLocaleUpperCase();
            }}
            onChange={(value) => {
              dispatch(setState({ ...state, selectedLocationOption: value }));
            }}
            placeholder="Filter by locations (e.g. CA,USLAX,USLGB)"
            useDebounce
          />
          <button
            className={`btn btn-sm bg-[#184f69] hover:bg-[#184f69]/70 border-[#285b73] mt-2 w-full text-white`}
            onClick={() => dispatch(setShowForm(true))}
            title="Save filter and add email notifications"
          >
            {selectedUserFilterOption
              ? 'Edit filter and email notifications'
              : 'Save filter and get email notifications'}
          </button>
          <UserFilterForm />
        </div>
      )}
    </div>
  );
};
