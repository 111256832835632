import { createListenerMiddleware } from '@reduxjs/toolkit';

import { fetchConfig } from '../../common/slice';
import { setupFavicon } from './utils';

export const setupFaviconListenerMiddleware = createListenerMiddleware();

setupFaviconListenerMiddleware.startListening({
  actionCreator: fetchConfig.fulfilled,
  effect: async (action, listenerApi) => {
    setupFavicon(action.payload);
    listenerApi.cancelActiveListeners();
  },
});
