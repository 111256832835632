import React, { useEffect } from 'react';

import { Spinner } from '../../../common/components/Spinner';
import { countryMap } from '../../../common/countryMapping';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { setTitleAndUpdate } from '../../title/slice';
import { useGetPortInformation } from '../hooks';
import { capitalize } from '../utils';

type Props = { portId: string };

export const PortInformation: React.FC<Props> = ({ portId }) => {
  const dispatch = useAppDispatch();
  const portQuery = useGetPortInformation(portId);
  const state = useAppSelector((state) => state.portPage);

  useEffect(() => {
    if (!portQuery.currentData || !portQuery.currentData[0]?.name) return;
    dispatch(setTitleAndUpdate(portQuery.currentData[0].name));
  }, [portQuery.currentData, dispatch]);

  if (portQuery.isFetching || portQuery.isLoading) {
    return <Spinner className="h-6 w-6" />;
  }

  return (
    <div className="PortInformation mt-4">
      <div className="border-b border-secondary mb-2">
        <span className="text-lg">Port Information</span>
      </div>
      {!!portQuery.currentData?.length ? (
        <>
          <div>
            <div>
              <h1 className="text-2xl">{portQuery.currentData[0].name}</h1>
            </div>
            <div className="text-stone-400">
              {portQuery.currentData[0].type && capitalize(portQuery.currentData[0].type)} |{' '}
              <span
                className={`fi fi-${portQuery.currentData[0].country_code?.toLowerCase()}`}
                title={countryMap[portQuery.currentData[0].country_code as keyof typeof countryMap]}
              />{' '}
              {countryMap[portQuery.currentData[0].country_code as keyof typeof countryMap]}
            </div>
            <div className="text-stone-400">
              {portQuery.currentData[0].unlocode && `UN/LOCODE ${portQuery.currentData[0].unlocode}`} |{' '}
              {`ID ${portQuery.currentData[0].id}`}
            </div>
            <div className="text-stone-400">
              Coordinates{' '}
              {`${portQuery.currentData[0].geo_point?.coordinates?.[1]}° ${portQuery.currentData[0].geo_point?.coordinates?.[0]}°`}
            </div>
            {state.updatePolygon && (
              <>
                <br />
                <div className="text-stone-400">
                  Polygon GeoJSON:
                  <br />
                  {JSON.stringify(state.portPolygon)}
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="my-2">Port not Found!</div>
      )}
    </div>
  );
};
