import { createListenerMiddleware } from '@reduxjs/toolkit';

import { RootState } from '../../store/store';
import { setAuth } from '../auth/slice';
import { setupDatadogRum } from './utils';

export const setupDatadogListenerMiddleware = createListenerMiddleware();

setupDatadogListenerMiddleware.startListening({
  actionCreator: setAuth,
  effect: async (action, listenerApi) => {
    const { msalInstance } = action.payload;
    const config = (listenerApi.getState() as RootState).common.config;
    if (!config || !msalInstance) {
      return;
    }
    if (config.DATADOG_RUM_ENABLED) {
      setupDatadogRum(config, msalInstance);
    }
    listenerApi.unsubscribe();
  },
});
