import { EventFiltersState } from '../event-filters/slice';
import { UserFiltersState } from './slice';

type UserFilterUrlParams = {
  filter?: number;
  action?: 'edit';
};

export const getStateFromUrl = (initialState: UserFiltersState): UserFiltersState => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<UserFilterUrlParams> = Object.fromEntries(urlSearchParams.entries());
  const state: UserFiltersState = { ...initialState };

  if (params.filter) {
    state.selectedUserFilterOptionId = Number(params.filter);
  }

  if (params.action === 'edit') {
    state.showForm = true;
  }

  return state;
};

const selectedFilterOptions: Record<string, string> = {
  selectedCauseOptions: 'Casualty causes',
  selectedCharacteristicOptions: 'Anomaly characteristics',
  selectedConfidenceMax: 'Anomaly confidence max',
  selectedConfidenceMin: 'Anomaly confidence min',
  selectedCrmLeadOptions: 'Casualty CRM lead statuses',
  selectedCustomerOption: 'Customers',
  selectedDescriptionOption: 'Casualty description',
  selectedEventOption: 'Event type',
  selectedFleetOption: 'Fleet',
  selectedLocationOption: 'Locations',
  selectedProductOptions: 'Products',
  selectedTimeRangeOption: 'Time range',
  selectedVesselTypeOptions: 'Vessel types',
};

export const getFilterItem = (key: string, value: any) => {
  if (typeof value === 'string') {
    return { key: selectedFilterOptions[key], value: value };
  }

  if (value.label) {
    return { key: selectedFilterOptions[key], value: value.label };
  }

  if ((value as any[]).length) {
    return { key: selectedFilterOptions[key], value: value.map((v: { label: string }) => v.label).join(', ') };
  }
};

export const getFilterList = (eventFiltersState: EventFiltersState) => {
  return Object.entries(eventFiltersState)
    .filter(([key, value]) => selectedFilterOptions[key] && value && value.toString().length > 0)
    .map(([key, value]) => {
      return getFilterItem(key, value);
    });
};
