import { aisBaseApi as api } from '../base/base-apis';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVesselPositions: build.query<GetVesselPositionsApiResponse, GetVesselPositionsApiArg>({
      query: (queryArg) => ({
        url: `/vessel-positions`,
        params: {
          imo: queryArg.imo,
          mmsi: queryArg.mmsi,
          installation_id: queryArg.installationId,
          mode: queryArg.mode,
          filter: queryArg.filter,
          extend: queryArg.extend,
          from: queryArg['from'],
          to: queryArg.to,
          downsample: queryArg.downsample,
          source: queryArg.source,
          fields: queryArg.fields,
          omit: queryArg.omit,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVesselPositionsLatest: build.query<GetVesselPositionsLatestApiResponse, GetVesselPositionsLatestApiArg>({
      query: (queryArg) => ({
        url: `/vessel-positions/latest`,
        params: {
          type: queryArg['type'],
          fields: queryArg.fields,
          omit: queryArg.omit,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVesselPositionsPredicted: build.query<GetVesselPositionsPredictedApiResponse, GetVesselPositionsPredictedApiArg>(
      {
        query: (queryArg) => ({
          url: `/vessel-positions/predicted`,
          params: { imo: queryArg.imo, timestamp: queryArg.timestamp },
        }),
      }
    ),
    getVesselPositionsAreaCircle: build.query<
      GetVesselPositionsAreaCircleApiResponse,
      GetVesselPositionsAreaCircleApiArg
    >({
      query: (queryArg) => ({
        url: `/vessel-positions/area/circle`,
        params: {
          type: queryArg['type'],
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          radius: queryArg.radius,
          mode: queryArg.mode,
          filter: queryArg.filter,
          extend: queryArg.extend,
          from: queryArg['from'],
          to: queryArg.to,
          downsample: queryArg.downsample,
          source: queryArg.source,
          fields: queryArg.fields,
          omit: queryArg.omit,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVesselPositionsAreaBox: build.query<GetVesselPositionsAreaBoxApiResponse, GetVesselPositionsAreaBoxApiArg>({
      query: (queryArg) => ({
        url: `/vessel-positions/area/box`,
        params: {
          type: queryArg['type'],
          min_latitude: queryArg.minLatitude,
          min_longitude: queryArg.minLongitude,
          max_latitude: queryArg.maxLatitude,
          max_longitude: queryArg.maxLongitude,
          mode: queryArg.mode,
          filter: queryArg.filter,
          extend: queryArg.extend,
          from: queryArg['from'],
          to: queryArg.to,
          downsample: queryArg.downsample,
          source: queryArg.source,
          fields: queryArg.fields,
          omit: queryArg.omit,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVesselPositionsAreaPolygon: build.query<
      GetVesselPositionsAreaPolygonApiResponse,
      GetVesselPositionsAreaPolygonApiArg
    >({
      query: (queryArg) => ({
        url: `/vessel-positions/area/polygon`,
        params: {
          type: queryArg['type'],
          coordinates: queryArg.coordinates,
          mode: queryArg.mode,
          filter: queryArg.filter,
          extend: queryArg.extend,
          from: queryArg['from'],
          to: queryArg.to,
          downsample: queryArg.downsample,
          source: queryArg.source,
          fields: queryArg.fields,
          omit: queryArg.omit,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVesselPositionsAreaCirclePredicted: build.query<
      GetVesselPositionsAreaCirclePredictedApiResponse,
      GetVesselPositionsAreaCirclePredictedApiArg
    >({
      query: (queryArg) => ({
        url: `/vessel-positions/area/circle/predicted`,
        params: {
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          radius: queryArg.radius,
          timestamp: queryArg.timestamp,
        },
      }),
    }),
    getVesselPositionsPort: build.query<GetVesselPositionsPortApiResponse, GetVesselPositionsPortApiArg>({
      query: (queryArg) => ({
        url: `/vessel-positions/port`,
        params: {
          next: queryArg.next,
          last: queryArg.last,
          current: queryArg.current,
          fields: queryArg.fields,
          omit: queryArg.omit,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVessels: build.query<GetVesselsApiResponse, GetVesselsApiArg>({
      query: (queryArg) => ({
        url: `/vessels`,
        params: {
          active_from: queryArg.activeFrom,
          filter: queryArg.filter,
          fields: queryArg.fields,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getVesselsOperationalProfile: build.query<
      GetVesselsOperationalProfileApiResponse,
      GetVesselsOperationalProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/vessels/operational-profile`,
        params: { imo: queryArg.imo, from: queryArg['from'], to: queryArg.to },
      }),
    }),
    getAggregationsDaily: build.query<GetAggregationsDailyApiResponse, GetAggregationsDailyApiArg>({
      query: (queryArg) => ({
        url: `/aggregations/daily`,
        params: { imo: queryArg.imo, from: queryArg['from'], to: queryArg.to },
      }),
    }),
    getAggregationsTileQualityByZAndXY: build.query<
      GetAggregationsTileQualityByZAndXYApiResponse,
      GetAggregationsTileQualityByZAndXYApiArg
    >({
      query: (queryArg) => ({
        url: `/aggregations/tile-quality/${queryArg.z}/${queryArg.x}/${queryArg.y}`,
        params: { year: queryArg.year, month: queryArg.month },
      }),
    }),
    getAggregationsQualityTilesByZAndXY: build.query<
      GetAggregationsQualityTilesByZAndXYApiResponse,
      GetAggregationsQualityTilesByZAndXYApiArg
    >({
      query: (queryArg) => ({
        url: `/aggregations/quality-tiles/${queryArg.z}/${queryArg.x}/${queryArg.y}`,
        params: { year: queryArg.year, month: queryArg.month },
      }),
    }),
    getPortEvents: build.query<GetPortEventsApiResponse, GetPortEventsApiArg>({
      query: (queryArg) => ({
        url: `/port-events`,
        params: {
          imo: queryArg.imo,
          port: queryArg.port,
          from: queryArg['from'],
          to: queryArg.to,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getPortEventsVesselVoyages: build.query<GetPortEventsVesselVoyagesApiResponse, GetPortEventsVesselVoyagesApiArg>({
      query: (queryArg) => ({
        url: `/port-events/vessel-voyages/`,
        params: {
          imo: queryArg.imo,
          from: queryArg['from'],
          arrival_port: queryArg.arrivalPort,
          departure_port: queryArg.departurePort,
          to: queryArg.to,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getPortEventsPortCalls: build.query<GetPortEventsPortCallsApiResponse, GetPortEventsPortCallsApiArg>({
      query: (queryArg) => ({
        url: `/port-events/port-calls`,
        params: {
          imo: queryArg.imo,
          port: queryArg.port,
          from: queryArg['from'],
          to: queryArg.to,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetVesselPositionsApiResponse = /** status 200 OK */ Response & {
  results?: VesselPosition[];
};
export type GetVesselPositionsApiArg = {
  /** Comma separated list of IMO Numbers. Supports max 20 identifiers. */
  imo?: number | string;
  /** Comma separated list of MMSI Numbers. Supports max 20 identifiers. */
  mmsi?: number;
  /** Comma separated list of installation IDs. Supports max 20 identifiers. */
  installationId?: number;
  /** Query all or only latest known vessel positions */
  mode?: 'latest' | 'all';
  /** Query positions for vessels only that fulfills the filter condition. */
  filter?: 'has_imo';
  /** Comma separated list of objects for enriching the response. "refpoint" will provide information regarding the exact position (reference point) of the AIS transceiver on board. */
  extend?: string;
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the first - e.g. 2020-01 -> 2020-01-01. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  from?: string;
  /** To timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the last - e.g. 2020-01 -> 2020-01-31. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  to?: string;
  /** Downsample the positions to one position every time period (minutes/hours/days). "30m" would return one position per 30 minutes. "auto" would let the API determine the suitable downsample period to allow the full requested period to be included in the response. "last" would only return the last position per vessel within the specified timerange */
  downsample?: string;
  /** Comma separated list of allowed sources of data for vessel position. By default allows all of them. Options: WAN (Wärtsilä land-based AIS stations), FOS (Wärtsilä vessel-based AIS station), SAT (MarineTraffic Satellite), TER (MarineTraffic Terrestrial), SSA (Ship Security Alert System), OWN (Vessel's own location from ECDIS) https://confluence.devops.wartsila.com/display/AIS/AIS+data+providers */
  source?: ('WAN' | 'FOS' | 'SAT' | 'TER' | 'SSA' | 'OWN')[];
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Comma separated list of JSON fields to exclude in the response. Supports hierarchy with dot annotation - e.g "vessel.information". Has precedence over fields query parameter */
  omit?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselPositionsLatestApiResponse = /** status 200 OK */ Response & {
  results?: VesselPosition[];
};
export type GetVesselPositionsLatestApiArg = {
  /** Type of records to fetch where the simple record contain primarily identifier and positional data whereas the full record additionally contains port, voyage, vessel information */
  type: 'simple' | 'full';
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Comma separated list of JSON fields to exclude in the response. Supports hierarchy with dot annotation - e.g "vessel.information". Has precedence over fields query parameter */
  omit?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselPositionsPredictedApiResponse = /** status 200 OK */ Response & {
  results?: PredictedVesselPosition[];
};
export type GetVesselPositionsPredictedApiArg = {
  /** Comma separated list of IMO Numbers. Supports max 20 identifiers. */
  imo: number;
  /** Position timestamp (RFC 3339 formatted UTC timestamp). Default if omitted is now. */
  timestamp?: string;
};
export type GetVesselPositionsAreaCircleApiResponse = /** status 200 OK */ Response & {
  results?: VesselPosition[];
};
export type GetVesselPositionsAreaCircleApiArg = {
  /** Type of records to fetch where the simple record contain primarily identifier and positional data whereas the full record additionally contains port, voyage, vessel information */
  type?: 'full' | 'simple' | 'all';
  /** Center position of the area search. Value in decimal degrees */
  latitude: number;
  /** Center position of the area search. Value in decimal degrees */
  longitude: number;
  /** Area search radius. Value in kilometers (km) */
  radius: number;
  /** Query all or only latest known vessel positions */
  mode?: 'latest' | 'all';
  /** Query positions for vessels only that fulfills the filter condition. */
  filter?: 'has_imo';
  /** Comma separated list of objects for enriching the response. "refpoint" will provide information regarding the exact position (reference point) of the AIS transceiver on board. */
  extend?: string;
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the first - e.g. 2020-01 -> 2020-01-01. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  from?: string;
  /** To timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the last - e.g. 2020-01 -> 2020-01-31. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  to?: string;
  /** Downsample the positions to one position every time period (minutes/hours/days). "30m" would return one position per 30 minutes. "auto" would let the API determine the suitable downsample period to allow the full requested period to be included in the response. "last" would only return the last position per vessel within the specified timerange */
  downsample?: string;
  /** Comma separated list of allowed sources of data for vessel position. By default allows all of them. Options: WAN (Wärtsilä land-based AIS stations), FOS (Wärtsilä vessel-based AIS station), SAT (MarineTraffic Satellite), TER (MarineTraffic Terrestrial), SSA (Ship Security Alert System), OWN (Vessel's own location from ECDIS) https://confluence.devops.wartsila.com/display/AIS/AIS+data+providers */
  source?: ('WAN' | 'FOS' | 'SAT' | 'TER' | 'SSA' | 'OWN')[];
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Comma separated list of JSON fields to exclude in the response. Supports hierarchy with dot annotation - e.g "vessel.information". Has precedence over fields query parameter */
  omit?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselPositionsAreaBoxApiResponse = /** status 200 OK */ Response & {
  results?: VesselPosition[];
};
export type GetVesselPositionsAreaBoxApiArg = {
  /** Type of records to fetch where the simple record contain primarily identifier and positional data whereas the full record additionally contains port, voyage, vessel information */
  type?: 'full' | 'simple' | 'all';
  /** Minimum latitude of the area search. Value in decimal degrees */
  minLatitude: number;
  /** Minimum longitude of the area search. Value in decimal degrees */
  minLongitude: number;
  /** Maximum latitude of the area search. Value in decimal degrees */
  maxLatitude: number;
  /** Maximum longitude of the area search. Value in decimal degrees */
  maxLongitude: number;
  /** Query all or only latest known vessel positions */
  mode?: 'latest' | 'all';
  /** Query positions for vessels only that fulfills the filter condition. */
  filter?: 'has_imo';
  /** Comma separated list of objects for enriching the response. "refpoint" will provide information regarding the exact position (reference point) of the AIS transceiver on board. */
  extend?: string;
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the first - e.g. 2020-01 -> 2020-01-01. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  from?: string;
  /** To timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the last - e.g. 2020-01 -> 2020-01-31. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  to?: string;
  /** Downsample the positions to one position every time period (minutes/hours/days). "30m" would return one position per 30 minutes. "auto" would let the API determine the suitable downsample period to allow the full requested period to be included in the response. "last" would only return the last position per vessel within the specified timerange */
  downsample?: string;
  /** Comma separated list of allowed sources of data for vessel position. By default allows all of them. Options: WAN (Wärtsilä land-based AIS stations), FOS (Wärtsilä vessel-based AIS station), SAT (MarineTraffic Satellite), TER (MarineTraffic Terrestrial), SSA (Ship Security Alert System), OWN (Vessel's own location from ECDIS) https://confluence.devops.wartsila.com/display/AIS/AIS+data+providers */
  source?: ('WAN' | 'FOS' | 'SAT' | 'TER' | 'SSA' | 'OWN')[];
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Comma separated list of JSON fields to exclude in the response. Supports hierarchy with dot annotation - e.g "vessel.information". Has precedence over fields query parameter */
  omit?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselPositionsAreaPolygonApiResponse = /** status 200 OK */ Response & {
  results?: VesselPosition[];
};
export type GetVesselPositionsAreaPolygonApiArg = {
  /** Type of records to fetch where the simple record contain primarily identifier and positional data whereas the full record additionally contains port, voyage, vessel information */
  type?: 'full' | 'simple' | 'all';
  /** A comma separated list of latitude and longitude values, starting with latitude and alternating. */
  coordinates: string;
  /** Query all or only latest known vessel positions */
  mode?: 'latest' | 'all';
  /** Query positions for vessels only that fulfills the filter condition. */
  filter?: 'has_imo';
  /** Comma separated list of objects for enriching the response. "refpoint" will provide information regarding the exact position (reference point) of the AIS transceiver on board. */
  extend?: string;
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the first - e.g. 2020-01 -> 2020-01-01. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  from?: string;
  /** To timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the last - e.g. 2020-01 -> 2020-01-31. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  to?: string;
  /** Downsample the positions to one position every time period (minutes/hours/days). "30m" would return one position per 30 minutes. "auto" would let the API determine the suitable downsample period to allow the full requested period to be included in the response. "last" would only return the last position per vessel within the specified timerange */
  downsample?: string;
  /** Comma separated list of allowed sources of data for vessel position. By default allows all of them. Options: WAN (Wärtsilä land-based AIS stations), FOS (Wärtsilä vessel-based AIS station), SAT (MarineTraffic Satellite), TER (MarineTraffic Terrestrial), SSA (Ship Security Alert System), OWN (Vessel's own location from ECDIS) https://confluence.devops.wartsila.com/display/AIS/AIS+data+providers */
  source?: ('WAN' | 'FOS' | 'SAT' | 'TER' | 'SSA' | 'OWN')[];
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Comma separated list of JSON fields to exclude in the response. Supports hierarchy with dot annotation - e.g "vessel.information". Has precedence over fields query parameter */
  omit?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselPositionsAreaCirclePredictedApiResponse = /** status 200 OK */ Response & {
  results?: PredictedVesselPosition[];
};
export type GetVesselPositionsAreaCirclePredictedApiArg = {
  /** Center position of the area search. Value in decimal degrees */
  latitude: number;
  /** Center position of the area search. Value in decimal degrees */
  longitude: number;
  /** Area search radius. Value in kilometers (km) */
  radius: number;
  /** Position timestamp (RFC 3339 formatted UTC timestamp). Default if omitted is now. */
  timestamp?: string;
};
export type GetVesselPositionsPortApiResponse = /** status 200 OK */ Response & {
  results?: VesselPosition[];
};
export type GetVesselPositionsPortApiArg = {
  /** Return positions of vessels travelling to this port. Accepts a UN/LOCODE (e.g. DEKEL) or name (e.g. KIEL) */
  next?: string;
  /** Return positions of vessels travelling from this port. Accepts a UN/LOCODE (e.g. DEKEL) or name (e.g. KIEL) */
  last?: string;
  /** Return positions of vessels currently at this port. Accepts a UN/LOCODE (e.g. DEKEL) or name (e.g. KIEL) */
  current?: string;
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Comma separated list of JSON fields to exclude in the response. Supports hierarchy with dot annotation - e.g "vessel.information". Has precedence over fields query parameter */
  omit?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselsApiResponse = /** status 200 OK */ Response & {
  results?: {
    mmsi?: number | null;
    imo?: number | null;
    vessel?: Vessel;
  }[];
};
export type GetVesselsApiArg = {
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Will only return vessels with reported positions since this timestamp */
  activeFrom?: string;
  /** Query positions for vessels only that fulfills the filter condition. */
  filter?: 'has_imo';
  /** Comma separated list of JSON fields to include in the response. Supports hierarchy with dot annotation - e.g "vessel.information". All fields are returned by default */
  fields?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetVesselsOperationalProfileApiResponse = /** status 200 OK */ {
  country_of_operation?: {
    country_code?: string;
    country_name?: string;
    time_spent_hours?: number;
    time_spent_percentage?: number;
  }[];
};
export type GetVesselsOperationalProfileApiArg = {
  /** Comma separated list of IMO Numbers. Supports max 20 identifiers. */
  imo: number;
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the first - e.g. 2020-01 -> 2020-01-01 */
  from?: string;
  /** To timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the last - e.g. 2020-01 -> 2020-01-31 */
  to?: string;
};
export type GetAggregationsDailyApiResponse = /** status 200 OK */ Response & {
  results?: DailyAggregation[];
};
export type GetAggregationsDailyApiArg = {
  /** Comma separated list of IMO Numbers. Supports max 20 identifiers. */
  imo: number;
  /** From timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the first - e.g. 2020-01 -> 2020-01-01. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  from?: string;
  /** To timestamp to be used (RFC 3339 formatted UTC timestamp or a valid substring thereof). Left out timestamp parts will defaulted to the last - e.g. 2020-01 -> 2020-01-31. Mandatory for mode "all" and optional for mode "latest". When "latest" is used in combination with to/from datetimes, the API returns each last known position for each vessel during the time period */
  to?: string;
};
export type GetAggregationsTileQualityByZAndXYApiResponse = /** status 200 OK */ Response & {
  results?: TileQuality[];
};
export type GetAggregationsTileQualityByZAndXYApiArg = {
  /** X tile */
  x: number;
  /** Y tile */
  y: number;
  /** Zoom level */
  z: number;
  /** Year */
  year?: number;
  /** Month */
  month?: number;
};
export type GetAggregationsQualityTilesByZAndXYApiResponse = /** status 200 OK */ string;
export type GetAggregationsQualityTilesByZAndXYApiArg = {
  /** X tile */
  x: number;
  /** Y tile */
  y: number;
  /** Zoom level */
  z: number;
  /** Year */
  year?: number;
  /** Month */
  month?: number;
};
export type GetPortEventsApiResponse = /** status 200 OK */ Response & {
  results?: PortEvents[];
};
export type GetPortEventsApiArg = {
  /** Vessel IMO number */
  imo?: number;
  /** Either the port id, port unlocode or port name */
  port?: number;
  /** Filter to search events starting from the given datetime */
  from?: string;
  /** Filter to search events ending at the given datetime */
  to?: string;
  /** Limits the amount of port events that generate the voyages */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetPortEventsVesselVoyagesApiResponse = /** status 200 OK */ Response & {
  results?: VesselVoyages[];
};
export type GetPortEventsVesselVoyagesApiArg = {
  /** Vessel IMO number */
  imo?: number;
  /** Filter to search events starting from the given datetime */
  from?: string;
  /** Either the port id, port unlocode or port name of the arrival port */
  arrivalPort?: number;
  /** Either the port id, port unlocode or port name of the departure port */
  departurePort?: number;
  /** Filter to search events ending at the given datetime */
  to?: string;
  /** Limits the amount of port events that generate the voyages */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type GetPortEventsPortCallsApiResponse = /** status 200 OK */ Response & {
  results?: PortCalls[];
};
export type GetPortEventsPortCallsApiArg = {
  /** Vessel IMO number */
  imo?: number;
  /** Either the port id, port unlocode or port name */
  port?: number;
  /** Filter to search events starting from the given datetime */
  from?: string;
  /** Filter to search events ending at the given datetime */
  to?: string;
  /** Limits the amount of vessel positions in the response */
  limit?: number;
  /** Offset the vessel positions in the response */
  offset?: number;
};
export type Response = {
  _previous?: string | null;
  _next?: string | null;
};
export type Vessel = {
  name?: string;
  type_id?: number;
  type_name?: string;
  callsign?: string;
  flag_country_code?: string;
  flag_country_name?: string;
  length?: number;
  width?: number;
  refpoint?: {
    a?: number | null;
    b?: number | null;
    c?: number | null;
    d?: number | null;
  };
  grt?: number;
  dwt?: number;
  year_built?: number;
  category_name?: string;
};
export type Port = {
  id?: number;
  name?: string;
  unlocode?: string;
  country_code?: string;
  country_name?: string;
};
export type VesselPosition = {
  mmsi?: number;
  imo?: number;
  installation_id?: number;
  mt_ship_id?: number;
  vessel?: Vessel;
  ports?: {
    current_port?: Port;
    last_port?: Port & {
      time?: string;
    };
    next_port?: Port;
  };
  positions?: {
    latitude?: number;
    longitude?: number;
    speed?: number;
    heading?: number;
    course?: number;
    status_id?: number;
    status_desc?: string;
    timestamp?: string;
    source?: string;
    rate_of_turn?: number;
    voyage?: {
      draught?: number;
      destination?: string;
      eta?: string;
      eta_estimated?: string;
      eta_update_timetamp?: string;
      distance_to_go?: number;
      distance_travelled?: number;
      avg_speed?: number;
      max_speed?: number;
    };
  }[];
};
export type Error = {
  message?: string;
};
export type PredictedVesselPosition = {
  imo?: number;
  latitude?: number;
  longitude?: number;
  timestamp?: string;
};
export type DailyAggregation = {
  aggregate_date?: string;
  mmsi_number?: number;
  imo?: number;
  call_sign?: string;
  record_count?: number;
  last_position_datetime?: string;
  last_position_latitude?: number;
  last_position_longitude?: number;
  last_nav_status?: string;
  last_speed?: number;
  last_static_datetime?: string;
  last_destination?: string;
  last_eta?: number;
  min_latitude?: number;
  max_latitude?: number;
  min_longitude?: number;
  max_longitude?: number;
  operating_distance?: number;
  min_speed?: number;
  max_speed?: number;
  avg_draught?: number;
  avg_abs_turn_rate?: number;
  distinct_destination_count?: number;
  distinct_destination_list?: string;
  distinct_nav_status_count?: number;
  distinct_nav_status_list?: string;
  nav_status_change_count?: number;
  top_nav_status?: string;
  hours_top_nav_status?: number;
  hours_moving?: number;
  hours_not_moving?: number;
  hours_uncertain?: number;
  last_movement_datetime?: string;
  distance_moved?: number;
};
export type TileQuality = {
  year_month?: string;
  coverage_percentage?: number;
  positions?: number;
  interpolated_positions?: number;
};
export type PortEvents = {
  mmsi?: number;
  imo?: number;
  vessel_name?: string;
  event_datetime?: string;
  event_type?: string;
  port_id?: number;
  port_name?: string;
  port_country_code?: string;
  port_unlocode?: string;
  port_latitude?: number;
  port_longitude?: number;
  latitude?: number;
  longitude?: number;
};
export type VesselVoyages = {
  mmsi?: number;
  imo?: number;
  vessel_name?: string;
  departure_port_id?: number;
  departure_port_name?: string;
  departure_port_country_code?: string;
  departure_port_unlocode?: string;
  departure_time?: string;
  arrival_port_id?: number;
  arrival_port_name?: string;
  arrival_port_country_code?: string;
  arrival_port_unlocode?: string;
  arrival_time?: string;
  duration_hours?: number;
};
export type PortCalls = {
  mmsi?: number;
  imo?: number;
  vessel_name?: string;
  port_id?: number;
  port_name?: string;
  port_country_code?: string;
  port_unlocode?: string;
  port_latitude?: number;
  port_longitude?: number;
  arrival_time?: string;
  departure_time?: string;
  duration_hours?: number;
};
export const {
  useGetVesselPositionsQuery,
  useGetVesselPositionsLatestQuery,
  useGetVesselPositionsPredictedQuery,
  useGetVesselPositionsAreaCircleQuery,
  useGetVesselPositionsAreaBoxQuery,
  useGetVesselPositionsAreaPolygonQuery,
  useGetVesselPositionsAreaCirclePredictedQuery,
  useGetVesselPositionsPortQuery,
  useGetVesselsQuery,
  useGetVesselsOperationalProfileQuery,
  useGetAggregationsDailyQuery,
  useGetAggregationsTileQualityByZAndXYQuery,
  useGetAggregationsQualityTilesByZAndXYQuery,
  useGetPortEventsQuery,
  useGetPortEventsVesselVoyagesQuery,
  useGetPortEventsPortCallsQuery,
} = injectedRtkApi;
