import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { ExclamationCircleIcon } from '../../../common/components/icons/ExclamationCircleIcon';
import { MinusCircleIcon } from '../../../common/components/icons/MinusCircleIcon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { Port, VesselPosition } from '../../../store/apis/ais-api';
import { useDeleteVesselFromFleetFleetImoNumberDeleteMutation, UserFleet } from '../../../store/apis/csi-api';
import { setSelectedVessel } from '../slice';

type Props = {
  vessel: UserFleet;
  vesselLocation?: VesselPosition;
  refetchFunction: () => void;
};

export const MyFleetVesselItem: React.FC<Props> = ({ vessel, vesselLocation, refetchFunction }) => {
  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector((state) => state.myFleet.selected);
  const [deleteVessel] = useDeleteVesselFromFleetFleetImoNumberDeleteMutation();

  const onClickMarkerIconHandler = (imo: number) => {
    if (imo === selectedItem) {
      dispatch(setSelectedVessel(undefined));
      return;
    }
    dispatch(setSelectedVessel(imo));
  };

  const handleDelete = async (e: React.MouseEvent, imo_number: number, name: string) => {
    e.stopPropagation();
    if (!window.confirm(`Are your sure you want to remove ${name} from your fleet?`)) {
      return;
    }

    try {
      await deleteVessel({ imoNumber: imo_number }).unwrap();
      toast.success('Vessel removed');
      refetchFunction();
    } catch (error) {
      toast.error('Could not remove vessel, please try again.');
    }
  };

  const renderPortLink = (port: Port) => {
    const portInformation = (
      <>
        <span title={port.country_name} className={`fi fi-${port.country_code?.toLowerCase()} mr-1`} />
        {port.name}
        {port.unlocode && ` (${port.unlocode})`}
      </>
    );

    if (!port.unlocode) {
      return <span className="text-gray-400">{portInformation}</span>;
    } else {
      return (
        <Link className="hover:text-info" title={port.unlocode} to={`/port/${port.unlocode}`}>
          {portInformation}
        </Link>
      );
    }
  };

  return (
    <div
      className={`MyFleetVesselItem border-b border-neutral w-full p-2 ${
        !!vesselLocation?.positions && 'cursor-pointer'
      } ${selectedItem === vessel.imo_number ? 'bg-secondary' : 'bg-primary'}`}
      data-event={`fleet-${vessel.imo_number}`}
      onClick={() => !!vesselLocation?.positions && onClickMarkerIconHandler(vessel.imo_number)}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="mb-1">
          <div className="flex mr-2 items-center">
            <Link
              className="text-2xl cursor-pointer hover:underline mr-1"
              to={`/vessel/${vessel.imo_number}`}
              title="View vessel information"
              target="_blank"
            >
              {vessel.vessel_name}
            </Link>
            {vessel.fleet_type === 'manual' ? (
              <div
                onClick={(e) => handleDelete(e, vessel.imo_number, vessel.vessel_name)}
                title="Remove vessel from fleet"
              >
                <MinusCircleIcon className="h-5 w-5 stroke-gray-500 hover:stroke-error cursor-pointer ml-1" />
              </div>
            ) : (
              <div
                title="Vessel is part of automatically generated fleet and cannot be removed"
                className="self-center"
              >
                <ExclamationCircleIcon className="h-3.5 w-3.5 stroke-gray-500 mb-3 hover:stroke-slate-200" />
              </div>
            )}
          </div>
          <div className="text-gray-400 text-xs">
            {`${vessel.type_name} | ${!!vessel.imo_number && 'IMO ' + vessel.imo_number}`}
            <a
              title="Link to CIB"
              hidden={!vessel.installation_id}
              href={`https://cib.data.wartsila.com/installations/${vessel.installation_id}`}
            >
              <span> | </span>
              <span className="hover:underline">ID {vessel.installation_id}</span>
            </a>
          </div>
        </div>
        {!!vesselLocation?.positions && (
          <img
            alt="Vessel marker"
            src={
              vesselLocation.positions[0].heading
                ? '/assets/images/positions/position-direction.svg'
                : '/assets/images/positions/position-no-direction.svg'
            }
            onClick={() => onClickMarkerIconHandler(vessel.imo_number)}
            className="h-10 w-10 mr-2 mt-1 cursor-pointer"
            style={{ transform: `rotate(${vesselLocation.positions[0].heading}deg)` }}
            title="Show on map"
          ></img>
        )}
      </div>

      <div className="flex items-center my-0.5">
        <img src="/assets/images/life-buoy.svg" alt="life buoy icon" className="w-4 h-4 mx-0.5" />
        <span className="text-stone-400 ml-1.5 text-sm">{vessel.status_name}</span>
      </div>
      {!!vesselLocation?.ports && (
        <>
          {!!vesselLocation.ports?.last_port && <div>Last port: {renderPortLink(vesselLocation.ports.last_port)}</div>}
          {!!vesselLocation.ports?.current_port && (
            <div>Current port: {renderPortLink(vesselLocation.ports.current_port)}</div>
          )}
          {!!vesselLocation.ports?.next_port && <div>Next port: {renderPortLink(vesselLocation.ports.next_port)}</div>}
        </>
      )}
    </div>
  );
};
