import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getStoredConfig, storeConfig } from '../../common/utils';

import type { PayloadAction } from '@reduxjs/toolkit';

type PollingState = {
  eventsPageEnablePolling: boolean;
};

const initialState: PollingState = { eventsPageEnablePolling: getStoredConfig()?.eventsPageEnablePolling || false };

const sliceName = 'polling';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setEnabledInEventsPage(state, action: PayloadAction<PollingState['eventsPageEnablePolling']>) {
      state.eventsPageEnablePolling = action.payload;
    },
  },
});

export const { setEnabledInEventsPage } = slice.actions;

export const setEnabledInEventsPageAndSave = createAsyncThunk<void, PollingState['eventsPageEnablePolling']>(
  `${sliceName}/setEnabledInEventsPageAndSave`,
  async (enabled: boolean, { dispatch }) => {
    dispatch(setEnabledInEventsPage(enabled));
    storeConfig({ eventsPageEnablePolling: enabled });
  }
);

export const { reducer } = slice;
