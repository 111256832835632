import { TimeRangeOption } from '../../common/types';

export const timeRangeOptions: TimeRangeOption[] = [
  {
    label: 'Past 24 hours',
    value: { amount: 24, unit: 'hours' },
  },
  {
    label: 'Past 1 week',
    value: { amount: 1, unit: 'weeks' },
  },
  {
    label: 'Past 2 weeks',
    value: { amount: 2, unit: 'weeks' },
  },
  {
    label: 'Past 1 month',
    value: { amount: 1, unit: 'months' },
  },
];
