import React from 'react';

import { useAppSelector } from '../../common/hooks';
import { customColors, themeColors } from '../../common/utils';
import { useGetServiceNotificationsServiceNotificationsGetQuery } from '../../store/apis/csi-api';
import { Banner as BannerType, getBannersForEnvironment } from './utils';

const colorMapping = {
  warning: themeColors.accent,
  critical: themeColors.error,
  positive: themeColors.success,
};

export const Banner: React.FC = () => {
  const { ENVIRONMENT } = useAppSelector((state) => state.common.config!);
  const query = useGetServiceNotificationsServiceNotificationsGetQuery();

  const getBannerStyles = (severity: string) => ({
    backgroundColor: colorMapping[severity as keyof typeof colorMapping] || customColors.infoLight,
    color: severity in colorMapping ? 'white' : 'black',
  });

  const activeBanners: BannerType[] = query.currentData ? getBannersForEnvironment(query.currentData, ENVIRONMENT) : [];

  return (
    <div className="Banner">
      {activeBanners.map((banner) => (
        <div
          key={banner.text}
          className={`h-6 flex flex-row justify-center items-center text-primary text-sm`}
          style={getBannerStyles(banner.severity)}
        >
          <span dangerouslySetInnerHTML={{ __html: banner.text }} />
        </div>
      ))}
    </div>
  );
};
