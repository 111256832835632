import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Places } from '../../store/apis/port-api';

import { RootState } from '../../store/store';
import { EventType, setShowFilters, setSelectedTab } from '../event-feed/slice';
import { resetState, setState } from '../event-filters/slice';

type VesselDetailsViewState = {
  isInstallationPartnersOpen: boolean;
  isEquipmentOpen: boolean;
  isRelatedCompaniesOpen: boolean;
  nextPort: PortInformation | undefined;
  lastPort: PortInformation | undefined;
  currentPort: PortInformation | undefined;
};

export type PortInformation = Places & {
  destinationType: string;
};

const initialState: VesselDetailsViewState = {
  isInstallationPartnersOpen: true,
  isEquipmentOpen: true,
  isRelatedCompaniesOpen: true,
  nextPort: undefined,
  lastPort: undefined,
  currentPort: undefined,
};

export type SingleVesselFilterArg = {
  imo: number;
  type?: EventType;
};

const slice = createSlice({
  name: 'vesselDetails',
  initialState,
  reducers: {
    setVesselDetailsState(_, action: PayloadAction<VesselDetailsViewState>) {
      return action.payload;
    },
  },
});

export const { setVesselDetailsState } = slice.actions;
export const { reducer } = slice;

export const setFiltersForSingleVessel = createAsyncThunk<void, SingleVesselFilterArg>(
  'vesselDetailsView/setFiltersForSingleVessel',
  async ({ imo, type }, { dispatch, getState }) => {
    dispatch(resetState());
    dispatch(setShowFilters(false));
    const state = (getState() as RootState).eventFilters;
    dispatch(setState({ ...state, selectedTimeRangeOption: null, selectedImo: Number(imo) }));

    if (type) {
      dispatch(setSelectedTab(type));
    }
  }
);
