import React, { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { currentUserSelector, setAuth } from './slice';

type Props = {
  children?: React.ReactNode;
};

export const AuthenticatedWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(currentUserSelector);
  const { instance } = useMsal();

  useEffect(() => {
    if (!instance) return;
    dispatch(setAuth({ msalInstance: instance }));
  }, [dispatch, instance]);

  if (!user) {
    return null;
  }

  return <>{children}</>;
};
