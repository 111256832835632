import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { acquireTokenForApiResourceRedirect, acquireTokenForApiResourceSilent } from '../../common/utils';
import { RootState } from '../store';

const createBaseQuery = (baseUrl: string, appId: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, api) => {
      const { msalInstance } = (api.getState() as RootState).auth;
      if (!msalInstance) return headers;

      try {
        const token = await acquireTokenForApiResourceSilent(msalInstance, appId);
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      } catch {
        await acquireTokenForApiResourceRedirect(msalInstance, appId);
      }

      return headers;
    },
  });

const ihsVesselDataBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { BASE_URL, APP_ID } = (api.getState() as RootState).common.config!.ENDPOINTS.IHS_VESSEL_DATA;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

const vesselEventBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { BASE_URL, APP_ID } = (api.getState() as RootState).common.config!.ENDPOINTS.VESSEL_EVENT;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

const installationBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { BASE_URL, APP_ID } = (api.getState() as RootState).common.config!.ENDPOINTS.INSTALLATION;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

const aisBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const { useDevAisApi } = (api.getState() as RootState).common;
  const { BASE_URL, APP_ID } = useDevAisApi
    ? (api.getState() as RootState).common.config!.ENDPOINTS.AIS_DEV
    : (api.getState() as RootState).common.config!.ENDPOINTS.AIS;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

const vesselCsiBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { BASE_URL, APP_ID } = (api.getState() as RootState).common.config!.ENDPOINTS.VESSEL_CSI;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

const portBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { BASE_URL, APP_ID } = (api.getState() as RootState).common.config!.ENDPOINTS.PORT;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

const iprQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const { BASE_URL, APP_ID } = (api.getState() as RootState).common.config!.ENDPOINTS.IPR;
  const rawBaseQuery = createBaseQuery(BASE_URL, APP_ID);
  return rawBaseQuery(args, api, extraOptions);
};

// initialize empty api services that we'll inject endpoints into later as needed
export const ihsVesselDataBaseApi = createApi({
  baseQuery: ihsVesselDataBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'apiIhsVesselData',
});

export const vesselEventBaseApi = createApi({
  baseQuery: vesselEventBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'apiVesselEvent',
});

export const installationBaseApi = createApi({
  baseQuery: installationBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'apiInstallation',
});

export const aisBaseApi = createApi({
  baseQuery: aisBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'apiAis',
});

export const vesselCsiBaseApi = createApi({
  baseQuery: vesselCsiBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'apiCsi',
});

export const portBaseApi = createApi({
  baseQuery: portBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'apiPort',
});

export const iprApi = createApi({
  baseQuery: iprQuery,
  endpoints: () => ({}),
  reducerPath: 'apiIpr',
});
