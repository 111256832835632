import { BaseSelectOption, TimeRangeOption } from '../../common/types';
import { casualtyCauses } from '../../common/utils';
import {
  CauseOption,
  CharacteristicOption,
  ConfidenceOption,
  CRMLeadOption,
  EventOption,
  FleetOption,
  ProductOption,
  VesselTypeOption,
} from './slice';

export const timeRangeOptions: TimeRangeOption[] = [
  {
    label: 'Past 30 minutes',
    value: { amount: 30, unit: 'minutes' },
  },
  {
    label: 'Past 1 hour',
    value: { amount: 1, unit: 'hours' },
  },
  {
    label: 'Past 4 hours',
    value: { amount: 4, unit: 'hours' },
  },
  {
    label: 'Past 12 hours',
    value: { amount: 12, unit: 'hours' },
  },
  {
    label: 'Past 1 day',
    value: { amount: 1, unit: 'days' },
  },
  {
    label: 'Past 2 days',
    value: { amount: 2, unit: 'days' },
    isDefault: true,
  },
  {
    label: 'Past 4 days',
    value: { amount: 4, unit: 'days' },
  },
  {
    label: 'Past 1 week',
    value: { amount: 1, unit: 'weeks' },
  },
  {
    label: 'Past 1 month',
    value: { amount: 1, unit: 'months' },
  },
  {
    label: 'Past 3 months',
    value: { amount: 3, unit: 'months' },
    conditional: true,
  },
  {
    label: 'Past 1 year',
    value: { amount: 12, unit: 'months' },
    conditional: true,
  },
];

export const fleetOptions: FleetOption[] = [
  {
    label: 'Global fleet',
    value: 'global-fleet',
  },
  {
    label: 'IMO fleet',
    value: 'imo-fleet',
  },
  {
    label: 'Wärtsilä fleet',
    value: 'wartsila-fleet',
    isDefault: true,
  },
];

export const eventOptions: EventOption[] = [
  {
    label: 'Anomalies',
    value: 'anomalies',
  },
  {
    label: 'Casualties',
    value: 'casualties',
  },
  {
    label: 'Dry docking',
    value: 'dry-docking',
  },
];

export const vesselTypeOptions: VesselTypeOption[] = [
  {
    label: 'Cargo Carrying (A)',
    value: 'A',
    level: 1,
  },
  {
    label: 'Tankers (A1)',
    value: 'A1',
    level: 2,
  },
  {
    label: 'Liquefied Gas (A11)',
    value: 'A11',
    level: 3,
  },
  {
    label: 'LNG Tanker (A11A)',
    value: 'A11A',
    level: 4,
  },
  {
    label: 'LPG Tanker (A11B)',
    value: 'A11B',
    level: 4,
  },
  {
    label: 'CO2 Tanker (A11C)',
    value: 'A11C',
    level: 4,
  },
  {
    label: 'Chemical (A12)',
    value: 'A12',
    level: 3,
  },
  {
    label: 'Chemical Tanker (A12A)',
    value: 'A12A',
    level: 4,
  },
  {
    label: 'Chemical/Oil Products Tanker (A12B)',
    value: 'A12B',
    level: 4,
  },
  {
    label: 'Wine Tanker (A12C)',
    value: 'A12C',
    level: 4,
  },
  {
    label: 'Vegetable Oil Tanker (A12D)',
    value: 'A12D',
    level: 4,
  },
  {
    label: 'Edible Oil Tanker (A12E)',
    value: 'A12E',
    level: 4,
  },
  {
    label: 'Beer Tanker (A12F)',
    value: 'A12F',
    level: 4,
  },
  {
    label: 'Latex Tanker (A12G)',
    value: 'A12G',
    level: 4,
  },
  {
    label: 'Oil (A13)',
    value: 'A13',
    level: 3,
  },
  {
    label: 'Crude Oil Tanker (A13A)',
    value: 'A13A',
    level: 4,
  },
  {
    label: 'Oil Products Tanker (A13B)',
    value: 'A13B',
    level: 4,
  },
  {
    label: 'Bitumen Tanker (A13C)',
    value: 'A13C',
    level: 4,
  },
  {
    label: 'Coal/Oil Mixture Tanker (A13E)',
    value: 'A13E',
    level: 4,
  },
  {
    label: 'Other Liquids (A14)',
    value: 'A14',
    level: 3,
  },
  {
    label: 'Water Tanker (A14A)',
    value: 'A14A',
    level: 4,
  },
  {
    label: 'Fruit Juice Tanker (A14E)',
    value: 'A14E',
    level: 4,
  },
  {
    label: 'Molasses Tanker (A14F)',
    value: 'A14F',
    level: 4,
  },
  {
    label: 'Glue Tanker (A14G)',
    value: 'A14G',
    level: 4,
  },
  {
    label: 'Alcohol Tanker (A14H)',
    value: 'A14H',
    level: 4,
  },
  {
    label: 'Caprolactam Tanker (A14N)',
    value: 'A14N',
    level: 4,
  },
  {
    label: 'Bulk Carriers (A2)',
    value: 'A2',
    level: 2,
  },
  {
    label: 'Bulk Dry (A21)',
    value: 'A21',
    level: 3,
  },
  {
    label: 'Bulk Carrier (A21A)',
    value: 'A21A',
    level: 4,
  },
  {
    label: 'Ore Carrier (A21B)',
    value: 'A21B',
    level: 4,
  },
  {
    label: 'Bulk Dry / Oil (A22)',
    value: 'A22',
    level: 3,
  },
  {
    label: 'Bulk/Oil Carrier (A22A)',
    value: 'A22A',
    level: 4,
  },
  {
    label: 'Ore/Oil Carrier (A22B)',
    value: 'A22B',
    level: 4,
  },
  {
    label: 'Self Discharging Bulk Dry (A23)',
    value: 'A23',
    level: 3,
  },
  {
    label: 'Self Discharging Bulk Carrier (A23A)',
    value: 'A23A',
    level: 4,
  },
  {
    label: 'Other Bulk Dry (A24)',
    value: 'A24',
    level: 3,
  },
  {
    label: 'Cement Carrier (A24A)',
    value: 'A24A',
    level: 4,
  },
  {
    label: 'Wood Chips Carrier (A24B)',
    value: 'A24B',
    level: 4,
  },
  {
    label: 'Urea Carrier (A24C)',
    value: 'A24C',
    level: 4,
  },
  {
    label: 'Aggregates Carrier (A24D)',
    value: 'A24D',
    level: 4,
  },
  {
    label: 'Limestone Carrier (A24E)',
    value: 'A24E',
    level: 4,
  },
  {
    label: 'Refined Sugar Carrier (A24G)',
    value: 'A24G',
    level: 4,
  },
  {
    label: 'Powder Carrier (A24H)',
    value: 'A24H',
    level: 4,
  },
  {
    label: 'Dry Cargo/Passenger (A3)',
    value: 'A3',
    level: 2,
  },
  {
    label: 'General Cargo (A31)',
    value: 'A31',
    level: 3,
  },
  {
    label: 'General Cargo Ship (A31A)',
    value: 'A31A',
    level: 4,
  },
  {
    label: 'Palletised Cargo Ship (A31B)',
    value: 'A31B',
    level: 4,
  },
  {
    label: 'Deck Cargo Ship (A31C)',
    value: 'A31C',
    level: 4,
  },
  {
    label: 'Passenger / General Cargo (A32)',
    value: 'A32',
    level: 3,
  },
  {
    label: 'Passenger/General Cargo Ship (A32A)',
    value: 'A32A',
    level: 4,
  },
  {
    label: 'Container (A33)',
    value: 'A33',
    level: 3,
  },
  {
    label: 'Container Ship (A33A)',
    value: 'A33A',
    level: 4,
  },
  {
    label: 'Passenger/Container Ship (A33B)',
    value: 'A33B',
    level: 4,
  },
  {
    label: 'Refrigerated Cargo (A34)',
    value: 'A34',
    level: 3,
  },
  {
    label: 'Refrigerated Cargo Ship (A34A)',
    value: 'A34A',
    level: 4,
  },
  {
    label: 'Ro-Ro Cargo (A35)',
    value: 'A35',
    level: 3,
  },
  {
    label: 'Ro-Ro Cargo Ship (A35A)',
    value: 'A35A',
    level: 4,
  },
  {
    label: 'Vehicles Carrier (A35B)',
    value: 'A35B',
    level: 4,
  },
  {
    label: 'Container/Ro-Ro Cargo Ship (A35C)',
    value: 'A35C',
    level: 4,
  },
  {
    label: 'Landing Craft (A35D)',
    value: 'A35D',
    level: 4,
  },
  {
    label: 'Passenger/Ro-Ro Cargo (A36)',
    value: 'A36',
    level: 3,
  },
  {
    label: 'Passenger/Ro-Ro Cargo Ship (A36A)',
    value: 'A36A',
    level: 4,
  },
  {
    label: 'Passenger/Landing Craft (A36B)',
    value: 'A36B',
    level: 4,
  },
  {
    label: 'Passenger (A37)',
    value: 'A37',
    level: 3,
  },
  {
    label: 'Passenger (Cruise) Ship (A37A)',
    value: 'A37A',
    level: 4,
  },
  {
    label: 'Passenger Ship (A37B)',
    value: 'A37B',
    level: 4,
  },
  {
    label: 'Other Dry Cargo (A38)',
    value: 'A38',
    level: 3,
  },
  {
    label: 'Livestock Carrier (A38A)',
    value: 'A38A',
    level: 4,
  },
  {
    label: 'Barge Carrier (A38B)',
    value: 'A38B',
    level: 4,
  },
  {
    label: 'Heavy Load Carrier (A38C)',
    value: 'A38C',
    level: 4,
  },
  {
    label: 'Nuclear Fuel Carrier (A38D)',
    value: 'A38D',
    level: 4,
  },
  {
    label: 'Pulp Carrier (A38H)',
    value: 'A38H',
    level: 4,
  },
  {
    label: 'Work Vessel (B)',
    value: 'B',
    level: 1,
  },
  {
    label: 'Fishing (B1)',
    value: 'B1',
    level: 2,
  },
  {
    label: 'Fish Catching (B11)',
    value: 'B11',
    level: 3,
  },
  {
    label: 'Fishing Vessel (B11B)',
    value: 'B11B',
    level: 4,
  },
  {
    label: 'Other Fishing (B12)',
    value: 'B12',
    level: 3,
  },
  {
    label: 'Fish Factory Ship (B12A)',
    value: 'B12A',
    level: 4,
  },
  {
    label: 'Fish Carrier (B12B)',
    value: 'B12B',
    level: 4,
  },
  {
    label: 'Fishing Support Vessel (B12D)',
    value: 'B12D',
    level: 4,
  },
  {
    label: 'Seal Catcher (B12E)',
    value: 'B12E',
    level: 4,
  },
  {
    label: 'Whale Catcher (B12F)',
    value: 'B12F',
    level: 4,
  },
  {
    label: 'Kelp Dredger (B12G)',
    value: 'B12G',
    level: 4,
  },
  {
    label: 'Pearl Shells Carrier (B12H)',
    value: 'B12H',
    level: 4,
  },
  {
    label: 'Offshore (B2)',
    value: 'B2',
    level: 2,
  },
  {
    label: 'Offshore Supply (B21)',
    value: 'B21',
    level: 3,
  },
  {
    label: 'Platform Supply Ship (B21A)',
    value: 'B21A',
    level: 4,
  },
  {
    label: 'Offshore Tug/Supply Ship (B21B)',
    value: 'B21B',
    level: 4,
  },
  {
    label: 'Other Offshore (B22)',
    value: 'B22',
    level: 3,
  },
  {
    label: 'Offshore Support Vessel (B22A)',
    value: 'B22A',
    level: 4,
  },
  {
    label: 'Drilling Ship (B22B)',
    value: 'B22B',
    level: 4,
  },
  {
    label: 'Pipe Layer (B22C)',
    value: 'B22C',
    level: 4,
  },
  {
    label: 'Production Testing Vessel (B22D)',
    value: 'B22D',
    level: 4,
  },
  {
    label: 'FPSO (Floating, Production, Storage, Offloading) (B22E)',
    value: 'B22E',
    level: 4,
  },
  {
    label: 'Well Stimulation Vessel (B22F)',
    value: 'B22F',
    level: 4,
  },
  {
    label: 'Standby Safety Vessel (B22G)',
    value: 'B22G',
    level: 4,
  },
  {
    label: 'FSO (Floating, Storage, Offloading) (B22H)',
    value: 'B22H',
    level: 4,
  },
  {
    label: 'Trenching Support Vessel (B22J)',
    value: 'B22J',
    level: 4,
  },
  {
    label: 'Pipe Burying Vessel (B22K)',
    value: 'B22K',
    level: 4,
  },
  {
    label: 'Miscellaneous (B3)',
    value: 'B3',
    level: 2,
  },
  {
    label: 'Research (B31)',
    value: 'B31',
    level: 3,
  },
  {
    label: 'Research Vessel (B31A)',
    value: 'B31A',
    level: 4,
  },
  {
    label: 'Towing / Pushing (B32)',
    value: 'B32',
    level: 3,
  },
  {
    label: 'Tug (B32A)',
    value: 'B32A',
    level: 4,
  },
  {
    label: 'Pusher Tug (B32B)',
    value: 'B32B',
    level: 4,
  },
  {
    label: 'Dredging (B33)',
    value: 'B33',
    level: 3,
  },
  {
    label: 'Dredger (B33A)',
    value: 'B33A',
    level: 4,
  },
  {
    label: 'Hopper Dredger (B33B)',
    value: 'B33B',
    level: 4,
  },
  {
    label: 'Other Activities (B34)',
    value: 'B34',
    level: 3,
  },
  {
    label: 'Motor Hopper (B34A)',
    value: 'B34A',
    level: 4,
  },
  {
    label: 'Crane Ship (B34B)',
    value: 'B34B',
    level: 4,
  },
  {
    label: 'Icebreaker (B34C)',
    value: 'B34C',
    level: 4,
  },
  {
    label: 'Cable Layer (B34D)',
    value: 'B34D',
    level: 4,
  },
  {
    label: 'Waste Disposal Vessel (B34E)',
    value: 'B34E',
    level: 4,
  },
  {
    label: 'Fire Fighting Vessel (B34F)',
    value: 'B34F',
    level: 4,
  },
  {
    label: 'Pollution Control Vessel (B34G)',
    value: 'B34G',
    level: 4,
  },
  {
    label: 'Patrol Vessel (B34H)',
    value: 'B34H',
    level: 4,
  },
  {
    label: 'Crew Boat (B34J)',
    value: 'B34J',
    level: 4,
  },
  {
    label: 'Training Ship (B34K)',
    value: 'B34K',
    level: 4,
  },
  {
    label: 'Utility Vessel (B34L)',
    value: 'B34L',
    level: 4,
  },
  {
    label: 'Search & Rescue Vessel (B34M)',
    value: 'B34M',
    level: 4,
  },
  {
    label: 'Pilot Vessel (B34N)',
    value: 'B34N',
    level: 4,
  },
  {
    label: 'Salvage Ship (B34P)',
    value: 'B34P',
    level: 4,
  },
  {
    label: 'Buoy/Lighthouse Vessel (B34Q)',
    value: 'B34Q',
    level: 4,
  },
  {
    label: 'Supply Tender (B34R)',
    value: 'B34R',
    level: 4,
  },
  {
    label: 'Mooring Vessel (B34S)',
    value: 'B34S',
    level: 4,
  },
  {
    label: 'Work/Repair Vessel (B34T)',
    value: 'B34T',
    level: 4,
  },
  {
    label: 'Hospital Vessel (B34U)',
    value: 'B34U',
    level: 4,
  },
  {
    label: 'Tank Cleaning Vessel (B34V)',
    value: 'B34V',
    level: 4,
  },
  {
    label: 'Trans Shipment Vessel (B34W)',
    value: 'B34W',
    level: 4,
  },
  {
    label: 'Anchor Hoy (B34X)',
    value: 'B34X',
    level: 4,
  },
  {
    label: 'Rocket Launch Support Ship (B34Y)',
    value: 'B34Y',
    level: 4,
  },
  {
    label: 'Log Tipping Ship (B34Z)',
    value: 'B34Z',
    level: 4,
  },
  {
    label: 'Other activities (B35)',
    value: 'B35',
    level: 3,
  },
  {
    label: 'Leisure Vessels (B35A)',
    value: 'B35A',
    level: 4,
  },
  {
    label: 'Dry Storage (B35B)',
    value: 'B35B',
    level: 4,
  },
  {
    label: 'Mining Vessel (B35C)',
    value: 'B35C',
    level: 4,
  },
  {
    label: 'Bunkering Tanker (B35E)',
    value: 'B35E',
    level: 4,
  },
  {
    label: 'Power Station Vessel (B35F)',
    value: 'B35F',
    level: 4,
  },
  {
    label: 'Vessel (function unknown) (B35X)',
    value: 'B35X',
    level: 4,
  },
  {
    label: 'Sailing Vessel (B35Y)',
    value: 'B35Y',
    level: 4,
  },
  {
    label: 'Non Seagoing Merchant Ships (W)',
    value: 'W',
    level: 1,
  },
  {
    label: 'Non-Seagoing Merchant Ships (W1)',
    value: 'W1',
    level: 2,
  },
  {
    label: 'Tanker (W11)',
    value: 'W11',
    level: 3,
  },
  {
    label: 'Inland Waterways Chemical Tanker (W11A)',
    value: 'W11A',
    level: 4,
  },
  {
    label: 'Inland Waterways Oil Tanker (W11B)',
    value: 'W11B',
    level: 4,
  },
  {
    label: 'Inland Waterways Other Liquids Tanker (W11C)',
    value: 'W11C',
    level: 4,
  },
  {
    label: 'Inland Waterways LPG Tanker (W11D)',
    value: 'W11D',
    level: 4,
  },
  {
    label: 'Dry Cargo/Passenger (W12)',
    value: 'W12',
    level: 3,
  },
  {
    label: 'Inland Waterways Dry Cargo (W12A)',
    value: 'W12A',
    level: 4,
  },
  {
    label: 'Inland Waterways Passenger/General Cargo (W12B)',
    value: 'W12B',
    level: 4,
  },
  {
    label: 'Inland Waterways Ro-Ro Cargo (W12C)',
    value: 'W12C',
    level: 4,
  },
  {
    label: 'Inland Waterways Passenger/Ro-Ro Cargo (W12D)',
    value: 'W12D',
    level: 4,
  },
  {
    label: 'Inland Waterways Passenger (W12E)',
    value: 'W12E',
    level: 4,
  },
  {
    label: 'Other Non-Seagoing (W13)',
    value: 'W13',
    level: 3,
  },
  {
    label: 'Inland Waterways Fishing (W13A)',
    value: 'W13A',
    level: 4,
  },
  {
    label: 'Inland Waterways Research (W13B)',
    value: 'W13B',
    level: 4,
  },
  {
    label: 'Inland Waterways Towing/Pushing (W13C)',
    value: 'W13C',
    level: 4,
  },
  {
    label: 'Inland Waterways Dredging (W13D)',
    value: 'W13D',
    level: 4,
  },
  {
    label: 'Inland Waterways Other Activities (W13E)',
    value: 'W13E',
    level: 4,
  },
  {
    label: 'Non Merchant (X)',
    value: 'X',
    level: 1,
  },
  {
    label: 'Non-Merchant Ships (X1)',
    value: 'X1',
    level: 2,
  },
  {
    label: 'Yacht (X11)',
    value: 'X11',
    level: 3,
  },
  {
    label: 'Yacht (X11A)',
    value: 'X11A',
    level: 4,
  },
  {
    label: 'Sail Training Ship (X11B)',
    value: 'X11B',
    level: 4,
  },
  {
    label: 'Naval/Naval Auxiliary (X11C)',
    value: 'X11C',
    level: 4,
  },
  {
    label: 'Other Non Merchant Ships (X11D)',
    value: 'X11D',
    level: 4,
  },
  {
    label: 'Non Propelled (Y)',
    value: 'Y',
    level: 1,
  },
  {
    label: 'Non-Propelled (Y1)',
    value: 'Y1',
    level: 2,
  },
  {
    label: 'Barge (Y11)',
    value: 'Y11',
    level: 3,
  },
  {
    label: 'Non Propelled Barge (Y11A)',
    value: 'Y11A',
    level: 4,
  },
  {
    label: 'Pontoon (Y11B)',
    value: 'Y11B',
    level: 4,
  },
  {
    label: 'Non Ship Structure (Z)',
    value: 'Z',
    level: 1,
  },
  {
    label: 'Non-Ship Structures (Z1)',
    value: 'Z1',
    level: 2,
  },
  {
    label: 'Non-ship structures (Z11)',
    value: 'Z11',
    level: 3,
  },
  {
    label: 'Air Cushion Vehicle (Hovercraft) (Z11A)',
    value: 'Z11A',
    level: 4,
  },
  {
    label: 'Floating Dock (Z11B)',
    value: 'Z11B',
    level: 4,
  },
  {
    label: 'Platform (Z11C)',
    value: 'Z11C',
    level: 4,
  },
  {
    label: 'Buoy (Z11D)',
    value: 'Z11D',
    level: 4,
  },
  {
    label: 'Linkspan/Jetty (Z11E)',
    value: 'Z11E',
    level: 4,
  },
  {
    label: 'Submersible (Z11F)',
    value: 'Z11F',
    level: 4,
  },
  {
    label: 'Underwater System (Z11G)',
    value: 'Z11G',
    level: 4,
  },
];

export const productFilters: ProductOption[] = [
  {
    value: 'Product:2-SDfEngines',
    label: '2-S DF Engines',
    type: 'Product',
    count: 15,
  },
  {
    value: 'Product:2-SOtherServices',
    label: '2-S Other Services',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Acoustics&SurveySystems(Acc)',
    label: 'Acoustics & Survey Systems (ACC)',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:Automation(Aut)',
    label: 'Automation (AUT)',
    type: 'Product',
    count: 8,
  },
  {
    value: 'Product:BallastWater',
    label: 'Ballast Water',
    type: 'Product',
    count: 5,
  },
  {
    value: 'Product:BearingsJp',
    label: 'Bearings JP',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:BespokeElectricalSystemsUk',
    label: 'Bespoke Electrical Systems UK',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:BiogasLiquefaction',
    label: 'Biogas Liquefaction',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:BiogasUpgrading',
    label: 'Biogas Upgrading',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Boiler',
    label: 'Boiler',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:BoilerTurbineGenerators',
    label: 'Boiler Turbine Generators',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Bolnes190',
    label: 'Bolnes 190',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:BulkheadSealsJp',
    label: 'Bulkhead Seals JP',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:BulkheadSealsUk',
    label: 'Bulkhead Seals UK',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:CompositeBearings',
    label: 'Composite Bearings',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Compressors',
    label: 'Compressors',
    type: 'Product',
    count: 7,
  },
  {
    value: 'Product:Cpp',
    label: 'CPP',
    type: 'Product',
    count: 22,
  },
  {
    value: 'Product:Crepelle',
    label: 'Crepelle',
    type: 'Product',
    count: 8,
  },
  {
    value: 'Product:Deutz232-234,616',
    label: 'Deutz 232-234, 616',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:Deutz528,628',
    label: 'Deutz 528, 628',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Deutz540,640',
    label: 'Deutz 540, 640',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Deutz604,620',
    label: 'Deutz 604, 620',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:DeutzOtherHighSpeed',
    label: 'Deutz Other High Speed',
    type: 'Product',
    count: 8,
  },
  {
    value: 'Product:DeutzOtherMediumSpeed',
    label: 'Deutz Other Medium Speed',
    type: 'Product',
    count: 16,
  },
  {
    value: 'Product:DirectElectricHeating',
    label: 'Direct Electric Heating',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:DynamicPositioning(Dp)',
    label: 'Dynamic Positioning (DP)',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:ElectricalSystems',
    label: 'Electrical Systems',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:EnergyStorage',
    label: 'Energy Storage',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:EnergySystems(Esy)',
    label: 'Energy Systems (ESY)',
    type: 'Product',
    count: 7,
  },
  {
    value: 'Product:Engine&PropulsionTraining',
    label: 'Engine & Propulsion Training',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:Eniram',
    label: 'Eniram',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:Ext.Communication(Com)',
    label: 'Ext. Communication (COM)',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:F240',
    label: 'F 240',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:FaceSealsEs',
    label: 'Face Seals ES',
    type: 'Product',
    count: 5,
  },
  {
    value: 'Product:FaceSealsIncl.Thruster/Submarine/Jet',
    label: 'Face Seals incl. Thruster / Submarine / Jet',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:FaceSealsSe',
    label: 'Face Seals SE',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:FaceSealsUk',
    label: 'Face Seals UK',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:FlareGasIgnition',
    label: 'FLARE GAS IGNITION',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Fpp',
    label: 'FPP',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:FreshWater',
    label: 'Fresh Water',
    type: 'Product',
    count: 12,
  },
  {
    value: 'Product:Gears',
    label: 'Gears',
    type: 'Product',
    count: 7,
  },
  {
    value: 'Product:GeneratingTurbine',
    label: 'Generating turbine',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:GeneratorBearings',
    label: 'Generator Bearings',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:GmtHighSpeed',
    label: 'GMT High Speed',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:GmtMediumSpeed',
    label: 'GMT Medium Speed',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Gvu',
    label: 'GVU',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:HarbourPowerSystems',
    label: 'Harbour Power Systems',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:HullAirLubrication',
    label: 'Hull Air Lubrication',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Hybrid&ElectricPropulsion',
    label: 'Hybrid & Electric Propulsion',
    type: 'Product',
    count: 31,
  },
  {
    value: 'Product:HydraulicCouplings,Bolts&Nuts',
    label: 'Hydraulic Couplings, Bolts & Nuts',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:HydroImpeller/HydroHubSeals',
    label: 'Hydro Impeller / Hydro Hub Seals',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:IndustrialProducts',
    label: 'Industrial Products',
    type: 'Product',
    count: 13,
  },
  {
    value: 'Product:IndustrialProductsAh',
    label: 'Industrial Products AH',
    type: 'Product',
    count: 8,
  },
  {
    value: 'Product:InertGas',
    label: 'Inert Gas',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:InternalCommunication(Int)',
    label: 'Internal Communication (INT)',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:LineshaftBearings',
    label: 'Lineshaft Bearings',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:LipSeals',
    label: 'Lip Seals',
    type: 'Product',
    count: 5,
  },
  {
    value: 'Product:LngLandBased',
    label: 'LNG LAND BASED',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:LngMarine',
    label: 'LNG MARINE',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:LngRegas',
    label: 'LNG REGAS',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Lngp',
    label: 'LNGP',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:LpgMarine',
    label: 'LPG MARINE',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:MissingProduct',
    label: 'Missing Product',
    type: 'Product',
    count: 9,
  },
  {
    value: 'Product:Navigation(Nav)',
    label: 'Navigation (NAV)',
    type: 'Product',
    count: 7,
  },
  {
    value: 'Product:Nohab',
    label: 'Nohab',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:Non-EngineRelated(Aux.Equipment)',
    label: 'Non-engine related (aux. equipment)',
    type: 'Product',
    count: 79,
  },
  {
    value: 'Product:Non-OemAutomation(Aut)',
    label: 'Non-OEM Automation (AUT)',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Non-OemInternalCommunication(Int)',
    label: 'Non-OEM Internal Communication (INT)',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Non-OemNavigation&Ext.Communication(Nav)',
    label: 'Non-OEM Navigation & Ext. Communication (NAV)',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:Non-Wärtsilä2-StrokeEngines',
    label: 'Non-Wärtsilä 2-Stroke Engines',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:Non-Wärtsilä4-StrokeEngines',
    label: 'Non-Wärtsilä 4-Stroke Engines',
    type: 'Product',
    count: 54,
  },
  {
    value: 'Product:Non-WärtsiläPropulsionEquipment',
    label: 'Non-Wärtsilä Propulsion Equipment',
    type: 'Product',
    count: 10,
  },
  {
    value: 'Product:NoxAbatement',
    label: 'NOx Abatement',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:OilyWater',
    label: 'Oily Water',
    type: 'Product',
    count: 11,
  },
  {
    value: 'Product:Other4-S',
    label: 'Other 4-S',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:OtherExhaustGas',
    label: 'Other Exhaust Gas',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:OtherOemEnginesOther',
    label: 'Other OEM Engines Other',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:OtherPowerConversion',
    label: 'Other Power Conversion',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:OtherR&OldSulzer',
    label: 'Other R & old Sulzer',
    type: 'Product',
    count: 65,
  },
  {
    value: 'Product:OtherSlsProducts',
    label: 'Other SLS products',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:PropulsionControls',
    label: 'Propulsion Controls',
    type: 'Product',
    count: 73,
  },
  {
    value: 'Product:PropulsionEnergySavingDevice',
    label: 'Propulsion Energy Saving Device',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:PumpTurbine',
    label: 'Pump Turbine',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Pumps-DeepWell',
    label: 'Pumps - Deep Well',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:Pumps-Inline',
    label: 'Pumps - Inline',
    type: 'Product',
    count: 14,
  },
  {
    value: 'Product:Pumps-Other',
    label: 'Pumps - Other',
    type: 'Product',
    count: 8,
  },
  {
    value: 'Product:R210/R150',
    label: 'R 210 / R 150',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Retired4-SEngines',
    label: 'Retired 4-S Engines',
    type: 'Product',
    count: 13,
  },
  {
    value: 'Product:RotorSail',
    label: 'Rotor Sail',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Rt-FlexLarge',
    label: 'RT-flex large',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:Rt-FlexSmall&Medium',
    label: 'RT-flex small & medium',
    type: 'Product',
    count: 24,
  },
  {
    value: 'Product:RtaMature',
    label: 'RTA mature',
    type: 'Product',
    count: 10,
  },
  {
    value: 'Product:RtaModern',
    label: 'RTA modern',
    type: 'Product',
    count: 24,
  },
  {
    value: 'Product:Rudder',
    label: 'Rudder',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:RudderSeals',
    label: 'Rudder Seals',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:ServiceOfferings',
    label: 'Service offerings',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:ShaftGeneratorSystems',
    label: 'Shaft Generator Systems',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:ShaftLineSupplies',
    label: 'Shaft Line Supplies',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Slrs',
    label: 'SLRS',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:SlsAuxiliarySystems',
    label: 'SLS Auxiliary systems',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:SolarPv',
    label: 'Solar PV',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:SoxAbatement',
    label: 'SOx Abatement',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:SpecialSealsJp',
    label: 'Special Seals JP',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:SpecialSealsSe',
    label: 'Special Seals SE',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:SpecialSealsUk',
    label: 'Special Seals UK',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:Sterntubes',
    label: 'Sterntubes',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:Stt',
    label: 'STT',
    type: 'Product',
    count: 14,
  },
  {
    value: 'Product:SulzerS/A',
    label: 'Sulzer S / A',
    type: 'Product',
    count: 23,
  },
  {
    value: 'Product:SulzerZ40',
    label: 'Sulzer Z 40',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:SulzerZa50',
    label: 'Sulzer ZA50',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Sw28/Sw280',
    label: 'SW 28 / SW 280',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Switchboard&ControlPanels',
    label: 'Switchboard & Control Panels',
    type: 'Product',
    count: 9,
  },
  {
    value: 'Product:TankControl-Land',
    label: 'Tank Control - Land',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:TankControl-Marine',
    label: 'Tank Control - Marine',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:ThrustBearings',
    label: 'Thrust Bearings',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:ThrusterSeals',
    label: 'Thruster Seals',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Tm410',
    label: 'TM 410',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Tm620',
    label: 'TM 620',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Tt',
    label: 'TT',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:TurnkeySolutions',
    label: 'Turnkey Solutions',
    type: 'Product',
    count: 7,
  },
  {
    value: 'Product:Ud(18,23,150,25)',
    label: 'UD (18, 23, 150, 25)',
    type: 'Product',
    count: 12,
  },
  {
    value: 'Product:Ud30',
    label: 'UD30',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Ud33,Ud45',
    label: 'UD33, UD45',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:Udx',
    label: 'UDX',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:UnderwaterServices',
    label: 'Underwater Services',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:Valves',
    label: 'Valves',
    type: 'Product',
    count: 9,
  },
  {
    value: 'Product:Vasa14/24',
    label: 'Vasa 14/24',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:Vasa22',
    label: 'Vasa 22',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Vasa32',
    label: 'Vasa 32',
    type: 'Product',
    count: 5,
  },
  {
    value: 'Product:VocRecovery',
    label: 'VOC RECOVERY',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:W14',
    label: 'W 14',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:W16',
    label: 'W 16',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:W20',
    label: 'W 20',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:W26',
    label: 'W 26',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:W31',
    label: 'W 31',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:W32',
    label: 'W 32',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:W33',
    label: 'W 33',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:W34',
    label: 'W 34',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:W38',
    label: 'W 38',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:W46',
    label: 'W 46',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:W46F',
    label: 'W 46 F',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:W46ts',
    label: 'W 46TS',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:W50',
    label: 'W 50',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:W64',
    label: 'W 64',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:W200,W220',
    label: 'W200, W220',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:WasteWater',
    label: 'Waste Water',
    type: 'Product',
    count: 13,
  },
  {
    value: 'Product:WaterJets',
    label: 'Water Jets',
    type: 'Product',
    count: 6,
  },
  {
    value: 'Product:WeldedEquipment',
    label: 'Welded equipment',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:WhiteMetalBearings',
    label: 'White metal Bearings',
    type: 'Product',
    count: 2,
  },
  {
    value: 'Product:Wichmann',
    label: 'Wichmann',
    type: 'Product',
    count: 3,
  },
  {
    value: 'Product:WindPower',
    label: 'Wind Power',
    type: 'Product',
    count: 1,
  },
  {
    value: 'Product:WärtsiläNohab',
    label: 'Wärtsilä Nohab',
    type: 'Product',
    count: 4,
  },
  {
    value: 'Product:XEngines',
    label: 'X engines',
    type: 'Product',
    count: 22,
  },
  {
    value: 'PortfolioCategory:2-SEngines',
    label: '2-S Engines',
    type: 'Portfolio Category',
    count: 184,
  },
  {
    value: 'PortfolioCategory:2-StrokeEngines',
    label: '2-Stroke Engines',
    type: 'Portfolio Category',
    count: 4,
  },
  {
    value: 'PortfolioCategory:4-SEngines',
    label: '4-S Engines',
    type: 'Portfolio Category',
    count: 325,
  },
  {
    value: 'PortfolioCategory:4-SEngines',
    label: '4-S ENGINES',
    type: 'Portfolio Category',
    count: 3,
  },
  {
    value: 'PortfolioCategory:Anc',
    label: 'ANC',
    type: 'Portfolio Category',
    count: 10,
  },
  {
    value: 'PortfolioCategory:Auxiliaries',
    label: 'Auxiliaries',
    type: 'Portfolio Category',
    count: 12,
  },
  {
    value: 'PortfolioCategory:Catalysts',
    label: 'Catalysts',
    type: 'Portfolio Category',
    count: 4,
  },
  {
    value: 'PortfolioCategory:Compressors',
    label: 'Compressors',
    type: 'Portfolio Category',
    count: 8,
  },
  {
    value: 'PortfolioCategory:Electrical&Automation',
    label: 'Electrical & Automation',
    type: 'Portfolio Category',
    count: 1,
  },
  {
    value: 'PortfolioCategory:ElectricalPowergendistrdrives',
    label: 'Electrical PowerGenDistrDrives',
    type: 'Portfolio Category',
    count: 8,
  },
  {
    value: 'PortfolioCategory:EnergyStorage',
    label: 'Energy Storage',
    type: 'Portfolio Category',
    count: 9,
  },
  {
    value: 'PortfolioCategory:Entertainment',
    label: 'Entertainment',
    type: 'Portfolio Category',
    count: 4,
  },
  {
    value: 'PortfolioCategory:Epms',
    label: 'EPMS',
    type: 'Portfolio Category',
    count: 52,
  },
  {
    value: 'PortfolioCategory:ExhaustTreatment',
    label: 'Exhaust Treatment',
    type: 'Portfolio Category',
    count: 9,
  },
  {
    value: 'PortfolioCategory:Fgss',
    label: 'FGSS',
    type: 'Portfolio Category',
    count: 5,
  },
  {
    value: 'PortfolioCategory:GasSolutions',
    label: 'Gas Solutions',
    type: 'Portfolio Category',
    count: 14,
  },
  {
    value: 'PortfolioCategory:Hydro',
    label: 'Hydro',
    type: 'Portfolio Category',
    count: 7,
  },
  {
    value: 'PortfolioCategory:Industrial',
    label: 'Industrial',
    type: 'Portfolio Category',
    count: 9,
  },
  {
    value: 'PortfolioCategory:Other',
    label: 'Other',
    type: 'Portfolio Category',
    count: 7,
  },
  {
    value: 'PortfolioCategory:Propellers&Transmissions',
    label: 'Propellers & Transmissions',
    type: 'Portfolio Category',
    count: 43,
  },
  {
    value: 'PortfolioCategory:PropulsionAuxiliarySystem',
    label: 'Propulsion Auxiliary System',
    type: 'Portfolio Category',
    count: 4,
  },
  {
    value: 'PortfolioCategory:Pumps&Valves',
    label: 'Pumps & Valves',
    type: 'Portfolio Category',
    count: 36,
  },
  {
    value: 'PortfolioCategory:Seals&Bearings',
    label: 'Seals & Bearings',
    type: 'Portfolio Category',
    count: 55,
  },
  {
    value: 'PortfolioCategory:Services4.0',
    label: 'Services 4.0',
    type: 'Portfolio Category',
    count: 1,
  },
  {
    value: 'PortfolioCategory:ShipDesign',
    label: 'Ship Design',
    type: 'Portfolio Category',
    count: 1,
  },
  {
    value: 'PortfolioCategory:SolarPower',
    label: 'Solar Power',
    type: 'Portfolio Category',
    count: 1,
  },
  {
    value: 'PortfolioCategory:SpecialProducts',
    label: 'Special Products',
    type: 'Portfolio Category',
    count: 10,
  },
  {
    value: 'PortfolioCategory:TankControl',
    label: 'Tank Control',
    type: 'Portfolio Category',
    count: 2,
  },
  {
    value: 'PortfolioCategory:Thrusters&Pcs',
    label: 'Thrusters & PCS',
    type: 'Portfolio Category',
    count: 93,
  },
  {
    value: 'PortfolioCategory:TurnkeySolutions',
    label: 'Turnkey Solutions',
    type: 'Portfolio Category',
    count: 2,
  },
  {
    value: 'PortfolioCategory:Voyage',
    label: 'Voyage',
    type: 'Portfolio Category',
    count: 32,
  },
  {
    value: 'PortfolioCategory:Water&Waste',
    label: 'Water & Waste',
    type: 'Portfolio Category',
    count: 41,
  },
  {
    value: 'PortfolioCategory:WaterJets',
    label: 'Water Jets',
    type: 'Portfolio Category',
    count: 7,
  },
  {
    value: 'PortfolioCategory:Wind',
    label: 'Wind',
    type: 'Portfolio Category',
    count: 1,
  },
  {
    value: 'ProductLine:2-Stroke',
    label: '2-stroke',
    type: 'Product Line',
    count: 177,
  },
  {
    value: 'ProductLine:2-Stroke',
    label: '2-Stroke',
    type: 'Product Line',
    count: 1,
  },
  {
    value: 'ProductLine:4-Stroke',
    label: '4-stroke',
    type: 'Product Line',
    count: 98,
  },
  {
    value: 'ProductLine:4-Stroke',
    label: '4-Stroke',
    type: 'Product Line',
    count: 4,
  },
  {
    value: 'ProductLine:EnergyStorage',
    label: 'Energy Storage',
    type: 'Product Line',
    count: 4,
  },
  {
    value: 'ProductLine:ExhaustTreatment',
    label: 'Exhaust Treatment',
    type: 'Product Line',
    count: 4,
  },
  {
    value: 'ProductLine:GasSolutions',
    label: 'Gas Solutions',
    type: 'Product Line',
    count: 16,
  },
  {
    value: 'ProductLine:Other',
    label: 'Other',
    type: 'Product Line',
    count: 209,
  },
  {
    value: 'ProductLine:OtherOemEngines',
    label: 'Other OEM Engines',
    type: 'Product Line',
    count: 94,
  },
  {
    value: 'ProductLine:Propulsion',
    label: 'Propulsion',
    type: 'Product Line',
    count: 175,
  },
  {
    value: 'ProductLine:Quantiparts',
    label: 'Quantiparts',
    type: 'Product Line',
    count: 100,
  },
  {
    value: 'ProductLine:Quantiparts',
    label: 'QuantiParts',
    type: 'Product Line',
    count: 3,
  },
  {
    value: 'ProductLine:Seals&Bearings',
    label: 'Seals & Bearings',
    type: 'Product Line',
    count: 60,
  },
  {
    value: 'SalesOrganisation:AE12',
    label: 'AE12',
    type: 'Sales Organisation',
    count: 1,
  },
  {
    value: 'SalesOrganisation:CA11',
    label: 'CA11',
    type: 'Sales Organisation',
    count: 1,
  },
  {
    value: 'SalesOrganisation:CH10',
    label: 'CH10',
    type: 'Sales Organisation',
    count: 180,
  },
  {
    value: 'SalesOrganisation:DE16',
    label: 'DE16',
    type: 'Sales Organisation',
    count: 11,
  },
  {
    value: 'SalesOrganisation:DE19',
    label: 'DE19',
    type: 'Sales Organisation',
    count: 3,
  },
  {
    value: 'SalesOrganisation:ES10',
    label: 'ES10',
    type: 'Sales Organisation',
    count: 16,
  },
  {
    value: 'SalesOrganisation:FI14',
    label: 'FI14',
    type: 'Sales Organisation',
    count: 63,
  },
  {
    value: 'SalesOrganisation:FI21',
    label: 'FI21',
    type: 'Sales Organisation',
    count: 12,
  },
  {
    value: 'SalesOrganisation:FI33',
    label: 'FI33',
    type: 'Sales Organisation',
    count: 1,
  },
  {
    value: 'SalesOrganisation:FR11',
    label: 'FR11',
    type: 'Sales Organisation',
    count: 29,
  },
  {
    value: 'SalesOrganisation:GB10',
    label: 'GB10',
    type: 'Sales Organisation',
    count: 14,
  },
  {
    value: 'SalesOrganisation:GB23',
    label: 'GB23',
    type: 'Sales Organisation',
    count: 7,
  },
  {
    value: 'SalesOrganisation:GB37',
    label: 'GB37',
    type: 'Sales Organisation',
    count: 1,
  },
  {
    value: 'SalesOrganisation:GB44',
    label: 'GB44',
    type: 'Sales Organisation',
    count: 1,
  },
  {
    value: 'SalesOrganisation:GB45',
    label: 'GB45',
    type: 'Sales Organisation',
    count: 26,
  },
  {
    value: 'SalesOrganisation:GLOB',
    label: 'GLOB',
    type: 'Sales Organisation',
    count: 310,
  },
  {
    value: 'SalesOrganisation:IE11',
    label: 'IE11',
    type: 'Sales Organisation',
    count: 5,
  },
  {
    value: 'SalesOrganisation:IT10',
    label: 'IT10',
    type: 'Sales Organisation',
    count: 21,
  },
  {
    value: 'SalesOrganisation:JP11',
    label: 'JP11',
    type: 'Sales Organisation',
    count: 15,
  },
  {
    value: 'SalesOrganisation:NL13',
    label: 'NL13',
    type: 'Sales Organisation',
    count: 107,
  },
  {
    value: 'SalesOrganisation:NL27',
    label: 'NL27',
    type: 'Sales Organisation',
    count: 92,
  },
  {
    value: 'SalesOrganisation:NO10',
    label: 'NO10',
    type: 'Sales Organisation',
    count: 68,
  },
  {
    value: 'SalesOrganisation:NO15',
    label: 'NO15',
    type: 'Sales Organisation',
    count: 3,
  },
  {
    value: 'SalesOrganisation:NO16',
    label: 'NO16',
    type: 'Sales Organisation',
    count: 9,
  },
  {
    value: 'SalesOrganisation:RU15',
    label: 'RU15',
    type: 'Sales Organisation',
    count: 1,
  },
  {
    value: 'SalesOrganisation:SE11',
    label: 'SE11',
    type: 'Sales Organisation',
    count: 7,
  },
  {
    value: 'ProductCategory:2-StrokeAndSpecializedServices',
    label: '2-Stroke and Specialized Services',
    type: 'Product Category',
    count: 227,
  },
  {
    value: 'ProductCategory:Acoustics&SurveySystems(Acc)',
    label: 'Acoustics & Survey Systems (ACC)',
    type: 'Product Category',
    count: 6,
  },
  {
    value: 'ProductCategory:Autom.,Navi.,Com.(Anc)',
    label: 'Autom., Navi., Com. (ANC)',
    type: 'Product Category',
    count: 17,
  },
  {
    value: 'ProductCategory:AuxilliarySystems',
    label: 'Auxilliary Systems',
    type: 'Product Category',
    count: 87,
  },
  {
    value: 'ProductCategory:CargoHandling',
    label: 'Cargo Handling',
    type: 'Product Category',
    count: 4,
  },
  {
    value: 'ProductCategory:ElectricalAndPowerManagementSystems',
    label: 'Electrical and Power Management Systems',
    type: 'Product Category',
    count: 52,
  },
  {
    value: 'ProductCategory:EnergyStorage',
    label: 'Energy Storage',
    type: 'Product Category',
    count: 6,
  },
  {
    value: 'ProductCategory:EnergySystems(Esy)',
    label: 'Energy Systems (ESY)',
    type: 'Product Category',
    count: 7,
  },
  {
    value: 'ProductCategory:ExhaustTreatment',
    label: 'Exhaust Treatment',
    type: 'Product Category',
    count: 9,
  },
  {
    value: 'ProductCategory:FlareGasIgnition',
    label: 'Flare Gas Ignition',
    type: 'Product Category',
    count: 2,
  },
  {
    value: 'ProductCategory:Funa',
    label: 'FUNA',
    type: 'Product Category',
    count: 4,
  },
  {
    value: 'ProductCategory:Gears',
    label: 'Gears',
    type: 'Product Category',
    count: 7,
  },
  {
    value: 'ProductCategory:HydroPower',
    label: 'Hydro Power',
    type: 'Product Category',
    count: 15,
  },
  {
    value: 'ProductCategory:IndustrialPower',
    label: 'Industrial Power',
    type: 'Product Category',
    count: 13,
  },
  {
    value: 'ProductCategory:InertGas',
    label: 'Inert Gas',
    type: 'Product Category',
    count: 3,
  },
  {
    value: 'ProductCategory:KeyAccountSolutions',
    label: 'Key Account Solutions',
    type: 'Product Category',
    count: 6,
  },
  {
    value: 'ProductCategory:LargeBoreEngines',
    label: 'Large Bore Engines',
    type: 'Product Category',
    count: 17,
  },
  {
    value: 'ProductCategory:Liquefaction&Gasification',
    label: 'Liquefaction & Gasification',
    type: 'Product Category',
    count: 3,
  },
  {
    value: 'ProductCategory:MarineElectricalSystems',
    label: 'Marine Electrical Systems',
    type: 'Product Category',
    count: 2,
  },
  {
    value: 'ProductCategory:MediumBoreEngines',
    label: 'Medium Bore Engines',
    type: 'Product Category',
    count: 19,
  },
  {
    value: 'ProductCategory:MissingProductCategory',
    label: 'Missing Product Category',
    type: 'Product Category',
    count: 9,
  },
  {
    value: 'ProductCategory:Non-OemAnc',
    label: 'Non-OEM ANC',
    type: 'Product Category',
    count: 7,
  },
  {
    value: 'ProductCategory:Other4-S',
    label: 'Other 4-S',
    type: 'Product Category',
    count: 20,
  },
  {
    value: 'ProductCategory:OtherOemPropulsion',
    label: 'Other OEM Propulsion',
    type: 'Product Category',
    count: 10,
  },
  {
    value: 'ProductCategory:OtherPropulsion',
    label: 'Other Propulsion',
    type: 'Product Category',
    count: 2,
  },
  {
    value: 'ProductCategory:OtherPropulsionServices',
    label: 'Other Propulsion Services',
    type: 'Product Category',
    count: 8,
  },
  {
    value: 'ProductCategory:Propellers',
    label: 'Propellers',
    type: 'Product Category',
    count: 27,
  },
  {
    value: 'ProductCategory:PropulsionControls',
    label: 'Propulsion controls',
    type: 'Product Category',
    count: 1,
  },
  {
    value: 'ProductCategory:PropulsionControls',
    label: 'Propulsion Controls',
    type: 'Product Category',
    count: 72,
  },
  {
    value: 'ProductCategory:Pumps',
    label: 'Pumps',
    type: 'Product Category',
    count: 20,
  },
  {
    value: 'ProductCategory:Quantiparts',
    label: 'QuantiParts',
    type: 'Product Category',
    count: 112,
  },
  {
    value: 'ProductCategory:RenewableGases',
    label: 'Renewable Gases',
    type: 'Product Category',
    count: 3,
  },
  {
    value: 'ProductCategory:SlsChina/Spain',
    label: 'SLS China / Spain',
    type: 'Product Category',
    count: 24,
  },
  {
    value: 'ProductCategory:SlsJapan',
    label: 'SLS Japan',
    type: 'Product Category',
    count: 16,
  },
  {
    value: 'ProductCategory:SlsUk',
    label: 'SLS UK',
    type: 'Product Category',
    count: 14,
  },
  {
    value: 'ProductCategory:SlsUnderwaterServices',
    label: 'SLS Underwater services',
    type: 'Product Category',
    count: 1,
  },
  {
    value: 'ProductCategory:SmallBoreEngines',
    label: 'Small Bore Engines',
    type: 'Product Category',
    count: 10,
  },
  {
    value: 'ProductCategory:SolarPower',
    label: 'Solar Power',
    type: 'Product Category',
    count: 1,
  },
  {
    value: 'ProductCategory:TankControl',
    label: 'Tank Control',
    type: 'Product Category',
    count: 3,
  },
  {
    value: 'ProductCategory:Thrusters',
    label: 'Thrusters',
    type: 'Product Category',
    count: 20,
  },
  {
    value: 'ProductCategory:TurnkeySolutions',
    label: 'Turnkey Solutions',
    type: 'Product Category',
    count: 7,
  },
  {
    value: 'ProductCategory:Valves',
    label: 'Valves',
    type: 'Product Category',
    count: 9,
  },
  {
    value: 'ProductCategory:Water&Waste',
    label: 'Water & Waste',
    type: 'Product Category',
    count: 56,
  },
  {
    value: 'ProductCategory:WindPower',
    label: 'Wind Power',
    type: 'Product Category',
    count: 1,
  },
  {
    value: 'Business:MarineSystems',
    label: 'Marine Systems',
    type: 'Business',
    count: 88,
  },
  {
    value: 'Business:MissingBusiness',
    label: 'Missing Business',
    type: 'Business',
    count: 9,
  },
  {
    value: 'Business:PortfolioBusinesses',
    label: 'Portfolio Businesses',
    type: 'Business',
    count: 120,
  },
  {
    value: 'Business:PowerSystems',
    label: 'Power Systems',
    type: 'Business',
    count: 712,
  },
  {
    value: 'Business:Voyage',
    label: 'Voyage',
    type: 'Business',
    count: 30,
  },
  {
    value: 'ServicesPortfolio:Non-Wärtsilä',
    label: 'Non-Wärtsilä',
    type: 'Services Portfolio',
    count: 245,
  },
  {
    value: 'ServicesPortfolio:Wärtsilä',
    label: 'Wärtsilä',
    type: 'Services Portfolio',
    count: 761,
  },
  {
    value: 'BusinessLine:2-StrokeAndSpecializedServices',
    label: '2-Stroke and Specialized Services',
    type: 'Business Line',
    count: 218,
  },
  {
    value: 'BusinessLine:4-StrokeEngines',
    label: '4-Stroke Engines',
    type: 'Business Line',
    count: 88,
  },
  {
    value: 'BusinessLine:AmericanHydro',
    label: 'American Hydro',
    type: 'Business Line',
    count: 15,
  },
  {
    value: 'BusinessLine:Autom.,Navi.,Com.(Anc)',
    label: 'Autom., Navi., Com. (ANC)',
    type: 'Business Line',
    count: 17,
  },
  {
    value: 'BusinessLine:Eniram',
    label: 'Eniram',
    type: 'Business Line',
    count: 6,
  },
  {
    value: 'BusinessLine:Entertainment',
    label: 'Entertainment',
    type: 'Business Line',
    count: 4,
  },
  {
    value: 'BusinessLine:ExhaustTreatment',
    label: 'Exhaust Treatment',
    type: 'Business Line',
    count: 12,
  },
  {
    value: 'BusinessLine:GasSolutions',
    label: 'Gas Solutions',
    type: 'Business Line',
    count: 16,
  },
  {
    value: 'BusinessLine:Non-OemE&A',
    label: 'Non-OEM E&A',
    type: 'Business Line',
    count: 8,
  },
  {
    value: 'BusinessLine:PowerConversions',
    label: 'Power Conversions',
    type: 'Business Line',
    count: 57,
  },
  {
    value: 'BusinessLine:PropulsionSystems',
    label: 'Propulsion Systems',
    type: 'Business Line',
    count: 134,
  },
  {
    value: 'BusinessLine:Quantiparts',
    label: 'QuantiParts',
    type: 'Business Line',
    count: 108,
  },
  {
    value: 'BusinessLine:Renewables',
    label: 'Renewables',
    type: 'Business Line',
    count: 22,
  },
  {
    value: 'BusinessLine:Seals&Bearings',
    label: 'Seals & Bearings',
    type: 'Business Line',
    count: 51,
  },
  {
    value: 'BusinessLine:SpecialProducts',
    label: 'Special Products',
    type: 'Business Line',
    count: 13,
  },
  {
    value: 'BusinessLine:TankControlSystems',
    label: 'Tank Control Systems',
    type: 'Business Line',
    count: 3,
  },
  {
    value: 'BusinessLine:Valves',
    label: 'Valves',
    type: 'Business Line',
    count: 9,
  },
  {
    value: 'BusinessLine:Water&Waste',
    label: 'Water & Waste',
    type: 'Business Line',
    count: 76,
  },
  {
    value: 'RefTypeGroup:4sEngineAutomation',
    label: '4S Engine Automation',
    type: 'Ref Type Group',
    count: 5,
  },
  {
    value: 'RefTypeGroup:4sEngineAuxiliarySystems',
    label: '4S Engine Auxiliary systems',
    type: 'Ref Type Group',
    count: 69,
  },
  {
    value: 'RefTypeGroup:Cbm-Propulsion',
    label: 'CBM-Propulsion',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Cpp-Rud-Wj-Fpp',
    label: 'CPP-Rud-WJ-FPP',
    type: 'Ref Type Group',
    count: 39,
  },
  {
    value: 'RefTypeGroup:Crepelle',
    label: 'Crepelle',
    type: 'Ref Type Group',
    count: 10,
  },
  {
    value: 'RefTypeGroup:EnergyStorage',
    label: 'Energy Storage',
    type: 'Ref Type Group',
    count: 6,
  },
  {
    value: 'RefTypeGroup:Environmental',
    label: 'Environmental',
    type: 'Ref Type Group',
    count: 30,
  },
  {
    value: 'RefTypeGroup:GasSolutions',
    label: 'Gas Solutions',
    type: 'Ref Type Group',
    count: 16,
  },
  {
    value: 'RefTypeGroup:Gears',
    label: 'Gears',
    type: 'Ref Type Group',
    count: 8,
  },
  {
    value: 'RefTypeGroup:Nohab',
    label: 'Nohab',
    type: 'Ref Type Group',
    count: 7,
  },
  {
    value: 'RefTypeGroup:Other',
    label: 'Other',
    type: 'Ref Type Group',
    count: 117,
  },
  {
    value: 'RefTypeGroup:PlantAutomation',
    label: 'Plant Automation',
    type: 'Ref Type Group',
    count: 17,
  },
  {
    value: 'RefTypeGroup:PropulsionControls',
    label: 'Propulsion controls',
    type: 'Ref Type Group',
    count: 72,
  },
  {
    value: 'RefTypeGroup:QuantipartsGmt',
    label: 'Quantiparts GMT',
    type: 'Ref Type Group',
    count: 4,
  },
  {
    value: 'RefTypeGroup:Seals&BearingsGb',
    label: 'Seals&Bearings GB',
    type: 'Ref Type Group',
    count: 15,
  },
  {
    value: 'RefTypeGroup:Seals&BearingsJp',
    label: 'Seals&Bearings JP',
    type: 'Ref Type Group',
    count: 15,
  },
  {
    value: 'RefTypeGroup:Seals&BearingsWes',
    label: 'Seals&Bearings WES',
    type: 'Ref Type Group',
    count: 13,
  },
  {
    value: 'RefTypeGroup:Seals&BearingsWse',
    label: 'Seals&Bearings WSE',
    type: 'Ref Type Group',
    count: 5,
  },
  {
    value: 'RefTypeGroup:Senitec',
    label: 'Senitec',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Stt-Tt',
    label: 'STT-TT',
    type: 'Ref Type Group',
    count: 22,
  },
  {
    value: 'RefTypeGroup:Ud',
    label: 'UD',
    type: 'Ref Type Group',
    count: 18,
  },
  {
    value: 'RefTypeGroup:Vasa1424',
    label: 'Vasa 14 24',
    type: 'Ref Type Group',
    count: 2,
  },
  {
    value: 'RefTypeGroup:WaterSolutions',
    label: 'Water Solutions',
    type: 'Ref Type Group',
    count: 27,
  },
  {
    value: 'RefTypeGroup:Wch2-Stroke',
    label: 'WCH 2-Stroke',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wch2-Stroke',
    label: 'WCH 2-stroke',
    type: 'Ref Type Group',
    count: 173,
  },
  {
    value: 'RefTypeGroup:WnlEngines',
    label: 'WNL Engines',
    type: 'Ref Type Group',
    count: 70,
  },
  {
    value: 'RefTypeGroup:WnlPropulsion',
    label: 'WNL Propulsion',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wno2-Stroke',
    label: 'WNO 2-stroke',
    type: 'Ref Type Group',
    count: 5,
  },
  {
    value: 'RefTypeGroup:WnoAutomation',
    label: 'WNO Automation',
    type: 'Ref Type Group',
    count: 35,
  },
  {
    value: 'RefTypeGroup:Wärtsilä14',
    label: 'Wärtsilä 14',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä16',
    label: 'Wärtsilä 16',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä20',
    label: 'Wärtsilä 20',
    type: 'Ref Type Group',
    count: 2,
  },
  {
    value: 'RefTypeGroup:Wärtsilä26',
    label: 'Wärtsilä 26',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä28sg',
    label: 'Wärtsilä 28SG',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä31',
    label: 'Wärtsilä 31',
    type: 'Ref Type Group',
    count: 2,
  },
  {
    value: 'RefTypeGroup:Wärtsilä32',
    label: 'Wärtsilä 32',
    type: 'Ref Type Group',
    count: 2,
  },
  {
    value: 'RefTypeGroup:Wärtsilä34sg',
    label: 'Wärtsilä 34SG',
    type: 'Ref Type Group',
    count: 2,
  },
  {
    value: 'RefTypeGroup:Wärtsilä38b',
    label: 'Wärtsilä 38B',
    type: 'Ref Type Group',
    count: 4,
  },
  {
    value: 'RefTypeGroup:Wärtsilä46',
    label: 'Wärtsilä 46',
    type: 'Ref Type Group',
    count: 6,
  },
  {
    value: 'RefTypeGroup:Wärtsilä46f',
    label: 'Wärtsilä 46F',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä46ts',
    label: 'Wärtsilä 46TS',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä64',
    label: 'Wärtsilä 64',
    type: 'Ref Type Group',
    count: 1,
  },
  {
    value: 'RefTypeGroup:Wärtsilä_200',
    label: 'Wärtsilä_200',
    type: 'Ref Type Group',
    count: 7,
  },
  {
    value: 'RefTypeGroup:ZAndGmt',
    label: 'Z and GMT',
    type: 'Ref Type Group',
    count: 13,
  },
  {
    value: 'BusinessUnit:AmericanHydro',
    label: 'American Hydro',
    type: 'Business Unit',
    count: 15,
  },
  {
    value: 'BusinessUnit:EnergyStorage&Optimisation',
    label: 'Energy Storage & Optimisation',
    type: 'Business Unit',
    count: 6,
  },
  {
    value: 'BusinessUnit:EntertainmentSystems',
    label: 'Entertainment Systems',
    type: 'Business Unit',
    count: 4,
  },
  {
    value: 'BusinessUnit:ExhaustTreatment',
    label: 'Exhaust Treatment',
    type: 'Business Unit',
    count: 9,
  },
  {
    value: 'BusinessUnit:GasSolutions',
    label: 'Gas Solutions',
    type: 'Business Unit',
    count: 15,
  },
  {
    value: 'BusinessUnit:KeyAccountSolutions',
    label: 'Key Account Solutions',
    type: 'Business Unit',
    count: 6,
  },
  {
    value: 'BusinessUnit:MarineElectricalSystems',
    label: 'Marine Electrical Systems',
    type: 'Business Unit',
    count: 9,
  },
  {
    value: 'BusinessUnit:MarineVoyageService',
    label: 'Marine Voyage Service',
    type: 'Business Unit',
    count: 24,
  },
  {
    value: 'BusinessUnit:MissingBusinessUnit',
    label: 'Missing Business Unit',
    type: 'Business Unit',
    count: 9,
  },
  {
    value: 'BusinessUnit:PowerSupply',
    label: 'Power Supply',
    type: 'Business Unit',
    count: 492,
  },
  {
    value: 'BusinessUnit:ProjectServices',
    label: 'Project Services',
    type: 'Business Unit',
    count: 52,
  },
  {
    value: 'BusinessUnit:Propulsion',
    label: 'Propulsion',
    type: 'Business Unit',
    count: 147,
  },
  {
    value: 'BusinessUnit:Renewables',
    label: 'Renewables',
    type: 'Business Unit',
    count: 15,
  },
  {
    value: 'BusinessUnit:ShaftLineSolutions',
    label: 'Shaft Line Solutions',
    type: 'Business Unit',
    count: 55,
  },
  {
    value: 'BusinessUnit:SpecialProducts',
    label: 'Special Products',
    type: 'Business Unit',
    count: 13,
  },
  {
    value: 'BusinessUnit:TankControlSystems',
    label: 'Tank Control Systems',
    type: 'Business Unit',
    count: 3,
  },
  {
    value: 'BusinessUnit:Valves',
    label: 'Valves',
    type: 'Business Unit',
    count: 9,
  },
  {
    value: 'BusinessUnit:Water&Waste',
    label: 'Water & Waste',
    type: 'Business Unit',
    count: 76,
  },
  {
    label: 'acbLips CPP (CPP-ACB)',
    value: 'CPP-ACB',
    type: 'Product Reference Type',
  },
  {
    label: 'AIFO (AIFO)',
    value: 'AIFO',
    type: 'Product Reference Type',
  },
  {
    label: 'Air & Gas Compressors (COMPR-AIR-GAS)',
    value: 'COMPR-AIR-GAS',
    type: 'Product Reference Type',
  },
  {
    label: 'Akasaka (AKASAKA)',
    value: 'AKASAKA',
    type: 'Product Reference Type',
  },
  {
    label: 'ANC Safety Communication Syste (ANC_SAFE COM SYS)',
    value: 'ANC_SAFE COM SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'ANC Subscriptions (ANC_SUBSCRIPTIONS)',
    value: 'ANC_SUBSCRIPTIONS',
    type: 'Product Reference Type',
  },
  {
    label: 'Ancos 2000 upgrade kit (CTRL-ANCOS2000R)',
    value: 'CTRL-ANCOS2000R',
    type: 'Product Reference Type',
  },
  {
    label: 'Auxiliaries (AUX)',
    value: 'AUX',
    type: 'Product Reference Type',
  },
  {
    label: 'Ballast Water Treatment (BWT)',
    value: 'BWT',
    type: 'Product Reference Type',
  },
  {
    label: 'Battery System (BATTERY_SYS)',
    value: 'BATTERY_SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Battery System (BATTERY_SYSTEM)',
    value: 'BATTERY_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Baudouin (BAUDOUIN)',
    value: 'BAUDOUIN',
    type: 'Product Reference Type',
  },
  {
    label: 'Bearing-OBSQ - Thrust Bearings (BEARING-OBSQ)',
    value: 'BEARING-OBSQ',
    type: 'Product Reference Type',
  },
  {
    label: 'Bearing-OBSY - Thrust Bearings (BEARING-OBSY)',
    value: 'BEARING-OBSY',
    type: 'Product Reference Type',
  },
  {
    label: 'Bearing-OMS - Sternsafe (BEARING-OMS)',
    value: 'BEARING-OMS',
    type: 'Product Reference Type',
  },
  {
    label: 'Bearing-OPSQ - Int. Shaft Bear (BEARING-OPSQ)',
    value: 'BEARING-OPSQ',
    type: 'Product Reference Type',
  },
  {
    label: 'Bearing-OPSY - Int. Shaft Bear (BEARING-OPSY)',
    value: 'BEARING-OPSY',
    type: 'Product Reference Type',
  },
  {
    label: 'BECOS Upgrade Kit (CTRL-BECOSR)',
    value: 'CTRL-BECOSR',
    type: 'Product Reference Type',
  },
  {
    label: 'Bergen (BERGEN)',
    value: 'BERGEN',
    type: 'Product Reference Type',
  },
  {
    label: 'Berliet (BERLIET)',
    value: 'BERLIET',
    type: 'Product Reference Type',
  },
  {
    label: 'Bespoke Electrical systems (BESPOKE_ELEC_UK)',
    value: 'BESPOKE_ELEC_UK',
    type: 'Product Reference Type',
  },
  {
    label: 'Bilge Guard (BWG)',
    value: 'BWG',
    type: 'Product Reference Type',
  },
  {
    label: 'Biogas Liquefaction Plant (LBG PLANT)',
    value: 'LBG PLANT',
    type: 'Product Reference Type',
  },
  {
    label: 'Boiler Turbine Generators (BTG)',
    value: 'BTG',
    type: 'Product Reference Type',
  },
  {
    label: 'Bolnes 190 (190)',
    value: '190',
    type: 'Product Reference Type',
  },
  {
    label: 'Briggs & Stratton (BRIG_STRATTON)',
    value: 'BRIG_STRATTON',
    type: 'Product Reference Type',
  },
  {
    label: 'BWMS-Wärtsilä AQUARIUS® EC (BWMS EC)',
    value: 'BWMS EC',
    type: 'Product Reference Type',
  },
  {
    label: 'BWMS-Wärtsilä AQUARIUS® UV (BWMS UV)',
    value: 'BWMS UV',
    type: 'Product Reference Type',
  },
  {
    label: 'Caterpillar (CATERPILLAR)',
    value: 'CATERPILLAR',
    type: 'Product Reference Type',
  },
  {
    label: 'CCS Carbon Capture and Storage (CCS)',
    value: 'CCS',
    type: 'Product Reference Type',
  },
  {
    label: 'CIPS Propeller (CIPS)',
    value: 'CIPS',
    type: 'Product Reference Type',
  },
  {
    label: 'CNG Compressors (COMPR-CNG)',
    value: 'COMPR-CNG',
    type: 'Product Reference Type',
  },
  {
    label: 'Compact Steerable Thruster/CPP (LCT-CS)',
    value: 'LCT-CS',
    type: 'Product Reference Type',
  },
  {
    label: 'Compact Steerable Thruster/FPP (LCT-FS)',
    value: 'LCT-FS',
    type: 'Product Reference Type',
  },
  {
    label: 'Composite bearing (COMPOSITE BEARING)',
    value: 'COMPOSITE BEARING',
    type: 'Product Reference Type',
  },
  {
    label: 'Condition mon.sys. propulsion (CMS-PROP)',
    value: 'CMS-PROP',
    type: 'Product Reference Type',
  },
  {
    label: 'Control System for Hull Air Lu (CTRL-HAL)',
    value: 'CTRL-HAL',
    type: 'Product Reference Type',
  },
  {
    label: 'Control System for Rotor Sail (CTRL-RTRS)',
    value: 'CTRL-RTRS',
    type: 'Product Reference Type',
  },
  {
    label: 'Control System for Rudder (CTRL-RUD)',
    value: 'CTRL-RUD',
    type: 'Product Reference Type',
  },
  {
    label: 'CPP-PL (CPP-PL)',
    value: 'CPP-PL',
    type: 'Product Reference Type',
  },
  {
    label: 'Crepelle 26 (CR26)',
    value: 'CR26',
    type: 'Product Reference Type',
  },
  {
    label: 'Crepelle 26GD (CR26GD)',
    value: 'CR26GD',
    type: 'Product Reference Type',
  },
  {
    label: 'Cummins (CUMMINS)',
    value: 'CUMMINS',
    type: 'Product Reference Type',
  },
  {
    label: 'DAF (DAF)',
    value: 'DAF',
    type: 'Product Reference Type',
  },
  {
    label: 'Daihatsu (DAIHATSU)',
    value: 'DAIHATSU',
    type: 'Product Reference Type',
  },
  {
    label: 'Daimler Chrysler (DAIMLER_CHRYSLER)',
    value: 'DAIMLER_CHRYSLER',
    type: 'Product Reference Type',
  },
  {
    label: 'Damatic (DAMATIC)',
    value: 'DAMATIC',
    type: 'Product Reference Type',
  },
  {
    label: 'Deere (DEERE)',
    value: 'DEERE',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz AM528 (D528)',
    value: 'D528',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz AM816 (D816)',
    value: 'D816',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz D484 (D484)',
    value: 'D484',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz D500 (D500)',
    value: 'D500',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz D511 (D511)',
    value: 'D511',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz RHS518 (D518)',
    value: 'D518',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz RS18/22 (D1822)',
    value: 'D1822',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz RSBV350 (D350)',
    value: 'D350',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz RSBV358 (D358)',
    value: 'D358',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz SB/VM540 (D540)',
    value: 'D540',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz SBVM628 (D628)',
    value: 'D628',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz SBVM640 (D640)',
    value: 'D640',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD232 (D232)',
    value: 'D232',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD234 (D234)',
    value: 'D234',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD440 (D440)',
    value: 'D440',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD441 (D441)',
    value: 'D441',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD501 (D501)',
    value: 'D501',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD510 (D510)',
    value: 'D510',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD601 (D601)',
    value: 'D601',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD602 (D602)',
    value: 'D602',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD604 (D604)',
    value: 'D604',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD604B (D604B)',
    value: 'D604B',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD616 (D616)',
    value: 'D616',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD620 (D620)',
    value: 'D620',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TBD645 (D645)',
    value: 'D645',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz TCD2016 (D2016)',
    value: 'D2016',
    type: 'Product Reference Type',
  },
  {
    label: 'DEUTZ TCD2020 (D2020)',
    value: 'D2020',
    type: 'Product Reference Type',
  },
  {
    label: 'Deutz VM545 (D545)',
    value: 'D545',
    type: 'Product Reference Type',
  },
  {
    label: 'Dieselair (DIESELAIR)',
    value: 'DIESELAIR',
    type: 'Product Reference Type',
  },
  {
    label: 'Dolphin (DOLPHIN)',
    value: 'DOLPHIN',
    type: 'Product Reference Type',
  },
  {
    label: 'Dry Waste (DRY-WASTE)',
    value: 'DRY-WASTE',
    type: 'Product Reference Type',
  },
  {
    label: 'Dynamic Positioning System (DPS)',
    value: 'DPS',
    type: 'Product Reference Type',
  },
  {
    label: 'E&A System Engineering (EA_SYS_ENG)',
    value: 'EA_SYS_ENG',
    type: 'Product Reference Type',
  },
  {
    label: 'Electric Propulsion System (ELECTR PROP SYSTEM)',
    value: 'ELECTR PROP SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'EnergoFlow Energy saving (EF)',
    value: 'EF',
    type: 'Product Reference Type',
  },
  {
    label: 'EnergoProFin (EPF)',
    value: 'EPF',
    type: 'Product Reference Type',
  },
  {
    label: 'Energy storage control system (GEMS1)',
    value: 'GEMS1',
    type: 'Product Reference Type',
  },
  {
    label: 'Eniram annual agmt.& analytics (TIER4)',
    value: 'TIER4',
    type: 'Product Reference Type',
  },
  {
    label: 'Eniram sol. deliveries and oth (ENIRAM_SOL)',
    value: 'ENIRAM_SOL',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal (FACE SEAL)',
    value: 'FACE SEAL',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - A-Series (A-SERIES)',
    value: 'A-SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - A-Series (A-SERIES - COM)',
    value: 'A-SERIES - COM',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - ANCILLARY EQ (ANCILLARY EQ)',
    value: 'ANCILLARY EQ',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - E-Series (E-SERIES)',
    value: 'E-SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - E-Series UK (E-SERIES UK)',
    value: 'E-SERIES UK',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - M-Series (M-SERIES)',
    value: 'M-SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - N-Series (N-SERIES - COM)',
    value: 'N-SERIES - COM',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - N-Series (N-SERIES)',
    value: 'N-SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - T10-Series (T10 SERIES)',
    value: 'T10 SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - T21-Series (T21 SERIES)',
    value: 'T21 SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal - T5-Series (T5 SERIES)',
    value: 'T5 SERIES',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal-CWS (FACE SEAL-CWS)',
    value: 'FACE SEAL-CWS',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal-KM (FACE SEAL-KM)',
    value: 'FACE SEAL-KM',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal-SeaQual (FACE SEAL-SEAQUAL)',
    value: 'FACE SEAL-SEAQUAL',
    type: 'Product Reference Type',
  },
  {
    label: 'Face Seal-X (FACE SEAL-X)',
    value: 'FACE SEAL-X',
    type: 'Product Reference Type',
  },
  {
    label: 'Face seals for Navy (FACE SEALS NAVY)',
    value: 'FACE SEALS NAVY',
    type: 'Product Reference Type',
  },
  {
    label: 'Fiat (FIAT)',
    value: 'FIAT',
    type: 'Product Reference Type',
  },
  {
    label: 'Flare Gas Ignition (FLARE GAS IGNITION)',
    value: 'FLARE GAS IGNITION',
    type: 'Product Reference Type',
  },
  {
    label: 'Fleet Operations Solutions (FOS)',
    value: 'FOS',
    type: 'Product Reference Type',
  },
  {
    label: 'Ford (FORD)',
    value: 'FORD',
    type: 'Product Reference Type',
  },
  {
    label: 'FP Propeller (FPP)',
    value: 'FPP',
    type: 'Product Reference Type',
  },
  {
    label: 'Francis (HYDRO_FRANCIS)',
    value: 'HYDRO_FRANCIS',
    type: 'Product Reference Type',
  },
  {
    label: 'Fresh Water - Multi Stage Flas (FWS-MSF)',
    value: 'FWS-MSF',
    type: 'Product Reference Type',
  },
  {
    label: 'Fresh Water - Single Stage Des (FWS-SSD)',
    value: 'FWS-SSD',
    type: 'Product Reference Type',
  },
  {
    label: 'FWS Mech. Vapor Compression (FWS-MVC)',
    value: 'FWS-MVC',
    type: 'Product Reference Type',
  },
  {
    label: 'FWS Plate Type Evaparator (FWS-PTE)',
    value: 'FWS-PTE',
    type: 'Product Reference Type',
  },
  {
    label: 'FWS Reverse Osmosis unit (FWS-RO)',
    value: 'FWS-RO',
    type: 'Product Reference Type',
  },
  {
    label: 'FWS Rising Film Evaporator (FWS-RF)',
    value: 'FWS-RF',
    type: 'Product Reference Type',
  },
  {
    label: 'FWS Thermal Vapor Compression (FWS-TVC)',
    value: 'FWS-TVC',
    type: 'Product Reference Type',
  },
  {
    label: 'Gas Valve Unit (GVU)',
    value: 'GVU',
    type: 'Product Reference Type',
  },
  {
    label: 'Gate Rudder Technology (RUDDER-GR)',
    value: 'RUDDER-GR',
    type: 'Product Reference Type',
  },
  {
    label: 'GEMS Control System (GEMS)',
    value: 'GEMS',
    type: 'Product Reference Type',
  },
  {
    label: 'General (25100)',
    value: '25100',
    type: 'Product Reference Type',
  },
  {
    label: 'General Motors (GM)',
    value: 'GM',
    type: 'Product Reference Type',
  },
  {
    label: 'Generator Bearings (GENERATOR BEARING)',
    value: 'GENERATOR BEARING',
    type: 'Product Reference Type',
  },
  {
    label: 'Geo Data (GEO DATA)',
    value: 'GEO DATA',
    type: 'Product Reference Type',
  },
  {
    label: 'Grandi Motori (GMT) 210 (GMT210)',
    value: 'GMT210',
    type: 'Product Reference Type',
  },
  {
    label: 'Grandi Motori (GMT) 230 (GMT230)',
    value: 'GMT230',
    type: 'Product Reference Type',
  },
  {
    label: 'Grandi Motori (GMT) 300 (GMT300)',
    value: 'GMT300',
    type: 'Product Reference Type',
  },
  {
    label: 'Grandi Motori (GMT) 320 (GMT320)',
    value: 'GMT320',
    type: 'Product Reference Type',
  },
  {
    label: 'Grandi Motori (GMT) 420 (GMT420)',
    value: 'GMT420',
    type: 'Product Reference Type',
  },
  {
    label: 'Grandi Motori (GMT) 550 (GMT550)',
    value: 'GMT550',
    type: 'Product Reference Type',
  },
  {
    label: 'GRS Systems (GRS SYSTEMS)',
    value: 'GRS SYSTEMS',
    type: 'Product Reference Type',
  },
  {
    label: 'HiMSEN 4 Stroke Engines (HIMSEN 4-S)',
    value: 'HIMSEN 4-S',
    type: 'Product Reference Type',
  },
  {
    label: 'HiTE Evaporator (FWS-HITE)',
    value: 'FWS-HITE',
    type: 'Product Reference Type',
  },
  {
    label: 'Hull Air Lubrication Technolog (HAL)',
    value: 'HAL',
    type: 'Product Reference Type',
  },
  {
    label: 'Hydraulic Couplings (HYDRAULIC EQUIPMEN)',
    value: 'HYDRAULIC EQUIPMEN',
    type: 'Product Reference Type',
  },
  {
    label: 'Hydro Plant Propeller (HYDRO_PROPELLER)',
    value: 'HYDRO_PROPELLER',
    type: 'Product Reference Type',
  },
  {
    label: 'Hydro Pump (HYDRO_PUMPS)',
    value: 'HYDRO_PUMPS',
    type: 'Product Reference Type',
  },
  {
    label: 'Hydroaccoustic Products (HYDROACCOU_PRODUCT)',
    value: 'HYDROACCOU_PRODUCT',
    type: 'Product Reference Type',
  },
  {
    label: 'Inert Gas Systems (IGS)',
    value: 'IGS',
    type: 'Product Reference Type',
  },
  {
    label: 'Integrated Control & Safety Sy (VA_ICSS)',
    value: 'VA_ICSS',
    type: 'Product Reference Type',
  },
  {
    label: 'Integrated product support (IPS)',
    value: 'IPS',
    type: 'Product Reference Type',
  },
  {
    label: 'Isotta Fraschini (IF)',
    value: 'IF',
    type: 'Product Reference Type',
  },
  {
    label: 'Iveco (IVECO)',
    value: 'IVECO',
    type: 'Product Reference Type',
  },
  {
    label: 'Jastram thruster/CPP (TT-CM)',
    value: 'TT-CM',
    type: 'Product Reference Type',
  },
  {
    label: 'Jastram thruster/FPP (TT-FM)',
    value: 'TT-FM',
    type: 'Product Reference Type',
  },
  {
    label: 'Joystick control system (CTRL-RCS-02R)',
    value: 'CTRL-RCS-02R',
    type: 'Product Reference Type',
  },
  {
    label: 'Kaplan (HYDRO_KAPLAN)',
    value: 'HYDRO_KAPLAN',
    type: 'Product Reference Type',
  },
  {
    label: 'LES Audio/Video (LES AUDIO/VIDEO)',
    value: 'LES AUDIO/VIDEO',
    type: 'Product Reference Type',
  },
  {
    label: 'Lighting System (LIGHTING_SYSTEMS)',
    value: 'LIGHTING_SYSTEMS',
    type: 'Product Reference Type',
  },
  {
    label: 'Linesafe Bearing (LSB2)',
    value: 'LSB2',
    type: 'Product Reference Type',
  },
  {
    label: 'Lip Seal (LIP SEAL)',
    value: 'LIP SEAL',
    type: 'Product Reference Type',
  },
  {
    label: 'Lip seal OEM (LIP SEAL_OTHERS)',
    value: 'LIP SEAL_OTHERS',
    type: 'Product Reference Type',
  },
  {
    label: 'Lip Seal-KLR - Steerguard (LIP SEAL-KLR)',
    value: 'LIP SEAL-KLR',
    type: 'Product Reference Type',
  },
  {
    label: 'Lip Seal-OLB - Floodguard (LIP SEAL-OLB)',
    value: 'LIP SEAL-OLB',
    type: 'Product Reference Type',
  },
  {
    label: 'Lip seals for Navy (LIP SEALS NAVY)',
    value: 'LIP SEALS NAVY',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips build-up propeller (FPP-B)',
    value: 'FPP-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/C-hub (CPP-C)',
    value: 'CPP-C',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/CF-hub (CPP-CF)',
    value: 'CPP-CF',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/CLF-hub (CPP-CLF)',
    value: 'CPP-CLF',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/CPS-hub (CPP-CPS)',
    value: 'CPP-CPS',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/D-hub (CPP-D)',
    value: 'CPP-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/E-hub (CPP-E)',
    value: 'CPP-E',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/L-hub (CPP-L)',
    value: 'CPP-L',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/LC-hub (CPP-LC)',
    value: 'CPP-LC',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/LCF-hub (CPP-LCF)',
    value: 'CPP-LCF',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/LH-hub (CPP-LH)',
    value: 'CPP-LH',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips CPP/LWH-hub (CPP-LWH)',
    value: 'CPP-LWH',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips Efficience Rudder (RUD-ER)',
    value: 'RUD-ER',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips Jets D-type (JETS-D)',
    value: 'JETS-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips Jets DL-type (JETS-DL)',
    value: 'JETS-DL',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips Jets E-type (JETS-E)',
    value: 'JETS-E',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips Jets LJX-type (JETS-LJX)',
    value: 'JETS-LJX',
    type: 'Product Reference Type',
  },
  {
    label: 'Lips Jets WLD-type (JETS-WLD)',
    value: 'JETS-WLD',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipsstick (CTRL-LIPSSTICK)',
    value: 'CTRL-LIPSSTICK',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 Clutch Control (CTRL-LT7CCU)',
    value: 'CTRL-LT7CCU',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 CPP advanced (CTRL-LT7CPPA)',
    value: 'CTRL-LT7CPPA',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 CPP basic (CTRL-LT7CPPB)',
    value: 'CTRL-LT7CPPB',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 Display (CTRL-LT7DISP)',
    value: 'CTRL-LT7DISP',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 Engine Start (CTRL-LT7ESS)',
    value: 'CTRL-LT7ESS',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 Steer.Thruster (CTRL-LT7STT)',
    value: 'CTRL-LT7STT',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 Trans.Thruster (CTRL-LT7TT)',
    value: 'CTRL-LT7TT',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic 7000 Waterjet (CTRL-LT7WJ)',
    value: 'CTRL-LT7WJ',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic CPP (CTRL-LTCPP)',
    value: 'CTRL-LTCPP',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic Steerable Thruster (CTRL-LTSTT)',
    value: 'CTRL-LTSTT',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic Transverse Thruster (CTRL-LTTT)',
    value: 'CTRL-LTTT',
    type: 'Product Reference Type',
  },
  {
    label: 'Lipstronic Waterjet (CTRL-LTWJ)',
    value: 'CTRL-LTWJ',
    type: 'Product Reference Type',
  },
  {
    label: 'Lister Blackstone (LISTER BLACKSTONE)',
    value: 'LISTER BLACKSTONE',
    type: 'Product Reference Type',
  },
  {
    label: 'Lister Petter (LISTER_PETTER)',
    value: 'LISTER_PETTER',
    type: 'Product Reference Type',
  },
  {
    label: 'LNG Land Based (LNG LAND BASED)',
    value: 'LNG LAND BASED',
    type: 'Product Reference Type',
  },
  {
    label: 'LNG Marine (LNG MARINE)',
    value: 'LNG MARINE',
    type: 'Product Reference Type',
  },
  {
    label: 'LNG Regas (LNG REGAS)',
    value: 'LNG REGAS',
    type: 'Product Reference Type',
  },
  {
    label: 'LNGPac (LNGP)',
    value: 'LNGP',
    type: 'Product Reference Type',
  },
  {
    label: 'Lombardini (LOMBARDINI)',
    value: 'LOMBARDINI',
    type: 'Product Reference Type',
  },
  {
    label: 'LPG Marine (LPG MARINE)',
    value: 'LPG MARINE',
    type: 'Product Reference Type',
  },
  {
    label: 'LT CPP upgrade kit type 02 (CTRL-LTCPPR)',
    value: 'CTRL-LTCPPR',
    type: 'Product Reference Type',
  },
  {
    label: 'LT STT upgrade kit type 02 (CTRL-LTSTTR)',
    value: 'CTRL-LTSTTR',
    type: 'Product Reference Type',
  },
  {
    label: 'LT TT upgrade kit type 02 (CTRL-LTTTR)',
    value: 'CTRL-LTTTR',
    type: 'Product Reference Type',
  },
  {
    label: 'LT7 CPP upgrade kit type 02 (CTRL-LT7CPPR)',
    value: 'CTRL-LT7CPPR',
    type: 'Product Reference Type',
  },
  {
    label: 'LT7 STT upgrade kit type 02 (CTRL-LT7STTR)',
    value: 'CTRL-LT7STTR',
    type: 'Product Reference Type',
  },
  {
    label: 'LT7 TT upgrade kit type 02 (CTRL-LT7TTR)',
    value: 'CTRL-LT7TTR',
    type: 'Product Reference Type',
  },
  {
    label: 'LV Switchboards (LV_SWITCHBOARD)',
    value: 'LV_SWITCHBOARD',
    type: 'Product Reference Type',
  },
  {
    label: 'MAK (MAK)',
    value: 'MAK',
    type: 'Product Reference Type',
  },
  {
    label: 'MAN B&W (MAN_BW)',
    value: 'MAN_BW',
    type: 'Product Reference Type',
  },
  {
    label: 'MCS Platinum (MCS)',
    value: 'MCS',
    type: 'Product Reference Type',
  },
  {
    label: 'Mercedes (MERCEDES)',
    value: 'MERCEDES',
    type: 'Product Reference Type',
  },
  {
    label: 'Methanol Fuel supply system (METHANOLPAC)',
    value: 'METHANOLPAC',
    type: 'Product Reference Type',
  },
  {
    label: 'Mirlees Blackstone (MIRRLEES BLACKSTON)',
    value: 'MIRRLEES BLACKSTON',
    type: 'Product Reference Type',
  },
  {
    label: 'Mitsubishi (MITSUBISHI)',
    value: 'MITSUBISHI',
    type: 'Product Reference Type',
  },
  {
    label: 'Mitsui (MITSUI)',
    value: 'MITSUI',
    type: 'Product Reference Type',
  },
  {
    label: 'Modular Fixed Thruster/CPP (LMT-CF)',
    value: 'LMT-CF',
    type: 'Product Reference Type',
  },
  {
    label: 'Modular Fixed Thruster/FPP (LMT-FF)',
    value: 'LMT-FF',
    type: 'Product Reference Type',
  },
  {
    label: 'Modular Steerable Thruster/CPP (LMT-CS)',
    value: 'LMT-CS',
    type: 'Product Reference Type',
  },
  {
    label: 'Modular Steerable Thruster/FPP (LMT-FS)',
    value: 'LMT-FS',
    type: 'Product Reference Type',
  },
  {
    label: 'Multifunction Display System (MFD 4000)',
    value: 'MFD 4000',
    type: 'Product Reference Type',
  },
  {
    label: 'MV Transformer for BESS proje (TRANSFORMER_ESS)',
    value: 'TRANSFORMER_ESS',
    type: 'Product Reference Type',
  },
  {
    label: 'MWM (MWM)',
    value: 'MWM',
    type: 'Product Reference Type',
  },
  {
    label: 'NACOS (NACOS)',
    value: 'NACOS',
    type: 'Product Reference Type',
  },
  {
    label: 'Navi-Harbour Vessel Traffic Sy (NAVI-HARBOUR VTS)',
    value: 'NAVI-HARBOUR VTS',
    type: 'Product Reference Type',
  },
  {
    label: 'Navi-Trainer Professional (NTPRO)',
    value: 'NTPRO',
    type: 'Product Reference Type',
  },
  {
    label: 'Night Vision Devices (NIGHTVISIONDEV)',
    value: 'NIGHTVISIONDEV',
    type: 'Product Reference Type',
  },
  {
    label: 'Niigata (NIIGATA)',
    value: 'NIIGATA',
    type: 'Product Reference Type',
  },
  {
    label: 'Nohab 2-stroke (NOHAB2T)',
    value: 'NOHAB2T',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-OEM EA Systems (WASFR)',
    value: 'WASFR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Air bottle (AIR_BOTTLE)',
    value: 'AIR_BOTTLE',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Air compressor (AIR_COMPRESSOR)',
    value: 'AIR_COMPRESSOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Air cooled cond (AIR_COOL_CONDENSER)',
    value: 'AIR_COOL_CONDENSER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Auxiliary boiler (AUXILIARY_BOILER)',
    value: 'AUXILIARY_BOILER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Auxiliary cont (AUX_CONTAINER)',
    value: 'AUX_CONTAINER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä AVR (AVR)',
    value: 'AVR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Bellows (BELLOWS)',
    value: 'BELLOWS',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Black start unit (BLACK_START_UNIT)',
    value: 'BLACK_START_UNIT',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä CEMS system (CEMS_SYSTEM)',
    value: 'CEMS_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Charge air filt (CHARGE_AIR_FILTER)',
    value: 'CHARGE_AIR_FILTER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Chem dosing sys (CHEM_DOSING_SYST)',
    value: 'CHEM_DOSING_SYST',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Cond preheater (CONDENS_PREHEATER)',
    value: 'CONDENS_PREHEATER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Control panel (CONTROL_PANEL)',
    value: 'CONTROL_PANEL',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Control valve PP (CONTROL_VALVES)',
    value: 'CONTROL_VALVES',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Cooling radiator (COOLING_RADIATOR)',
    value: 'COOLING_RADIATOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Cooling tower (COOLING_TOWER)',
    value: 'COOLING_TOWER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Cranes (CRANES)',
    value: 'CRANES',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä DC system for PP (DC_SYSTEM)',
    value: 'DC_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Exh gas boilers (EXHAUST_GAS_BOILER)',
    value: 'EXHAUST_GAS_BOILER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Exh gas vent fan (EG_VENT_FAN)',
    value: 'EG_VENT_FAN',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Feed water pump (FEED_WATER_PUMP)',
    value: 'FEED_WATER_PUMP',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Fire detection (FIRE_DETECTION)',
    value: 'FIRE_DETECTION',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Fire pump stat (FIRE_PUMP_STATION)',
    value: 'FIRE_PUMP_STATION',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Flex Coupling (FLEX_COUPLING)',
    value: 'FLEX_COUPLING',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä FreqC for PP (FREQ_CONVERTER)',
    value: 'FREQ_CONVERTER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Gas Analyzer (ANALYZER)',
    value: 'ANALYZER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Gas detection (GAS_DETECTION)',
    value: 'GAS_DETECTION',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Gas press reduc (GAS_PRESS_REDUC)',
    value: 'GAS_PRESS_REDUC',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Gas Valve Unit (GVU_EXT)',
    value: 'GVU_EXT',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Gear (GE-EX)',
    value: 'GE-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Generator (GENERATOR)',
    value: 'GENERATOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä HR container (HR_CONTAINER)',
    value: 'HR_CONTAINER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä HV System (HV_SYSTEM)',
    value: 'HV_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä HVAC (HVAC)',
    value: 'HVAC',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Hydro & Indust. (HYDRO&IND_EXT)',
    value: 'HYDRO&IND_EXT',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Jets (JETS-EX)',
    value: 'JETS-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä LV system for PP (LV_SYSTEM)',
    value: 'LV_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä MV system for PP (MV_SYSTEM)',
    value: 'MV_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Oil detector (OIL_DETECTOR)',
    value: 'OIL_DETECTOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Oxi cat for PP (OXIDATION_CATALYST)',
    value: 'OXIDATION_CATALYST',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Propeller CIPS (CIPS-EX)',
    value: 'CIPS-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Propeller CPP (CPP-EX)',
    value: 'CPP-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Propeller FPP (FPP-EX)',
    value: 'FPP-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Propeller STT (STT-EX)',
    value: 'STT-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Propeller TT (TT-EX)',
    value: 'TT-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Prote relay (PROTECTION_RELAY)',
    value: 'PROTECTION_RELAY',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Rudder (RUDDER-EX)',
    value: 'RUDDER-EX',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Rupture disk (RUPTURE_DISK)',
    value: 'RUPTURE_DISK',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä SCADA gateway (SCADA_GATEWAY)',
    value: 'SCADA_GATEWAY',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä SCR system PP (SCR_SYSTEM)',
    value: 'SCR_SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Separator (SEPARATOR)',
    value: 'SEPARATOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Speed controller (SPEED_CONTROLLER)',
    value: 'SPEED_CONTROLLER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Steam header (STEAM_HEADER)',
    value: 'STEAM_HEADER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Steam tur & gen (STEAM_TURB_AND_GEN)',
    value: 'STEAM_TURB_AND_GEN',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Tanks for PP (TANKS)',
    value: 'TANKS',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Tariff metering (TARIFF_METERING)',
    value: 'TARIFF_METERING',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Transformer (TRANSFORMER)',
    value: 'TRANSFORMER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Treat wat boost (WATER_BOOSTER)',
    value: 'WATER_BOOSTER',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä UPS (UPS)',
    value: 'UPS',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Wat soften filt (WTR_SOFTENING_FILT)',
    value: 'WTR_SOFTENING_FILT',
    type: 'Product Reference Type',
  },
  {
    label: 'Non-Wärtsilä Water treatment (WATER_TREATMENT)',
    value: 'WATER_TREATMENT',
    type: 'Product Reference Type',
  },
  {
    label: 'Nozzle (NOZZLE)',
    value: 'NOZZLE',
    type: 'Product Reference Type',
  },
  {
    label: 'Oil & Water Lubricated Sterntu (STERN TUBE / E)',
    value: 'STERN TUBE / E',
    type: 'Product Reference Type',
  },
  {
    label: 'Oily Water Separation - H-seri (OWS-H)',
    value: 'OWS-H',
    type: 'Product Reference Type',
  },
  {
    label: 'Oily Water Separation-Victor M (OWS-V)',
    value: 'OWS-V',
    type: 'Product Reference Type',
  },
  {
    label: 'OTH Energy Systems (ENERGY_SYS)',
    value: 'ENERGY_SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Accoustics & Survey Syst (ACCOU&SURV_SYS)',
    value: 'ACCOU&SURV_SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Automation systems (OTHER AUT SYSTEMS)',
    value: 'OTHER AUT SYSTEMS',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Fresh Water System (FWS-OTHER)',
    value: 'FWS-OTHER',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Internal Comm. System (OTH INT COMM SYS)',
    value: 'OTH INT COMM SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Navigation and External (OTH NAV & EXT COMM)',
    value: 'OTH NAV & EXT COMM',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Power Conv. Sys (OTH_PCO_SYS)',
    value: 'OTH_PCO_SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Other Power Distrib. Systems (POW_DIST_SYS)',
    value: 'POW_DIST_SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'OTHER S&B PRODUCTS (OTHER S&B PRODUCTS)',
    value: 'OTHER S&B PRODUCTS',
    type: 'Product Reference Type',
  },
  {
    label: 'Paxman (PAXMAN)',
    value: 'PAXMAN',
    type: 'Product Reference Type',
  },
  {
    label: 'PCS-Platinum / ECS-Platinum (PCS/ESS)',
    value: 'PCS/ESS',
    type: 'Product Reference Type',
  },
  {
    label: 'Perkins (PERKINS)',
    value: 'PERKINS',
    type: 'Product Reference Type',
  },
  {
    label: 'Peugeot (PEUGEOT)',
    value: 'PEUGEOT',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick (PIELSTICK)',
    value: 'PIELSTICK',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC2 (PC2)',
    value: 'PC2',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC2-2 (PC2-2)',
    value: 'PC2-2',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC2-3 (PC2-3)',
    value: 'PC2-3',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC2-5 (PC2-5)',
    value: 'PC2-5',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC2-6 (PC2-6)',
    value: 'PC2-6',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC4 (PC4)',
    value: 'PC4',
    type: 'Product Reference Type',
  },
  {
    label: 'Pielstick PC4-2 (PC4-2)',
    value: 'PC4-2',
    type: 'Product Reference Type',
  },
  {
    label: 'PMS/EMS Management System (PMS/EMS MGMT SYS)',
    value: 'PMS/EMS MGMT SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Positioning Technology PRS (POSITIONTECHN_PRS)',
    value: 'POSITIONTECHN_PRS',
    type: 'Product Reference Type',
  },
  {
    label: 'Power Conversion system (PCS_INVERT)',
    value: 'PCS_INVERT',
    type: 'Product Reference Type',
  },
  {
    label: 'Power Conversion System (PCS_INVERTER)',
    value: 'PCS_INVERTER',
    type: 'Product Reference Type',
  },
  {
    label: 'Poyaud UD150 (UD150)',
    value: 'UD150',
    type: 'Product Reference Type',
  },
  {
    label: 'Poyaud UD18 (UD18)',
    value: 'UD18',
    type: 'Product Reference Type',
  },
  {
    label: 'Poyaud UD23 (UD23)',
    value: 'UD23',
    type: 'Product Reference Type',
  },
  {
    label: 'Poyaud UD25 (UD25)',
    value: 'UD25',
    type: 'Product Reference Type',
  },
  {
    label: 'Poyaud UDX (UDX)',
    value: 'UDX',
    type: 'Product Reference Type',
  },
  {
    label: 'Pump Seal (PUMP SEAL)',
    value: 'PUMP SEAL',
    type: 'Product Reference Type',
  },
  {
    label: 'Pump Turbine (HYDRO_PUMP_TURBINE)',
    value: 'HYDRO_PUMP_TURBINE',
    type: 'Product Reference Type',
  },
  {
    label: 'Pumps (PUMPS)',
    value: 'PUMPS',
    type: 'Product Reference Type',
  },
  {
    label: 'Puregas biogas upgrading plant (PUREGAS)',
    value: 'PUREGAS',
    type: 'Product Reference Type',
  },
  {
    label: 'Renault (RENAULT)',
    value: 'RENAULT',
    type: 'Product Reference Type',
  },
  {
    label: 'Rig Tensioning Compressors (COMPR-RTC)',
    value: 'COMPR-RTC',
    type: 'Product Reference Type',
  },
  {
    label: 'Rolls Royce (ROLLS_ROYCE)',
    value: 'ROLLS_ROYCE',
    type: 'Product Reference Type',
  },
  {
    label: 'Rotor Sail Technology (RTRS)',
    value: 'RTRS',
    type: 'Product Reference Type',
  },
  {
    label: 'Rudder (RUDDER)',
    value: 'RUDDER',
    type: 'Product Reference Type',
  },
  {
    label: 'Rugerini (RUGERINI)',
    value: 'RUGERINI',
    type: 'Product Reference Type',
  },
  {
    label: 'SACM UD30 (UD30)',
    value: 'UD30',
    type: 'Product Reference Type',
  },
  {
    label: 'SACM UD33 (UD33)',
    value: 'UD33',
    type: 'Product Reference Type',
  },
  {
    label: 'SACM UD45 (UD45)',
    value: 'UD45',
    type: 'Product Reference Type',
  },
  {
    label: 'Safety & Security Systems (SAFETY & SEC SYST)',
    value: 'SAFETY & SEC SYST',
    type: 'Product Reference Type',
  },
  {
    label: 'Saviem (SAVIEM)',
    value: 'SAVIEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Scania (SCANIA)',
    value: 'SCANIA',
    type: 'Product Reference Type',
  },
  {
    label: 'Schottel Thruster (LMT-S)',
    value: 'LMT-S',
    type: 'Product Reference Type',
  },
  {
    label: 'Seismic Compressors (COMPR-SEISMIC)',
    value: 'COMPR-SEISMIC',
    type: 'Product Reference Type',
  },
  {
    label: 'Senitec treatment systems (SENITEC)',
    value: 'SENITEC',
    type: 'Product Reference Type',
  },
  {
    label: 'Server Wärtsilä Operator Inter (SWOIS)',
    value: 'SWOIS',
    type: 'Product Reference Type',
  },
  {
    label: 'Shaft Generator System (SHAFT GEN SYSTEM)',
    value: 'SHAFT GEN SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Shaft Line Repair Services (SLRS)',
    value: 'SLRS',
    type: 'Product Reference Type',
  },
  {
    label: 'Shaft Line supplies (SHAFT LINE SUPPLIE)',
    value: 'SHAFT LINE SUPPLIE',
    type: 'Product Reference Type',
  },
  {
    label: 'Shaft Power Limitation (CTRL-SPL-00)',
    value: 'CTRL-SPL-00',
    type: 'Product Reference Type',
  },
  {
    label: 'Ship Design (SHIPDESIGN)',
    value: 'SHIPDESIGN',
    type: 'Product Reference Type',
  },
  {
    label: 'Shore Connection System (SHORE CON. SYSTEM)',
    value: 'SHORE CON. SYSTEM',
    type: 'Product Reference Type',
  },
  {
    label: 'Signature Management Solution (SIGNATURE_MGMT)',
    value: 'SIGNATURE_MGMT',
    type: 'Product Reference Type',
  },
  {
    label: 'Situational Assistance Systems (SAS)',
    value: 'SAS',
    type: 'Product Reference Type',
  },
  {
    label: 'SKL Motor (SKL_MOTOR)',
    value: 'SKL_MOTOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Skoda (SKODA)',
    value: 'SKODA',
    type: 'Product Reference Type',
  },
  {
    label: 'Smit Bolnes (SMIT BOLNES)',
    value: 'SMIT BOLNES',
    type: 'Product Reference Type',
  },
  {
    label: 'SOLAR PV Panel (SOLAR_EN)',
    value: 'SOLAR_EN',
    type: 'Product Reference Type',
  },
  {
    label: 'Sonar Solutions (SONAR_SYSTEMS)',
    value: 'SONAR_SYSTEMS',
    type: 'Product Reference Type',
  },
  {
    label: 'SOx Cleaning/Scrubber (SOX)',
    value: 'SOX',
    type: 'Product Reference Type',
  },
  {
    label: 'Special Power Supplies (SPEC_POW_SUPPLY)',
    value: 'SPEC_POW_SUPPLY',
    type: 'Product Reference Type',
  },
  {
    label: 'Special Seal (SPECIAL)',
    value: 'SPECIAL',
    type: 'Product Reference Type',
  },
  {
    label: 'STERN TUBE (STERN TUBE)',
    value: 'STERN TUBE',
    type: 'Product Reference Type',
  },
  {
    label: 'Stern Tube Assembly (STERN TUBE / S)',
    value: 'STERN TUBE / S',
    type: 'Product Reference Type',
  },
  {
    label: 'SternSafe - OMS-N&M (STERN TUBE BEARING)',
    value: 'STERN TUBE BEARING',
    type: 'Product Reference Type',
  },
  {
    label: 'Stork R210 (210)',
    value: '210',
    type: 'Product Reference Type',
  },
  {
    label: 'Stork-Werkspoor 280 (280)',
    value: '280',
    type: 'Product Reference Type',
  },
  {
    label: 'Stork-Werkspoor F240 (240)',
    value: '240',
    type: 'Product Reference Type',
  },
  {
    label: 'Stork-Werkspoor TM410 (410)',
    value: '410',
    type: 'Product Reference Type',
  },
  {
    label: 'Stork-Werkspoor TM620 (620)',
    value: '620',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S AL20 (AL20)',
    value: 'AL20',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S AL20H (AL20H)',
    value: 'AL20H',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S AL25 (AL25)',
    value: 'AL25',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ASL25D (ASL25D)',
    value: 'ASL25D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ASL25H (ASL25H)',
    value: 'ASL25H',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ASV25 (ASV25)',
    value: 'ASV25',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ASV25D (ASV25D)',
    value: 'ASV25D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ASV25H (ASV25H)',
    value: 'ASV25H',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ATL25 (ATL25)',
    value: 'ATL25',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ATL25D (ATL25D)',
    value: 'ATL25D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ATL25H (ATL25H)',
    value: 'ATL25H',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ATV25 (ATV25)',
    value: 'ATV25',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ATV25D (ATV25D)',
    value: 'ATV25D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S ATV25H (ATV25H)',
    value: 'ATV25H',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S AV25 (AV25)',
    value: 'AV25',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S S20 (S20)',
    value: 'S20',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S S20D (S20D)',
    value: 'S20D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S S20H (S20H)',
    value: 'S20H',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer A/S S20U (S20U)',
    value: 'S20U',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BAF22 (BAF22)',
    value: 'BAF22',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BAF29 (BAF29)',
    value: 'BAF29',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BAH22 (BAH22)',
    value: 'BAH22',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BAH29 (BAH29)',
    value: 'BAH29',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BCAF29 (BCAF29)',
    value: 'BCAF29',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BH22 (BH22)',
    value: 'BH22',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer B BW29 (BW29)',
    value: 'BW29',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer D DDW22 (DDW22)',
    value: 'DDW22',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer M MH51 (MH51)',
    value: 'MH51',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RD44 (RD44)',
    value: 'RD44',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RD56 (RD56)',
    value: 'RD56',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RD68 (RD68)',
    value: 'RD68',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RD76 (RD76)',
    value: 'RD76',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RD90 (RD90)',
    value: 'RD90',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RF68 (RF68)',
    value: 'RF68',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RF76 (RF76)',
    value: 'RF76',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLA56 (RLA56)',
    value: 'RLA56',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLA66 (RLA66)',
    value: 'RLA66',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLA90 (RLA90)',
    value: 'RLA90',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLB56 (RLB56)',
    value: 'RLB56',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLB66 (RLB66)',
    value: 'RLB66',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLB76 (RLB76)',
    value: 'RLB76',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLB90 (RLB90)',
    value: 'RLB90',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLBF56 (RLBF56)',
    value: 'RLBF56',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RLBF90 (RLBF90)',
    value: 'RLBF90',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RND68 (RND68)',
    value: 'RND68',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RND68M (RND68M)',
    value: 'RND68M',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RND76 (RND76)',
    value: 'RND76',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RND76M (RND76M)',
    value: 'RND76M',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RND90 (RND90)',
    value: 'RND90',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RND90M (RND90M)',
    value: 'RND90M',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RNF68 (RNF68)',
    value: 'RNF68',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RNF68M (RNF68M)',
    value: 'RNF68M',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer R-other RNF90M (RNF90M)',
    value: 'RNF90M',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RT-FLEX60C (RT-FLEX60C)',
    value: 'RT-FLEX60C',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RT-FLEX60C-B (RT-FLEX60C-B)',
    value: 'RT-FLEX60C-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RT-FLEX68-B (RT-FLEX68-B)',
    value: 'RT-FLEX68-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA38 (RTA38)',
    value: 'RTA38',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA48 (RTA48)',
    value: 'RTA48',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA48T (RTA48T)',
    value: 'RTA48T',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA48T-B (RTA48T-B)',
    value: 'RTA48T-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA48T-D (RTA48T-D)',
    value: 'RTA48T-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA52 (RTA52)',
    value: 'RTA52',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA52U (RTA52U)',
    value: 'RTA52U',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA58 (RTA58)',
    value: 'RTA58',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA58T (RTA58T)',
    value: 'RTA58T',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA58T-B (RTA58T-B)',
    value: 'RTA58T-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA58T-D (RTA58T-D)',
    value: 'RTA58T-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA62 (RTA62)',
    value: 'RTA62',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA62U (RTA62U)',
    value: 'RTA62U',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA62U-B (RTA62U-B)',
    value: 'RTA62U-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA68 (RTA68)',
    value: 'RTA68',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA68-B (RTA68-B)',
    value: 'RTA68-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA72 (RTA72)',
    value: 'RTA72',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA72U (RTA72U)',
    value: 'RTA72U',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA72U-B (RTA72U-B)',
    value: 'RTA72U-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA76 (RTA76)',
    value: 'RTA76',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84 (RTA84)',
    value: 'RTA84',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84C (RTA84C)',
    value: 'RTA84C',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84C-U (RTA84C-U)',
    value: 'RTA84C-U',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84M (RTA84M)',
    value: 'RTA84M',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84T (RTA84T)',
    value: 'RTA84T',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84T-B (RTA84T-B)',
    value: 'RTA84T-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA84T-D (RTA84T-D)',
    value: 'RTA84T-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA96C (RTA96C)',
    value: 'RTA96C',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer RT RTA96C-B (RTA96C-B)',
    value: 'RTA96C-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer SD SAD60 (SAD60)',
    value: 'SAD60',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TAD24 (TAD24)',
    value: 'TAD24',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TAD36 (TAD36)',
    value: 'TAD36',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TAD48 (TAD48)',
    value: 'TAD48',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TAF36 (TAF36)',
    value: 'TAF36',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TAF48 (TAF48)',
    value: 'TAF48',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TAG36 (TAG36)',
    value: 'TAG36',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TD29 (TD29)',
    value: 'TD29',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TD36 (TD36)',
    value: 'TD36',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TD48 (TD48)',
    value: 'TD48',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TF48 (TF48)',
    value: 'TF48',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer T TW24 (TW24)',
    value: 'TW24',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer V VD25 (VD25)',
    value: 'VD25',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer Z40 (Z40)',
    value: 'Z40',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer ZA40 (ZA40)',
    value: 'ZA40',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer ZA40S (ZA40S)',
    value: 'ZA40S',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer ZA50S (ZA50S)',
    value: 'ZA50S',
    type: 'Product Reference Type',
  },
  {
    label: 'Sulzer ZH40/48 (ZH40/48)',
    value: 'ZH40/48',
    type: 'Product Reference Type',
  },
  {
    label: 'Thrust Bearigns - OBSQ/Y-F (THRUST BEARING)',
    value: 'THRUST BEARING',
    type: 'Product Reference Type',
  },
  {
    label: 'Transas Integrated Bridge Solu (TNR)',
    value: 'TNR',
    type: 'Product Reference Type',
  },
  {
    label: 'Transverse thruster/D-type hub (TT-CT/D)',
    value: 'TT-CT/D',
    type: 'Product Reference Type',
  },
  {
    label: 'Transverse thruster/FPP (TT-FT)',
    value: 'TT-FT',
    type: 'Product Reference Type',
  },
  {
    label: 'Transverse thruster/VBS hub (TT-CT/VBS)',
    value: 'TT-CT/VBS',
    type: 'Product Reference Type',
  },
  {
    label: 'Tunnel (TUNNEL)',
    value: 'TUNNEL',
    type: 'Product Reference Type',
  },
  {
    label: 'Turnkey Cable System (TURNKEY_CABLE_SYS)',
    value: 'TURNKEY_CABLE_SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Turnkey Navy Automation (TNA)',
    value: 'TNA',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic CCU joystick/A Retrofit (CTRL-UNIC-RF-CCU)',
    value: 'CTRL-UNIC-RF-CCU',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic CPP Advanced Retrofit (CTRL-UNIC-RF-CPPA)',
    value: 'CTRL-UNIC-RF-CPPA',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic CPP Basic Rertofit (CTRL-UNIC-RF-CPPB)',
    value: 'CTRL-UNIC-RF-CPPB',
    type: 'Product Reference Type',
  },
  {
    label: 'UNIC CPP Standard (CTRL-UNIC-RF-CPP-S)',
    value: 'CTRL-UNIC-RF-CPP-S',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic IWW Retrofit (CTRL-UNIC-RF-IWW)',
    value: 'CTRL-UNIC-RF-IWW',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic Retrofit rudder ctr sys. (CTRL-UNIC-RF-RUD)',
    value: 'CTRL-UNIC-RF-RUD',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic Rudder control systems (CTRL-UNIC-RUD)',
    value: 'CTRL-UNIC-RUD',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic STT Retrofit (CTRL-UNIC-RF-STT)',
    value: 'CTRL-UNIC-RF-STT',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic TT Retrofit (CTRL-UNIC-RF-TT)',
    value: 'CTRL-UNIC-RF-TT',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic Waterjet (CTRL-UNIC-WJ)',
    value: 'CTRL-UNIC-WJ',
    type: 'Product Reference Type',
  },
  {
    label: 'Unic Waterjet Retrofit (CTRL-UNIC-RF-WJ)',
    value: 'CTRL-UNIC-RF-WJ',
    type: 'Product Reference Type',
  },
  {
    label: 'UPS, Resistors and Loadbanks (UPS_RESTOR_LOADBAN)',
    value: 'UPS_RESTOR_LOADBAN',
    type: 'Product Reference Type',
  },
  {
    label: 'Valmatic (VALMATIC)',
    value: 'VALMATIC',
    type: 'Product Reference Type',
  },
  {
    label: 'VOC Recovery (VOC RECOVERY)',
    value: 'VOC RECOVERY',
    type: 'Product Reference Type',
  },
  {
    label: 'Volvo Penta (PENTA)',
    value: 'PENTA',
    type: 'Product Reference Type',
  },
  {
    label: 'Waste Water System- MBR (WWS-MBR)',
    value: 'WWS-MBR',
    type: 'Product Reference Type',
  },
  {
    label: 'Waste Water System- STP (WWS-STP)',
    value: 'WWS-STP',
    type: 'Product Reference Type',
  },
  {
    label: 'Waste Water System- Vaccum Sys (WWS-VST)',
    value: 'WWS-VST',
    type: 'Product Reference Type',
  },
  {
    label: 'Waste Water Systems - Incinera (WWS-INCINERATOR)',
    value: 'WWS-INCINERATOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Water Quality Systems (WATER QUALITY SYS)',
    value: 'WATER QUALITY SYS',
    type: 'Product Reference Type',
  },
  {
    label: 'Waterjet WXJ (JETS-WXJ)',
    value: 'JETS-WXJ',
    type: 'Product Reference Type',
  },
  {
    label: 'White metal bearing (BEARING)',
    value: 'BEARING',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmann 28 (28)',
    value: '28',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmann AC (AC)',
    value: 'AC',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmann AX (AX)',
    value: 'AX',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmann CPP/PR-hub (CPP-PR)',
    value: 'CPP-PR',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmatic-WM (CTRL-WM)',
    value: 'CTRL-WM',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmatic-WMR (CTRL-WMR)',
    value: 'CTRL-WMR',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmatic2-WM2 (CTRL-WM2)',
    value: 'CTRL-WM2',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichmatic2-WM2R (CTRL-WM2R)',
    value: 'CTRL-WM2R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichtronic-WT (CTRL-WT)',
    value: 'CTRL-WT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichtronic-WT2R (CTRL-WT2R)',
    value: 'CTRL-WT2R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichtronic-WTR (CTRL-WTR)',
    value: 'CTRL-WTR',
    type: 'Product Reference Type',
  },
  {
    label: 'Wichtronic2-WT2 (CTRL-WT2)',
    value: 'CTRL-WT2',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD  X-46 (W-X46)',
    value: 'W-X46',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD  X-46DF (W-X46DF)',
    value: 'W-X46DF',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD CX40DF (CX40DF)',
    value: 'CX40DF',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD CX52 (CX52)',
    value: 'CX52',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD RT-flex50-E (RT-FLEX50-E)',
    value: 'RT-FLEX50-E',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD RTX-7 (RTX-7)',
    value: 'RTX-7',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD RTX-8 (RTX-8)',
    value: 'RTX-8',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD SCE52 (SCE52)',
    value: 'SCE52',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X40DF (W-X40DF)',
    value: 'W-X40DF',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X52-S2.0 (X52-S2.0)',
    value: 'X52-S2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X52DF-1.1 (X52DF-1.1)',
    value: 'X52DF-1.1',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X52DF-2.1 (X52DF-2.1)',
    value: 'X52DF-2.1',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X52DF-S1.0 (X52DF-S1.0)',
    value: 'X52DF-S1.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X62-B (W-X62-B)',
    value: 'W-X62-B',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X62-S2.0 (X62-S2.0)',
    value: 'X62-S2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X62DF-1.1 (X62DF-1.1)',
    value: 'X62DF-1.1',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X62DF-2.1 (X62DF-2.1)',
    value: 'X62DF-2.1',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X62DF-S1.0 (X62DF-S1.0)',
    value: 'X62DF-S1.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X72-B (W-X72-B)',
    value: 'W-X72-B',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X72DF-1.1 (X72DF-1.1)',
    value: 'X72DF-1.1',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X72DF-1.2 (X72DF-1.2)',
    value: 'X72DF-1.2',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X72DF-2.1 (X72DF-2.1)',
    value: 'X72DF-2.1',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X72DF-2.2 (X72DF-2.2)',
    value: 'X72DF-2.2',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X72DF-IPS-VCR (X72DF-IPS-VCR)',
    value: 'X72DF-IPS-VCR',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X82-2.0 (X82-2.0)',
    value: 'X82-2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X82-D (W-X82-D)',
    value: 'W-X82-D',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X82DF-1.0 (X82DF-1.0)',
    value: 'X82DF-1.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X82DF-2.0 (X82DF-2.0)',
    value: 'X82DF-2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X92-B (W-X92-B)',
    value: 'W-X92-B',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD X92DF-2.0 (X92DF-2.0)',
    value: 'X92DF-2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD XS52-S2.0 (XS52-S2.0)',
    value: 'XS52-S2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD XS52DF-S1.0 (XS52DF-S1.0)',
    value: 'XS52DF-S1.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD XS62-S2.0 (XS62-S2.0)',
    value: 'XS62-S2.0',
    type: 'Product Reference Type',
  },
  {
    label: 'WinGD XS62DF-S1.0 (XS62DF-S1.0)',
    value: 'XS62DF-S1.0',
    type: 'Product Reference Type',
  },
  {
    label: 'Wola - Henschel (WOLA_HENSCHEL)',
    value: 'WOLA_HENSCHEL',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 14 (W14)',
    value: 'W14',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 16 (W16)',
    value: 'W16',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 20 (W20)',
    value: 'W20',
    type: 'Product Reference Type',
  },
  {
    label: 'WÄRTSILÄ 200 (W200)',
    value: 'W200',
    type: 'Product Reference Type',
  },
  {
    label: 'WÄRTSILÄ 220 (W220)',
    value: 'W220',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 25 (W25)',
    value: 'W25',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 26 (W26)',
    value: 'W26',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 28SG (W28SG)',
    value: 'W28SG',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 31 (W31)',
    value: 'W31',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 32 (W32)',
    value: 'W32',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 33 (W33)',
    value: 'W33',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 34 (W34)',
    value: 'W34',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 38 (W38A)',
    value: 'W38A',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 38 (W38B)',
    value: 'W38B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 38C (W38C)',
    value: 'W38C',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 46 (W46)',
    value: 'W46',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 46 Two-Stage (W46TS)',
    value: 'W46TS',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 46F (W46F)',
    value: 'W46F',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 50 (W50)',
    value: 'W50',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä 64 (W64)',
    value: 'W64',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä CPP/F-hub (CPP-F)',
    value: 'CPP-F',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä CPP/G-hub (CPP-G)',
    value: 'CPP-G',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä CPP/GF-hub (CPP-GF)',
    value: 'CPP-GF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Data Collection unit (WDCU)',
    value: 'WDCU',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä EnduraPac (ENDURAPAC)',
    value: 'ENDURAPAC',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Engine Aux Module (ENG_AUX_MOD)',
    value: 'ENG_AUX_MOD',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Exh gas module (EXHAUST_GAS_MODULE)',
    value: 'EXHAUST_GAS_MODULE',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä FO transf pump unit (FUEL_OIL_TRANSFER)',
    value: 'FUEL_OIL_TRANSFER',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Fuel oil booster (FUEL_OIL_BOOSTER)',
    value: 'FUEL_OIL_BOOSTER',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Fuel oil feeder (FUEL_OIL_FEEDER)',
    value: 'FUEL_OIL_FEEDER',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gas Regulating Unit (GAS_REG_UNIT)',
    value: 'GAS_REG_UNIT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä GasReformer (GR)',
    value: 'GR',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear SH Flender eng (MGB-WSH)',
    value: 'MGB-WSH',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear Single/Horiz (GE-SH)',
    value: 'GE-SH',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear Single/Vertical (GE-SV)',
    value: 'GE-SV',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear SV Flender eng (MGB-WSV)',
    value: 'MGB-WSV',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear SV/2 Flender eng (MGB-WSV2)',
    value: 'MGB-WSV2',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear TH Flender eng (MGB-WTH)',
    value: 'MGB-WTH',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Gear Twin/Horiz (GE-TH)',
    value: 'GE-TH',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä HMI (HMI)',
    value: 'HMI',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Module appendix (MODULE_APPENDIX)',
    value: 'MODULE_APPENDIX',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Mud Water Decanter (MWD)',
    value: 'MWD',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Nohab 25 (WN25)',
    value: 'WN25',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Nohab 25DF (WN25DF)',
    value: 'WN25DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Nohab 25SG (W25SG)',
    value: 'W25SG',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Nohab F10 (F10)',
    value: 'F10',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Nohab F20 (F20)',
    value: 'F20',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Nohab F30 (F30)',
    value: 'F30',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä NOx Reducer (NOR)',
    value: 'NOR',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Oily Water Biological (OWBT)',
    value: 'OWBT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Oily Water Separator (OWS)',
    value: 'OWS',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Oily Water Treatment (OWT)',
    value: 'OWT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Operator Interface Sy (WOIS)',
    value: 'WOIS',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - BCU (CTRL-LMCS-01)',
    value: 'CTRL-LMCS-01',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - BCU ReFit (CTRL-LMCS-01R)',
    value: 'CTRL-LMCS-01R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - HaiWang (CTRL-LMCS-03)',
    value: 'CTRL-LMCS-03',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - HaiWang ReFit (CTRL-LMCS-03R)',
    value: 'CTRL-LMCS-03R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - PLC (CTRL-LMCS-00)',
    value: 'CTRL-LMCS-00',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - PLC ReFit (CTRL-LMCS-00R)',
    value: 'CTRL-LMCS-00R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - ProTouch (CTRL-RCS-00)',
    value: 'CTRL-RCS-00',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - WAGO (CTRL-LMCS-02)',
    value: 'CTRL-LMCS-02',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä PCS - WAGO ReFit (CTRL-LMCS-02R)',
    value: 'CTRL-LMCS-02R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Pipe rack (PIPE_RACK)',
    value: 'PIPE_RACK',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Platform (PLATFORM)',
    value: 'PLATFORM',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Protouch (PROTOUCH)',
    value: 'PROTOUCH',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX48T-D (RT-FLEX48T-D)',
    value: 'RT-FLEX48T-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX50 (RT-FLEX50)',
    value: 'RT-FLEX50',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX50-B (RT-FLEX50-B)',
    value: 'RT-FLEX50-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX50-D (RT-FLEX50-D)',
    value: 'RT-FLEX50-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-flex50DF (RT-FLEX50DF)',
    value: 'RT-FLEX50DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX58T-B (RT-FLEX58T-B)',
    value: 'RT-FLEX58T-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX58T-D_V0 (RT-FLEX58T-D_V0)',
    value: 'RT-FLEX58T-D_V0',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX58T-D_V1 (RT-FLEX58T-D_V1)',
    value: 'RT-FLEX58T-D_V1',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX58T-D_V2 (RT-FLEX58T-D_V2)',
    value: 'RT-FLEX58T-D_V2',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-flex58T-E (RT-FLEX58T-E)',
    value: 'RT-FLEX58T-E',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX58TD_ER-3 (RT-FLEX58TD_ER-3)',
    value: 'RT-FLEX58TD_ER-3',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-flex68-D_L (RT-FLEX68-D_L)',
    value: 'RT-FLEX68-D_L',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX68-D_V0 (RT-FLEX68-D_V0)',
    value: 'RT-FLEX68-D_V0',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX68-D_V1 (RT-FLEX68-D_V1)',
    value: 'RT-FLEX68-D_V1',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX82C (RT-FLEX82C)',
    value: 'RT-FLEX82C',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX82T (RT-FLEX82T)',
    value: 'RT-FLEX82T',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX84T-D (RT-FLEX84T-D)',
    value: 'RT-FLEX84T-D',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RT-FLEX96C-B (RT-FLEX96C-B)',
    value: 'RT-FLEX96C-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RTA82C (RTA82C)',
    value: 'RTA82C',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RTA82T (RTA82T)',
    value: 'RTA82T',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RTX-4 (RTX-4)',
    value: 'RTX-4',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RTX-5 (RTX-5)',
    value: 'RTX-5',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä RTX-6 (RTX-6)',
    value: 'RTX-6',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Scrubber Water Treatm (SWT)',
    value: 'SWT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Slop Water Cleaning (SWC)',
    value: 'SWC',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Steerable Thruster (WST)',
    value: 'WST',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Steerable Thruster-F (WST-F)',
    value: 'WST-F',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Steerable Thruster-R (WST-R)',
    value: 'WST-R',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Steerable Thruster-RT (WST-RT)',
    value: 'WST-RT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Steerable Thruster-RU (WST-RU)',
    value: 'WST-RU',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Steerable Thruster-U (WST-U)',
    value: 'WST-U',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Transverse Thruster (WTT)',
    value: 'WTT',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Vasa 14 (14)',
    value: '14',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Vasa 22 (22)',
    value: '22',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Vasa 22/26 (22/26)',
    value: '22/26',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Vasa 24 (24)',
    value: '24',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Vasa 32 (32)',
    value: '32',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä Vasa 34 (34)',
    value: '34',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-35-B (W-X35-B)',
    value: 'W-X35-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-40-B (W-X40-B)',
    value: 'W-X40-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-52 (W-X52)',
    value: 'W-X52',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-52DF (W-X52DF)',
    value: 'W-X52DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-62DF (W-X62DF)',
    value: 'W-X62DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-82-B (W-X82-B)',
    value: 'W-X82-B',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-82DF (W-X82DF)',
    value: 'W-X82DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X-92DF (W-X92DF)',
    value: 'W-X92DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X35 (W-X35)',
    value: 'W-X35',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X40 (W-X40)',
    value: 'W-X40',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X62 (W-X62)',
    value: 'W-X62',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X72 (W-X72)',
    value: 'W-X72',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X72DF (W-X72DF)',
    value: 'W-X72DF',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X82 (W-X82)',
    value: 'W-X82',
    type: 'Product Reference Type',
  },
  {
    label: 'Wärtsilä X92 (W-X92)',
    value: 'W-X92',
    type: 'Product Reference Type',
  },
  {
    label: 'Yanmar (YANMAR)',
    value: 'YANMAR',
    type: 'Product Reference Type',
  },
];

export const characteristicOptions = [
  {
    label: 'Destination change',
    value: 'destination_change.neq.null',
    urlValue: 'dc',
  },
  { label: 'Drifting', value: 'drifting.neq.0', urlValue: 'd' },
  {
    label: 'Not under command',
    value: 'not_under_command.neq.0',
    urlValue: 'nuc',
  },
  {
    label: 'Ongoing',
    value: 'end_datetime.is.null',
    urlValue: 'ong',
  },
  {
    label: 'Significant speed decrease',
    value: 'speed_change.lt.-5',
    urlValue: 'ssd',
  },
  {
    label: 'Status change',
    value: 'status_change.neq.null',
    urlValue: 'sc',
  },
  {
    label: 'Tug involved',
    value: 'involved_tug_mmsi.neq.0',
    urlValue: 'ti',
  },
  {
    label: 'Waiting for orders',
    value: 'waiting_for_orders.neq.0',
    urlValue: 'wfo',
  },
  {
    label: 'Waiting for port',
    value: 'waiting_for_port.neq.0',
    urlValue: 'wfp',
  },
];

export const confidenceOptions: ConfidenceOption[] = [
  {
    label: 'Very low',
    value: 1,
    urlValue: 'vl',
    minQuery: null,
    maxQuery: 'anomaly_confidence.lt.0.6',
  },
  {
    label: 'Low',
    value: 2,
    urlValue: 'l',
    minQuery: 'anomaly_confidence.gte.0.6',
    maxQuery: 'anomaly_confidence.lt.0.7',
  },
  {
    label: 'Medium',
    value: 3,
    urlValue: 'm',
    minQuery: 'anomaly_confidence.gte.0.7',
    maxQuery: 'anomaly_confidence.lt.0.85',
    isDefaultMin: true,
  },
  {
    label: 'High',
    value: 4,
    urlValue: 'h',
    minQuery: 'anomaly_confidence.gte.0.85',
    maxQuery: 'anomaly_confidence.lt.0.95',
  },
  {
    label: 'Very High',
    value: 5,
    urlValue: 'vh',
    minQuery: 'anomaly_confidence.gte.0.95',
    maxQuery: null,
    isDefaultMax: true,
  },
];

export const causeOptions: CauseOption[] = Object.entries(casualtyCauses).map((entry) => {
  const [code, description] = entry;
  return { label: description, value: code };
});

export const crmLeadOptions: CRMLeadOption[] = [
  {
    label: 'Rejected',
    value: 'Rejected',
    urlValue: 'r',
  },
  {
    label: 'Pending',
    value: 'Pending',
    urlValue: 'p',
  },
  {
    label: 'In Progress',
    value: 'In Progress',
    urlValue: 'ip',
  },
  {
    label: 'Confirmed',
    value: 'Confirmed',
    urlValue: 'c',
  },
  {
    label: 'Unknown',
    value: 'Unknown',
    urlValue: 'u',
  },
];

export const drydockingStatusOptions: CharacteristicOption[] = [
  {
    label: 'Drydocking events',
    value: 'events',
    urlValue: '',
    isDefault: true,
  },
  {
    label: 'Currently in drydock',
    value: 'cid',
    urlValue: 'cid',
  },
];

export const userFilterEmailOptions: BaseSelectOption[] = [
  { label: 'No', value: null },
  { label: 'Instant', value: 'instant' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];
