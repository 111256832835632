import React from 'react';
import { Link } from 'react-router-dom';

import { ClockIcon } from '../../../common/components/icons/ClockIcon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { Anomalies, Casualties, Drydockings } from '../../../store/apis/vessel-event-api';
import { EventType, setSelectedEvent } from '../slice';
import { getLinkToVesselFromEvent } from '../utils';
import { PortCalls } from '../../../store/apis/ais-api';

type Props = {
  item: Anomalies | Casualties | Drydockings | PortCalls;
  type: EventType;
  date?: string;
  className?: string;
  children?: React.ReactNode;
};

export const ListItem: React.FC<Props> = ({ item, type, date, className, children }) => {
  const dispatch = useAppDispatch();
  const mapInstance = useAppSelector((state) => state.map.mapInstance);
  const vesselMapInstance = useAppSelector((state) => state.vesselMap.mapInstance);
  const selectedEvent = useAppSelector((state) => state.eventFeed.selectedEvent);
  const showFilters = useAppSelector((state) => state.eventFeed.showFilters);

  let imgSrc: string | undefined;
  switch (type) {
    case 'anomaly':
      imgSrc = '/assets/images/markers/anomaly.svg';
      break;
    case 'casualty':
      imgSrc = '/assets/images/markers/casualty.svg';
      break;
    case 'dry-dock':
      imgSrc = '/assets/images/markers/drydock.svg';
      break;
    case 'port-calls':
      imgSrc = '/assets/images/markers/port.svg';
      break;
    default:
      imgSrc = '/assets/images/markers/anomaly.svg';
      break;
  }

  const renderVesselInfo = () => {
    if ('installation_id' in item) {
      const { installation_id, imo_number, vessel_type_name } = item;

      const installationLink = installation_id
        ? `<a target="_blank" class="hover:underline" href="https://cib.data.wartsila.com/installations/${installation_id}">${installation_id}</a>`
        : null;

      let text = '';
      if (vessel_type_name) {
        text = text.concat(vessel_type_name);
      }
      if (imo_number) {
        const imo = text ? ` | IMO ${imo_number}` : `IMO ${imo_number}`;
        text = text.concat(imo);
      }
      if (installation_id) {
        const id = text ? ` | ID ${installationLink}` : `ID ${installationLink}`;
        text = text.concat(id);
      }

      return text;
    }
    return '';
  };

  const renderItemForVesselPage = () => (
    <div
      data-event={`${type}-${'id' in item ? item.id : ''}`}
      className={`ListItem ${
        (hasCoordinates || type === 'port-calls') && 'cursor-pointer'
      } border-b border-neutral pt-3 p-2 text-[#f2f2f2] rounded-[3px] ${className ? className : ''}`}
      onClick={onClickMarkerIconHandler}
    >
      <div className="ListItem__header flex flex-row">
        <div className="flex-1 flex flex-col">
          <span className="text-gray-400">
            <span className="flex flex-wrap items-center">
              <ClockIcon className="h-4 w-4" />
              <span className="ml-1">{date}</span>
              <div className="text-gray-400 text-sm capitalize pl-1">
                | {type === 'dry-dock' ? 'dry-docking' : type}
              </div>
            </span>
          </span>
          {children}
        </div>
        <div className="flex flex-col">
          <img
            alt={`${type} marker`}
            src={imgSrc}
            className={`mb-1 mx-2 h-10 w-10 ${
              hasCoordinates || type === 'port-calls' ? 'cursor-pointer' : 'opacity-10'
            }`}
            title={hasCoordinates || type === 'port-calls' ? 'Show on map' : 'There is no location information'}
          ></img>
        </div>
      </div>
    </div>
  );

  const renderItem = () => (
    <div
      data-event={`${type}-${'id' in item ? item.id : ''}`}
      className={`ListItem border-b border-neutral pt-3 p-2 text-[#f2f2f2] rounded-[3px] ${className ? className : ''}`}
    >
      <div className="ListItem__header flex flex-row">
        <div className="flex-1 flex flex-col">
          <span className="text-gray-400">
            <span className="flex flex-wrap items-center">
              <ClockIcon className="h-4 w-4" />
              <span className="ml-1">{date}</span>
            </span>
          </span>
          <h1 className={`text-lg font-medium ${!vesselLink ? 'opacity-50' : ''}`}>
            {vesselLink ? (
              <Link to={vesselLink} target="_blank" rel="noopener noreferrer" className="hover:underline">
                {item.vessel_name}
              </Link>
            ) : (
              <span title="Vessel page is not available for vessels without an IMO number">{item.vessel_name}</span>
            )}
          </h1>
          <span className="text-gray-400 text-xs" dangerouslySetInnerHTML={{ __html: renderVesselInfo() }} />
          {children}
        </div>
        <div className="flex flex-col">
          <img
            alt={`${type} marker`}
            src={imgSrc}
            className={`mb-1 h-12 w-12 ${hasCoordinates ? 'cursor-pointer' : 'opacity-10'}`}
            title={hasCoordinates ? 'Show on map' : 'There is no location information'}
            onClick={onClickMarkerIconHandler}
          ></img>
        </div>
      </div>
    </div>
  );

  const id = 'id' in item ? item.id : (item as PortCalls).arrival_time;
  // Using loose equality comparison on purpose to check for undefined and null
  // This is needed as the TS type from OpenAPI schema does not reflect their actual values, coordinates can be null.
  const hasCoordinates = 'latitude' in item && item.latitude != null && item.longitude != null;

  const onClickMarkerIconHandler = () => {
    if ('port_id' in item) {
      const selected = selectedEvent?.id === id && selectedEvent?.type === type;
      dispatch(setSelectedEvent(!selected ? { id: id as number, type } : null));
      vesselMapInstance?.flyTo({ center: [item.port_longitude as number, item.port_latitude as number], zoom: 10 });
      return;
    }
    if (!hasCoordinates) {
      return;
    }

    const selected = selectedEvent?.id === item.id && selectedEvent?.type === type;
    dispatch(setSelectedEvent(!selected ? { id: item.id as number, type } : null));

    if (hasCoordinates && !selected) {
      mapInstance?.flyTo({ center: [item.longitude as number, item.latitude as number] });
    }
  };

  const vesselLink = getLinkToVesselFromEvent(item, type, id);

  return <>{showFilters ? renderItem() : renderItemForVesselPage()}</>;
};
