import React, { useState } from 'react';

import { Select } from '../../../common/components/Select';
import { useAppSelector } from '../../../common/hooks';
import { debounce } from '../../../common/utils';
import { useCreateVesselInFleetFleetPostMutation } from '../../../store/apis/csi-api';
import { Shipdata } from '../../../store/apis/ihs-vessel-data-api';
import { useGetMyFleet, useSearchVesselsQuery } from '../hooks';
import { filterSearchResults } from '../utils';

type Props = {
  className?: string;
};

export const MyFleetSearch: React.FC<Props> = ({ className }) => {
  const manualFleet = useAppSelector((state) => state.myFleet.myManualFleet);
  const [input, setInput] = useState<string>('');
  const searchQuery = useSearchVesselsQuery(input);
  const [addToFleet] = useCreateVesselInFleetFleetPostMutation();
  const fleetQuery = useGetMyFleet();

  const searchResults = (searchQuery.currentData as Shipdata[]) || [];
  const options = filterSearchResults(searchResults, manualFleet);

  const onInputChangeHandler = debounce((input: string) => {
    setInput(input);
  }, 500);

  const onChangeHandler = async (value: Shipdata) => {
    if (!value.lrimoshipno) return;
    await addToFleet({ imoNumber: Number(value.lrimoshipno) });
    fleetQuery.refetch();
  };

  const getOptionLabel = (option: Shipdata) => `${option.shipname} (${option.shiptypelevel4}, ${option.yearofbuild},
    IMO ${option.lrimoshipno})`;

  return (
    <>
      <div className="MyFleetSearch border-b border-secondary pl-1 mb-2">
        <span className="text-lg">Add vessel to your fleet</span>
      </div>
      <Select
        value={null}
        options={options}
        getOptionLabel={getOptionLabel}
        onInputChange={onInputChangeHandler}
        onChange={onChangeHandler}
        isClearable
        isLoading={searchQuery.isFetching}
        className={className}
        placeholder="Find vessel by name or IMO number"
        noOptionsMessage={() => (input ? `No vessels found for "${input}"` : 'Start typing vessel name or IMO number')}
        menuPortalTarget={document.body}
      />
    </>
  );
};
