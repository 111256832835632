import { portBaseApi as api } from '../base/base-apis';
import { Polygon } from 'geojson';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    get: build.query<GetApiResponse, GetApiArg>({
      query: () => ({ url: `/` }),
    }),
    getCharacteristics: build.query<GetCharacteristicsApiResponse, GetCharacteristicsApiArg>({
      query: (queryArg) => ({
        url: `/characteristics`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          place_id: queryArg.placeId,
          wpi_version: queryArg.wpiVersion,
          port_name: queryArg.portName,
          harbor_size_code: queryArg.harborSizeCode,
          harbor_type_code: queryArg.harborTypeCode,
          shelter_afforded_code: queryArg.shelterAffordedCode,
          entrance_restriction_tide: queryArg.entranceRestrictionTide,
          entrance_restriction_swell: queryArg.entranceRestrictionSwell,
          entrance_restriction_ice: queryArg.entranceRestrictionIce,
          entrance_restriction_other: queryArg.entranceRestrictionOther,
          overhead_limits: queryArg.overheadLimits,
          channel_depth: queryArg.channelDepth,
          anchorage_depth: queryArg.anchorageDepth,
          cargo_pier_depth: queryArg.cargoPierDepth,
          oil_terminal_depth: queryArg.oilTerminalDepth,
          tide: queryArg.tide,
          maxsize_vessel_code: queryArg.maxsizeVesselCode,
          good_holding_ground: queryArg.goodHoldingGround,
          turning_area: queryArg.turningArea,
          first_port_of_entry: queryArg.firstPortOfEntry,
          us_representative: queryArg.usRepresentative,
          eta_message: queryArg.etaMessage,
          pilotage_compulsory: queryArg.pilotageCompulsory,
          pilotage_available: queryArg.pilotageAvailable,
          pilotage_local_assist: queryArg.pilotageLocalAssist,
          pilotage_advisable: queryArg.pilotageAdvisable,
          tugs_salvage: queryArg.tugsSalvage,
          tugs_assist: queryArg.tugsAssist,
          quarantine_pratique: queryArg.quarantinePratique,
          quarantine_deratt_cert: queryArg.quarantineDerattCert,
          quarantine_other: queryArg.quarantineOther,
          communications_telephone: queryArg.communicationsTelephone,
          communications_telegraph: queryArg.communicationsTelegraph,
          communications_radio: queryArg.communicationsRadio,
          communications_radio_tel: queryArg.communicationsRadioTel,
          communications_air: queryArg.communicationsAir,
          communications_rail: queryArg.communicationsRail,
          load_offload_wharves: queryArg.loadOffloadWharves,
          load_offload_anchor: queryArg.loadOffloadAnchor,
          load_offload_med_moor: queryArg.loadOffloadMedMoor,
          load_offload_beach_moor: queryArg.loadOffloadBeachMoor,
          load_offload_ice_moor: queryArg.loadOffloadIceMoor,
          medical_facilities: queryArg.medicalFacilities,
          garbage_disposal: queryArg.garbageDisposal,
          degauss: queryArg.degauss,
          dirty_ballast: queryArg.dirtyBallast,
          cranes_fixed: queryArg.cranesFixed,
          cranes_mobile: queryArg.cranesMobile,
          cranes_floating: queryArg.cranesFloating,
          lifts_100_tons_plus: queryArg.lifts100TonsPlus,
          lifts_50_100_tons: queryArg.lifts50100Tons,
          lifts_25_49_tons: queryArg.lifts2549Tons,
          lifts_0_24_tons: queryArg.lifts024Tons,
          services_longshore: queryArg.servicesLongshore,
          services_elect: queryArg.servicesElect,
          services_steam: queryArg.servicesSteam,
          services_navig_equip: queryArg.servicesNavigEquip,
          services_elect_repair: queryArg.servicesElectRepair,
          supplies_provisions: queryArg.suppliesProvisions,
          supplies_water: queryArg.suppliesWater,
          supplies_fuel_oil: queryArg.suppliesFuelOil,
          supplies_diesel_oil: queryArg.suppliesDieselOil,
          supplies_deck: queryArg.suppliesDeck,
          supplies_engine: queryArg.suppliesEngine,
          repair_code: queryArg.repairCode,
          drydock: queryArg.drydock,
          railway: queryArg.railway,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postCharacteristics: build.mutation<PostCharacteristicsApiResponse, PostCharacteristicsApiArg>({
      query: (queryArg) => ({
        url: `/characteristics`,
        method: 'POST',
        body: queryArg.characteristics,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteCharacteristics: build.mutation<DeleteCharacteristicsApiResponse, DeleteCharacteristicsApiArg>({
      query: (queryArg) => ({
        url: `/characteristics`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          place_id: queryArg.placeId,
          wpi_version: queryArg.wpiVersion,
          port_name: queryArg.portName,
          harbor_size_code: queryArg.harborSizeCode,
          harbor_type_code: queryArg.harborTypeCode,
          shelter_afforded_code: queryArg.shelterAffordedCode,
          entrance_restriction_tide: queryArg.entranceRestrictionTide,
          entrance_restriction_swell: queryArg.entranceRestrictionSwell,
          entrance_restriction_ice: queryArg.entranceRestrictionIce,
          entrance_restriction_other: queryArg.entranceRestrictionOther,
          overhead_limits: queryArg.overheadLimits,
          channel_depth: queryArg.channelDepth,
          anchorage_depth: queryArg.anchorageDepth,
          cargo_pier_depth: queryArg.cargoPierDepth,
          oil_terminal_depth: queryArg.oilTerminalDepth,
          tide: queryArg.tide,
          maxsize_vessel_code: queryArg.maxsizeVesselCode,
          good_holding_ground: queryArg.goodHoldingGround,
          turning_area: queryArg.turningArea,
          first_port_of_entry: queryArg.firstPortOfEntry,
          us_representative: queryArg.usRepresentative,
          eta_message: queryArg.etaMessage,
          pilotage_compulsory: queryArg.pilotageCompulsory,
          pilotage_available: queryArg.pilotageAvailable,
          pilotage_local_assist: queryArg.pilotageLocalAssist,
          pilotage_advisable: queryArg.pilotageAdvisable,
          tugs_salvage: queryArg.tugsSalvage,
          tugs_assist: queryArg.tugsAssist,
          quarantine_pratique: queryArg.quarantinePratique,
          quarantine_deratt_cert: queryArg.quarantineDerattCert,
          quarantine_other: queryArg.quarantineOther,
          communications_telephone: queryArg.communicationsTelephone,
          communications_telegraph: queryArg.communicationsTelegraph,
          communications_radio: queryArg.communicationsRadio,
          communications_radio_tel: queryArg.communicationsRadioTel,
          communications_air: queryArg.communicationsAir,
          communications_rail: queryArg.communicationsRail,
          load_offload_wharves: queryArg.loadOffloadWharves,
          load_offload_anchor: queryArg.loadOffloadAnchor,
          load_offload_med_moor: queryArg.loadOffloadMedMoor,
          load_offload_beach_moor: queryArg.loadOffloadBeachMoor,
          load_offload_ice_moor: queryArg.loadOffloadIceMoor,
          medical_facilities: queryArg.medicalFacilities,
          garbage_disposal: queryArg.garbageDisposal,
          degauss: queryArg.degauss,
          dirty_ballast: queryArg.dirtyBallast,
          cranes_fixed: queryArg.cranesFixed,
          cranes_mobile: queryArg.cranesMobile,
          cranes_floating: queryArg.cranesFloating,
          lifts_100_tons_plus: queryArg.lifts100TonsPlus,
          lifts_50_100_tons: queryArg.lifts50100Tons,
          lifts_25_49_tons: queryArg.lifts2549Tons,
          lifts_0_24_tons: queryArg.lifts024Tons,
          services_longshore: queryArg.servicesLongshore,
          services_elect: queryArg.servicesElect,
          services_steam: queryArg.servicesSteam,
          services_navig_equip: queryArg.servicesNavigEquip,
          services_elect_repair: queryArg.servicesElectRepair,
          supplies_provisions: queryArg.suppliesProvisions,
          supplies_water: queryArg.suppliesWater,
          supplies_fuel_oil: queryArg.suppliesFuelOil,
          supplies_diesel_oil: queryArg.suppliesDieselOil,
          supplies_deck: queryArg.suppliesDeck,
          supplies_engine: queryArg.suppliesEngine,
          repair_code: queryArg.repairCode,
          drydock: queryArg.drydock,
          railway: queryArg.railway,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchCharacteristics: build.mutation<PatchCharacteristicsApiResponse, PatchCharacteristicsApiArg>({
      query: (queryArg) => ({
        url: `/characteristics`,
        method: 'PATCH',
        body: queryArg.characteristics,
        headers: { Prefer: queryArg.prefer },
        params: {
          place_id: queryArg.placeId,
          wpi_version: queryArg.wpiVersion,
          port_name: queryArg.portName,
          harbor_size_code: queryArg.harborSizeCode,
          harbor_type_code: queryArg.harborTypeCode,
          shelter_afforded_code: queryArg.shelterAffordedCode,
          entrance_restriction_tide: queryArg.entranceRestrictionTide,
          entrance_restriction_swell: queryArg.entranceRestrictionSwell,
          entrance_restriction_ice: queryArg.entranceRestrictionIce,
          entrance_restriction_other: queryArg.entranceRestrictionOther,
          overhead_limits: queryArg.overheadLimits,
          channel_depth: queryArg.channelDepth,
          anchorage_depth: queryArg.anchorageDepth,
          cargo_pier_depth: queryArg.cargoPierDepth,
          oil_terminal_depth: queryArg.oilTerminalDepth,
          tide: queryArg.tide,
          maxsize_vessel_code: queryArg.maxsizeVesselCode,
          good_holding_ground: queryArg.goodHoldingGround,
          turning_area: queryArg.turningArea,
          first_port_of_entry: queryArg.firstPortOfEntry,
          us_representative: queryArg.usRepresentative,
          eta_message: queryArg.etaMessage,
          pilotage_compulsory: queryArg.pilotageCompulsory,
          pilotage_available: queryArg.pilotageAvailable,
          pilotage_local_assist: queryArg.pilotageLocalAssist,
          pilotage_advisable: queryArg.pilotageAdvisable,
          tugs_salvage: queryArg.tugsSalvage,
          tugs_assist: queryArg.tugsAssist,
          quarantine_pratique: queryArg.quarantinePratique,
          quarantine_deratt_cert: queryArg.quarantineDerattCert,
          quarantine_other: queryArg.quarantineOther,
          communications_telephone: queryArg.communicationsTelephone,
          communications_telegraph: queryArg.communicationsTelegraph,
          communications_radio: queryArg.communicationsRadio,
          communications_radio_tel: queryArg.communicationsRadioTel,
          communications_air: queryArg.communicationsAir,
          communications_rail: queryArg.communicationsRail,
          load_offload_wharves: queryArg.loadOffloadWharves,
          load_offload_anchor: queryArg.loadOffloadAnchor,
          load_offload_med_moor: queryArg.loadOffloadMedMoor,
          load_offload_beach_moor: queryArg.loadOffloadBeachMoor,
          load_offload_ice_moor: queryArg.loadOffloadIceMoor,
          medical_facilities: queryArg.medicalFacilities,
          garbage_disposal: queryArg.garbageDisposal,
          degauss: queryArg.degauss,
          dirty_ballast: queryArg.dirtyBallast,
          cranes_fixed: queryArg.cranesFixed,
          cranes_mobile: queryArg.cranesMobile,
          cranes_floating: queryArg.cranesFloating,
          lifts_100_tons_plus: queryArg.lifts100TonsPlus,
          lifts_50_100_tons: queryArg.lifts50100Tons,
          lifts_25_49_tons: queryArg.lifts2549Tons,
          lifts_0_24_tons: queryArg.lifts024Tons,
          services_longshore: queryArg.servicesLongshore,
          services_elect: queryArg.servicesElect,
          services_steam: queryArg.servicesSteam,
          services_navig_equip: queryArg.servicesNavigEquip,
          services_elect_repair: queryArg.servicesElectRepair,
          supplies_provisions: queryArg.suppliesProvisions,
          supplies_water: queryArg.suppliesWater,
          supplies_fuel_oil: queryArg.suppliesFuelOil,
          supplies_diesel_oil: queryArg.suppliesDieselOil,
          supplies_deck: queryArg.suppliesDeck,
          supplies_engine: queryArg.suppliesEngine,
          repair_code: queryArg.repairCode,
          drydock: queryArg.drydock,
          railway: queryArg.railway,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getGeometries: build.query<GetGeometriesApiResponse, GetGeometriesApiArg>({
      query: (queryArg) => ({
        url: `/geometries`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          name: queryArg.name,
          type: queryArg['type'],
          source: queryArg.source,
          status: queryArg.status,
          remarks: queryArg.remarks,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postGeometries: build.mutation<PostGeometriesApiResponse, PostGeometriesApiArg>({
      query: (queryArg) => ({
        url: `/geometries`,
        method: 'POST',
        body: queryArg.geometries,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteGeometries: build.mutation<DeleteGeometriesApiResponse, DeleteGeometriesApiArg>({
      query: (queryArg) => ({
        url: `/geometries`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          name: queryArg.name,
          type: queryArg['type'],
          source: queryArg.source,
          status: queryArg.status,
          remarks: queryArg.remarks,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchGeometries: build.mutation<PatchGeometriesApiResponse, PatchGeometriesApiArg>({
      query: (queryArg) => ({
        url: `/geometries`,
        method: 'PATCH',
        body: queryArg.geometries,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          name: queryArg.name,
          type: queryArg['type'],
          source: queryArg.source,
          status: queryArg.status,
          remarks: queryArg.remarks,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getIdentifiers: build.query<GetIdentifiersApiResponse, GetIdentifiersApiArg>({
      query: (queryArg) => ({
        url: `/identifiers`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          type: queryArg['type'],
          identifier: queryArg.identifier,
          source: queryArg.source,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postIdentifiers: build.mutation<PostIdentifiersApiResponse, PostIdentifiersApiArg>({
      query: (queryArg) => ({
        url: `/identifiers`,
        method: 'POST',
        body: queryArg.identifiers,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteIdentifiers: build.mutation<DeleteIdentifiersApiResponse, DeleteIdentifiersApiArg>({
      query: (queryArg) => ({
        url: `/identifiers`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          type: queryArg['type'],
          identifier: queryArg.identifier,
          source: queryArg.source,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchIdentifiers: build.mutation<PatchIdentifiersApiResponse, PatchIdentifiersApiArg>({
      query: (queryArg) => ({
        url: `/identifiers`,
        method: 'PATCH',
        body: queryArg.identifiers,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          type: queryArg['type'],
          identifier: queryArg.identifier,
          source: queryArg.source,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getNavigationPoints: build.query<GetNavigationPointsApiResponse, GetNavigationPointsApiArg>({
      query: (queryArg) => ({
        url: `/navigation_points`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          type: queryArg['type'],
          direction: queryArg.direction,
          source: queryArg.source,
          status: queryArg.status,
          remarks: queryArg.remarks,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postNavigationPoints: build.mutation<PostNavigationPointsApiResponse, PostNavigationPointsApiArg>({
      query: (queryArg) => ({
        url: `/navigation_points`,
        method: 'POST',
        body: queryArg.navigationPoints,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteNavigationPoints: build.mutation<DeleteNavigationPointsApiResponse, DeleteNavigationPointsApiArg>({
      query: (queryArg) => ({
        url: `/navigation_points`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          type: queryArg['type'],
          direction: queryArg.direction,
          source: queryArg.source,
          status: queryArg.status,
          remarks: queryArg.remarks,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchNavigationPoints: build.mutation<PatchNavigationPointsApiResponse, PatchNavigationPointsApiArg>({
      query: (queryArg) => ({
        url: `/navigation_points`,
        method: 'PATCH',
        body: queryArg.navigationPoints,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          type: queryArg['type'],
          direction: queryArg.direction,
          source: queryArg.source,
          status: queryArg.status,
          remarks: queryArg.remarks,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getPlaceNavigationPoints: build.query<GetPlaceNavigationPointsApiResponse, GetPlaceNavigationPointsApiArg>({
      query: (queryArg) => ({
        url: `/place_navigation_points`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          place_id: queryArg.placeId,
          navigation_point_id: queryArg.navigationPointId,
          role: queryArg.role,
          type: queryArg['type'],
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postPlaceNavigationPoints: build.mutation<PostPlaceNavigationPointsApiResponse, PostPlaceNavigationPointsApiArg>({
      query: (queryArg) => ({
        url: `/place_navigation_points`,
        method: 'POST',
        body: queryArg.placeNavigationPoints,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deletePlaceNavigationPoints: build.mutation<
      DeletePlaceNavigationPointsApiResponse,
      DeletePlaceNavigationPointsApiArg
    >({
      query: (queryArg) => ({
        url: `/place_navigation_points`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          place_id: queryArg.placeId,
          navigation_point_id: queryArg.navigationPointId,
          role: queryArg.role,
          type: queryArg['type'],
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchPlaceNavigationPoints: build.mutation<PatchPlaceNavigationPointsApiResponse, PatchPlaceNavigationPointsApiArg>(
      {
        query: (queryArg) => ({
          url: `/place_navigation_points`,
          method: 'PATCH',
          body: queryArg.placeNavigationPoints,
          headers: { Prefer: queryArg.prefer },
          params: {
            place_id: queryArg.placeId,
            navigation_point_id: queryArg.navigationPointId,
            role: queryArg.role,
            type: queryArg['type'],
            insert_datetime: queryArg.insertDatetime,
            insert_user: queryArg.insertUser,
            update_datetime: queryArg.updateDatetime,
            update_user: queryArg.updateUser,
          },
        }),
      }
    ),
    getPlaces: build.query<GetPlacesApiResponse, GetPlacesApiArg>({
      query: (queryArg) => ({
        url: `/places`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          type: queryArg['type'],
          subtype: queryArg.subtype,
          status: queryArg.status,
          parent_id: queryArg.parentId,
          country_code: queryArg.countryCode,
          subdivision_code: queryArg.subdivisionCode,
          mrv_area: queryArg.mrvArea,
          wpi_region_id: queryArg.wpiRegionId,
          wpi_id: queryArg.wpiId,
          unlocode: queryArg.unlocode,
          eniram_code: queryArg.eniramCode,
          mt_id: queryArg.mtId,
          remarks: queryArg.remarks,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
          or: queryArg.or,
        },
      }),
    }),
    postPlaces: build.mutation<PostPlacesApiResponse, PostPlacesApiArg>({
      query: (queryArg) => ({
        url: `/places`,
        method: 'POST',
        body: queryArg.places,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deletePlaces: build.mutation<DeletePlacesApiResponse, DeletePlacesApiArg>({
      query: (queryArg) => ({
        url: `/places`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          type: queryArg['type'],
          subtype: queryArg.subtype,
          status: queryArg.status,
          parent_id: queryArg.parentId,
          country_code: queryArg.countryCode,
          subdivision_code: queryArg.subdivisionCode,
          mrv_area: queryArg.mrvArea,
          wpi_region_id: queryArg.wpiRegionId,
          wpi_id: queryArg.wpiId,
          unlocode: queryArg.unlocode,
          eniram_code: queryArg.eniramCode,
          mt_id: queryArg.mtId,
          remarks: queryArg.remarks,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchPlaces: build.mutation<PatchPlacesApiResponse, PatchPlacesApiArg>({
      query: (queryArg) => ({
        url: `/places`,
        method: 'PATCH',
        body: queryArg.places,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          type: queryArg['type'],
          subtype: queryArg.subtype,
          status: queryArg.status,
          parent_id: queryArg.parentId,
          country_code: queryArg.countryCode,
          subdivision_code: queryArg.subdivisionCode,
          mrv_area: queryArg.mrvArea,
          wpi_region_id: queryArg.wpiRegionId,
          wpi_id: queryArg.wpiId,
          unlocode: queryArg.unlocode,
          eniram_code: queryArg.eniramCode,
          mt_id: queryArg.mtId,
          remarks: queryArg.remarks,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getSubdivisions: build.query<GetSubdivisionsApiResponse, GetSubdivisionsApiArg>({
      query: (queryArg) => ({
        url: `/subdivisions`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          country_code: queryArg.countryCode,
          code: queryArg.code,
          name: queryArg.name,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postSubdivisions: build.mutation<PostSubdivisionsApiResponse, PostSubdivisionsApiArg>({
      query: (queryArg) => ({
        url: `/subdivisions`,
        method: 'POST',
        body: queryArg.subdivisions,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteSubdivisions: build.mutation<DeleteSubdivisionsApiResponse, DeleteSubdivisionsApiArg>({
      query: (queryArg) => ({
        url: `/subdivisions`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          country_code: queryArg.countryCode,
          code: queryArg.code,
          name: queryArg.name,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchSubdivisions: build.mutation<PatchSubdivisionsApiResponse, PatchSubdivisionsApiArg>({
      query: (queryArg) => ({
        url: `/subdivisions`,
        method: 'PATCH',
        body: queryArg.subdivisions,
        headers: { Prefer: queryArg.prefer },
        params: {
          country_code: queryArg.countryCode,
          code: queryArg.code,
          name: queryArg.name,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getTerminals: build.query<GetTerminalsApiResponse, GetTerminalsApiArg>({
      query: (queryArg) => ({
        url: `/terminals`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          code: queryArg.code,
          name: queryArg.name,
          type: queryArg['type'],
          source: queryArg.source,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          uuid: queryArg.uuid,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postTerminals: build.mutation<PostTerminalsApiResponse, PostTerminalsApiArg>({
      query: (queryArg) => ({
        url: `/terminals`,
        method: 'POST',
        body: queryArg.terminals,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteTerminals: build.mutation<DeleteTerminalsApiResponse, DeleteTerminalsApiArg>({
      query: (queryArg) => ({
        url: `/terminals`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          code: queryArg.code,
          name: queryArg.name,
          type: queryArg['type'],
          source: queryArg.source,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          uuid: queryArg.uuid,
        },
      }),
    }),
    patchTerminals: build.mutation<PatchTerminalsApiResponse, PatchTerminalsApiArg>({
      query: (queryArg) => ({
        url: `/terminals`,
        method: 'PATCH',
        body: queryArg.terminals,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          code: queryArg.code,
          name: queryArg.name,
          type: queryArg['type'],
          source: queryArg.source,
          geometry: queryArg.geometry,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          uuid: queryArg.uuid,
        },
      }),
    }),
    getUnlocodes: build.query<GetUnlocodesApiResponse, GetUnlocodesApiArg>({
      query: (queryArg) => ({
        url: `/unlocodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          unlocode: queryArg.unlocode,
          name: queryArg.name,
          name_ascii: queryArg.nameAscii,
          country_code: queryArg.countryCode,
          country_name: queryArg.countryName,
          subdivision_code: queryArg.subdivisionCode,
          subdivision_name: queryArg.subdivisionName,
          function_string: queryArg.functionString,
          function_codes: queryArg.functionCodes,
          function_names: queryArg.functionNames,
          status_code: queryArg.statusCode,
          status_name: queryArg.statusName,
          yearmonth: queryArg.yearmonth,
          iata_code: queryArg.iataCode,
          coordinates_string: queryArg.coordinatesString,
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          version: queryArg.version,
          insert_datetime: queryArg.insertDatetime,
          place_id: queryArg.placeId,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getUrls: build.query<GetUrlsApiResponse, GetUrlsApiArg>({
      query: (queryArg) => ({
        url: `/urls`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          url_type: queryArg.urlType,
          url: queryArg.url,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postUrls: build.mutation<PostUrlsApiResponse, PostUrlsApiArg>({
      query: (queryArg) => ({
        url: `/urls`,
        method: 'POST',
        body: queryArg.urls,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteUrls: build.mutation<DeleteUrlsApiResponse, DeleteUrlsApiArg>({
      query: (queryArg) => ({
        url: `/urls`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          url_type: queryArg.urlType,
          url: queryArg.url,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchUrls: build.mutation<PatchUrlsApiResponse, PatchUrlsApiArg>({
      query: (queryArg) => ({
        url: `/urls`,
        method: 'PATCH',
        body: queryArg.urls,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_id: queryArg.placeId,
          url_type: queryArg.urlType,
          url: queryArg.url,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    getVesselRestrictions: build.query<GetVesselRestrictionsApiResponse, GetVesselRestrictionsApiArg>({
      query: (queryArg) => ({
        url: `/vessel_restrictions`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_geometry_id: queryArg.placeGeometryId,
          max_length: queryArg.maxLength,
          max_beam: queryArg.maxBeam,
          min_draught: queryArg.minDraught,
          max_draught: queryArg.maxDraught,
          max_gross_tonnage: queryArg.maxGrossTonnage,
          ais_vessel_types: queryArg.aisVesselTypes,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postVesselRestrictions: build.mutation<PostVesselRestrictionsApiResponse, PostVesselRestrictionsApiArg>({
      query: (queryArg) => ({
        url: `/vessel_restrictions`,
        method: 'POST',
        body: queryArg.vesselRestrictions,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteVesselRestrictions: build.mutation<DeleteVesselRestrictionsApiResponse, DeleteVesselRestrictionsApiArg>({
      query: (queryArg) => ({
        url: `/vessel_restrictions`,
        method: 'DELETE',
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_geometry_id: queryArg.placeGeometryId,
          max_length: queryArg.maxLength,
          max_beam: queryArg.maxBeam,
          min_draught: queryArg.minDraught,
          max_draught: queryArg.maxDraught,
          max_gross_tonnage: queryArg.maxGrossTonnage,
          ais_vessel_types: queryArg.aisVesselTypes,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    patchVesselRestrictions: build.mutation<PatchVesselRestrictionsApiResponse, PatchVesselRestrictionsApiArg>({
      query: (queryArg) => ({
        url: `/vessel_restrictions`,
        method: 'PATCH',
        body: queryArg.vesselRestrictions,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          place_geometry_id: queryArg.placeGeometryId,
          max_length: queryArg.maxLength,
          max_beam: queryArg.maxBeam,
          min_draught: queryArg.minDraught,
          max_draught: queryArg.maxDraught,
          max_gross_tonnage: queryArg.maxGrossTonnage,
          ais_vessel_types: queryArg.aisVesselTypes,
          insert_datetime: queryArg.insertDatetime,
          insert_user: queryArg.insertUser,
          update_datetime: queryArg.updateDatetime,
          update_user: queryArg.updateUser,
        },
      }),
    }),
    postRpcLatlon2Id: build.mutation<PostRpcLatlon2IdApiResponse, PostRpcLatlon2IdApiArg>({
      query: (queryArg) => ({
        url: `/rpc/latlon2id`,
        method: 'POST',
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiResponse = unknown;
export type GetApiArg = void;
export type GetCharacteristicsApiResponse =
  /** status 200 OK */
  Characteristics[] | /** status 206 Partial Content */ undefined;
export type GetCharacteristicsApiArg = {
  placeId?: string;
  wpiVersion?: string;
  portName?: string;
  harborSizeCode?: string;
  harborTypeCode?: string;
  shelterAffordedCode?: string;
  entranceRestrictionTide?: string;
  entranceRestrictionSwell?: string;
  entranceRestrictionIce?: string;
  entranceRestrictionOther?: string;
  overheadLimits?: string;
  channelDepth?: string;
  anchorageDepth?: string;
  cargoPierDepth?: string;
  oilTerminalDepth?: string;
  tide?: string;
  maxsizeVesselCode?: string;
  goodHoldingGround?: string;
  turningArea?: string;
  firstPortOfEntry?: string;
  usRepresentative?: string;
  etaMessage?: string;
  pilotageCompulsory?: string;
  pilotageAvailable?: string;
  pilotageLocalAssist?: string;
  pilotageAdvisable?: string;
  tugsSalvage?: string;
  tugsAssist?: string;
  quarantinePratique?: string;
  quarantineDerattCert?: string;
  quarantineOther?: string;
  communicationsTelephone?: string;
  communicationsTelegraph?: string;
  communicationsRadio?: string;
  communicationsRadioTel?: string;
  communicationsAir?: string;
  communicationsRail?: string;
  loadOffloadWharves?: string;
  loadOffloadAnchor?: string;
  loadOffloadMedMoor?: string;
  loadOffloadBeachMoor?: string;
  loadOffloadIceMoor?: string;
  medicalFacilities?: string;
  garbageDisposal?: string;
  degauss?: string;
  dirtyBallast?: string;
  cranesFixed?: string;
  cranesMobile?: string;
  cranesFloating?: string;
  lifts100TonsPlus?: string;
  lifts50100Tons?: string;
  lifts2549Tons?: string;
  lifts024Tons?: string;
  servicesLongshore?: string;
  servicesElect?: string;
  servicesSteam?: string;
  servicesNavigEquip?: string;
  servicesElectRepair?: string;
  suppliesProvisions?: string;
  suppliesWater?: string;
  suppliesFuelOil?: string;
  suppliesDieselOil?: string;
  suppliesDeck?: string;
  suppliesEngine?: string;
  repairCode?: string;
  drydock?: string;
  railway?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostCharacteristicsApiResponse = unknown;
export type PostCharacteristicsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** characteristics */
  characteristics: Characteristics;
};
export type DeleteCharacteristicsApiResponse = unknown;
export type DeleteCharacteristicsApiArg = {
  placeId?: string;
  wpiVersion?: string;
  portName?: string;
  harborSizeCode?: string;
  harborTypeCode?: string;
  shelterAffordedCode?: string;
  entranceRestrictionTide?: string;
  entranceRestrictionSwell?: string;
  entranceRestrictionIce?: string;
  entranceRestrictionOther?: string;
  overheadLimits?: string;
  channelDepth?: string;
  anchorageDepth?: string;
  cargoPierDepth?: string;
  oilTerminalDepth?: string;
  tide?: string;
  maxsizeVesselCode?: string;
  goodHoldingGround?: string;
  turningArea?: string;
  firstPortOfEntry?: string;
  usRepresentative?: string;
  etaMessage?: string;
  pilotageCompulsory?: string;
  pilotageAvailable?: string;
  pilotageLocalAssist?: string;
  pilotageAdvisable?: string;
  tugsSalvage?: string;
  tugsAssist?: string;
  quarantinePratique?: string;
  quarantineDerattCert?: string;
  quarantineOther?: string;
  communicationsTelephone?: string;
  communicationsTelegraph?: string;
  communicationsRadio?: string;
  communicationsRadioTel?: string;
  communicationsAir?: string;
  communicationsRail?: string;
  loadOffloadWharves?: string;
  loadOffloadAnchor?: string;
  loadOffloadMedMoor?: string;
  loadOffloadBeachMoor?: string;
  loadOffloadIceMoor?: string;
  medicalFacilities?: string;
  garbageDisposal?: string;
  degauss?: string;
  dirtyBallast?: string;
  cranesFixed?: string;
  cranesMobile?: string;
  cranesFloating?: string;
  lifts100TonsPlus?: string;
  lifts50100Tons?: string;
  lifts2549Tons?: string;
  lifts024Tons?: string;
  servicesLongshore?: string;
  servicesElect?: string;
  servicesSteam?: string;
  servicesNavigEquip?: string;
  servicesElectRepair?: string;
  suppliesProvisions?: string;
  suppliesWater?: string;
  suppliesFuelOil?: string;
  suppliesDieselOil?: string;
  suppliesDeck?: string;
  suppliesEngine?: string;
  repairCode?: string;
  drydock?: string;
  railway?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchCharacteristicsApiResponse = unknown;
export type PatchCharacteristicsApiArg = {
  placeId?: string;
  wpiVersion?: string;
  portName?: string;
  harborSizeCode?: string;
  harborTypeCode?: string;
  shelterAffordedCode?: string;
  entranceRestrictionTide?: string;
  entranceRestrictionSwell?: string;
  entranceRestrictionIce?: string;
  entranceRestrictionOther?: string;
  overheadLimits?: string;
  channelDepth?: string;
  anchorageDepth?: string;
  cargoPierDepth?: string;
  oilTerminalDepth?: string;
  tide?: string;
  maxsizeVesselCode?: string;
  goodHoldingGround?: string;
  turningArea?: string;
  firstPortOfEntry?: string;
  usRepresentative?: string;
  etaMessage?: string;
  pilotageCompulsory?: string;
  pilotageAvailable?: string;
  pilotageLocalAssist?: string;
  pilotageAdvisable?: string;
  tugsSalvage?: string;
  tugsAssist?: string;
  quarantinePratique?: string;
  quarantineDerattCert?: string;
  quarantineOther?: string;
  communicationsTelephone?: string;
  communicationsTelegraph?: string;
  communicationsRadio?: string;
  communicationsRadioTel?: string;
  communicationsAir?: string;
  communicationsRail?: string;
  loadOffloadWharves?: string;
  loadOffloadAnchor?: string;
  loadOffloadMedMoor?: string;
  loadOffloadBeachMoor?: string;
  loadOffloadIceMoor?: string;
  medicalFacilities?: string;
  garbageDisposal?: string;
  degauss?: string;
  dirtyBallast?: string;
  cranesFixed?: string;
  cranesMobile?: string;
  cranesFloating?: string;
  lifts100TonsPlus?: string;
  lifts50100Tons?: string;
  lifts2549Tons?: string;
  lifts024Tons?: string;
  servicesLongshore?: string;
  servicesElect?: string;
  servicesSteam?: string;
  servicesNavigEquip?: string;
  servicesElectRepair?: string;
  suppliesProvisions?: string;
  suppliesWater?: string;
  suppliesFuelOil?: string;
  suppliesDieselOil?: string;
  suppliesDeck?: string;
  suppliesEngine?: string;
  repairCode?: string;
  drydock?: string;
  railway?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** characteristics */
  characteristics: Characteristics;
};
export type GetGeometriesApiResponse = /** status 200 OK */ Geometries[] | /** status 206 Partial Content */ undefined;
export type GetGeometriesApiArg = {
  id?: string;
  placeId?: string;
  name?: string;
  type?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: Polygon;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostGeometriesApiResponse = unknown;
export type PostGeometriesApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** geometries */
  geometries: Geometries;
};
export type DeleteGeometriesApiResponse = unknown;
export type DeleteGeometriesApiArg = {
  id?: string;
  placeId?: string;
  name?: string;
  type?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchGeometriesApiResponse = unknown;
export type PatchGeometriesApiArg = {
  id?: string;
  placeId?: string;
  name?: string;
  type?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** geometries */
  geometries: Geometries;
};
export type GetIdentifiersApiResponse =
  /** status 200 OK */
  Identifiers[] | /** status 206 Partial Content */ undefined;
export type GetIdentifiersApiArg = {
  id?: string;
  placeId?: string;
  type?: string;
  identifier?: string;
  source?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostIdentifiersApiResponse = unknown;
export type PostIdentifiersApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** identifiers */
  identifiers: Identifiers;
};
export type DeleteIdentifiersApiResponse = unknown;
export type DeleteIdentifiersApiArg = {
  id?: string;
  placeId?: string;
  type?: string;
  identifier?: string;
  source?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchIdentifiersApiResponse = unknown;
export type PatchIdentifiersApiArg = {
  id?: string;
  placeId?: string;
  type?: string;
  identifier?: string;
  source?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** identifiers */
  identifiers: Identifiers;
};
export type GetNavigationPointsApiResponse =
  /** status 200 OK */
  NavigationPoints[] | /** status 206 Partial Content */ undefined;
export type GetNavigationPointsApiArg = {
  id?: string;
  name?: string;
  type?: string;
  direction?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostNavigationPointsApiResponse = unknown;
export type PostNavigationPointsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** navigation_points */
  navigationPoints: NavigationPoints;
};
export type DeleteNavigationPointsApiResponse = unknown;
export type DeleteNavigationPointsApiArg = {
  id?: string;
  name?: string;
  type?: string;
  direction?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchNavigationPointsApiResponse = unknown;
export type PatchNavigationPointsApiArg = {
  id?: string;
  name?: string;
  type?: string;
  direction?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** navigation_points */
  navigationPoints: NavigationPoints;
};
export type GetPlaceNavigationPointsApiResponse =
  /** status 200 OK */
  PlaceNavigationPoints[] | /** status 206 Partial Content */ undefined;
export type GetPlaceNavigationPointsApiArg = {
  placeId?: string;
  navigationPointId?: string;
  role?: string;
  type?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostPlaceNavigationPointsApiResponse = unknown;
export type PostPlaceNavigationPointsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** place_navigation_points */
  placeNavigationPoints: PlaceNavigationPoints;
};
export type DeletePlaceNavigationPointsApiResponse = unknown;
export type DeletePlaceNavigationPointsApiArg = {
  placeId?: string;
  navigationPointId?: string;
  role?: string;
  type?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchPlaceNavigationPointsApiResponse = unknown;
export type PatchPlaceNavigationPointsApiArg = {
  placeId?: string;
  navigationPointId?: string;
  role?: string;
  type?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** place_navigation_points */
  placeNavigationPoints: PlaceNavigationPoints;
};
export type GetPlacesApiResponse = /** status 200 OK */ Places[] | /** status 206 Partial Content */ undefined;
export type GetPlacesApiArg = {
  id?: string;
  name?: string;
  type?: string;
  subtype?: string;
  status?: string;
  parentId?: string;
  countryCode?: string;
  subdivisionCode?: string;
  mrvArea?: string;
  wpiRegionId?: string;
  wpiId?: string;
  unlocode?: string;
  eniramCode?: string;
  mtId?: string;
  remarks?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
  or?: string;
};
export type PostPlacesApiResponse = unknown;
export type PostPlacesApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** places */
  places: Places;
};
export type DeletePlacesApiResponse = unknown;
export type DeletePlacesApiArg = {
  id?: string;
  name?: string;
  type?: string;
  subtype?: string;
  status?: string;
  parentId?: string;
  countryCode?: string;
  subdivisionCode?: string;
  mrvArea?: string;
  wpiRegionId?: string;
  wpiId?: string;
  unlocode?: string;
  eniramCode?: string;
  mtId?: string;
  remarks?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchPlacesApiResponse = unknown;
export type PatchPlacesApiArg = {
  id?: string;
  name?: string;
  type?: string;
  subtype?: string;
  status?: string;
  parentId?: string;
  countryCode?: string;
  subdivisionCode?: string;
  mrvArea?: string;
  wpiRegionId?: string;
  wpiId?: string;
  unlocode?: string;
  eniramCode?: string;
  mtId?: string;
  remarks?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** places */
  places: Places;
};
export type GetSubdivisionsApiResponse =
  /** status 200 OK */
  Subdivisions[] | /** status 206 Partial Content */ undefined;
export type GetSubdivisionsApiArg = {
  countryCode?: string;
  code?: string;
  name?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostSubdivisionsApiResponse = unknown;
export type PostSubdivisionsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** subdivisions */
  subdivisions: Subdivisions;
};
export type DeleteSubdivisionsApiResponse = unknown;
export type DeleteSubdivisionsApiArg = {
  countryCode?: string;
  code?: string;
  name?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchSubdivisionsApiResponse = unknown;
export type PatchSubdivisionsApiArg = {
  countryCode?: string;
  code?: string;
  name?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** subdivisions */
  subdivisions: Subdivisions;
};
export type GetTerminalsApiResponse = /** status 200 OK */ Terminals[] | /** status 206 Partial Content */ undefined;
export type GetTerminalsApiArg = {
  id?: string;
  placeId?: string;
  code?: string;
  name?: string;
  type?: string;
  source?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  uuid?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostTerminalsApiResponse = unknown;
export type PostTerminalsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** terminals */
  terminals: Terminals;
};
export type DeleteTerminalsApiResponse = unknown;
export type DeleteTerminalsApiArg = {
  id?: string;
  placeId?: string;
  code?: string;
  name?: string;
  type?: string;
  source?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  uuid?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchTerminalsApiResponse = unknown;
export type PatchTerminalsApiArg = {
  id?: string;
  placeId?: string;
  code?: string;
  name?: string;
  type?: string;
  source?: string;
  geometry?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  uuid?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** terminals */
  terminals: Terminals;
};
export type GetUnlocodesApiResponse = /** status 200 OK */ Unlocodes[] | /** status 206 Partial Content */ undefined;
export type GetUnlocodesApiArg = {
  unlocode?: string;
  name?: string;
  nameAscii?: string;
  countryCode?: string;
  countryName?: string;
  subdivisionCode?: string;
  subdivisionName?: string;
  functionString?: string;
  functionCodes?: string;
  functionNames?: string;
  statusCode?: string;
  statusName?: string;
  yearmonth?: string;
  iataCode?: string;
  coordinatesString?: string;
  latitude?: string;
  longitude?: string;
  version?: string;
  insertDatetime?: string;
  placeId?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetUrlsApiResponse = /** status 200 OK */ Urls[] | /** status 206 Partial Content */ undefined;
export type GetUrlsApiArg = {
  id?: string;
  placeId?: string;
  urlType?: string;
  url?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostUrlsApiResponse = unknown;
export type PostUrlsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** urls */
  urls: Urls;
};
export type DeleteUrlsApiResponse = unknown;
export type DeleteUrlsApiArg = {
  id?: string;
  placeId?: string;
  urlType?: string;
  url?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchUrlsApiResponse = unknown;
export type PatchUrlsApiArg = {
  id?: string;
  placeId?: string;
  urlType?: string;
  url?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** urls */
  urls: Urls;
};
export type GetVesselRestrictionsApiResponse =
  /** status 200 OK */
  VesselRestrictions[] | /** status 206 Partial Content */ undefined;
export type GetVesselRestrictionsApiArg = {
  id?: string;
  placeGeometryId?: string;
  maxLength?: string;
  maxBeam?: string;
  minDraught?: string;
  maxDraught?: string;
  maxGrossTonnage?: string;
  aisVesselTypes?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type PostVesselRestrictionsApiResponse = unknown;
export type PostVesselRestrictionsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** vessel_restrictions */
  vesselRestrictions: VesselRestrictions;
};
export type DeleteVesselRestrictionsApiResponse = unknown;
export type DeleteVesselRestrictionsApiArg = {
  id?: string;
  placeGeometryId?: string;
  maxLength?: string;
  maxBeam?: string;
  minDraught?: string;
  maxDraught?: string;
  maxGrossTonnage?: string;
  aisVesselTypes?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
};
export type PatchVesselRestrictionsApiResponse = unknown;
export type PatchVesselRestrictionsApiArg = {
  id?: string;
  placeGeometryId?: string;
  maxLength?: string;
  maxBeam?: string;
  minDraught?: string;
  maxDraught?: string;
  maxGrossTonnage?: string;
  aisVesselTypes?: string;
  insertDatetime?: string;
  insertUser?: string;
  updateDatetime?: string;
  updateUser?: string;
  /** Preference */
  prefer?: 'return=representation' | 'return=minimal' | 'return=none';
  /** vessel_restrictions */
  vesselRestrictions: VesselRestrictions;
};
export type PostRpcLatlon2IdApiResponse = unknown;
export type PostRpcLatlon2IdApiArg = {
  /** Preference */
  prefer?: 'params=single-object';
  body: {
    lat: number;
    lon: number;
  };
};
export type Characteristics = {
  place_id?: number;
  wpi_version?: number;
  port_name?: string;
  harbor_size_code?: string;
  harbor_type_code?: string;
  shelter_afforded_code?: string;
  entrance_restriction_tide?: boolean;
  entrance_restriction_swell?: boolean;
  entrance_restriction_ice?: boolean;
  entrance_restriction_other?: boolean;
  overhead_limits?: boolean;
  channel_depth?: string;
  anchorage_depth?: string;
  cargo_pier_depth?: string;
  oil_terminal_depth?: string;
  tide?: number;
  maxsize_vessel_code?: string;
  good_holding_ground?: boolean;
  turning_area?: boolean;
  first_port_of_entry?: boolean;
  us_representative?: boolean;
  eta_message?: boolean;
  pilotage_compulsory?: boolean;
  pilotage_available?: boolean;
  pilotage_local_assist?: boolean;
  pilotage_advisable?: boolean;
  tugs_salvage?: boolean;
  tugs_assist?: boolean;
  quarantine_pratique?: boolean;
  quarantine_deratt_cert?: boolean;
  quarantine_other?: boolean;
  communications_telephone?: boolean;
  communications_telegraph?: boolean;
  communications_radio?: boolean;
  communications_radio_tel?: boolean;
  communications_air?: boolean;
  communications_rail?: boolean;
  load_offload_wharves?: boolean;
  load_offload_anchor?: boolean;
  load_offload_med_moor?: boolean;
  load_offload_beach_moor?: boolean;
  load_offload_ice_moor?: boolean;
  medical_facilities?: boolean;
  garbage_disposal?: boolean;
  degauss?: boolean;
  dirty_ballast?: boolean;
  cranes_fixed?: boolean;
  cranes_mobile?: boolean;
  cranes_floating?: boolean;
  lifts_100_tons_plus?: boolean;
  lifts_50_100_tons?: boolean;
  lifts_25_49_tons?: boolean;
  lifts_0_24_tons?: boolean;
  services_longshore?: boolean;
  services_elect?: boolean;
  services_steam?: boolean;
  services_navig_equip?: boolean;
  services_elect_repair?: boolean;
  supplies_provisions?: boolean;
  supplies_water?: boolean;
  supplies_fuel_oil?: boolean;
  supplies_diesel_oil?: boolean;
  supplies_deck?: boolean;
  supplies_engine?: boolean;
  repair_code?: string;
  drydock?: string;
  railway?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type Geometries = {
  id?: number;
  place_id?: number;
  name?: string;
  type?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type Identifiers = {
  id?: number;
  place_id?: number;
  type?: string;
  identifier?: string;
  source?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type NavigationPoints = {
  id?: number;
  name?: string;
  type?: string;
  direction?: string;
  source?: string;
  status?: string;
  remarks?: string;
  geometry?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type PlaceNavigationPoints = {
  place_id?: number;
  navigation_point_id?: number;
  role?: string;
  type?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type GeoPoint = {
  type?: string;
  coordinates?: number[];
};
export type Places = {
  id?: number;
  name?: string;
  type?: string;
  subtype?: string;
  status?: string;
  parent_id?: number;
  country_code?: string;
  subdivision_code?: string;
  mrv_area?: string;
  wpi_region_id?: number;
  wpi_id?: number;
  unlocode?: string;
  eniram_code?: string;
  mt_id?: number;
  remarks?: string;
  geo_point?: GeoPoint;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type Subdivisions = {
  country_code?: string;
  code?: string;
  name?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type Terminals = {
  id?: number;
  place_id?: number;
  code?: string;
  name?: string;
  type?: string;
  source?: string;
  geometry?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
  uuid?: string;
};
export type Unlocodes = {
  unlocode?: string;
  name?: string;
  name_ascii?: string;
  country_code?: string;
  country_name?: string;
  subdivision_code?: string;
  subdivision_name?: string;
  function_string?: string;
  function_codes?: string;
  function_names?: string;
  status_code?: string;
  status_name?: string;
  yearmonth?: string;
  iata_code?: string;
  coordinates_string?: string;
  latitude?: number;
  longitude?: number;
  geo_point?: GeoPoint;
  version?: number;
  insert_datetime?: string;
  place_id?: number;
};
export type Urls = {
  id?: number;
  place_id?: number;
  url_type?: string;
  url?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export type VesselRestrictions = {
  id?: number;
  place_geometry_id?: number;
  max_length?: number;
  max_beam?: number;
  min_draught?: number;
  max_draught?: number;
  max_gross_tonnage?: number;
  ais_vessel_types?: string;
  insert_datetime?: string;
  insert_user?: string;
  update_datetime?: string;
  update_user?: string;
};
export const {
  useGetQuery,
  useGetCharacteristicsQuery,
  usePostCharacteristicsMutation,
  useDeleteCharacteristicsMutation,
  usePatchCharacteristicsMutation,
  useGetGeometriesQuery,
  usePostGeometriesMutation,
  useDeleteGeometriesMutation,
  usePatchGeometriesMutation,
  useGetIdentifiersQuery,
  usePostIdentifiersMutation,
  useDeleteIdentifiersMutation,
  usePatchIdentifiersMutation,
  useGetNavigationPointsQuery,
  usePostNavigationPointsMutation,
  useDeleteNavigationPointsMutation,
  usePatchNavigationPointsMutation,
  useGetPlaceNavigationPointsQuery,
  usePostPlaceNavigationPointsMutation,
  useDeletePlaceNavigationPointsMutation,
  usePatchPlaceNavigationPointsMutation,
  useGetPlacesQuery,
  usePostPlacesMutation,
  useDeletePlacesMutation,
  usePatchPlacesMutation,
  useGetSubdivisionsQuery,
  usePostSubdivisionsMutation,
  useDeleteSubdivisionsMutation,
  usePatchSubdivisionsMutation,
  useGetTerminalsQuery,
  usePostTerminalsMutation,
  useDeleteTerminalsMutation,
  usePatchTerminalsMutation,
  useGetUnlocodesQuery,
  useGetUrlsQuery,
  usePostUrlsMutation,
  useDeleteUrlsMutation,
  usePatchUrlsMutation,
  useGetVesselRestrictionsQuery,
  usePostVesselRestrictionsMutation,
  useDeleteVesselRestrictionsMutation,
  usePatchVesselRestrictionsMutation,
  usePostRpcLatlon2IdMutation,
} = injectedRtkApi;
