import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './date-picker-theme.css';

import dayjs from 'dayjs';
import React from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';

import { ArrowRight } from '../../common/components/icons/ArrowRight';
import { ChevronDown } from '../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../common/components/icons/ChevronUp';
import { LocationIcon } from '../../common/components/icons/LocationIcon';
import { RefreshIcon } from '../../common/components/icons/RefreshIcon';
import { Select } from '../../common/components/Select';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { TimeRangeOption } from '../../common/types';
import { themeColors } from '../../common/utils';
import { setSelectedEvent } from '../event-feed/slice';
import {
  useGetLatestVesselPositionQuery,
  useGetVesselPositionsForSelectedDatesQuery,
} from '../vessel-details-view/hooks';
import { setSelectedPositionTimestamp } from '../vessel-map/slice';
import {
  setDates,
  setDatesAndUpdateUrl,
  setDatesAndNullUrl,
  setSelectedTimeRangeOption,
  setSelectedTimeRangeOptionAndUpdateUrl,
  toggleShow,
} from './slice';

type Props = {
  className?: string;
};

export const VesselMapDateSelect: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();

  const imo = useAppSelector((state) => state.eventFilters.selectedImo);
  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(imo);

  const state = useAppSelector((state) => state.vesselMapDateSelect);
  const startDate = dayjs(state.startDate).format('YYYY-MM-DD');
  const endDate = dayjs(state.endDate).format('YYYY-MM-DD');

  const onSelectChangeHandler = async (option: TimeRangeOption | null) => {
    await dispatch(setSelectedEvent(null));
    dispatch(setSelectedPositionTimestamp(null));
    dispatch(setSelectedTimeRangeOptionAndUpdateUrl({ timeRangeOption: option }));

    if (!option) {
      return;
    }

    const startDate = dayjs().utc().subtract(option.value.amount, option.value.unit).startOf('day').toISOString();
    const endDate = dayjs().utc().toISOString();

    dispatch(
      setDates({
        startDate,
        endDate,
      })
    );
  };

  const onCalendarChangeHandler = async (range: RangeKeyDict) => {
    // Dates in the calendar have local timezone, we should always store date values as UTC in the redux store.
    // We convert them to UTC and keep the time as is (time is 00:00:00 for the selected dates in the calendar)
    const startDateUtcDate = dayjs(range.selection.startDate).utc(true).format('YYYY-MM-DD');
    const endDateUtcDate = dayjs(range.selection.endDate).utc(true).format('YYYY-MM-DD');

    await dispatch(setSelectedEvent(null));
    dispatch(setSelectedTimeRangeOption(null));
    dispatch(setSelectedPositionTimestamp(null));
    dispatch(
      setDatesAndUpdateUrl({
        startDate: startDateUtcDate,
        endDate: endDateUtcDate,
      })
    );
  };

  const onCurrentLocationHandler = async () => {
    const currentDate = dayjs(new Date()).format('YYYY-MM-DD');

    await dispatch(setSelectedEvent(null));
    dispatch(setSelectedTimeRangeOption(null));
    dispatch(setSelectedPositionTimestamp(null));
    dispatch(
      setDatesAndNullUrl({
        startDate: currentDate,
        endDate: currentDate,
      })
    );
  };

  const onRefreshHandler = () => {
    positionsQuery.refetch();
  };

  return (
    <div className={`VesselMapDateSelect ${className ? className : ''}`}>
      <div className="flex justify-between flex-col 2xl:flex-row">
        <Select
          value={state.selectedTimeRangeOption}
          options={state.timeRangeOptions}
          onChange={onSelectChangeHandler}
          isClearable
          placeholder="Date range"
          className="mb-1 2xl:mb-0 2xl:mr-1 2xl:min-w-[180px]"
          menuPortalTarget={document.body}
        />
        <div className="mb-1 2xl:mb-0 2xl:mr-1">
          <div
            className={`bg-primary text-primary-content px-3 py-1 cursor-pointer flex items-center justify-between min-h-[38px] ${
              state.show ? 'rounded-t-[4px] border-b border-secondary' : 'rounded-[4px]'
            }`}
            onClick={() => dispatch(toggleShow())}
          >
            <span className="flex items-cente">
              {startDate === endDate ? (
                startDate
              ) : (
                <>
                  {startDate} <ArrowRight className="text-accent h-5 w-5 mx-3" /> {endDate}
                </>
              )}
            </span>
            {state.show ? <ChevronUp className="ml-3 h5 w-5" /> : <ChevronDown className="ml-3 h5 w-5" />}
          </div>
          {state.show && (
            <DateRange
              weekStartsOn={1}
              editableDateInputs
              rangeColors={[themeColors.secondary]}
              showDateDisplay={false}
              onChange={onCalendarChangeHandler}
              ranges={[{ startDate: dayjs(startDate).toDate(), endDate: dayjs(endDate).toDate(), key: state.key }]}
            />
          )}
        </div>
        {endDate >= dayjs(new Date()).format('YYYY-MM-DD') ? (
          <div
            title="Refresh"
            className="bg-primary rounded-[4px] cursor-pointer flex items-center justify-center h-[38px] w-[38px] ml-auto"
            onClick={onRefreshHandler}
          >
            <RefreshIcon className="h5 w-5 text-accent" />
          </div>
        ) : (
          <div
            title="Go to current location"
            className="bg-primary rounded-[4px] cursor-pointer flex items-center justify-center h-[38px] w-[38px] ml-auto"
            onClick={onCurrentLocationHandler}
          >
            <LocationIcon className="h5 w-5 text-accent" />
          </div>
        )}
      </div>
    </div>
  );
};
