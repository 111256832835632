import { LngLatLike } from 'mapbox-gl';
import { useEffect } from 'react';

import { useAppSelector } from '../../common/hooks';
import { VesselPosition } from '../../store/apis/ais-api';
import { getMinMaxCoordinates } from './utils';

export const useRepositionMapWhenPositionsChange = (vesselPosition?: VesselPosition) => {
  const map = useAppSelector((state) => state.vesselMap.mapInstance);

  useEffect(() => {
    if (!vesselPosition?.positions?.length) {
      return;
    }

    const coordinates = getMinMaxCoordinates(vesselPosition);
    const offset = 2.5;
    const southWesternCoordinates: LngLatLike = [coordinates.minLon - offset, coordinates.minLat - offset];
    const northEasternCoordinates: LngLatLike = [coordinates.maxLon + offset, coordinates.maxLat + offset];

    map?.fitBounds([southWesternCoordinates, northEasternCoordinates], { speed: 2 });
  }, [map, vesselPosition]);
};
