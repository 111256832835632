import dayjs from 'dayjs';
import { FillLayer, VectorSource } from 'mapbox-gl';

export const getEmissionSourceProps = (tilesUrl: string): VectorSource => ({
  id: 'emission-source',
  type: 'vector',
  tiles: [tilesUrl],
  minzoom: 2,
  maxzoom: 10,
});

export const emissionLayerProps: FillLayer = {
  id: 'emission-layer',
  type: 'fill',
  source: 'emission-source',
  'source-layer': 'emissions',
  paint: {
    'fill-antialias': false,
    'fill-opacity': 0.8,
    'fill-color': ['get', 'color'],
  },
};

export const getAisQualitySourceProps = (tilesUrl: string): VectorSource => ({
  id: 'ais-quality-source',
  type: 'vector',
  tiles: [tilesUrl],
  minzoom: 2,
  maxzoom: 10,
});

export const aisQualityLayerProps: FillLayer = {
  id: 'ais-quality-layer',
  type: 'fill',
  source: 'ais-quality-source',
  'source-layer': 'quality',
  paint: {
    'fill-antialias': false,
    'fill-opacity': 0.7,
    'fill-color': [
      'rgb',
      ['min', 150, ['*', 380, ['-', 1, ['get', 'coverage_percentage']]]],
      ['min', 255, ['*', 140, ['max', 0, ['-', ['get', 'coverage_percentage'], 0.6]]]],
      ['min', 255, ['*', 500, ['max', 0, ['-', ['get', 'coverage_percentage'], 0.6]]]],
    ],
  },
};

export const getDatesForAISCall = (date?: string) => {
  const qualityLayerDate = date ? new Date(date) : new Date();
  return [qualityLayerDate.getFullYear(), qualityLayerDate.getMonth()];
};
