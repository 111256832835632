import { useEffect } from 'react';
import { LngLatLike } from 'react-map-gl';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { useGetVesselPositionsQuery, VesselPosition } from '../../store/apis/ais-api';
import {
  useGetUserFleetFleetGetQuery,
  useIsUserAFleetUserFleetUserGetQuery,
  UserFleet,
} from '../../store/apis/csi-api';
import { GetShipdataApiArg, useGetShipdataQuery } from '../../store/apis/ihs-vessel-data-api';
import { setMyAutomaticFleet, setMyManualFleet, setSelectedTab } from './slice';

const defaultQueryParams: Partial<GetShipdataApiArg> = {
  limit: '10',
  shipstatuscode: 'not.in.(D,K,M)',
  select:
    'lrimoshipno,shipname,shipstatuscode,shipstatus,yearofbuild,flagcode,shiptypelevel4,maritimemobileserviceidentitymmsinumber',
};

export const useSearchVesselsQuery = (input: string) => {
  const queryParams = !!parseInt(input)
    ? { ...defaultQueryParams, lrimoshipno: `ilike.*${input}*` }
    : { ...defaultQueryParams, shipname: `ilike.*${input}*` };
  return useGetShipdataQuery({ ...queryParams }, { skip: !input });
};

const compareNames = (a: UserFleet, b: UserFleet) => {
  if (a.status_name === 'In Service/Commission' && a.status_name !== b.status_name) return -1;
  if (b.status_name === 'In Service/Commission' && a.status_name !== b.status_name) return 1;
  if (a.vessel_name < b.vessel_name) return -1;
  if (a.vessel_name > b.vessel_name) return 1;
  return 0;
};

export const useGetMyFleet = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.myFleet);
  const hasMyFleet = useIsUserAFleetUserFleetUserGetQuery({ person: state.otherFleetUser }).currentData
    ?.user_is_a_fleet_user;
  const fleetQuery = useGetUserFleetFleetGetQuery(
    { hasFleet: !!hasMyFleet, person: state.otherFleetUser },
    { skip: hasMyFleet === undefined }
  );
  useEffect(() => {
    if (hasMyFleet === undefined || fleetQuery.isLoading) {
      return;
    }

    const automaticFleet = fleetQuery.data?.filter((vessel) => vessel.fleet_type === 'automatic');
    automaticFleet?.sort(compareNames);
    dispatch(setMyAutomaticFleet(automaticFleet));
    !state.selectedTab &&
      dispatch(setSelectedTab(automaticFleet && automaticFleet.length > 0 ? 'automatic' : 'manual'));

    const manualFleet = fleetQuery.data?.filter((vessel) => vessel.fleet_type === 'manual');
    manualFleet?.sort(compareNames);
    dispatch(setMyManualFleet(manualFleet));
  }, [dispatch, fleetQuery.data, fleetQuery.isLoading]);
  return fleetQuery;
};

const filterOld = (vessel: VesselPosition) => {
  const now = new Date();
  now.setHours(new Date().getHours() - 24);
  return !!vessel.positions?.[0].timestamp && new Date(vessel.positions?.[0].timestamp) > now;
};

export const useGetFilteredVesselLocations = () => {
  const state = useAppSelector((state) => state.myFleet);
  const selectedFleet = state.selectedTab === 'manual' ? state.myManualFleet : state.myAutomaticFleet;

  let vesselIMOs = selectedFleet
    ?.slice(0, 200)
    .map((vessel) => vessel.imo_number)
    .toString();
  let vesselLocationQuery = useGetVesselPositionsQuery(
    { imo: vesselIMOs },
    { skip: !selectedFleet || !vesselIMOs?.length }
  );
  const vesselLocations = vesselLocationQuery.currentData?.results || [];

  return { currentData: vesselLocations.filter(filterOld), isLoading: vesselLocationQuery.isLoading };
};

export const useGetMapProps = () => {
  const { MAPBOX_STYLE, MAPBOX_ACCESS_TOKEN } = useAppSelector((state) => state.common.config!);
  const mapStyle = MAPBOX_STYLE;
  const maxZoom = undefined;
  return {
    accessToken: MAPBOX_ACCESS_TOKEN,
    mapStyle,
    maxZoom,
  };
};

export const useRepositionMapWhenPositionsChange = (vesselPosition?: VesselPosition[]) => {
  const map = useAppSelector((state) => state.myFleet.mapInstance);

  useEffect(() => {
    const offset = 0.5;
    if (!vesselPosition || vesselPosition.length === 0) return;
    const southWesternCoordinates: LngLatLike = [
      Math.min(...vesselPosition.map((vessel) => vessel.positions?.[0].longitude || 0)) - offset,
      Math.min(...vesselPosition.map((vessel) => vessel.positions?.[0].latitude || 0)) - offset,
    ];
    const northEasternCoordinates: LngLatLike = [
      Math.max(...vesselPosition.map((vessel) => vessel.positions?.[0].longitude || 0)) + offset,
      Math.max(...vesselPosition.map((vessel) => vessel.positions?.[0].longitude || 0)) + offset,
    ];

    map?.fitBounds([southWesternCoordinates, northEasternCoordinates], { speed: 2 });
  }, [map, vesselPosition]);
};
