import { createListenerMiddleware } from '@reduxjs/toolkit';

import { RootState } from '../../store/store';
import { initialState, setState } from './slice';
import { buildFilterFromUserFilter, setFilterStateToUrl } from './utils';

export const eventFilterListenerMiddleware = createListenerMiddleware();

eventFilterListenerMiddleware.startListening({
  actionCreator: setState,
  effect: async (action) => {
    // Do not add filters to URL when showing events for a specific vessel
    if (action.payload.selectedImo) {
      return;
    }

    setFilterStateToUrl(action.payload);
  },
});

eventFilterListenerMiddleware.startListening({
  predicate: (_, currentState, previousState) => {
    const currentUserFilterId = (currentState as RootState).userFilters.selectedUserFilterOptionId;
    const previousUserFilterId = (previousState as RootState).userFilters.selectedUserFilterOptionId;
    const currentUserFilterOptions = (currentState as RootState).userFilters.userFilterOptions;
    const previousUserFilterOptions = (previousState as RootState).userFilters.userFilterOptions;
    return (
      currentUserFilterId !== previousUserFilterId ||
      (!!currentUserFilterId && currentUserFilterOptions !== previousUserFilterOptions)
    );
  },
  effect: async (_, listenerApi) => {
    const eventFilters = (listenerApi.getState() as RootState).eventFilters;
    const userFilters = (listenerApi.getState() as RootState).userFilters;
    if (!userFilters.selectedUserFilterOptionId) {
      // The selected user filter got cleared, the filter state should be reset
      listenerApi.dispatch(setState(initialState));
      return;
    }

    const selectedUserFilter =
      userFilters.userFilterOptions?.find((option) => option.id === userFilters.selectedUserFilterOptionId) || null;
    if (!selectedUserFilter) {
      return;
    }

    const filter = buildFilterFromUserFilter(selectedUserFilter, {
      ...initialState,
      // Persist these filters
      fleetOptions: eventFilters.fleetOptions,
    });
    listenerApi.dispatch(setState(filter));
  },
});
