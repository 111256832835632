import React from 'react';

import { ExclamationTriangleIcon } from '../../../common/components/icons/ExclamationTriangleIcon';
import { MagnifyingGlassIcon } from '../../../common/components/icons/MagnifyingGlassIcon';

type Props = {
  children?: React.ReactNode;
};

export const EventAttribute: React.FC<Props> = ({ children }) => {
  const renderEventAttributeIcon = () => {
    if (children === 'Not under command') {
      return <ExclamationTriangleIcon className="h-5 w-5 stroke-[#a53333]" />;
    } else if (children !== null && typeof children === 'object') {
      return <MagnifyingGlassIcon className="h-5 w-5 my-0.5 stroke-gray-400" />;
    } else {
      return <img src="/assets/images/life-buoy.svg" alt="life buoy icon" className="w-4 h-4 mx-0.5" />;
    }
  };

  return (
    <div className="EventAttribute flex items-center my-0.5">
      {renderEventAttributeIcon()}
      <span className="ml-1.5 text-sm">{children}</span>
    </div>
  );
};
