import { useMemo } from 'react';
import { MapRef } from 'react-map-gl';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import useResizeObserver from 'use-resize-observer';

import { AccountInfo } from '@azure/msal-browser';

import { currentUserSelector } from '../features/auth/slice';
import { filterValuesAsJsonSelector } from '../features/event-filters/slice';
import { useGetAnomaliesQuery, useGetCasualtiesQuery, useGetDrydockingsQuery } from '../store/apis/vessel-event-api';
import { buildQueryFilter } from './utils';

import type { RootState, AppDispatch } from '../store/store';
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGetFilteredEvents = (pollingInterval?: number) => {
  const eventFilters = useAppSelector((state) => state.eventFilters);
  const filterValuesAsJson = useAppSelector(filterValuesAsJsonSelector);
  const currentUser = useAppSelector(currentUserSelector) as AccountInfo;

  const { anomalyParams, casualtyParams, dryDockParams } = useMemo(
    () => buildQueryFilter(eventFilters, currentUser),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValuesAsJson, currentUser]
  );

  const anomalies = useGetAnomaliesQuery(
    { ...anomalyParams },
    {
      refetchOnMountOrArgChange: true,
      skip: !!eventFilters.selectedEventOption && eventFilters.selectedEventOption.value !== 'anomalies',
      pollingInterval,
    }
  );
  const casualties = useGetCasualtiesQuery(
    { ...casualtyParams },
    {
      refetchOnMountOrArgChange: true,
      skip: !!eventFilters.selectedEventOption && eventFilters.selectedEventOption.value !== 'casualties',
      pollingInterval,
    }
  );
  const drydockings = useGetDrydockingsQuery(
    { ...dryDockParams },
    {
      refetchOnMountOrArgChange: true,
      skip: !!eventFilters.selectedEventOption && eventFilters.selectedEventOption.value !== 'dry-docking',
      pollingInterval,
    }
  );

  const events = useMemo(
    () => ({
      anomalies,
      casualties,
      drydockings,
    }),
    [anomalies, casualties, drydockings]
  );

  return events;
};

export const useCallResizeOnMapWhenContainerSizeChange = (mapRef: MapRef | null) => {
  useResizeObserver({
    ref: mapRef?.getContainer(),
    onResize: () => {
      mapRef?.resize();
    },
  });
};
