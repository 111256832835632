import { installationBaseApi as api } from '../base/base-apis';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    installationsGet: build.query<InstallationsGetApiResponse, InstallationsGetApiArg>({
      query: (queryArg) => ({
        url: `/installations`,
        params: {
          name: queryArg.name,
          imo_number: queryArg.imoNumber,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          fields: queryArg.fields,
        },
      }),
    }),
    installationsInstallationIdGet: build.query<
      InstallationsInstallationIdGetApiResponse,
      InstallationsInstallationIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/installations/${queryArg.installationId}`,
        params: { fields: queryArg.fields, omit: queryArg.omit },
      }),
    }),
    installationsInstallationIdPartnersGet: build.query<
      InstallationsInstallationIdPartnersGetApiResponse,
      InstallationsInstallationIdPartnersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/installations/${queryArg.installationId}/partners`,
        params: {
          name: queryArg.name,
          limit: queryArg.limit,
          offset: queryArg.offset,
          fields: queryArg.fields,
          sort: queryArg.sort,
        },
      }),
    }),
    installationsInstallationIdEquipmentsGet: build.query<
      InstallationsInstallationIdEquipmentsGetApiResponse,
      InstallationsInstallationIdEquipmentsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/installations/${queryArg.installationId}/equipments`,
        params: {
          name: queryArg.name,
          limit: queryArg.limit,
          offset: queryArg.offset,
          fields: queryArg.fields,
          sort: queryArg.sort,
        },
      }),
    }),
    installationsInstallationIdProjectsGet: build.query<
      InstallationsInstallationIdProjectsGetApiResponse,
      InstallationsInstallationIdProjectsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/installations/${queryArg.installationId}/projects`,
        params: {
          name: queryArg.name,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          fields: queryArg.fields,
        },
      }),
    }),
    installationsInstallationIdContractsGet: build.query<
      InstallationsInstallationIdContractsGetApiResponse,
      InstallationsInstallationIdContractsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/installations/${queryArg.installationId}/contracts`,
        params: {
          name: queryArg.name,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          fields: queryArg.fields,
        },
      }),
    }),
    partnersPartnerRolePartnerIdInstallations: build.query<
      PartnersPartnerRolePartnerIdInstallationsApiResponse,
      PartnersPartnerRolePartnerIdInstallationsApiArg
    >({
      query: (queryArg) => ({ url: `/partners/${queryArg.partnerRole}/${queryArg.partnerId}/installations` }),
    }),
    partnersPartnerRole: build.query<PartnersPartnerRoleApiResponse, PartnersPartnerRoleApiArg>({
      query: (queryArg) => ({ url: `/partners/${queryArg.partnerRole}` }),
    }),
    installationsSearch: build.query<InstallationsSearchApiResponse, InstallationsSearchApiArg>({
      query: (queryArg) => ({
        url: `/installations/search`,
        params: {
          query: queryArg.query,
          limit: queryArg.limit,
          omit_deprecated_records: queryArg.omitDeprecatedRecords,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type InstallationsGetApiResponse = /** status 200 Status 200 */ InstallationArray;
export type InstallationsGetApiArg = {
  /** name of the installation */
  name?: string;
  /** IMO number of the installation */
  imoNumber?: number;
  /** Number of responses */
  limit?: number;
  /** For pagination */
  offset?: number;
  /** Sorting attribute */
  sort?: string;
  /** Comma separated list of relevant fields */
  fields?: string;
};
export type InstallationsInstallationIdGetApiResponse =
  /** status 200 successful operation */
  Installation | /** status 206 partial content */ Installation;
export type InstallationsInstallationIdGetApiArg = {
  /** Installation id */
  installationId: string;
  /** Comma separated list of relevant fields */
  fields?: string;
  /** Comma separated list of data to omit */
  omit?: string;
};
export type InstallationsInstallationIdPartnersGetApiResponse = /** status 200 Status 200 */ PartnerArray;
export type InstallationsInstallationIdPartnersGetApiArg = {
  installationId: string;
  /** name of the partner */
  name?: string;
  /** Number of responses */
  limit?: number;
  /** Start from */
  offset?: number;
  /** Comma separated list of relevant fields */
  fields?: string;
  /** Sorting attribute */
  sort?: string;
};
export type InstallationsInstallationIdEquipmentsGetApiResponse = unknown;
export type InstallationsInstallationIdEquipmentsGetApiArg = {
  installationId: string;
  /** name of the equipment */
  name?: string;
  /** For pagination */
  limit?: number;
  /** For pagination */
  offset?: number;
  /** Comma separated list of relevant fields */
  fields?: string;
  /** Sorting attribute */
  sort?: string;
};
export type InstallationsInstallationIdProjectsGetApiResponse = unknown;
export type InstallationsInstallationIdProjectsGetApiArg = {
  installationId: string;
  /** name of the project */
  name?: string;
  /** For pagination */
  limit?: number;
  /** For pagination */
  offset?: number;
  /** Sorting attribute */
  sort?: string;
  /** Comma separated list of relevant fields */
  fields?: string;
};
export type InstallationsInstallationIdContractsGetApiResponse = unknown;
export type InstallationsInstallationIdContractsGetApiArg = {
  installationId: string;
  /** name of the contract */
  name?: string;
  /** For pagination */
  limit?: number;
  /** For pagination */
  offset?: number;
  /** Sort parameter */
  sort?: string;
  /** Comma separated list of relevant fields */
  fields?: string;
};
export type PartnersPartnerRolePartnerIdInstallationsApiResponse = unknown;
export type PartnersPartnerRolePartnerIdInstallationsApiArg = {
  /** Partner Role */
  partnerRole: string;
  /** Partner ID */
  partnerId: string;
};
export type PartnersPartnerRoleApiResponse = unknown;
export type PartnersPartnerRoleApiArg = {
  /** Partner Role */
  partnerRole: string;
};
export type InstallationsSearchApiResponse =
  /** status 200 successful operation */
  Installation | /** status 206 partial content */ InstallationSearchResult;
export type InstallationsSearchApiArg = {
  /** Query string */
  query: string;
  /** Query string */
  limit?: number;
  /** Whether to omit depreacted records or not */
  omitDeprecatedRecords?: boolean;
};
export type InstallationStatus = {
  code?: 'OP-P' | 'OP-Y' | 'OP-N' | 'OP-S' | 'OP-I' | 'OP-C';
  name?: 'Pending' | 'Operating' | 'Not Operating' | 'Scrapped' | 'Invalid' | 'Cancelled';
  effectiveDate?: string;
};
export type InstallationSystemStatus = {
  isBlocked?: boolean;
  isMarkedForDeletion?: boolean;
};
export type InstallationCategory = {
  code?: string;
  name?: string;
};
export type InstallationType = {
  code?: string;
  name?: string;
};
export type InstallationIdentification = {
  identifier?: string;
  type?: 'name' | 'legacyId' | 'projectNumber' | 'imoNumber' | 'hullNumber' | 'mmsiNumber';
  validUntil?: string;
};
export type InstallationClassification = {
  group?: string;
  cluster?: string;
  segment?: string;
  isItarRegulated?: boolean;
  isMovable?: boolean;
};
export type InstallationLifecycle = {
  orderDate?: string;
  cancellationDate?: string;
  commissioningDate?: string;
  deliveryDate?: string;
  scrappedDate?: string;
};
export type LocationCountry = {
  code?: string;
  name?: string;
};
export type Location = {
  latitude?: number;
  longitude?: number;
  country?: LocationCountry;
  name?: string;
};
export type PartnerType = {
  code?: 'AP' | 'KU' | 'PE' | 'TP';
  name?: string;
};
export type PartnerRole = {
  code?: string;
  name?: string;
};
export type Partner = {
  id?: string;
  name?: string;
  type?: PartnerType;
  role?: PartnerRole[];
};
export type EquipmentCategory = {
  code?: string;
  name?: string;
};
export type EquipmentProductReferenceType = {
  code?: string;
  name?: string;
};
export type EquipmentProductApplication = {
  code?: string;
  name?: string;
};
export type EquipmentOperatingStatus = {
  code?: string;
  name?: string;
};
export type Equipment = {
  id?: number;
  description?: string;
  nickname?: string;
  serialNumber?: string;
  category?: EquipmentCategory;
  productReferenceType?: EquipmentProductReferenceType;
  productType?: string;
  productExtension?: string;
  productApplication?: EquipmentProductApplication;
  operatingStatus?: EquipmentOperatingStatus;
  manufacturerSerialNumber?: string;
  runningHoursValue?: number;
  runningHoursDate?: string;
};
export type ProjectStatus = {
  code?: string;
  name?: string;
};
export type Employee = {
  id?: number;
  accdom?: string;
  name?: string;
};
export type Project = {
  id?: string;
  name?: string;
  status?: ProjectStatus;
  startDate?: string;
  endDate?: string;
  manager?: Employee;
};
export type ContractStatus = {
  code?: string;
  name?: string;
};
export type Contract = {
  id?: string;
  name?: string;
  type?: string;
  status?: ContractStatus;
  startDate?: string;
  endDate?: string;
  manager?: Employee;
};
export type InstallationMedia = {
  url?: string;
  type?: 'video' | 'photo';
};
export type InstallationMarineConstruction = {
  shipyard?: string;
  hullNumber?: string;
};
export type InstallationMarineFlagCountry = {
  code?: string;
  name?: string;
};
export type InstallationMarineVesselSize = {
  type?: 'capacity' | 'length';
  value?: number;
  unit?: 'CBM' | 'DWT' | 'GT' | 'LGC' | 'TEU';
};
export type InstallationMarineSurvey = {
  surveyDate?: string;
  location?: Location;
  type?: string;
  updateDate?: string;
};
export type InstallationMarineAis = {
  datetime?: string;
  latitude?: number;
  longitude?: number;
  navigationStatus?: string;
  speed?: number;
  course?: number;
  heading?: number;
  draught?: number;
  destination?: string;
  eta?: string;
};
export type InstallationMarine = {
  imoNumber?: number;
  construction?: InstallationMarineConstruction;
  flagCountry?: InstallationMarineFlagCountry;
  operationCountry?: InstallationMarineFlagCountry;
  vesselSize?: InstallationMarineVesselSize[];
  iceClass?: string;
  inlandIndicator?: boolean;
  survey?: InstallationMarineSurvey[];
  ais?: InstallationMarineAis;
};
export type InstallationEnergyLoadType = {
  code?: string;
  name?: string;
};
export type InstallationEnergyWarranty = {
  networkCompany?: InstallationEnergyLoadType;
  startDate?: string;
  endDate?: string;
  manager?: Employee;
};
export type InstallationEnergy = {
  loadType?: InstallationEnergyLoadType;
  warranty?: InstallationEnergyWarranty;
};
export type InstallationMetadataLink = {
  system?: string;
  url?: string;
};
export type InstallationMetadata = {
  creationDate?: string;
  creationUser?: Employee;
  remarks?: string;
  links?: InstallationMetadataLink[];
};
export type Installation = {
  id?: number;
  name?: string;
  status?: InstallationStatus;
  systemStatus?: InstallationSystemStatus;
  category?: InstallationCategory;
  type?: InstallationType;
  identification?: InstallationIdentification[];
  classification?: InstallationClassification;
  lifecycle?: InstallationLifecycle;
  location?: Location;
  partner?: Partner[];
  equipment?: Equipment[];
  project?: Project[];
  contract?: Contract[];
  expertiseCenters?: string[];
  media?: InstallationMedia[];
  marine?: InstallationMarine;
  energy?: InstallationEnergy;
  metadata?: InstallationMetadata;
};
export type InstallationArray = Installation[];
export type PartnerArray = Partner[];
export type InstallationSearchResult = {
  id?: number;
  name?: string;
  status_code?: string;
  status_name?: string;
  imo?: number;
  mmsi?: number;
  hull_number?: string;
  project_name?: string;
  old_names?: string[];
  operator_id?: string;
  operator_name?: string;
  projects?: string[];
  number_of_equipment?: number;
};
export const {
  useInstallationsGetQuery,
  useInstallationsInstallationIdGetQuery,
  useInstallationsInstallationIdPartnersGetQuery,
  useInstallationsInstallationIdEquipmentsGetQuery,
  useInstallationsInstallationIdProjectsGetQuery,
  useInstallationsInstallationIdContractsGetQuery,
  usePartnersPartnerRolePartnerIdInstallationsQuery,
  usePartnersPartnerRoleQuery,
  useInstallationsSearchQuery,
} = injectedRtkApi;
