import { useState } from 'react';
import { ChevronLeft } from '../../common/components/icons/ChevronLeft';
import { ChevronRight } from '../../common/components/icons/ChevronRight';
import { useAppDispatch, useAppSelector, useCallResizeOnMapWhenContainerSizeChange } from '../../common/hooks';
import { setTitleAndUpdate } from '../title/slice';
import { VesselMapZoom } from '../vessel-map-zoom/VesselMapZoom';
import { MyFleetMap } from './components/MyFleetMap';
import { MyFleetSearch } from './components/MyFleetSearch';
import { MyFleetVessels } from './components/MyFleetVessels';

export const MyFleetView: React.FC = () => {
  const map = useAppSelector((state) => state.myFleet.mapInstance);
  const [isOpen, setIsOpen] = useState(true);

  useCallResizeOnMapWhenContainerSizeChange(map);

  const dispatch = useAppDispatch();
  dispatch(setTitleAndUpdate());

  return (
    <div className="MyFleetView p-3 h-full bg-primary text-primary-content flex justify-between flex-col lg:flex-row overflow-hidden">
      {isOpen ? (
        <div className="min-h-[35rem] lg:min-h-[unset] my-2 lg:my-0 relative items-center">
          <div className="flex flex-row justify-between">
            <div className="mt-1">
              <MyFleetSearch className="w-72" />
            </div>
            <div
              className="cursor-pointer hover:bg-secondary rounded py-1 px-2 text-xs w-fit h-fit"
              onClick={() => setIsOpen(!isOpen)}
              title="Hide left panel"
            >
              <ChevronLeft />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer hover:bg-secondary rounded py-1 px-2 text-xs mb-1 w-fit h-fit"
          onClick={() => setIsOpen(!isOpen)}
          title="Show left panel"
        >
          <ChevronRight />
        </div>
      )}
      <div className="flex-grow min-h-[35rem] lg:min-h-[unset] mx-2 my-2 lg:my-0 relative">
        <div className="absolute left-0 top-0 z-10 m-1 hidden sm:block">
          <div className="flex flex-col items-center">
            <VesselMapZoom map={map} />
          </div>
        </div>
        <MyFleetMap />
      </div>
      <div className="min-h-[35rem] lg:min-h-[unset] mx-2 my-2 lg:my-0 h-full">
        <MyFleetVessels />
      </div>
    </div>
  );
};
