import './index.css';
import 'rc-slider/assets/index.css';

import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import utcPlugin from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { AuthenticatedWrapper } from './features/auth/AuthenticatedWrapper';
import { AuthenticationWrapper } from './features/auth/AuthenticationWrapper';
import { UsageLogWrapper } from './features/usage-log/UsageLogWrapper';
import { store } from './store/store';

dayjs.extend(relativeTimePlugin);
dayjs.extend(durationPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(isBetweenPlugin);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <AuthenticationWrapper>
      <AuthenticatedWrapper>
        <UsageLogWrapper>
          <BrowserRouter>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </BrowserRouter>
        </UsageLogWrapper>
      </AuthenticatedWrapper>
    </AuthenticationWrapper>
  </Provider>
);
