import { useAppSelector } from '../../common/hooks';
import { MapStyle } from '../../common/types';
import { navigationalMapStyle } from './utils';

export const useGetMapProps = (originPage: 'port' | 'vessel') => {
  const { MAPBOX_STYLE, MAPBOX_ACCESS_TOKEN, MAPBOX_STREET_STYLE } = useAppSelector((state) => state.common.config!);
  const { emissionLayer } = useAppSelector((state) => state.mapLayers);
  const { selectedVesselMapStyle } = useAppSelector((state) => state.switchMapStyle);
  const { selectedPortMapStyle } = useAppSelector((state) => state.switchMapStyle);
  const selectedMapStyle: MapStyle = originPage === 'port' ? selectedPortMapStyle : selectedVesselMapStyle;

  let mapStyle;
  switch (selectedMapStyle) {
    case 'navigational':
      mapStyle = navigationalMapStyle;
      break;
    case 'street':
      mapStyle = MAPBOX_STREET_STYLE;
      break;
    case 'default':
      mapStyle = MAPBOX_STYLE;
  }
  const maxZoom = selectedMapStyle === 'navigational' ? 16 : undefined;
  const minZoom = emissionLayer.enabled && originPage === 'vessel' ? 2 : undefined;

  return {
    mapboxAccessToken: MAPBOX_ACCESS_TOKEN,
    mapStyle,
    maxZoom,
    minZoom,
  };
};
