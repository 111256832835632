import React from 'react';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  inputClassName?: string;
  children?: React.ReactNode;
};

export const Checkbox: React.FC<Props> = ({ value, onChange, className, inputClassName, children }) => {
  return (
    <div className={`flex ${className ? className : ''}`}>
      <input
        type="checkbox"
        checked={value}
        onChange={() => onChange(!value)}
        className={`checkbox ${inputClassName ? inputClassName : ''}`}
      />
      {children}
    </div>
  );
};
