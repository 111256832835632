import React from 'react';
import { useAppSelector } from '../../../common/hooks';

export const LocationInformation: React.FC = () => {
  const state = useAppSelector((state) => state.portPage);
  const location = useAppSelector((state) => state.location);

  return (
    <div className="LocationInformation mt-4">
      <div className="border-b border-secondary mb-2">
        {!location.showUserLocation ? (
          <span className="text-lg">Area Information</span>
        ) : (
          <span className="text-lg">Your Area Information</span>
        )}
      </div>
      {!state.locationCoordinates ? (
        <div className="text-lg">
          <p className="mb-2">Showing vessels from:</p>
          <p>
            Latitudes between{' '}
            <span className="text-accent-content">{state.locationBoxCoordinates?.minLat.toFixed(4)}</span>° and{' '}
            <span className="text-accent-content">{state.locationBoxCoordinates?.maxLat.toFixed(4)}</span>°{' '}
          </p>
          <p>
            Longitudes between{' '}
            <span className="text-accent-content">{state.locationBoxCoordinates?.minLon.toFixed(4)}</span>° and{' '}
            <span className="text-accent-content">{state.locationBoxCoordinates?.maxLon.toFixed(4)}</span>°{' '}
          </p>
        </div>
      ) : (
        <span className="text-lg">
          Vessels within <span className="text-accent-content">10</span> km from{' '}
          <span className="text-accent-content">
            {state.locationCoordinates?.latitude.toFixed(5)}° {state.locationCoordinates?.longitude.toFixed(5)}°
          </span>
        </span>
      )}
    </div>
  );
};
