import React from 'react';
import { MapRef } from 'react-map-gl';

import { MinusIcon } from '../../common/components/icons/MinusIcon';
import { PlusIcon } from '../../common/components/icons/PlusIcon';

type Props = {
  map: MapRef | null;
  className?: string;
};

export const VesselMapZoom: React.FC<Props> = ({ map, className }) => (
  <div className={`VesselMapZoom ${className ? className : ''}`}>
    <div title="Zoom in" className="p-1 bg-secondary rounded-full cursor-pointer mb-1" onClick={() => map?.zoomIn()}>
      <PlusIcon />
    </div>
    <div title="Zoom out" className="p-1 bg-secondary rounded-full cursor-pointer" onClick={() => map?.zoomOut()}>
      <MinusIcon />
    </div>
  </div>
);
