import { IPublicClientApplication } from '@azure/msal-browser';
import { datadogRum } from '@datadog/browser-rum';

import { Config } from '../../common/slice';

export const setupDatadogRum = (config: Config, msalInstance: IPublicClientApplication) => {
  const [account] = msalInstance.getAllAccounts();
  datadogRum.init({
    applicationId: config.DATADOG_RUM_APPLICATION_ID,
    clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'vessel-csi',
    env: config.ENVIRONMENT,
    version: config.VERSION,
    sessionSampleRate: 100, // 100% == track all users
    trackUserInteractions: false,
    proxy: config.DATADOG_PROXY_HOST,
  });
  datadogRum.setUser({
    name: account.name,
    email: account.username,
  });
};
