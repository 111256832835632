import { createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';

import { EventType } from '../event-feed/slice';

export type SingleVesselFilterArg = {
  imo: number;
  type?: EventType;
};

type SearchCookie = {
  shipname: string;
  lrimoshipno: string;
  shiptypelevel4: string;
  yearofbuild: string;
};

export type VesselSearchState = {
  cookies: SearchCookie[];
};

const initialState: VesselSearchState = { cookies: [] };

const slice = createSlice({
  name: 'searchOptions',
  initialState,
  reducers: {
    getSearchCookies(state) {
      const cookie = new Cookies();
      const searchCookie: SearchCookie[] = (cookie.get('recently_visited') as SearchCookie[]) || [];

      state.cookies = searchCookie;
    },
  },
});

export const { getSearchCookies } = slice.actions;
export const { reducer } = slice;
