import dayjs from 'dayjs';

import {
  CasualtyCauseKey,
  casualtyCauses,
  getEventOptionValueFromEventType,
  getEventTypeFromEventOptionValue,
} from '../../common/utils';
import { Anomalies, Casualties, Drydockings } from '../../store/apis/vessel-event-api';
import { EventOptionValue } from '../event-filters/slice';
import { EventFeedState, EventType } from './slice';
import { PortCalls } from '../../store/apis/ais-api';

type NavStatusKey =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '95'
  | '96'
  | '97'
  | '98'
  | '99';

const navStatuses: Record<NavStatusKey, string> = {
  '0': 'Under Way Using Engine',
  '1': 'At Anchor',
  '2': 'Not Under Command',
  '3': 'Restricted Manoeuvrability',
  '4': 'Constrained By Her Draught',
  '5': 'Moored',
  '6': 'Aground',
  '7': 'Engaged In Fishing',
  '8': 'Underway Sailing',
  '9': 'Reserved For Future Amendment',
  '10': 'Reserved For Future Amendment',
  '11': 'Unknown',
  '12': 'Unknown',
  '13': 'Reserved For Future Use',
  '14': 'AIS-SART',
  '15': 'Not Defined',
  '95': 'Base Station',
  '96': 'Class B',
  '97': 'SAR Aircraft',
  '98': 'Aid to Navigation',
  '99': 'Class B',
};

export const getLinkToVesselFromEvent = (
  event: Anomalies | Casualties | Drydockings,
  eventType: EventType,
  eventId: number | undefined | string
) => {
  const type = getEventOptionValueFromEventType(eventType);

  if (!event.vessel_name) {
    return null;
  }

  if (event.imo_number && type && eventId) {
    return `/vessel/${event.imo_number}/${type}/${eventId}`;
  }

  if (event.imo_number) {
    return `/vessel/${event.imo_number}`;
  }

  return null;
};

export const getSpeedChangedText = (anomaly: Anomalies) => {
  const { speed_change } = anomaly;
  if (speed_change && speed_change < -5) return 'Significant speed decrease';
  return null;
};

export const getDestinationChangedText = (anomaly: Anomalies) => {
  const { destination_change } = anomaly;
  if (!destination_change) return null;

  const [from, to] = destination_change.split('|');
  return `Destination changed from ${from} to ${to}`;
};

export const getStatusChangedText = (anomaly: Anomalies) => {
  const { status_change } = anomaly;
  if (!status_change) return null;

  const [from, to] = status_change.split('|') as NavStatusKey[];
  return `Status changed from ${navStatuses[from]} to ${navStatuses[to]}`;
};

export const getDriftingText = (anomaly: Anomalies) => {
  const { drifting } = anomaly;
  if (!drifting) return null;

  return `Drifting`;
};

export const getWaitingForPortText = (anomaly: Anomalies) => {
  const { waiting_for_port } = anomaly;
  if (!waiting_for_port) return null;

  return `Waiting for port`;
};

export const getWaitingForOrdersText = (anomaly: Anomalies) => {
  const { waiting_for_orders } = anomaly;
  if (!waiting_for_orders) return null;

  return `Waiting for orders`;
};

export const getCommandText = (anomaly: Anomalies) => {
  const { not_under_command } = anomaly;
  if (!not_under_command) return null;

  return 'Not under command';
};

export const getInvolvedTugText = (anomaly: Anomalies) => {
  const { involved_tug_mmsi } = anomaly;
  if (!involved_tug_mmsi) return null;

  return `Tug involved (MMSI ${involved_tug_mmsi})`;
};

export const getDurationText = (anomaly: Anomalies) => {
  const { duration } = anomaly;
  if (!duration) {
    // Ongoing anomaly
    return `Duration: Ongoing since ${dayjs(anomaly.start_datetime).utc(true).fromNow()}`;
  }

  // Sometimes duration can be e.g. 03:36:13.402461, we'll pass it to the .split() formatter, it can handle it.
  const unexpectedFormat = duration.indexOf('.') > -1;

  // Check if duration contains more than "hh:mm:ss" e.g. "2 days 10:20:15"
  if (duration.length > 8 && !unexpectedFormat) {
    const days = duration.substring(0, duration.length - 9);
    const time = duration.substring(days.length + 1);
    return `Duration: ${days} ${formatTimeDuration(time)}`;
  }

  return `Duration: ${formatTimeDuration(duration)}`;
};

export const getPortCallsFlag = (portCall: PortCalls) => {
  const { port_country_code } = portCall;
  if (!port_country_code) return '';

  return `fi fi-${port_country_code.toLowerCase()}`;
};

export const getPortDescription = (portCall: PortCalls) => {
  const { port_name, port_id, port_unlocode } = portCall;
  if (!port_name || !port_id) return null;

  return ` ${port_name} (${port_id} | ${port_unlocode})`;
};

const hoursToDays = (hours: number) => {
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  let result = '';
  if (days > 0) {
    result += `${days | 0} ${days > 1 ? 'days' : 'day'}`;
  }
  if (remainingHours > 0) {
    result += ` ${remainingHours | 0} ${remainingHours > 1 ? 'hours' : 'hour'}`;
  }
  return result.trim();
};

export const getPortCallDurationText = (portCall: PortCalls, endDateString: string | undefined) => {
  const endDate = endDateString ? dayjs(endDateString) : undefined;
  const { duration_hours } = portCall;
  if (!duration_hours) {
    // Ongoing port-call
    if (endDate?.isBefore(dayjs().startOf('day'))) {
      return 'Ongoing beyond selected range';
    }
    return `Duration: Ongoing since ${dayjs(portCall.arrival_time).utc(true).fromNow()}`;
  }

  return `Duration: ${hoursToDays(duration_hours)}`;
};

/**
 * Format hh:mm:ss string to <amount> hours, <amount> minutes
 * @param duration
 */
const formatTimeDuration = (duration: string) => {
  const durations = duration.split(':');
  const hours = Number(durations[0]);
  const minutes = Number(durations[1]);

  const hourStr = hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : '';
  const minuteStr = minutes ? `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : '';

  if (hourStr && minuteStr) {
    return `${hourStr}, ${minuteStr}`;
  }

  if (hourStr) {
    return hourStr;
  }

  if (minuteStr) {
    return minuteStr;
  }

  return '';
};

export const getConfidenceText = (anomaly: Anomalies) => {
  const { anomaly_confidence } = anomaly;
  if (!anomaly_confidence) return null;

  let confidence = '';
  if (anomaly_confidence < 0.6) {
    confidence = 'Very Low';
  } else if (anomaly_confidence < 0.7) {
    confidence = 'Low';
  } else if (anomaly_confidence < 0.85) {
    confidence = 'Medium';
  } else if (anomaly_confidence < 0.95) {
    confidence = 'High';
  } else {
    confidence = 'Very High';
  }

  return `Confidence: ${confidence}`;
};

export const getCasualtyCauses = (casualty: Casualties) => {
  if (!casualty.casualty_causes) return [];

  const causes = casualty.casualty_causes.split(' ') as CasualtyCauseKey[];
  return causes.map((cause) => casualtyCauses[cause]);
};

export const getSourceText = (casualty: Casualties) => {
  // "2021-11-11" has been used as the default date for historic casualties, do not display these (DVC-805)
  if (!casualty?.insert_datetime || casualty?.insert_datetime?.startsWith('2021-11-11')) {
    return '(Source: LLI)';
  }

  const date = dayjs(casualty.insert_datetime).format('YYYY-MM-DD');
  return `(Source: LLI ${date})`;
};

export const getLocationFlagClassNames = (dryDocking: Drydockings) => {
  if (!dryDocking?.location) {
    return '';
  }
  const countryCode = dryDocking.location.substring(0, 2);
  return `fi fi-${countryCode.toLowerCase()}`;
};

const getDurationForDrydock = (dryDocking: Drydockings) => {
  if (!dryDocking.start_datetime) {
    return null;
  }

  const start = dayjs.utc(dryDocking.start_datetime);

  if (dryDocking.end_datetime) {
    const end = dayjs.utc(dryDocking.end_datetime);
    const duration = dayjs.duration(end.diff(start));
    return `Dry docking stay lasted ${duration.humanize()}`;
  }

  const end = dayjs.utc();
  const duration = dayjs.duration(end.diff(start));
  return `In dry dock since ${duration.humanize()}`;
};

const getVesselAge = (dryDocking: Drydockings) => {
  return dryDocking.build_year ? `Vessel built ${dryDocking.build_year}` : null;
};

export const getDurationAndAge = (dryDocking: Drydockings) => {
  const dryDockDuration = getDurationForDrydock(dryDocking);
  const vesselAge = getVesselAge(dryDocking);
  if (dryDockDuration && vesselAge) {
    return `${dryDockDuration} | ${vesselAge}`;
  }

  if (dryDockDuration) {
    return dryDockDuration;
  }

  if (vesselAge) {
    return vesselAge;
  }
};

export const scrollToEventInFeed = (type: EventType, id: number | string) => {
  const listItem = document.querySelector(`div[data-event='${type}-${id}']`);
  listItem?.scrollIntoView({ behavior: 'smooth' });
};

export const getStateFromUrl = (initialState: EventFeedState): EventFeedState => {
  const urlPaths = window.location.pathname.split('/');
  const eventPathIndex = urlPaths.findIndex(
    (path) =>
      (path as EventOptionValue) === 'anomalies' ||
      (path as EventOptionValue) === 'casualties' ||
      (path as EventOptionValue) === 'dry-docking' ||
      (path as EventOptionValue) === 'port-calls'
  );
  if (eventPathIndex === -1) {
    return initialState;
  }

  const eventId = Number(urlPaths[eventPathIndex + 1]);
  if (isNaN(eventId)) {
    return initialState;
  }

  return {
    ...initialState,
    selectedEvent: {
      type: getEventTypeFromEventOptionValue(urlPaths[eventPathIndex] as EventOptionValue),
      id: eventId,
    },
  };
};

export const getDifferenceInTimeForCasualtyText = (casualty: Casualties) => {
  if (!casualty.insert_datetime || !casualty.casualty_date) return 'Anomaly detected by us';
  const difference = new Date(casualty.insert_datetime).getDate() - new Date(casualty.casualty_date).getDate();
  if (difference <= 0) return 'Anomaly detected by us earlier';
  if (difference === 1) return 'Anomaly detected by us a day earlier';
  else return `Anomaly detected by us ${difference} days earlier`;
};

export const getAnomalySortingWeight = (anomaly: Anomalies) => {
  let weight = 0;
  anomaly.end_datetime && (weight += 2);
  anomaly.not_under_command && (weight += 3);
  anomaly.casualty_id && (weight += 3);
  anomaly.distance_coast_km && anomaly.distance_coast_km > 500 && (weight += 1);
  anomaly.waiting_for_orders && (weight -= 2);
  anomaly.waiting_for_port && (weight -= 2);
  const endTime = (!!anomaly.end_datetime && new Date(anomaly.end_datetime).valueOf()) || new Date().valueOf();
  anomaly.start_datetime &&
    endTime - new Date(anomaly.start_datetime).valueOf() > 18000000 /*5 hours*/ &&
    (weight += 1);
  return weight;
};
