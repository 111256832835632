import { ihsVesselDataBaseApi as api } from '../base/base-apis';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuxengines: build.query<GetAuxenginesApiResponse, GetAuxenginesApiArg>({
      query: (queryArg) => ({
        url: `/auxengines`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          enginesequence: queryArg.enginesequence,
          enginebuilder: queryArg.enginebuilder,
          enginedesigner: queryArg.enginedesigner,
          enginemodel: queryArg.enginemodel,
          numberofcylinders: queryArg.numberofcylinders,
          bore: queryArg.bore,
          stroke: queryArg.stroke,
          stroketype: queryArg.stroketype,
          maxpower: queryArg.maxpower,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getAuxiliarygenerators: build.query<GetAuxiliarygeneratorsApiResponse, GetAuxiliarygeneratorsApiArg>({
      query: (queryArg) => ({
        url: `/auxiliarygenerators`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          number: queryArg['number'],
          kweach: queryArg.kweach,
          voltage1: queryArg.voltage1,
          voltage2: queryArg.voltage2,
          frequency: queryArg.frequency,
          acdc: queryArg.acdc,
          mainenginedriven: queryArg.mainenginedriven,
          seq: queryArg.seq,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getBoilersexpanded: build.query<GetBoilersexpandedApiResponse, GetBoilersexpandedApiArg>({
      query: (queryArg) => ({
        url: `/boilersexpanded`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequence: queryArg.sequence,
          builder: queryArg.builder,
          design: queryArg.design,
          effectivedate: queryArg.effectivedate,
          numberofboilers: queryArg.numberofboilers,
          boilertypecode: queryArg.boilertypecode,
          boilertypedecode: queryArg.boilertypedecode,
          firingtypecode: queryArg.firingtypecode,
          firingtypedecode: queryArg.firingtypedecode,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getBuilderandsubcontractordetails: build.query<
      GetBuilderandsubcontractordetailsApiResponse,
      GetBuilderandsubcontractordetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/builderandsubcontractordetails`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          buildercode: queryArg.buildercode,
          shipbuilderfullstyle: queryArg.shipbuilderfullstyle,
          shipbuilder: queryArg.shipbuilder,
          fulladdress: queryArg.fulladdress,
          countryname: queryArg.countryname,
          contact: queryArg.contact,
          emailaddress: queryArg.emailaddress,
          telephone: queryArg.telephone,
          telex: queryArg.telex,
          website: queryArg.website,
          countrycode: queryArg.countrycode,
          towncode: queryArg.towncode,
          builderstatus: queryArg.builderstatus,
          facsimile: queryArg.facsimile,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getBuilderandsubcontractorlinkfile: build.query<
      GetBuilderandsubcontractorlinkfileApiResponse,
      GetBuilderandsubcontractorlinkfileApiArg
    >({
      query: (queryArg) => ({
        url: `/builderandsubcontractorlinkfile`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequencenumber: queryArg.sequencenumber,
          buildercode: queryArg.buildercode,
          section: queryArg.section,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getCapacities: build.query<GetCapacitiesApiResponse, GetCapacitiesApiArg>({
      query: (queryArg) => ({
        url: `/capacities`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          bale: queryArg.bale,
          horsepower: queryArg.horsepower,
          numberofbarges: queryArg.numberofbarges,
          bollardpull: queryArg.bollardpull,
          numberofcars: queryArg.numberofcars,
          gascapacity: queryArg.gascapacity,
          graincapacity: queryArg.graincapacity,
          indicatedhorsepower: queryArg.indicatedhorsepower,
          liquidcapacity: queryArg.liquidcapacity,
          numberofpassengers: queryArg.numberofpassengers,
          numberrefrigeratedcontainers: queryArg.numberrefrigeratedcontainers,
          numberofteu: queryArg.numberofteu,
          numberoftrucks: queryArg.numberoftrucks,
          numberofvehicles: queryArg.numberofvehicles,
          numberofrailwagons: queryArg.numberofrailwagons,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getClasscodes: build.query<GetClasscodesApiResponse, GetClasscodesApiArg>({
      query: (queryArg) => ({
        url: `/classcodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          classcode: queryArg.classcode,
          class: queryArg['class'],
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getCompanyfleetcounts: build.query<GetCompanyfleetcountsApiResponse, GetCompanyfleetcountsApiArg>({
      query: (queryArg) => ({
        url: `/companyfleetcounts`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          owcode: queryArg.owcode,
          shortcompanyname: queryArg.shortcompanyname,
          registeredownercount: queryArg.registeredownercount,
          shipmanagercount: queryArg.shipmanagercount,
          operatorcount: queryArg.operatorcount,
          groupownercount: queryArg.groupownercount,
          doccount: queryArg.doccount,
          fleetsize: queryArg.fleetsize,
          inservicecount: queryArg.inservicecount,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getCompanyfulldetailsandparentcode: build.query<
      GetCompanyfulldetailsandparentcodeApiResponse,
      GetCompanyfulldetailsandparentcodeApiArg
    >({
      query: (queryArg) => ({
        url: `/companyfulldetailsandparentcode`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          owcode: queryArg.owcode,
          shortcompanyname: queryArg.shortcompanyname,
          countryname: queryArg.countryname,
          townname: queryArg.townname,
          telephone: queryArg.telephone,
          telex: queryArg.telex,
          emailaddress: queryArg.emailaddress,
          website: queryArg.website,
          careofcode: queryArg.careofcode,
          roomfloorbuilding1: queryArg.roomfloorbuilding1,
          roomfloorbuilding2: queryArg.roomfloorbuilding2,
          roomfloorbuilding3: queryArg.roomfloorbuilding3,
          pobox: queryArg.pobox,
          streetnumber: queryArg.streetnumber,
          street: queryArg.street,
          prepostcode: queryArg.prepostcode,
          postpostcode: queryArg.postpostcode,
          lastchangedate: queryArg.lastchangedate,
          parentcompany: queryArg.parentcompany,
          nationalityofregistration: queryArg.nationalityofregistration,
          nationalityofcontrol: queryArg.nationalityofcontrol,
          companystatus: queryArg.companystatus,
          fullcompanyname: queryArg.fullcompanyname,
          fulladdress: queryArg.fulladdress,
          facsimile: queryArg.facsimile,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getCompanyvesselrelationships: build.query<
      GetCompanyvesselrelationshipsApiResponse,
      GetCompanyvesselrelationshipsApiArg
    >({
      query: (queryArg) => ({
        url: `/companyvesselrelationships`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          registeredownercode: queryArg.registeredownercode,
          registeredowner: queryArg.registeredowner,
          shipmanagercode: queryArg.shipmanagercode,
          shipmanager: queryArg.shipmanager,
          operatorcode: queryArg.operatorcode,
          operator: queryArg.operator,
          groupbeneficialownercode: queryArg.groupbeneficialownercode,
          groupbeneficialowner: queryArg.groupbeneficialowner,
          doccode: queryArg.doccode,
          doccompany: queryArg.doccompany,
          technicalmanagercode: queryArg.technicalmanagercode,
          technicalmanager: queryArg.technicalmanager,
          operatorgroupcode: queryArg.operatorgroupcode,
          operatorgroup: queryArg.operatorgroup,
          shipmanagergroup: queryArg.shipmanagergroup,
          docgroupcode: queryArg.docgroupcode,
          docgroup: queryArg.docgroup,
          technicalmanagergroupcode: queryArg.technicalmanagergroupcode,
          technicalmanagergroup: queryArg.technicalmanagergroup,
          shipmanagergroupcode: queryArg.shipmanagergroupcode,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getEnginebuildercodes: build.query<GetEnginebuildercodesApiResponse, GetEnginebuildercodesApiArg>({
      query: (queryArg) => ({
        url: `/enginebuildercodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          enginebuilderlargestcode: queryArg.enginebuilderlargestcode,
          enginebuilderlargest: queryArg.enginebuilderlargest,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getEnginebuilderdetails: build.query<GetEnginebuilderdetailsApiResponse, GetEnginebuilderdetailsApiArg>({
      query: (queryArg) => ({
        url: `/enginebuilderdetails`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          enginebuilderlargestcode: queryArg.enginebuilderlargestcode,
          enginebuildershortname: queryArg.enginebuildershortname,
          enginebuilderfullname: queryArg.enginebuilderfullname,
          fulladdress: queryArg.fulladdress,
          townname: queryArg.townname,
          countryname: queryArg.countryname,
          telephone: queryArg.telephone,
          emailaddress: queryArg.emailaddress,
          website: queryArg.website,
          telex: queryArg.telex,
          countrycode: queryArg.countrycode,
          towncode: queryArg.towncode,
          facsimile: queryArg.facsimile,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getFlagcodes: build.query<GetFlagcodesApiResponse, GetFlagcodesApiArg>({
      query: (queryArg) => ({
        url: `/flagcodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          countrynamecode: queryArg.countrynamecode,
          countryname: queryArg.countryname,
          iso2: queryArg.iso2,
          iso3: queryArg.iso3,
          fromdate: queryArg.fromdate,
          todate: queryArg.todate,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getFueltypecodes: build.query<GetFueltypecodesApiResponse, GetFueltypecodesApiArg>({
      query: (queryArg) => ({
        url: `/fueltypecodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          code: queryArg.code,
          fueltype: queryArg.fueltype,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getIceclass: build.query<GetIceclassApiResponse, GetIceclassApiArg>({
      query: (queryArg) => ({
        url: `/iceclass`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          iceclasscode: queryArg.iceclasscode,
          iceclass: queryArg.iceclass,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getMainengines: build.query<GetMainenginesApiResponse, GetMainenginesApiArg>({
      query: (queryArg) => ({
        url: `/mainengines`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          position: queryArg.position,
          enginetype: queryArg.enginetype,
          enginedesigner: queryArg.enginedesigner,
          enginebuilder: queryArg.enginebuilder,
          enginemodel: queryArg.enginemodel,
          numberofcylinders: queryArg.numberofcylinders,
          bore: queryArg.bore,
          stroke: queryArg.stroke,
          stroketype: queryArg.stroketype,
          powerbhp: queryArg.powerbhp,
          powerkw: queryArg.powerkw,
          rpm: queryArg.rpm,
          enginebuildercode: queryArg.enginebuildercode,
          enginemakercode: queryArg.enginemakercode,
          cylinderarrangementcode: queryArg.cylinderarrangementcode,
          cylinderarrangementdecode: queryArg.cylinderarrangementdecode,
          bhpofmainoilengines: queryArg.bhpofmainoilengines,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getMaingenerators: build.query<GetMaingeneratorsApiResponse, GetMaingeneratorsApiArg>({
      query: (queryArg) => ({
        url: `/maingenerators`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequencenumber: queryArg.sequencenumber,
          generatorposition: queryArg.generatorposition,
          number: queryArg['number'],
          kw: queryArg.kw,
          voltage: queryArg.voltage,
          acdcindicator: queryArg.acdcindicator,
          frequency: queryArg.frequency,
          generatormaker: queryArg.generatormaker,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getNamehistory: build.query<GetNamehistoryApiResponse, GetNamehistoryApiArg>({
      query: (queryArg) => ({
        url: `/namehistory`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequence: queryArg.sequence,
          vesselname: queryArg.vesselname,
          effective_date: queryArg.effectiveDate,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getPropellers: build.query<GetPropellersApiResponse, GetPropellersApiArg>({
      query: (queryArg) => ({
        url: `/propellers`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequence: queryArg.sequence,
          propellerposition: queryArg.propellerposition,
          propellertype: queryArg.propellertype,
          propellertypecode: queryArg.propellertypecode,
          rpmservice: queryArg.rpmservice,
          rpmmaximum: queryArg.rpmmaximum,
          nozzletype: queryArg.nozzletype,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getPropulsiontypedecode: build.query<GetPropulsiontypedecodeApiResponse, GetPropulsiontypedecodeApiArg>({
      query: (queryArg) => ({
        url: `/propulsiontypedecode`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          propulsiontypecode: queryArg.propulsiontypecode,
          propulsiontype: queryArg.propulsiontype,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getShipdata: build.query<GetShipdataApiResponse, GetShipdataApiArg>({
      query: (queryArg) => ({
        url: `/shipdata`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          classificationsocietycode: queryArg.classificationsocietycode,
          classificationsociety: queryArg.classificationsociety,
          lrimoshipno: queryArg.lrimoshipno,
          shipname: queryArg.shipname,
          shipstatus: queryArg.shipstatus,
          shipstatuscode: queryArg.shipstatuscode,
          shipstatuseffectivedate: queryArg.shipstatuseffectivedate,
          deadweight: queryArg.deadweight,
          grosstonnage: queryArg.grosstonnage,
          yearofbuild: queryArg.yearofbuild,
          deliverydate: queryArg.deliverydate,
          deathdate: queryArg.deathdate,
          flagcode: queryArg.flagcode,
          flagname: queryArg.flagname,
          doccompany: queryArg.doccompany,
          documentofcompliancedoccompanycode: queryArg.documentofcompliancedoccompanycode,
          groupbeneficialowner: queryArg.groupbeneficialowner,
          groupbeneficialownercompanycode: queryArg.groupbeneficialownercompanycode,
          operator: queryArg.operator,
          operatorcompanycode: queryArg.operatorcompanycode,
          registeredowner: queryArg.registeredowner,
          registeredownercode: queryArg.registeredownercode,
          technicalmanager: queryArg.technicalmanager,
          technicalmanagercode: queryArg.technicalmanagercode,
          shipmanager: queryArg.shipmanager,
          shipmanagercompanycode: queryArg.shipmanagercompanycode,
          shiptypegroup: queryArg.shiptypegroup,
          shiptypelevel2: queryArg.shiptypelevel2,
          shiptypelevel3: queryArg.shiptypelevel3,
          shiptypelevel4: queryArg.shiptypelevel4,
          shiptypelevel5: queryArg.shiptypelevel5,
          shiptypelevel5hulltype: queryArg.shiptypelevel5Hulltype,
          shiptypelevel5subgroup: queryArg.shiptypelevel5Subgroup,
          shiptypelevel5subtype: queryArg.shiptypelevel5Subtype,
          statcode5: queryArg.statcode5,
          lastupdatedate: queryArg.lastupdatedate,
          shipbuilder: queryArg.shipbuilder,
          yardnumber: queryArg.yardnumber,
          shipbuildersubcontractor: queryArg.shipbuildersubcontractor,
          shipbuildersubcontractorshipyardyardhullno: queryArg.shipbuildersubcontractorshipyardyardhullno,
          auxiliarygeneratorsdescriptivenarrative: queryArg.auxiliarygeneratorsdescriptivenarrative,
          auxiliaryenginesnarrative: queryArg.auxiliaryenginesnarrative,
          mainenginebuilder: queryArg.mainenginebuilder,
          mainenginebuildercode: queryArg.mainenginebuildercode,
          mainenginedesigner: queryArg.mainenginedesigner,
          mainenginedesignercode: queryArg.mainenginedesignercode,
          mainenginemodel: queryArg.mainenginemodel,
          mainenginenumberofcylinders: queryArg.mainenginenumberofcylinders,
          mainenginerpm: queryArg.mainenginerpm,
          mainenginestroketype: queryArg.mainenginestroketype,
          mainenginetype: queryArg.mainenginetype,
          numberofallengines: queryArg.numberofallengines,
          numberofauxiliaryengines: queryArg.numberofauxiliaryengines,
          numberofgenerators: queryArg.numberofgenerators,
          numberofmainengines: queryArg.numberofmainengines,
          numberofpropulsionunits: queryArg.numberofpropulsionunits,
          numberofthrusters: queryArg.numberofthrusters,
          powerbhpihpshpmax: queryArg.powerbhpihpshpmax,
          powerbhpihpshpservice: queryArg.powerbhpihpshpservice,
          powerkwmax: queryArg.powerkwmax,
          powerkwservice: queryArg.powerkwservice,
          primemoverdescriptivenarrative: queryArg.primemoverdescriptivenarrative,
          primemoverdescriptiveoverviewnarrative: queryArg.primemoverdescriptiveoverviewnarrative,
          propellertype: queryArg.propellertype,
          propulsiontype: queryArg.propulsiontype,
          propulsiontypecode: queryArg.propulsiontypecode,
          thrustersdescriptivenarrative: queryArg.thrustersdescriptivenarrative,
          totalhorsepowerofauxiliarygenerators: queryArg.totalhorsepowerofauxiliarygenerators,
          totalhorsepowerofmainengines: queryArg.totalhorsepowerofmainengines,
          totalhorsepowerofmaingenerators: queryArg.totalhorsepowerofmaingenerators,
          totalkilowattsofmainengines: queryArg.totalkilowattsofmainengines,
          totalpowerofallengines: queryArg.totalpowerofallengines,
          classnarrative: queryArg.classnarrative,
          consumptionspeed1: queryArg.consumptionspeed1,
          consumptionspeed2: queryArg.consumptionspeed2,
          consumptionvalue1: queryArg.consumptionvalue1,
          consumptionvalue2: queryArg.consumptionvalue2,
          contractdate: queryArg.contractdate,
          dateofbuild: queryArg.dateofbuild,
          exname: queryArg.exname,
          fuelconsumptionmainenginesonly: queryArg.fuelconsumptionmainenginesonly,
          fuelconsumptiontotal: queryArg.fuelconsumptiontotal,
          fueltype1capacity: queryArg.fueltype1Capacity,
          fueltype1code: queryArg.fueltype1Code,
          fueltype1first: queryArg.fueltype1First,
          fueltype2capacity: queryArg.fueltype2Capacity,
          fueltype2code: queryArg.fueltype2Code,
          fueltype2second: queryArg.fueltype2Second,
          geardescriptivenarrative: queryArg.geardescriptivenarrative,
          gearnolargest: queryArg.gearnolargest,
          gearswllargest: queryArg.gearswllargest,
          geartypelargest: queryArg.geartypelargest,
          gearless: queryArg.gearless,
          icecapabilitydescriptivenarrative: queryArg.icecapabilitydescriptivenarrative,
          imochemicalclassi: queryArg.imochemicalclassi,
          imochemicalclassii: queryArg.imochemicalclassii,
          imochemicalclassiii: queryArg.imochemicalclassiii,
          leadshipinseriesbyimonumber: queryArg.leadshipinseriesbyimonumber,
          maritimemobileserviceidentitymmsinumber: queryArg.maritimemobileserviceidentitymmsinumber,
          newconstructionentrydate: queryArg.newconstructionentrydate,
          numberofdecks: queryArg.numberofdecks,
          numberofhatches: queryArg.numberofhatches,
          numberofholds: queryArg.numberofholds,
          numberoftanks: queryArg.numberoftanks,
          portofregistry: queryArg.portofregistry,
          shipbuildercompanycode: queryArg.shipbuildercompanycode,
          shipbuildersubcontractorcode: queryArg.shipbuildersubcontractorcode,
          speed: queryArg.speed,
          speedmax: queryArg.speedmax,
          speedservice: queryArg.speedservice,
          standardshipdesign: queryArg.standardshipdesign,
          teu: queryArg.teu,
          teucapacity14thomogenous: queryArg.teucapacity14Thomogenous,
          totalpowerofauxiliaryengines: queryArg.totalpowerofauxiliaryengines,
          bulbousbow: queryArg.bulbousbow,
          bunkersdescriptivenarrative: queryArg.bunkersdescriptivenarrative,
          callsign: queryArg.callsign,
          canceldate: queryArg.canceldate,
          compensatedgrosstonnagecgt: queryArg.compensatedgrosstonnagecgt,
          countryofbuild: queryArg.countryofbuild,
          countryofbuildcode: queryArg.countryofbuildcode,
          hulltype: queryArg.hulltype,
          hulltypecode: queryArg.hulltypecode,
          lengthoverallloa: queryArg.lengthoverallloa,
          propellermanufacturer: queryArg.propellermanufacturer,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getShiptypecodes: build.query<GetShiptypecodesApiResponse, GetShiptypecodesApiArg>({
      query: (queryArg) => ({
        url: `/shiptypecodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          statcode5: queryArg.statcode5,
          shiptypelevel5: queryArg.shiptypelevel5,
          level4code: queryArg.level4Code,
          shiptypelevel4: queryArg.shiptypelevel4,
          level3code: queryArg.level3Code,
          shiptypelevel3: queryArg.shiptypelevel3,
          level2code: queryArg.level2Code,
          shiptypelevel2: queryArg.shiptypelevel2,
          shiptypelevel1code: queryArg.shiptypelevel1Code,
          shiptypelevel1: queryArg.shiptypelevel1,
          hulltype: queryArg.hulltype,
          subgroup: queryArg.subgroup,
          subtype: queryArg.subtype,
          hulltypecode: queryArg.hulltypecode,
          subgroupcode: queryArg.subgroupcode,
          subtypecode: queryArg.subtypecode,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getSpecialfeatures: build.query<GetSpecialfeaturesApiResponse, GetSpecialfeaturesApiArg>({
      query: (queryArg) => ({
        url: `/specialfeatures`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequence: queryArg.sequence,
          specialfeature: queryArg.specialfeature,
          specialfeaturecode: queryArg.specialfeaturecode,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getStatuscodes: build.query<GetStatuscodesApiResponse, GetStatuscodesApiArg>({
      query: (queryArg) => ({
        url: `/statuscodes`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          statuscode: queryArg.statuscode,
          status: queryArg.status,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getStatushistory: build.query<GetStatushistoryApiResponse, GetStatushistoryApiArg>({
      query: (queryArg) => ({
        url: `/statushistory`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          status: queryArg.status,
          sequence: queryArg.sequence,
          statusdate: queryArg.statusdate,
          statuscode: queryArg.statuscode,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getSurveydatehistory: build.query<GetSurveydatehistoryApiResponse, GetSurveydatehistoryApiArg>({
      query: (queryArg) => ({
        url: `/surveydatehistory`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          classsocietycode: queryArg.classsocietycode,
          specialsurvey: queryArg.specialsurvey,
          continuoussurvey: queryArg.continuoussurvey,
          dockingsurvey: queryArg.dockingsurvey,
          tailshaftsurvey: queryArg.tailshaftsurvey,
          classsociety: queryArg.classsociety,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getSurveydates: build.query<GetSurveydatesApiResponse, GetSurveydatesApiArg>({
      query: (queryArg) => ({
        url: `/surveydates`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          classsocietycode: queryArg.classsocietycode,
          specialsurvey: queryArg.specialsurvey,
          specialsurveylakes: queryArg.specialsurveylakes,
          continuoushullsurvey: queryArg.continuoushullsurvey,
          continuousmachinerysurvey: queryArg.continuousmachinerysurvey,
          tailshaftsurvey: queryArg.tailshaftsurvey,
          dockingsurvey: queryArg.dockingsurvey,
          annualsurvey: queryArg.annualsurvey,
          classsociety: queryArg.classsociety,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getThrusters: build.query<GetThrustersApiResponse, GetThrustersApiArg>({
      query: (queryArg) => ({
        url: `/thrusters`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequence: queryArg.sequence,
          thrustertype: queryArg.thrustertype,
          thrustertypecode: queryArg.thrustertypecode,
          numberofthrusters: queryArg.numberofthrusters,
          thrusterposition: queryArg.thrusterposition,
          thrusterbhp: queryArg.thrusterbhp,
          thrusterkw: queryArg.thrusterkw,
          typeofinstallation: queryArg.typeofinstallation,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getTurbochargers: build.query<GetTurbochargersApiResponse, GetTurbochargersApiArg>({
      query: (queryArg) => ({
        url: `/turbochargers`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrno: queryArg.lrno,
          sequencenumber: queryArg.sequencenumber,
          builder: queryArg.builder,
          countryofbuild: queryArg.countryofbuild,
          design: queryArg.design,
          designation: queryArg.designation,
          model: queryArg.model,
          mainauxeng: queryArg.mainauxeng,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getPropellermanufacturer: build.query<GetPropellermanufacturerApiResponse, GetPropellermanufacturerApiArg>({
      query: (queryArg) => ({
        url: `/propellermanufacturer`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          imo: queryArg.imo,
          propellermanufacturer: queryArg.propellermanufacturer,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getVesselCompanies: build.query<GetVesselCompaniesApiResponse, GetVesselCompaniesApiArg>({
      query: (queryArg) => ({
        url: `/vessel_companies`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          lrimoshipno: queryArg.lrimoshipno,
          role_code: queryArg.roleCode,
          role_name: queryArg.roleName,
          country_code: queryArg.countryCode,
          owcode: queryArg.owcode,
          shortcompanyname: queryArg.shortcompanyname,
          countryname: queryArg.countryname,
          townname: queryArg.townname,
          telephone: queryArg.telephone,
          telex: queryArg.telex,
          emailaddress: queryArg.emailaddress,
          website: queryArg.website,
          careofcode: queryArg.careofcode,
          roomfloorbuilding1: queryArg.roomfloorbuilding1,
          roomfloorbuilding2: queryArg.roomfloorbuilding2,
          roomfloorbuilding3: queryArg.roomfloorbuilding3,
          pobox: queryArg.pobox,
          streetnumber: queryArg.streetnumber,
          street: queryArg.street,
          prepostcode: queryArg.prepostcode,
          postpostcode: queryArg.postpostcode,
          lastchangedate: queryArg.lastchangedate,
          parentcompany: queryArg.parentcompany,
          nationalityofregistration: queryArg.nationalityofregistration,
          nationalityofcontrol: queryArg.nationalityofcontrol,
          companystatus: queryArg.companystatus,
          fullcompanyname: queryArg.fullcompanyname,
          fulladdress: queryArg.fulladdress,
          facsimile: queryArg.facsimile,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    imoSearch: build.query<ImoSearchApiResponse, ImoSearchApiArg>({
      query: (queryArg) => ({ url: `/rpc/imo_search`, params: { query: queryArg.query } }),
    }),
    vesselSearch: build.query<VesselSearchApiResponse, VesselSearchApiArg>({
      query: (queryArg) => ({ url: `/rpc/vessel_search`, params: { query: queryArg.query } }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetAuxenginesApiResponse = /** status 200 OK */ AuxenginesArray | /** status 206 Partial Content */ string;
export type GetAuxenginesApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  enginesequence?: string;
  /** Format - character varying. */
  enginebuilder?: string;
  /** Format - character varying. */
  enginedesigner?: string;
  /** Format - character varying. */
  enginemodel?: string;
  /** Format - character varying. */
  numberofcylinders?: string;
  /** Format - character varying. */
  bore?: string;
  /** Format - character varying. */
  stroke?: string;
  /** Format - character varying. */
  stroketype?: string;
  /** Format - character varying. */
  maxpower?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetAuxiliarygeneratorsApiResponse =
  /** status 200 OK */
  AuxiliarygeneratorsArray | /** status 206 Partial Content */ string;
export type GetAuxiliarygeneratorsApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - smallint. */
  number?: string;
  /** Format - numeric. */
  kweach?: string;
  /** Format - smallint. */
  voltage1?: string;
  /** Format - smallint. */
  voltage2?: string;
  /** Format - smallint. */
  frequency?: string;
  /** Format - character varying. */
  acdc?: string;
  /** Format - character varying. */
  mainenginedriven?: string;
  /** Format - character varying. */
  seq?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetBoilersexpandedApiResponse =
  /** status 200 OK */
  BoilersexpandedArray | /** status 206 Partial Content */ string;
export type GetBoilersexpandedApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - integer. */
  sequence?: string;
  /** Format - character varying. */
  builder?: string;
  /** Format - character varying. */
  design?: string;
  /** Format - character varying. */
  effectivedate?: string;
  /** Format - character varying. */
  numberofboilers?: string;
  /** Format - character varying. */
  boilertypecode?: string;
  /** Format - character varying. */
  boilertypedecode?: string;
  /** Format - character varying. */
  firingtypecode?: string;
  /** Format - character varying. */
  firingtypedecode?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetBuilderandsubcontractordetailsApiResponse =
  /** status 200 OK */
  BuilderandsubcontractordetailsArray | /** status 206 Partial Content */ string;
export type GetBuilderandsubcontractordetailsApiArg = {
  /** Format - character. */
  buildercode?: string;
  /** Format - character varying. */
  shipbuilderfullstyle?: string;
  /** Format - character varying. */
  shipbuilder?: string;
  /** Format - character varying. */
  fulladdress?: string;
  /** Format - character varying. */
  countryname?: string;
  /** Format - character varying. */
  contact?: string;
  /** Format - character varying. */
  emailaddress?: string;
  /** Format - character varying. */
  telephone?: string;
  /** Format - character varying. */
  telex?: string;
  /** Format - character varying. */
  website?: string;
  /** Format - character varying. */
  countrycode?: string;
  /** Format - character varying. */
  towncode?: string;
  /** Format - character varying. */
  builderstatus?: string;
  /** Format - character varying. */
  facsimile?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetBuilderandsubcontractorlinkfileApiResponse =
  /** status 200 OK */
  BuilderandsubcontractorlinkfileArray | /** status 206 Partial Content */ string;
export type GetBuilderandsubcontractorlinkfileApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  sequencenumber?: string;
  /** Format - character varying. */
  buildercode?: string;
  /** Format - character varying. */
  section?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetCapacitiesApiResponse = /** status 200 OK */ CapacitiesArray | /** status 206 Partial Content */ string;
export type GetCapacitiesApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - integer. */
  bale?: string;
  /** Format - integer. */
  horsepower?: string;
  /** Format - integer. */
  numberofbarges?: string;
  /** Format - integer. */
  bollardpull?: string;
  /** Format - integer. */
  numberofcars?: string;
  /** Format - integer. */
  gascapacity?: string;
  /** Format - integer. */
  graincapacity?: string;
  /** Format - integer. */
  indicatedhorsepower?: string;
  /** Format - integer. */
  liquidcapacity?: string;
  /** Format - smallint. */
  numberofpassengers?: string;
  /** Format - smallint. */
  numberrefrigeratedcontainers?: string;
  /** Format - integer. */
  numberofteu?: string;
  /** Format - integer. */
  numberoftrucks?: string;
  /** Format - integer. */
  numberofvehicles?: string;
  /** Format - integer. */
  numberofrailwagons?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetClasscodesApiResponse = /** status 200 OK */ ClasscodesArray | /** status 206 Partial Content */ string;
export type GetClasscodesApiArg = {
  /** Format - character varying. */
  classcode?: string;
  /** Format - character varying. */
  class?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetCompanyfleetcountsApiResponse =
  /** status 200 OK */
  CompanyfleetcountsArray | /** status 206 Partial Content */ string;
export type GetCompanyfleetcountsApiArg = {
  /** Format - character varying. */
  owcode?: string;
  /** Format - character varying. */
  shortcompanyname?: string;
  /** Format - integer. */
  registeredownercount?: string;
  /** Format - integer. */
  shipmanagercount?: string;
  /** Format - integer. */
  operatorcount?: string;
  /** Format - integer. */
  groupownercount?: string;
  /** Format - integer. */
  doccount?: string;
  /** Format - integer. */
  fleetsize?: string;
  /** Format - integer. */
  inservicecount?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetCompanyfulldetailsandparentcodeApiResponse =
  /** status 200 OK */
  CompanyfulldetailsandparentcodeArray | /** status 206 Partial Content */ string;
export type GetCompanyfulldetailsandparentcodeApiArg = {
  /** Format - character varying. */
  owcode?: string;
  /** Format - character varying. */
  shortcompanyname?: string;
  /** Format - character varying. */
  countryname?: string;
  /** Format - character varying. */
  townname?: string;
  /** Format - character varying. */
  telephone?: string;
  /** Format - character varying. */
  telex?: string;
  /** Format - character varying. */
  emailaddress?: string;
  /** Format - character varying. */
  website?: string;
  /** Format - character varying. */
  careofcode?: string;
  /** Format - character varying. */
  roomfloorbuilding1?: string;
  /** Format - character varying. */
  roomfloorbuilding2?: string;
  /** Format - character varying. */
  roomfloorbuilding3?: string;
  /** Format - character varying. */
  pobox?: string;
  /** Format - character varying. */
  streetnumber?: string;
  /** Format - character varying. */
  street?: string;
  /** Format - character varying. */
  prepostcode?: string;
  /** Format - character varying. */
  postpostcode?: string;
  /** Format - character varying. */
  lastchangedate?: string;
  /** Format - character varying. */
  parentcompany?: string;
  /** Format - character varying. */
  nationalityofregistration?: string;
  /** Format - character varying. */
  nationalityofcontrol?: string;
  /** Format - character varying. */
  companystatus?: string;
  /** Format - character varying. */
  fullcompanyname?: string;
  /** Format - character varying. */
  fulladdress?: string;
  /** Format - character varying. */
  facsimile?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetCompanyvesselrelationshipsApiResponse =
  /** status 200 OK */
  CompanyvesselrelationshipsArray | /** status 206 Partial Content */ string;
export type GetCompanyvesselrelationshipsApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character. */
  registeredownercode?: string;
  /** Format - character varying. */
  registeredowner?: string;
  /** Format - character. */
  shipmanagercode?: string;
  /** Format - character varying. */
  shipmanager?: string;
  /** Format - character. */
  operatorcode?: string;
  /** Format - character varying. */
  operator?: string;
  /** Format - character. */
  groupbeneficialownercode?: string;
  /** Format - character varying. */
  groupbeneficialowner?: string;
  /** Format - character. */
  doccode?: string;
  /** Format - character varying. */
  doccompany?: string;
  /** Format - character. */
  technicalmanagercode?: string;
  /** Format - character varying. */
  technicalmanager?: string;
  /** Format - character varying. */
  operatorgroupcode?: string;
  /** Format - character varying. */
  operatorgroup?: string;
  /** Format - character varying. */
  shipmanagergroup?: string;
  /** Format - character varying. */
  docgroupcode?: string;
  /** Format - character varying. */
  docgroup?: string;
  /** Format - character varying. */
  technicalmanagergroupcode?: string;
  /** Format - character varying. */
  technicalmanagergroup?: string;
  /** Format - character varying. */
  shipmanagergroupcode?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetEnginebuildercodesApiResponse =
  /** status 200 OK */
  EnginebuildercodesArray | /** status 206 Partial Content */ string;
export type GetEnginebuildercodesApiArg = {
  /** Format - character varying. */
  enginebuilderlargestcode?: string;
  /** Format - character varying. */
  enginebuilderlargest?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetEnginebuilderdetailsApiResponse =
  /** status 200 OK */
  EnginebuilderdetailsArray | /** status 206 Partial Content */ string;
export type GetEnginebuilderdetailsApiArg = {
  /** Format - character. */
  enginebuilderlargestcode?: string;
  /** Format - character varying. */
  enginebuildershortname?: string;
  /** Format - character varying. */
  enginebuilderfullname?: string;
  /** Format - character varying. */
  fulladdress?: string;
  /** Format - character varying. */
  townname?: string;
  /** Format - character varying. */
  countryname?: string;
  /** Format - character varying. */
  telephone?: string;
  /** Format - character varying. */
  emailaddress?: string;
  /** Format - character varying. */
  website?: string;
  /** Format - character varying. */
  telex?: string;
  /** Format - character varying. */
  countrycode?: string;
  /** Format - character varying. */
  towncode?: string;
  /** Format - character varying. */
  facsimile?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetFlagcodesApiResponse = /** status 200 OK */ FlagcodesArray | /** status 206 Partial Content */ string;
export type GetFlagcodesApiArg = {
  /** Format - character varying. */
  countrynamecode?: string;
  /** Format - character varying. */
  countryname?: string;
  /** Format - character varying. */
  iso2?: string;
  /** Format - character varying. */
  iso3?: string;
  /** Format - character varying. */
  fromdate?: string;
  /** Format - character varying. */
  todate?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetFueltypecodesApiResponse =
  /** status 200 OK */
  FueltypecodesArray | /** status 206 Partial Content */ string;
export type GetFueltypecodesApiArg = {
  /** Format - character varying. */
  code?: string;
  /** Format - character varying. */
  fueltype?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetIceclassApiResponse = /** status 200 OK */ IceclassArray | /** status 206 Partial Content */ string;
export type GetIceclassApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  iceclasscode?: string;
  /** Format - character varying. */
  iceclass?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetMainenginesApiResponse =
  /** status 200 OK */
  MainenginesArray | /** status 206 Partial Content */ string;
export type GetMainenginesApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  position?: string;
  /** Format - character varying. */
  enginetype?: string;
  /** Format - character varying. */
  enginedesigner?: string;
  /** Format - character varying. */
  enginebuilder?: string;
  /** Format - character varying. */
  enginemodel?: string;
  /** Format - smallint. */
  numberofcylinders?: string;
  /** Format - smallint. */
  bore?: string;
  /** Format - smallint. */
  stroke?: string;
  /** Format - character varying. */
  stroketype?: string;
  /** Format - integer. */
  powerbhp?: string;
  /** Format - integer. */
  powerkw?: string;
  /** Format - smallint. */
  rpm?: string;
  /** Format - character varying. */
  enginebuildercode?: string;
  /** Format - character varying. */
  enginemakercode?: string;
  /** Format - character varying. */
  cylinderarrangementcode?: string;
  /** Format - character varying. */
  cylinderarrangementdecode?: string;
  /** Format - integer. */
  bhpofmainoilengines?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetMaingeneratorsApiResponse =
  /** status 200 OK */
  MaingeneratorsArray | /** status 206 Partial Content */ string;
export type GetMaingeneratorsApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  sequencenumber?: string;
  /** Format - character varying. */
  generatorposition?: string;
  /** Format - smallint. */
  number?: string;
  /** Format - integer. */
  kw?: string;
  /** Format - integer. */
  voltage?: string;
  /** Format - character varying. */
  acdcindicator?: string;
  /** Format - smallint. */
  frequency?: string;
  /** Format - character varying. */
  generatormaker?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetNamehistoryApiResponse =
  /** status 200 OK */
  NamehistoryArray | /** status 206 Partial Content */ string;
export type GetNamehistoryApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  sequence?: string;
  /** Format - character varying. */
  vesselname?: string;
  /** Format - character varying. */
  effectiveDate?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetPropellersApiResponse = /** status 200 OK */ PropellersArray | /** status 206 Partial Content */ string;
export type GetPropellersApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  sequence?: string;
  /** Format - character varying. */
  propellerposition?: string;
  /** Format - character varying. */
  propellertype?: string;
  /** Format - character varying. */
  propellertypecode?: string;
  /** Format - smallint. */
  rpmservice?: string;
  /** Format - smallint. */
  rpmmaximum?: string;
  /** Format - character varying. */
  nozzletype?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetPropulsiontypedecodeApiResponse =
  /** status 200 OK */
  PropulsiontypedecodeArray | /** status 206 Partial Content */ string;
export type GetPropulsiontypedecodeApiArg = {
  /** Format - character varying. */
  propulsiontypecode?: string;
  /** Format - character varying. */
  propulsiontype?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetShipdataApiResponse = /** status 200 OK */ ShipdataArray | /** status 206 Partial Content */ string;
export type GetShipdataApiArg = {
  /** Format - character varying. A two letter code used to denote the current Classification Society of a vessel. e.g. 'LR' for Lloyd's Register. */
  classificationsocietycode?: string;
  /** Format - character varying. The organisation that has certified the ship's structural integrity as well as the reliability of its propulsion, steering,  power generation and other auxiliary systems required to maintain essential services on board. These are primarily members or affiliates of the International Association of Classification Societies (IACS) but may also include other Recognized Organizations approved by Flag Administrations. On occasion a vessel may be classed by more than one society simultaneously. */
  classificationsociety?: string;
  /** Format - character varying. The IMO Number based on the LR No is a unique number assigned to each vessel hull. This number is issued by LR Fairplay on behalf of the IMO under IMO Resolution A.600(15) */
  lrimoshipno?: string;
  /** Format - character varying. Vessel Name  in English format, as recorded by the vessels registration authority. To avoid problems with fonts and characters sets the name may use nearest equivalent English character. */
  shipname?: string;
  /** Format - character varying. Current ship status relating to a vessel Under Construction, In Service, Converting, Casualty or demolition status. History table is available and values are given in date order from earliest known status. */
  shipstatus?: string;
  /** Format - character. Code to indicate current ship status */
  shipstatuscode?: string;
  /** Format - character varying. Effective date of current ship status */
  shipstatuseffectivedate?: string;
  /** Format - integer. Deadweight (dwt) - The weight in tonnes (1000 kg) of cargo, stores, fuel, passengers and crew carried by the ship when loaded to her maximum summer Draught. */
  deadweight?: string;
  /** Format - integer. Gross Tonnage (GT) - Gross Tonnage is a function of the moulded volume of all enclosed spaces of the ship as per the 1969 International convention on tonnage measurement of ships. Some older domestic trading vessels may still show pre 1969 GRT values. */
  grosstonnage?: string;
  /** Format - character varying. Year of Build  (DOB)  which in nominally referred to as the year of delivery for new construction vessels. Format is yyyy, this field is a subset of the Date of build. */
  yearofbuild?: string;
  /** Format - character varying. The date when vessel comes in service/commission. */
  deliverydate?: string;
  /** Format - character varying. This is the date recorded for when a vessel is no longer in active use. */
  deathdate?: string;
  /** Format - character. Indicates the flag and the country code for the registry under which the vessel operates.  Vessels may appear in more than one registry (parallel registry) although only one may be active at any one time. */
  flagcode?: string;
  /** Format - character varying. Indicates the flag country for the registry under which the vessel operates. Vessels may appear in more than one registry (parallel registry) although only one may be active at any one time. */
  flagname?: string;
  /** Format - character varying. Name of company on the Document of Compliance (DOC) certificate. The company responsible for implementing the Safety Management System, as required under the ISM Code. */
  doccompany?: string;
  /** Format - character. DOC company code. */
  documentofcompliancedoccompanycode?: string;
  /** Format - character varying. This is the parent company of the Registered Owner. It is the controlling interest behind its fleet and the ultimate beneficiary from the ownership. A Group Beneficial Owner may or may not directly own ships itself as a Registered Owner. It may be the Manager of its fleet, which is in turn owned by subsidiary companies. Its ships may also be managed by a 3rd party under contract. In some circumstances a ship may be owned by a financial organisation who has no operational involvement whatever. In Shipping Circles, the lessee company, which may also sometimes be referred to as the Disponent Owner, can also be the Group Beneficial Owner, Commercial Manager or Commercial Operator of the ship. */
  groupbeneficialowner?: string;
  /** Format - character. The unique seven-digit IMO numerical code assigned to the Group Beneficial Owner */
  groupbeneficialownercompanycode?: string;
  /** Format - character varying. The company responsible for the commercial decisions concerning the employment of a ship and therefore who decides how and where that asset is employed. The direct beneficiary of the profits from the operations of the ship, this company may also be responsible for purchasing decisions on bunkers and port services.  A medium to long-term time or bareboat charterer is considered to be the operator of the ship.  Vessels within commercial pools are considered to be operated by the pool. */
  operator?: string;
  /** Format - character. The unique seven-digit IMO numerical code assigned to the Operator */
  operatorcompanycode?: string;
  /** Format - character varying. The legal title of ownership of the vessel that appears on the ship's registration documents. It may be an Owner/Manager or a wholly-owned subsidiary in a larger shipping group; or a bank or one-ship company vehicle set up by the bank; or of course, it may be a “brass-plate” company created on paper to legally own a ship and possibly to limit liability for the "real" owners and/or benefit from off-shore tax laws. It may anyway be a legal-requirement of the flag-state with whom the ship is registered for the legal owner to be a company registered in that country.  */
  registeredowner?: string;
  /** Format - character. The unique seven-digit IMO numerical code assigned to the Registered Owner */
  registeredownercode?: string;
  /** Format - character varying. The company designated by the ship owner or operator or ship manager to be specifically responsible for the technical operation and technical superintendancy of a ship. This company may also be responsible for purchases regarding the fleet, such as repairs, spares, re-engining, surveys, dry-docking, etc. */
  technicalmanager?: string;
  /** Format - character. Technical Manager Code and decode name of the company designated by the ship owner or operator or ship manager, to be specifically responsible for the technical operation and technical superintendancy of a ship. */
  technicalmanagercode?: string;
  /** Format - character varying. The company designated by the ship owner or charterer to be responsible for the day to day commercial running of the ship and the best contact for the ship regarding commercial matters. Including post fixture responsibilities, such as laytime, demurrage, insurance and charter clauses. This company may be an owner related company, or a third-party manager, whose purpose is primarily the management of ships for their ship-owning clients. In some circumstances a ship may be owned by a financial organisation who has no operational involvement whatever. The lessee company, or one of its subsidiary companies, may be deemed to be the commercial manager of the ship. */
  shipmanager?: string;
  /** Format - character. The unique seven-digit IMO numerical code assigned to the Ship Manager. */
  shipmanagercompanycode?: string;
  /** Format - character varying. Shiptype Group describes the general ship type in terms of its commercial operation. Eg Panamax or handymax carriers etc */
  shiptypegroup?: string;
  /** Format - character varying. This decoded field is based on the Hierarchical StatCode5 Ship Type. Level 2 is the broad type category such as Tanker or Bulk Carrier. */
  shiptypelevel2?: string;
  /** Format - character varying. This decoded field is based on the Hierarchical StatCode5 Ship Type. Level 3 is the general design type or cargo group such as Liquid gas or Bulk Dry. */
  shiptypelevel3?: string;
  /** Format - character varying. This decoded field is based on the Hierarchical StatCode5 Ship Type. Level 4 is the general design type category such as LNG Tanker or Bulk Carrier. */
  shiptypelevel4?: string;
  /** Format - character varying. This decoded field is based on the Hierarchical StatCode5 Ship Type. Level 5 is the full design type definition. */
  shiptypelevel5?: string;
  /** Format - character varying. Part of the shiptype descriptive system 'Stat5'. Examples include 'Ship Shape Including Multi-Hulls', 'Ship Shape Semi-Sub'. */
  shiptypelevel5Hulltype?: string;
  /** Format - character varying. Part of the shiptype descriptive system 'Stat5'. The subgroup indicates detail such as  'Dry Bulk Cargo', 'Petroleum Products'.  */
  shiptypelevel5Subgroup?: string;
  /** Format - character varying. Part of the shiptype descriptive system 'Stat5'. Detailed Level 5 subtype code such as for example,  'LC' (Co2 Tanker), 'LP' (Molten Sulphur Tanker). */
  shiptypelevel5Subtype?: string;
  /** Format - character varying. This is the 7 character alphanumeric code which has been adopted as ship type standard. Based on the Previous Statcode4, this extends to a further level of detail to describe a vessels design. */
  statcode5?: string;
  /** Format - timestamp without time zone. Date and time when the record was last updated in the IHS database. The timestamp can also be related to changes in fields which are not part of the subscription. */
  lastupdatedate?: string;
  /** Format - character varying. The Shipbuilder that is constructing the ship or has constructed her in the past. The builder will be a shipbuilder within a large building group such as Hyundai H.I. or a singular (yard) shipbuilding company. Use along with the DOB, Yard No. and status. */
  shipbuilder?: string;
  /** Format - character varying. Yard Number or Hull Number assigned to a vessel during its construction. */
  yardnumber?: string;
  /** Format - character varying. Shipbuilder Sub Contractor. Often contracted for specialist fitting out work. */
  shipbuildersubcontractor?: string;
  /** Format - character varying. Yard or hull number as issued by as Sub Contracted Shipyard where appropriate. */
  shipbuildersubcontractorshipyardyardhullno?: string;
  /** Format - character varying. Free text Narrative describing each auxiliary generator (not prime mover plant) stating number, voltage power and frequency of each set. */
  auxiliarygeneratorsdescriptivenarrative?: string;
  /** Format - character varying. A Free text narrative describing each of the Auxiliary engines (Non Prime Movers). Detailing Builder, make, type, designation, Bore Stroke, Power, Cycle type. */
  auxiliaryenginesnarrative?: string;
  /** Format - character varying. Engine maker for prime mover, i.e. company who manufactured the engine which may have been under licence, this is not necessarily the engine designer.  */
  mainenginebuilder?: string;
  /** Format - character varying. Engine Maker code for prime mover, i.e. company who constructed the engine which may have been under licence. This is not necessarily the engine designer. The first 3 digits of the code are the country code. */
  mainenginebuildercode?: string;
  /** Format - character varying. The original designer of the engine.  */
  mainenginedesigner?: string;
  /** Format - character varying. Engine Designer Code  The original designer of the engine. This is a three character alpha code. */
  mainenginedesignercode?: string;
  /** Format - character varying. Engine Model Designation as issued by the designer. It is often formed in a way to indicate number of cylinders and bore size. */
  mainenginemodel?: string;
  /** Format - integer. Number of cylinders in each prime mover engine. */
  mainenginenumberofcylinders?: string;
  /** Format - smallint. Machinery - Prime Mover Max. Power. Maximum RPM.                              */
  mainenginerpm?: string;
  /** Format - character varying. Stroke type for prime mover IE 2 or 4 stroke.  It is the number of movements a piston makes to complete a full ignition cycle. */
  mainenginestroketype?: string;
  /** Format - character varying. Engine Type Indicator IE 01 = Diesel, 02 = Gas Turbine, 03 = Steam Turbine, 04 = Steam Reciprocating. */
  mainenginetype?: string;
  /** Format - integer. Total number of both prime movers an all auxiliary engines, where the auxiliary engine is not a prime mover but is used to drive electrical Generators. */
  numberofallengines?: string;
  /** Format - integer. Total number of auxiliary engines, where the engine is not a prime mover but is used to drive electrical Generators. */
  numberofauxiliaryengines?: string;
  /** Format - integer. Total number of diesel generators on board. Does not include portable/emergency generators. */
  numberofgenerators?: string;
  /** Format - integer. Number of Main Engines. This applies to Prime Movers only. */
  numberofmainengines?: string;
  /** Format - integer. Number of propulsion units. */
  numberofpropulsionunits?: string;
  /** Format - integer. Number of thrusters on the vessel usually at bow or stern, or for dynamic positioning excluding any main propulsion thrusters.  */
  numberofthrusters?: string;
  /** Format - integer. Prime mover maximum continuous rating power. For Diesels shown as BHP, Steam Turbine SHP and Steam Reciprocal IHP. */
  powerbhpihpshpmax?: string;
  /** Format - integer. Prime mover service speed power. For Diesels shown as BHP, Steam Turbine SHP and Steam Reciprocal IHP. This is normally determined as the continuous service rating (CSR) about 85% of MCR. */
  powerbhpihpshpservice?: string;
  /** Format - integer. Prime mover maximum continuous rating power, usually the maximum catalogue power recorded in KW. */
  powerkwmax?: string;
  /** Format - integer. Prime mover service speed power, This is normally determined as the continuous service rating (CSR) about 85% of MCR. */
  powerkwservice?: string;
  /** Format - character varying. Free text narrative describing the Prime Mover detail. This shows the number, make, builder, model, type, bore, stroke cycle, and power of each prime mover unit. */
  primemoverdescriptivenarrative?: string;
  /** Format - character varying. Free text narrative describing an overview of the Prime Mover equipment. This details the Number of prime movers, the type, its gearing and transmission, and total power as MCR - Maximum continuous rating and CSR continuous service rating. */
  primemoverdescriptiveoverviewnarrative?: string;
  /** Format - character varying. Type of propeller or primary propulsion unit used to propel the vessel.  */
  propellertype?: string;
  /** Format - character varying. Indicates the type of power configuration of prime mover and the drive connection. */
  propulsiontype?: string;
  /** Format - character varying. Code of between 2 and 4 alpha characters decoded as below denoting type of power configuration of prime mover and drive connection. */
  propulsiontypecode?: string;
  /** Format - character varying. Free text narrative describing the vessels Thrusters. This details the position, type, propeller type, and thrust power of each unit. */
  thrustersdescriptivenarrative?: string;
  /** Format - integer. Total Power of Auxiliary Generators recorded in brake horsepower. */
  totalhorsepowerofauxiliarygenerators?: string;
  /** Format - integer. Total Horsepower of Main propulsion engines only, this does not include auxiliary engines. */
  totalhorsepowerofmainengines?: string;
  /** Format - integer. Total Power or Main Generators recorded in brake horsepower. */
  totalhorsepowerofmaingenerators?: string;
  /** Format - integer. Total vessel's power in KW produced by all the prime movers.  */
  totalkilowattsofmainengines?: string;
  /** Format - integer. Total power in Kilowatts of both prime movers an all auxiliary engines, where the auxiliary engine is not a prime mover but is used to drive electrical Generators. */
  totalpowerofallengines?: string;
  /** Format - character varying. Free text Narrative describing classification. May comprise more than one class. Denotes class society and classing date, survey dates notation class limitations. */
  classnarrative?: string;
  /** Format - numeric. First set of vessel speed at which main engine fuel consumption is recorded.  */
  consumptionspeed1?: string;
  /** Format - numeric. Second set of vessel speed at which main engine fuel consumption is recorded, if available. */
  consumptionspeed2?: string;
  /** Format - numeric. First set of main engine fuel consumption figure expressed in tonnes per day.  */
  consumptionvalue1?: string;
  /** Format - numeric. Second set of main engine fuel consumption figure expressed in tonnes per day, if available.  */
  consumptionvalue2?: string;
  /** Format - character varying. The date when vessel is a confirmed order. */
  contractdate?: string;
  /** Format - character. Date of Build  (DOB)  which in nominally referred to as the actual or expected date of delivery for vessels after construction. Format is yyyymm. */
  dateofbuild?: string;
  /** Format - character varying. The previous name of the vessel if applicable. This will only display the most recent in the case of several ex names. This is normally displayed in upper and lower case. */
  exname?: string;
  /** Format - numeric. Fuel consumption from Main Engines in tonnes per day running at continuous Service Rating (CSR). */
  fuelconsumptionmainenginesonly?: string;
  /** Format - numeric. Fuel consumption from Main Engines and normal Auxiliary machinery in tonnes per day running at continuous Service Rating (CSR). */
  fuelconsumptiontotal?: string;
  /** Format - numeric. Fuel Capacity in cubic metres of fuel as defined in Fuel Type 1. This will be linked with Fuel type 1. See Fuel Types */
  fueltype1Capacity?: string;
  /** Format - character varying. This code defines the type of fuel and should appear as the lightest fuel in Fuel Type1 and second lightest in fuel type 2. */
  fueltype1Code?: string;
  /** Format - character varying. This code defines the type of fuel and should appear as the lightest fuel in Fuel Type1 and second lightest in fuel type 2. */
  fueltype1First?: string;
  /** Format - numeric. Fuel Capacity in cubic metres of fuel as defined in Fuel Type 2. This will be linked with Fuel type 2. See Fuel Types. */
  fueltype2Capacity?: string;
  /** Format - character varying. A code to indicate the second lightest type of fuel onboard mainly residual oil. */
  fueltype2Code?: string;
  /** Format - character varying. This code defines the type of fuel and should appear as the lightest fuel in Fuel Type1 and second lightest in fuel type 2. */
  fueltype2Second?: string;
  /** Format - character varying. free text narrative describing cargo lifting gear or cargo Pumps. Details the Number, type and Safe working load (SWL) of cargo lifting gear, or in the case of liquid cargoes, the number and pumping rate of cargo pumps. */
  geardescriptivenarrative?: string;
  /** Format - integer. No of the largest sets of gear on the ship e.g. Numbers of derricks */
  gearnolargest?: string;
  /** Format - numeric. The Safe Working Load (SWL) in tonnes of the largest cargo handling gear (lifting ability) on the ship. The gear can be a crane, derrick, A frame, gantry etc. */
  gearswllargest?: string;
  /** Format - character varying. Type of largest gear which could for example, be a gantry crane, A-frame or derrick. */
  geartypelargest?: string;
  /** Format - character varying. Indicates when a dry cargo vessel is gearless, i.e. with no equipment onboard for cargo loading/unloading. */
  gearless?: string;
  /** Format - character varying. Free text narrative describing Ice Capability. This details the type of design restrictions placed on a vessel for operating in Ice bound waters. */
  icecapabilitydescriptivenarrative?: string;
  /** Format - character varying. Vessel constructed for carriage of IMO Chemical Class I cargoes. These are deemed to be the most hazardous of types I, II and III. No individual tank should hold more  than 1250 m3. */
  imochemicalclassi?: string;
  /** Format - character varying. Vessel constructed for carriage of IMO Chemical Class II cargoes. These are deemed to be the second most hazardous of types I, II and III, ( I being the most hazardous) No individual tank should hold more  than 3000 m3. */
  imochemicalclassii?: string;
  /** Format - character varying. Vessel constructed for carriage of IMO Chemical Class III cargoes. These are deemed to be the least hazardous of types I, II and III,  (I being the most hazardous)  There are no filling restrictions for these cargoes. */
  imochemicalclassiii?: string;
  /** Format - character. Lead standard design or sister ship of a particular design stored as an LR No. */
  leadshipinseriesbyimonumber?: string;
  /** Format - character varying. Mobile Maritime Station Identifier (MMSI) is a 9 digit number used to identify vessels in VHF radio communications. The first three digits denote the country of registry. when a flag change is effected this number will also change. */
  maritimemobileserviceidentitymmsinumber?: string;
  /** Format - character varying. Date Vessel details first entered on database when effectively issued with an IMO No. */
  newconstructionentrydate?: string;
  /** Format - character varying. Number of Non-Continuous Decks. */
  numberofdecks?: string;
  /** Format - integer. This is the number of cargo hatch openings, including wing and centre tanks. It does not include stores accesses or small hatches in fishing vessels. */
  numberofhatches?: string;
  /** Format - integer. This is the number of cargo holds openings, including wing and centre holds. */
  numberofholds?: string;
  /** Format - integer. Total number of available integral cargo carrying tanks */
  numberoftanks?: string;
  /** Format - character varying. The Port of registry at which the vessel is registered with a flag authority and which appears on the stern of the vessel under the ship name. */
  portofregistry?: string;
  /** Format - character varying. First or only Shipbuilder code. This code comprises a three alpha country code followed by a 3 digit group code then a three digit yard code. In the case of ships being built at more than one yard the additional yards will not be included. */
  shipbuildercompanycode?: string;
  /** Format - character varying. Shipbuilder Sub Contractor Code. This is a 9 digit alpha numeric field, characters 1-3 define the country of the builder, characters 4-6 the Building group and characters 7-9 the shipyard. */
  shipbuildersubcontractorcode?: string;
  /** Format - numeric. Maximum vessel Speed in knots when the ships engine is running at Maximum continuous rating (MCR). */
  speed?: string;
  /** Format - numeric. Maximum vessel speed in knots when the ships engine is running at MCR. */
  speedmax?: string;
  /** Format - numeric. Speed in knots recorded under normal service conditions. */
  speedservice?: string;
  /** Format - character varying. Vessel Standard Design Series Name. The generally appointed name for a design of ship when used for multiple deliveries of the same design. */
  standardshipdesign?: string;
  /** Format - integer. TEU (20 Foot Equivalent Unit) Capacity. This is recognised as the standard Container unit. */
  teu?: string;
  /** Format - integer. TEU (20 Foot Equivalent Unit) Capacity based  on an average weight of 14 tonnes of homogenous cargo. */
  teucapacity14Thomogenous?: string;
  /** Format - integer. Total power in Kilowatts of auxiliary engines, where the engine is not a prime mover but is used to drive electrical Generators. */
  totalpowerofauxiliaryengines?: string;
  /** Format - character varying. Indicates the vessel has a  Bulbous Bow. */
  bulbousbow?: string;
  /** Format - character varying. Free text Narrative describing Bunker fuels and consumptions. Defining the two most significant fuel types and capacities, if heating coils are used and fuel consumption rates in tpd. */
  bunkersdescriptivenarrative?: string;
  /** Format - character varying. Callsign. An Alpha numeric identifier used for radio communications. Each flag authority has a designated call sign range, from which the national radio authority issue a call sign for each vessel in that fleet. */
  callsign?: string;
  /** Format - character varying. The date when vessel order was cancelled before any construction. */
  canceldate?: string;
  /** Format - integer. Compensated gross tonnage (cgt) is a statistical tool providing a standardised measurement of the relative output of merchant shipbuilding activity in large aggregates such as 'World' or 'Regions'. */
  compensatedgrosstonnagecgt?: string;
  /** Format - character varying. Country of Build  based on the first or only builder. */
  countryofbuild?: string;
  /** Format - character varying. Country of Build Code  based on first or only builder. */
  countryofbuildcode?: string;
  /** Format - character varying. Defines the type of hull structure IE includes Double bottoms and/or double side, Full or partial and Marpol 13F etc. */
  hulltype?: string;
  /** Format - character varying. A code to indicate the type of hull structure such as double hull, single hull, double bottoms. */
  hulltypecode?: string;
  /** Format - numeric. The distance in meters, parallel to the centre line between  the extreme points at the bow and stern of a vessel outside of the main hull in metres. If the vessel has a bulbous bow, this is also included in this measurement. */
  lengthoverallloa?: string;
  /** Format - character varying. */
  propellermanufacturer?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetShiptypecodesApiResponse =
  /** status 200 OK */
  ShiptypecodesArray | /** status 206 Partial Content */ string;
export type GetShiptypecodesApiArg = {
  /** Format - character varying. */
  statcode5?: string;
  /** Format - character varying. */
  shiptypelevel5?: string;
  /** Format - character varying. */
  level4Code?: string;
  /** Format - character varying. */
  shiptypelevel4?: string;
  /** Format - character varying. */
  level3Code?: string;
  /** Format - character varying. */
  shiptypelevel3?: string;
  /** Format - character varying. */
  level2Code?: string;
  /** Format - character varying. */
  shiptypelevel2?: string;
  /** Format - character varying. */
  shiptypelevel1Code?: string;
  /** Format - character varying. */
  shiptypelevel1?: string;
  /** Format - character varying. */
  hulltype?: string;
  /** Format - character varying. */
  subgroup?: string;
  /** Format - character varying. */
  subtype?: string;
  /** Format - integer. */
  hulltypecode?: string;
  /** Format - character. */
  subgroupcode?: string;
  /** Format - character. */
  subtypecode?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetSpecialfeaturesApiResponse =
  /** status 200 OK */
  SpecialfeaturesArray | /** status 206 Partial Content */ string;
export type GetSpecialfeaturesApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  sequence?: string;
  /** Format - character varying. */
  specialfeature?: string;
  /** Format - character varying. */
  specialfeaturecode?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetStatuscodesApiResponse =
  /** status 200 OK */
  StatuscodesArray | /** status 206 Partial Content */ string;
export type GetStatuscodesApiArg = {
  /** Format - character. */
  statuscode?: string;
  /** Format - character varying. */
  status?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetStatushistoryApiResponse =
  /** status 200 OK */
  StatushistoryArray | /** status 206 Partial Content */ string;
export type GetStatushistoryApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  status?: string;
  /** Format - character varying. */
  sequence?: string;
  /** Format - character varying. */
  statusdate?: string;
  /** Format - character varying. */
  statuscode?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetSurveydatehistoryApiResponse =
  /** status 200 OK */
  SurveydatehistoryArray | /** status 206 Partial Content */ string;
export type GetSurveydatehistoryApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  classsocietycode?: string;
  /** Format - character varying. */
  specialsurvey?: string;
  /** Format - character varying. */
  continuoussurvey?: string;
  /** Format - character varying. */
  dockingsurvey?: string;
  /** Format - character varying. */
  tailshaftsurvey?: string;
  /** Format - character varying. */
  classsociety?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetSurveydatesApiResponse =
  /** status 200 OK */
  SurveydatesArray | /** status 206 Partial Content */ string;
export type GetSurveydatesApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  classsocietycode?: string;
  /** Format - character varying. */
  specialsurvey?: string;
  /** Format - character varying. */
  specialsurveylakes?: string;
  /** Format - character varying. */
  continuoushullsurvey?: string;
  /** Format - character varying. */
  continuousmachinerysurvey?: string;
  /** Format - character varying. */
  tailshaftsurvey?: string;
  /** Format - character varying. */
  dockingsurvey?: string;
  /** Format - character varying. */
  annualsurvey?: string;
  /** Format - character varying. */
  classsociety?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetThrustersApiResponse = /** status 200 OK */ ThrustersArray | /** status 206 Partial Content */ string;
export type GetThrustersApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - character varying. */
  sequence?: string;
  /** Format - character varying. */
  thrustertype?: string;
  /** Format - character varying. */
  thrustertypecode?: string;
  /** Format - smallint. */
  numberofthrusters?: string;
  /** Format - character varying. */
  thrusterposition?: string;
  /** Format - integer. */
  thrusterbhp?: string;
  /** Format - integer. */
  thrusterkw?: string;
  /** Format - character varying. */
  typeofinstallation?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetTurbochargersApiResponse =
  /** status 200 OK */
  TurbochargersArray | /** status 206 Partial Content */ string;
export type GetTurbochargersApiArg = {
  /** Format - character varying. */
  lrno?: string;
  /** Format - integer. */
  sequencenumber?: string;
  /** Format - character varying. */
  builder?: string;
  /** Format - character varying. */
  countryofbuild?: string;
  /** Format - character varying. */
  design?: string;
  /** Format - character varying. */
  designation?: string;
  /** Format - character varying. */
  model?: string;
  /** Format - character varying. */
  mainauxeng?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetPropellermanufacturerApiResponse =
  /** status 200 OK */
  PropellermanufacturerArray | /** status 206 Partial Content */ string;
export type GetPropellermanufacturerApiArg = {
  /** Format - character varying. */
  imo?: string;
  /** Format - character varying. */
  propellermanufacturer?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetVesselCompaniesApiResponse =
  /** status 200 OK */
  VesselCompaniesArray | /** status 206 Partial Content */ string;
export type GetVesselCompaniesApiArg = {
  /** Format - character varying. */
  lrimoshipno?: string;
  /** Format - text. */
  roleCode?: string;
  /** Format - text. */
  roleName?: string;
  /** Format - character varying. */
  countryCode?: string;
  /** Format - character varying. */
  owcode?: string;
  /** Format - character varying. */
  shortcompanyname?: string;
  /** Format - character varying. */
  countryname?: string;
  /** Format - character varying. */
  townname?: string;
  /** Format - character varying. */
  telephone?: string;
  /** Format - character varying. */
  telex?: string;
  /** Format - character varying. */
  emailaddress?: string;
  /** Format - character varying. */
  website?: string;
  /** Format - character varying. */
  careofcode?: string;
  /** Format - character varying. */
  roomfloorbuilding1?: string;
  /** Format - character varying. */
  roomfloorbuilding2?: string;
  /** Format - character varying. */
  roomfloorbuilding3?: string;
  /** Format - character varying. */
  pobox?: string;
  /** Format - character varying. */
  streetnumber?: string;
  /** Format - character varying. */
  street?: string;
  /** Format - character varying. */
  prepostcode?: string;
  /** Format - character varying. */
  postpostcode?: string;
  /** Format - character varying. */
  lastchangedate?: string;
  /** Format - character varying. */
  parentcompany?: string;
  /** Format - character varying. */
  nationalityofregistration?: string;
  /** Format - character varying. */
  nationalityofcontrol?: string;
  /** Format - character varying. */
  companystatus?: string;
  /** Format - character varying. */
  fullcompanyname?: string;
  /** Format - character varying. */
  fulladdress?: string;
  /** Format - character varying. */
  facsimile?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type ImoSearchApiResponse = /** status 200 Successful Response */ RpcImoSearchGet200ApplicationJsonResponse;
export type ImoSearchApiArg = {
  /** search query */
  query: string;
};
export type VesselSearchApiResponse =
  /** status 200 Successful Response */ RpcVesselSearchGet200ApplicationJsonResponse;
export type VesselSearchApiArg = {
  /** search query */
  query: string;
};
export type Auxengines = {
  lrno: string;
  enginesequence: string;
  enginebuilder?: string;
  enginedesigner?: string;
  enginemodel?: string;
  numberofcylinders?: string;
  bore?: string;
  stroke?: string;
  stroketype?: string;
  maxpower?: string;
};
export type AuxenginesArray = Auxengines[];
export type Auxiliarygenerators = {
  lrno: string;
  number?: string;
  kweach?: number;
  voltage1?: string;
  voltage2?: string;
  frequency?: string;
  acdc?: string;
  mainenginedriven?: string;
  seq: string;
};
export type AuxiliarygeneratorsArray = Auxiliarygenerators[];
export type Boilersexpanded = {
  lrno: string;
  sequence: number;
  builder?: string;
  design?: string;
  effectivedate?: string;
  numberofboilers?: string;
  boilertypecode?: string;
  boilertypedecode?: string;
  firingtypecode?: string;
  firingtypedecode?: string;
};
export type BoilersexpandedArray = Boilersexpanded[];
export type Builderandsubcontractordetails = {
  buildercode: string;
  shipbuilderfullstyle?: string;
  shipbuilder?: string;
  fulladdress?: string;
  countryname?: string;
  contact?: string;
  emailaddress?: string;
  telephone?: string;
  telex?: string;
  website?: string;
  countrycode?: string;
  towncode?: string;
  builderstatus?: string;
  facsimile?: string;
};
export type BuilderandsubcontractordetailsArray = Builderandsubcontractordetails[];
export type Builderandsubcontractorlinkfile = {
  lrno: string;
  sequencenumber: string;
  buildercode?: string;
  section?: string;
};
export type BuilderandsubcontractorlinkfileArray = Builderandsubcontractorlinkfile[];
export type Capacities = {
  lrno: string;
  bale?: number;
  horsepower?: number;
  numberofbarges?: number;
  bollardpull?: number;
  numberofcars?: number;
  gascapacity?: number;
  graincapacity?: number;
  indicatedhorsepower?: number;
  liquidcapacity?: number;
  numberofpassengers?: string;
  numberrefrigeratedcontainers?: string;
  numberofteu?: number;
  numberoftrucks?: number;
  numberofvehicles?: number;
  numberofrailwagons?: number;
};
export type CapacitiesArray = Capacities[];
export type Classcodes = {
  classcode: string;
  class?: string;
};
export type ClasscodesArray = Classcodes[];
export type Companyfleetcounts = {
  owcode: string;
  shortcompanyname?: string;
  registeredownercount?: number;
  shipmanagercount?: number;
  operatorcount?: number;
  groupownercount?: number;
  doccount?: number;
  fleetsize?: number;
  inservicecount?: number;
};
export type CompanyfleetcountsArray = Companyfleetcounts[];
export type Companyfulldetailsandparentcode = {
  owcode: string;
  shortcompanyname?: string;
  countryname?: string;
  townname?: string;
  telephone?: string;
  telex?: string;
  emailaddress?: string;
  website?: string;
  careofcode?: string;
  roomfloorbuilding1?: string;
  roomfloorbuilding2?: string;
  roomfloorbuilding3?: string;
  pobox?: string;
  streetnumber?: string;
  street?: string;
  prepostcode?: string;
  postpostcode?: string;
  lastchangedate?: string;
  parentcompany?: string;
  nationalityofregistration?: string;
  nationalityofcontrol?: string;
  companystatus?: string;
  fullcompanyname?: string;
  fulladdress?: string;
  facsimile?: string;
};
export type CompanyfulldetailsandparentcodeArray = Companyfulldetailsandparentcode[];
export type Companyvesselrelationships = {
  lrno: string;
  registeredownercode?: string;
  registeredowner?: string;
  shipmanagercode?: string;
  shipmanager?: string;
  operatorcode?: string;
  operator?: string;
  groupbeneficialownercode?: string;
  groupbeneficialowner?: string;
  doccode?: string;
  doccompany?: string;
  technicalmanagercode?: string;
  technicalmanager?: string;
  operatorgroupcode?: string;
  operatorgroup?: string;
  shipmanagergroup?: string;
  docgroupcode?: string;
  docgroup?: string;
  technicalmanagergroupcode?: string;
  technicalmanagergroup?: string;
  shipmanagergroupcode?: string;
};
export type CompanyvesselrelationshipsArray = Companyvesselrelationships[];
export type Enginebuildercodes = {
  enginebuilderlargestcode: string;
  enginebuilderlargest?: string;
};
export type EnginebuildercodesArray = Enginebuildercodes[];
export type Enginebuilderdetails = {
  enginebuilderlargestcode: string;
  enginebuildershortname?: string;
  enginebuilderfullname?: string;
  fulladdress?: string;
  townname?: string;
  countryname?: string;
  telephone?: string;
  emailaddress?: string;
  website?: string;
  telex?: string;
  countrycode?: string;
  towncode?: string;
  facsimile?: string;
};
export type EnginebuilderdetailsArray = Enginebuilderdetails[];
export type Flagcodes = {
  countrynamecode: string;
  countryname?: string;
  iso2?: string;
  iso3?: string;
  fromdate?: string;
  todate?: string;
};
export type FlagcodesArray = Flagcodes[];
export type Fueltypecodes = {
  code: string;
  fueltype?: string;
};
export type FueltypecodesArray = Fueltypecodes[];
export type Iceclass = {
  lrno: string;
  iceclasscode: string;
  iceclass?: string;
};
export type IceclassArray = Iceclass[];
export type Mainengines = {
  lrno: string;
  position: string;
  enginetype?: string;
  enginedesigner?: string;
  enginebuilder?: string;
  enginemodel?: string;
  numberofcylinders?: string;
  bore?: string;
  stroke?: string;
  stroketype?: string;
  powerbhp?: number;
  powerkw?: number;
  rpm?: string;
  enginebuildercode?: string;
  enginemakercode?: string;
  cylinderarrangementcode?: string;
  cylinderarrangementdecode?: string;
  bhpofmainoilengines?: number;
};
export type MainenginesArray = Mainengines[];
export type Maingenerators = {
  lrno?: string;
  sequencenumber?: string;
  generatorposition?: string;
  number?: string;
  kw?: number;
  voltage?: number;
  acdcindicator?: string;
  frequency?: string;
  generatormaker?: string;
};
export type MaingeneratorsArray = Maingenerators[];
export type Namehistory = {
  lrno: string;
  sequence: string;
  vesselname?: string;
  effective_date?: string;
};
export type NamehistoryArray = Namehistory[];
export type Propellers = {
  lrno: string;
  sequence: string;
  propellerposition?: string;
  propellertype?: string;
  propellertypecode?: string;
  rpmservice?: string;
  rpmmaximum?: string;
  nozzletype?: string;
};
export type PropellersArray = Propellers[];
export type Propulsiontypedecode = {
  propulsiontypecode: string;
  propulsiontype?: string;
};
export type PropulsiontypedecodeArray = Propulsiontypedecode[];
export type Shipdata = {
  classificationsocietycode?: string;
  classificationsociety?: string;
  lrimoshipno: string;
  shipname?: string;
  shipstatus?: string;
  shipstatuscode?: string;
  shipstatuseffectivedate?: string;
  deadweight?: number;
  grosstonnage?: number;
  yearofbuild?: string;
  deliverydate?: string;
  deathdate?: string;
  flagcode?: string;
  flagname?: string;
  doccompany?: string;
  documentofcompliancedoccompanycode?: string;
  groupbeneficialowner?: string;
  groupbeneficialownercompanycode?: string;
  operator?: string;
  operatorcompanycode?: string;
  registeredowner?: string;
  registeredownercode?: string;
  technicalmanager?: string;
  technicalmanagercode?: string;
  shipmanager?: string;
  shipmanagercompanycode?: string;
  shiptypegroup?: string;
  shiptypelevel2?: string;
  shiptypelevel3?: string;
  shiptypelevel4?: string;
  shiptypelevel5?: string;
  shiptypelevel5hulltype?: string;
  shiptypelevel5subgroup?: string;
  shiptypelevel5subtype?: string;
  statcode5?: string;
  lastupdatedate?: string;
  shipbuilder?: string;
  yardnumber?: string;
  shipbuildersubcontractor?: string;
  shipbuildersubcontractorshipyardyardhullno?: string;
  auxiliarygeneratorsdescriptivenarrative?: string;
  auxiliaryenginesnarrative?: string;
  mainenginebuilder?: string;
  mainenginebuildercode?: string;
  mainenginedesigner?: string;
  mainenginedesignercode?: string;
  mainenginemodel?: string;
  mainenginenumberofcylinders?: number;
  mainenginerpm?: string;
  mainenginestroketype?: string;
  mainenginetype?: string;
  numberofallengines?: number;
  numberofauxiliaryengines?: number;
  numberofgenerators?: number;
  numberofmainengines?: number;
  numberofpropulsionunits?: number;
  numberofthrusters?: number;
  powerbhpihpshpmax?: number;
  powerbhpihpshpservice?: number;
  powerkwmax?: number;
  powerkwservice?: number;
  primemoverdescriptivenarrative?: string;
  primemoverdescriptiveoverviewnarrative?: string;
  propellertype?: string;
  propulsiontype?: string;
  propulsiontypecode?: string;
  thrustersdescriptivenarrative?: string;
  totalhorsepowerofauxiliarygenerators?: number;
  totalhorsepowerofmainengines?: number;
  totalhorsepowerofmaingenerators?: number;
  totalkilowattsofmainengines?: number;
  totalpowerofallengines?: number;
  classnarrative?: string;
  consumptionspeed1?: number;
  consumptionspeed2?: number;
  consumptionvalue1?: number;
  consumptionvalue2?: number;
  contractdate?: string;
  dateofbuild?: string;
  exname?: string;
  fuelconsumptionmainenginesonly?: number;
  fuelconsumptiontotal?: number;
  fueltype1capacity?: number;
  fueltype1code?: string;
  fueltype1first?: string;
  fueltype2capacity?: number;
  fueltype2code?: string;
  fueltype2second?: string;
  geardescriptivenarrative?: string;
  gearnolargest?: number;
  gearswllargest?: number;
  geartypelargest?: string;
  gearless?: string;
  icecapabilitydescriptivenarrative?: string;
  imochemicalclassi?: string;
  imochemicalclassii?: string;
  imochemicalclassiii?: string;
  leadshipinseriesbyimonumber?: string;
  maritimemobileserviceidentitymmsinumber?: string;
  newconstructionentrydate?: string;
  numberofdecks?: string;
  numberofhatches?: number;
  numberofholds?: number;
  numberoftanks?: number;
  portofregistry?: string;
  shipbuildercompanycode?: string;
  shipbuildersubcontractorcode?: string;
  speed?: number;
  speedmax?: number;
  speedservice?: number;
  standardshipdesign?: string;
  teu?: number;
  teucapacity14thomogenous?: number;
  totalpowerofauxiliaryengines?: number;
  bulbousbow?: string;
  bunkersdescriptivenarrative?: string;
  callsign?: string;
  canceldate?: string;
  compensatedgrosstonnagecgt?: number;
  countryofbuild?: string;
  countryofbuildcode?: string;
  hulltype?: string;
  hulltypecode?: string;
  lengthoverallloa?: number;
  propellermanufacturer?: string;
};
export type ShipdataArray = Shipdata[];
export type Shiptypecodes = {
  statcode5: string;
  shiptypelevel5?: string;
  level4code?: string;
  shiptypelevel4?: string;
  level3code?: string;
  shiptypelevel3?: string;
  level2code?: string;
  shiptypelevel2?: string;
  shiptypelevel1code?: string;
  shiptypelevel1?: string;
  hulltype?: string;
  subgroup?: string;
  subtype?: string;
  hulltypecode?: number;
  subgroupcode?: string;
  subtypecode?: string;
};
export type ShiptypecodesArray = Shiptypecodes[];
export type Specialfeatures = {
  lrno: string;
  sequence: string;
  specialfeature?: string;
  specialfeaturecode?: string;
};
export type SpecialfeaturesArray = Specialfeatures[];
export type Statuscodes = {
  statuscode: string;
  status?: string;
};
export type StatuscodesArray = Statuscodes[];
export type Statushistory = {
  lrno: string;
  status?: string;
  sequence: string;
  statusdate?: string;
  statuscode?: string;
};
export type StatushistoryArray = Statushistory[];
export type Surveydatehistory = {
  lrno?: string;
  classsocietycode?: string;
  specialsurvey?: string;
  continuoussurvey?: string;
  dockingsurvey?: string;
  tailshaftsurvey?: string;
  classsociety?: string;
};
export type SurveydatehistoryArray = Surveydatehistory[];
export type Surveydates = {
  lrno: string;
  classsocietycode: string;
  specialsurvey?: string;
  specialsurveylakes?: string;
  continuoushullsurvey?: string;
  continuousmachinerysurvey?: string;
  tailshaftsurvey?: string;
  dockingsurvey?: string;
  annualsurvey?: string;
  classsociety?: string;
};
export type SurveydatesArray = Surveydates[];
export type Thrusters = {
  lrno: string;
  sequence: string;
  thrustertype?: string;
  thrustertypecode?: string;
  numberofthrusters?: string;
  thrusterposition?: string;
  thrusterbhp?: number;
  thrusterkw?: number;
  typeofinstallation?: string;
};
export type ThrustersArray = Thrusters[];
export type Turbochargers = {
  lrno: string;
  sequencenumber: number;
  builder?: string;
  countryofbuild?: string;
  design?: string;
  designation?: string;
  model?: string;
  mainauxeng?: string;
};
export type TurbochargersArray = Turbochargers[];
export type Propellermanufacturer = {
  imo: string;
  propellermanufacturer?: string;
};
export type PropellermanufacturerArray = Propellermanufacturer[];
export type VesselCompanies = {
  lrimoshipno?: string;
  role_code?: string;
  role_name?: string;
  country_code?: string;
  owcode?: string;
  shortcompanyname?: string;
  countryname?: string;
  townname?: string;
  telephone?: string;
  telex?: string;
  emailaddress?: string;
  website?: string;
  careofcode?: string;
  roomfloorbuilding1?: string;
  roomfloorbuilding2?: string;
  roomfloorbuilding3?: string;
  pobox?: string;
  streetnumber?: string;
  street?: string;
  prepostcode?: string;
  postpostcode?: string;
  lastchangedate?: string;
  parentcompany?: string;
  nationalityofregistration?: string;
  nationalityofcontrol?: string;
  companystatus?: string;
  fullcompanyname?: string;
  fulladdress?: string;
  facsimile?: string;
};
export type VesselCompaniesArray = VesselCompanies[];
export type RpcImoSearchGet200ApplicationJsonResponse = {
  imo_number?: number;
  name?: string;
}[];
export type RpcVesselSearchGet200ApplicationJsonResponse = {
  imo_number?: number;
  name?: string;
}[];
export const {
  useGetAuxenginesQuery,
  useGetAuxiliarygeneratorsQuery,
  useGetBoilersexpandedQuery,
  useGetBuilderandsubcontractordetailsQuery,
  useGetBuilderandsubcontractorlinkfileQuery,
  useGetCapacitiesQuery,
  useGetClasscodesQuery,
  useGetCompanyfleetcountsQuery,
  useGetCompanyfulldetailsandparentcodeQuery,
  useGetCompanyvesselrelationshipsQuery,
  useGetEnginebuildercodesQuery,
  useGetEnginebuilderdetailsQuery,
  useGetFlagcodesQuery,
  useGetFueltypecodesQuery,
  useGetIceclassQuery,
  useGetMainenginesQuery,
  useGetMaingeneratorsQuery,
  useGetNamehistoryQuery,
  useGetPropellersQuery,
  useGetPropulsiontypedecodeQuery,
  useGetShipdataQuery,
  useGetShiptypecodesQuery,
  useGetSpecialfeaturesQuery,
  useGetStatuscodesQuery,
  useGetStatushistoryQuery,
  useGetSurveydatehistoryQuery,
  useGetSurveydatesQuery,
  useGetThrustersQuery,
  useGetTurbochargersQuery,
  useGetPropellermanufacturerQuery,
  useGetVesselCompaniesQuery,
  useImoSearchQuery,
  useVesselSearchQuery,
} = injectedRtkApi;
