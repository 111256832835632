import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coordinates } from '../port-view/slice';

export type LocationState = {
  userLocation: Coordinates | null;
  isFetching: boolean;
  isSearching: boolean;
  showUserLocation: boolean;
};

const initialState: LocationState = {
  userLocation: null,
  isFetching: false,
  isSearching: false,
  showUserLocation: false,
};

const slice = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: {
    setUserLocation(state, action: PayloadAction<LocationState['userLocation']>) {
      state.userLocation = action.payload;
    },
    setIsFetching(state, action: PayloadAction<LocationState['isFetching']>) {
      state.isFetching = action.payload;
    },
    setIsSearching(state, action: PayloadAction<LocationState['isSearching']>) {
      state.isSearching = action.payload;
    },
    setShowUserLocation(state, action: PayloadAction<LocationState['showUserLocation']>) {
      state.showUserLocation = action.payload;
    },
  },
});

export const { setUserLocation, setIsFetching, setIsSearching, setShowUserLocation } = slice.actions;
export const { reducer } = slice;
