import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronLeft } from '../../common/components/icons/ChevronLeft';
import { ChevronRight } from '../../common/components/icons/ChevronRight';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { useSetUserLocation } from '../location/hooks';
import { setShowUserLocation } from '../location/slice';
import { SwitchMapStyle } from '../switch-map-style/SwitchMapStyle';
import { setTitleAndUpdate } from '../title/slice';
import { VesselMapZoom } from '../vessel-map-zoom/VesselMapZoom';
import { LocationInformation } from './components/LocationInformation';
import { LocationVessels } from './components/LocationVessels';
import { PortInformation } from './components/PortInformation';
import { PortMap } from './components/PortMap';
import { PortSearch } from './components/PortSearch';
import { PortVessels } from './components/PortVessels';
import { setIsSearchOpen, setLocationBoxCoordinates, setLocationCoordinates } from './slice';
import { checkForCoordinatesInURL } from './utils';

type Props = {};

export const PortView: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.portPage);
  const [isOpen, setIsOpen] = useState(true);
  const urlCoordinates: boolean = checkForCoordinatesInURL();

  let { portId } = useParams<{ portId: string }>();

  useEffect(() => {
    if (portId) return;
    dispatch(setTitleAndUpdate());
  }, [portId, dispatch]);

  useSetUserLocation();

  useEffect(() => {
    dispatch(setIsSearchOpen(!!!portId));
    if (!urlCoordinates) {
      dispatch(setLocationBoxCoordinates(undefined));
      dispatch(setLocationCoordinates(undefined));
      dispatch(setShowUserLocation(false));
    }
  }, [portId, dispatch, urlCoordinates]);

  const coordinates = state.locationCoordinates || state.locationBoxCoordinates;

  return (
    <div className="PortView p-3 h-full bg-primary text-primary-content flex justify-between flex-col lg:flex-row">
      {isOpen ? (
        <div className="flex-grow basis-0 lg:max-w-[26rem] lg:overflow-auto flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="mt-1 basis-11/12">
              <PortSearch />
            </div>
            <div
              className="cursor-pointer hover:bg-secondary rounded py-1 px-2 text-xs w-fit h-fit"
              onClick={() => setIsOpen(!isOpen)}
              title="Hide left panel"
            >
              <ChevronLeft />
            </div>
          </div>

          {portId ? <PortInformation portId={portId} /> : coordinates && <LocationInformation />}
        </div>
      ) : (
        <div
          className="cursor-pointer hover:bg-secondary rounded py-1 px-2 text-xs mb-1 w-fit h-fit"
          onClick={() => setIsOpen(!isOpen)}
          title="Show left panel"
        >
          <ChevronRight />
        </div>
      )}
      <div className="flex-grow min-h-[35rem] lg:min-h-[unset] ml-2 my-2 lg:my-0 relative">
        <div className="absolute left-0 top-0 z-10 m-1 hidden sm:block">
          <div className="flex flex-col items-center">
            <SwitchMapStyle className="mb-1" originPage="port" />
            <VesselMapZoom map={state.mapInstance} />
          </div>
        </div>
        <PortMap portId={portId} />
      </div>
      <div className={`flex-grow basis-0 lg:max-w-[26rem] mx-2 max-w-[unset] flex flex-col`}>
        {portId ? <PortVessels /> : coordinates && <LocationVessels />}
      </div>
    </div>
  );
};
