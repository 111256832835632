import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSelectOption } from '../../common/types';
import { getStateFromUrl } from './utils';

export type EventType = 'anomaly' | 'casualty' | 'dry-dock' | 'port-calls' | 'chronological';
export type SelectedEvent = {
  type: EventType;
  id: number | string;
} | null;

export const anomalySortOptions = [
  { label: 'By relevance', value: 'relevance' },
  { label: 'By date, descending', value: 'chronological' },
];

export type EventFeedState = {
  selectedTab: EventType;
  selectedEvent: SelectedEvent;
  showFilters: boolean;
  anomalySorting: BaseSelectOption;
};

const initialState: EventFeedState = {
  selectedTab: 'anomaly',
  selectedEvent: null,
  showFilters: true,
  anomalySorting: anomalySortOptions[0],
};
const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: 'eventFeed',
  initialState: initialStateFromUrl,
  reducers: {
    setSelectedTab(state, action: PayloadAction<EventType>) {
      state.selectedTab = action.payload;
    },
    setSelectedEvent(state, action: PayloadAction<EventFeedState['selectedEvent']>) {
      state.selectedEvent = action.payload;
    },
    setShowFilters(state, action: PayloadAction<EventFeedState['showFilters']>) {
      state.showFilters = action.payload;
    },
    setAnomalySorting(state, action: PayloadAction<EventFeedState['anomalySorting']>) {
      state.anomalySorting = action.payload;
    },
  },
});

export const { setSelectedTab, setSelectedEvent, setShowFilters, setAnomalySorting } = slice.actions;
export const { reducer } = slice;
