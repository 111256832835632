import { IPublicClientApplication } from '@azure/msal-browser';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store/store';

export type AuthState = {
  msalInstance: IPublicClientApplication | null;
};

const initialState: AuthState = { msalInstance: null };

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<AuthState>) {
      state.msalInstance = action.payload.msalInstance;
    },
  },
});

const authSelector = (state: RootState) => state.auth;

export const currentUserSelector = createSelector(authSelector, (state) => {
  if (!state.msalInstance) {
    return null;
  }

  const [account] = state.msalInstance.getAllAccounts();
  return account;
});

export const { setAuth } = slice.actions;
export const { reducer } = slice;
