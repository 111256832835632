import '../styles.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MapGL, { MapLayerMouseEvent, MapRef, Marker, Popup, ScaleControl, ViewState, Layer, Source } from 'react-map-gl';
import { Polygon, Geometry } from 'geojson';
import { useNavigate } from 'react-router-dom';

import { LocationIcon } from '../../../common/components/icons/LocationIcon';
import { RefreshIcon } from '../../../common/components/icons/RefreshIcon';
import { MagnifyingGlassIcon } from '../../../common/components/icons/MagnifyingGlassIcon';
import { Spinner } from '../../../common/components/Spinner';
import { useAppDispatch, useAppSelector, useCallResizeOnMapWhenContainerSizeChange } from '../../../common/hooks';
import { VesselPosition } from '../../../store/apis/ais-api';
import { GeoPoint, useGetPlacesQuery, useGetGeometriesQuery } from '../../../store/apis/port-api';
import { setIsSearching, setShowUserLocation } from '../../location/slice';
import { useGetMapProps } from '../../switch-map-style/hooks';
import {
  useGetVesselsArrivingToPort,
  useGetVesselsAtPort,
  useGetVesselsDepartingFromPort,
  useGetVesselsInArea,
  useGetVesselsInBox,
  useRepositionMapWhenPositionsChange,
} from '../hooks';
import {
  Coordinates,
  PortStatus,
  setBoxCoordinatesAndUpdateUrl,
  setCoordinatesAndUpdateUrl,
  setLocationBoxCoordinates,
  setLocationCoordinates,
  setMapInstance as setMapInstanceAction,
  setSelectedVessel,
  setPortPolygon,
  setUpdatePortPolygonPermissions,
} from '../slice';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { currentUserSelector } from '../../auth/slice';

type Props = {
  portId?: string;
};

export const PortMap: React.FC<Props> = ({ portId }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.portPage);
  const user = useAppSelector(currentUserSelector);
  const polygonEditors = useAppSelector((state) => state.common.config?.POLYGON_EDITORS_LIST);
  const locationState = useAppSelector((state) => state.location);
  const [zoomLevel, setZoomLevel] = useState<undefined | number>(undefined);
  const [mapInstance, setMapInstance] = useState<MapRef | null>(null);
  const navigate = useNavigate();
  const mapProps = useGetMapProps('port');
  const [vesselsInPortQuery] = useGetVesselsAtPort();
  const [vesselsArrivingQuery] = useGetVesselsArrivingToPort();
  const [vesselsDepartingQuery] = useGetVesselsDepartingFromPort();
  const [vesselsInBox, vesselsInBoxIsLoading] = useGetVesselsInBox();
  const [vesselsInArea, vesselsInAreaIsLoading] = useGetVesselsInArea();
  const [draw, setDraw] = useState<MapboxDraw | undefined>();

  const portQuery = useGetPlacesQuery(Number(portId) ? { id: `eq.${portId}` } : { unlocode: `eq.${portId}` }, {
    skip: !portId,
  });

  const portPolygonQuery = useGetGeometriesQuery(
    { placeId: `eq.${portQuery.currentData?.[0]?.id}`, type: 'eq.port_area' },
    {
      skip: !portQuery.currentData?.[0]?.id,
    }
  );

  useRepositionMapWhenPositionsChange(portQuery.currentData?.[0]?.geo_point as GeoPoint | undefined);
  useCallResizeOnMapWhenContainerSizeChange(mapInstance);

  const initialMapState: Partial<ViewState> = useMemo(
    () => ({
      latitude: 35,
      longitude: 0,
      zoom: 1.5,
    }),
    []
  );

  useEffect(() => {
    if (!mapInstance) return;
    dispatch(setMapInstanceAction(mapInstance));
    dispatch(setUpdatePortPolygonPermissions({ user: user, polygonEditors: polygonEditors }));
    if (state.updatePolygon) {
      const drawComponent = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          trash: true,
        },
        defaultMode: 'draw_polygon',
      });
      setDraw(drawComponent);
      mapInstance?.getMap()?.addControl(drawComponent, 'bottom-right');
    }
  }, [dispatch, mapInstance, state.updatePolygon]);

  useEffect(() => {
    if (!draw) return;
    if (!!portPolygonQuery?.currentData?.length && portPolygonQuery.currentData[0].geometry != undefined)
      draw.add(portPolygonQuery?.currentData?.[0].geometry as unknown as Polygon);
    dispatch(setPortPolygon(portPolygonQuery?.currentData?.[0].geometry as unknown as Polygon));
  }, [portPolygonQuery]);

  const printNewPolygon = () => {
    const newThingy: Geometry | undefined = draw?.getSelected().features[0].geometry;
    dispatch(setPortPolygon(newThingy as Polygon));
  };

  const handleZoom = () => {
    setZoomLevel(mapInstance?.getZoom());
  };

  const searchBox = () => {
    navigate('/port');
    if (!mapInstance) return;
    dispatch(setLocationCoordinates(undefined));
    dispatch(setShowUserLocation(false));
    const box = {
      minLat: mapInstance.getBounds().getSouth(),
      minLon: mapInstance.getBounds().getWest(),
      maxLat: mapInstance.getBounds().getNorth(),
      maxLon: mapInstance.getBounds().getEast(),
    };
    dispatch(setBoxCoordinatesAndUpdateUrl(box));
  };

  let position = portQuery.currentData?.[0]?.geo_point;

  const handleMapClick = useCallback(
    (e: MapLayerMouseEvent) => {
      e.originalEvent.stopPropagation();
      if (state.selected) {
        dispatch(setSelectedVessel());
      }
    },
    [dispatch, state.selected]
  );

  const searchVesselsNearUser = () => {
    navigate('/port');
    if (locationState.userLocation) {
      dispatch(setCoordinatesAndUpdateUrl(locationState.userLocation));
      dispatch(setShowUserLocation(true));
    }
    dispatch(setLocationBoxCoordinates(undefined));
    dispatch(setIsSearching(true));
  };

  const handleSelectMarker = (originalEvent: MouseEvent, identifier?: number) => {
    originalEvent.stopPropagation();
    if (!identifier) return;
    if (identifier === state.selected) {
      dispatch(setSelectedVessel(undefined));
      return;
    }
    dispatch(setSelectedVessel(identifier));
    const listItem = document.querySelector(`div[data-event='port-${identifier}']`);
    listItem?.scrollIntoView({ behavior: 'smooth' });
  };

  const marker = useMemo(() => {
    if (!state.locationCoordinates && (portQuery.isFetching || !position?.coordinates || !portId)) {
      return null;
    }
    const location =
      state.locationCoordinates ||
      ({ longitude: position?.coordinates?.[0], latitude: position?.coordinates?.[1] } as Coordinates);
    if (location?.latitude && location?.longitude)
      return (
        <Marker longitude={location.longitude} latitude={location.latitude} anchor="bottom" style={{ zIndex: '10' }}>
          <LocationIcon
            className={`w-6 h-6 fill-white stroke-primary ${locationState.showUserLocation && 'animate-bounce'}`}
          />
        </Marker>
      );
  }, [state.locationCoordinates, locationState, portId, portQuery, position?.coordinates]);

  const vesselMarkers = useMemo(() => {
    const vessels = state.locationCoordinates
      ? vesselsInArea
      : state.locationBoxCoordinates
        ? vesselsInBox
        : state.selectedTab === PortStatus.InPort
          ? vesselsInPortQuery
          : state.selectedTab === PortStatus.Arriving
            ? vesselsArrivingQuery
            : vesselsDepartingQuery;
    if (!vessels) return;
    return vessels.map((vessel: VesselPosition) => {
      const identifier = vessel.imo ? vessel.imo : vessel.mmsi;
      const selected = identifier === state.selected;
      const image = vessel.positions?.[0].heading
        ? '/assets/images/positions/position-direction.svg'
        : '/assets/images/positions/position-no-direction.svg';
      if (vessel.positions?.[0]?.latitude && vessel.positions?.[0]?.longitude)
        return (
          <Marker
            longitude={vessel.positions[0].longitude}
            latitude={vessel.positions[0].latitude}
            anchor="center"
            rotation={vessel.positions?.[0].heading}
            key={identifier}
            onClick={(e) => handleSelectMarker(e.originalEvent, identifier)}
          >
            <img src={image} alt="position-direction" className="w-[18px] mr-1 cursor-pointer"></img>
            {selected && (
              <Popup
                closeButton={false}
                closeOnClick={false}
                latitude={vessel.positions?.[0].latitude as number}
                longitude={vessel.positions?.[0].longitude as number}
                anchor="top"
                className="map__marker__popup"
              >
                {vessel.vessel?.name}
              </Popup>
            )}
          </Marker>
        );
    });
  }, [
    state.selected,
    state.selectedTab,
    state.locationCoordinates,
    vesselsInPortQuery,
    vesselsArrivingQuery,
    vesselsDepartingQuery,
    vesselsInBox,
    vesselsInArea,
  ]);

  return (
    <div className="w-full h-full">
      <MapGL
        {...mapProps}
        initialViewState={initialMapState}
        ref={setMapInstance}
        onClick={handleMapClick}
        onZoomEnd={handleZoom}
        dragRotate={false}
        touchZoomRotate={false}
      >
        {(portQuery.isFetching || vesselsInAreaIsLoading || vesselsInBoxIsLoading || locationState.isFetching) && (
          <div className="absolute w-full h-full flex items-center justify-center">
            <div className="bg-primary/90 rounded-[4px] flex items-center justify-center p-10 z-50">
              <Spinner className="w-8 h-8" />
            </div>
          </div>
        )}
        {vesselMarkers}
        {marker}
        {!!portPolygonQuery?.currentData?.length && portPolygonQuery.currentData[0].geometry && (
          <Source id="json-data" data={portPolygonQuery.currentData?.[0].geometry} type="geojson">
            <Layer
              id="layer"
              source="json-data"
              type="line"
              paint={{
                'line-color': 'rgba(0, 20, 40, 0.2)',
                'line-width': 3,
              }}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
              }}
            ></Layer>
          </Source>
        )}
        <div className="absolute right-0 top-0 z-10 m-1 flex">
          {(zoomLevel || 0) < 10.5 ? (
            <div className="bg-neutral opacity-80 rounded p-1 px-2">
              <span>Zoom in to search area</span>
            </div>
          ) : (
            <div className="flex flex-row items-center bg-primary hover:bg-secondary rounded p-1 pr-2">
              <MagnifyingGlassIcon className="h-4 w-4 mx-1 text-accent" />
              <span onClick={searchBox} className="cursor-pointer">
                Search area
              </span>
            </div>
          )}
          <div
            className="flex flex-row items-center bg-primary hover:bg-secondary rounded cursor-pointer p-1 pr-2 ml-2"
            onClick={() => searchVesselsNearUser()}
          >
            <LocationIcon className="h-4 w-4 mx-1 text-accent" />
            Show vessels near me
          </div>
          {state.updatePolygon &&
            !!portPolygonQuery?.currentData?.length &&
            portPolygonQuery.currentData[0].geometry && (
              <div
                className="flex flex-row items-center bg-primary hover:bg-secondary rounded cursor-pointer p-1 pr-2 ml-2"
                onClick={printNewPolygon}
              >
                <RefreshIcon className="h-4 w-4 mx-1 text-accent" />
                Update Polygon GeoJSON
              </div>
            )}
        </div>
        <ScaleControl position="bottom-right" maxWidth={200} unit="nautical" />
      </MapGL>
    </div>
  );
};
