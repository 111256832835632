import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSelectOption } from '../../common/types';
import { UserFilter } from '../../store/apis/csi-api';
import { RootState } from '../../store/store';
import { userFilterEmailOptions } from '../event-filters/filter-options';
import { getStateFromUrl } from './utils';

export type UserFiltersState = {
  showForm: boolean;
  form: {
    name: string;
    emailOption: BaseSelectOption;
    allowFee: boolean;
  };
  selectedUserFilterOptionId: number | null;
  userFilterOptions: UserFilter[] | null;
};

const initialState: UserFiltersState = {
  showForm: false,
  form: {
    name: '',
    emailOption: userFilterEmailOptions[0],
    allowFee: false,
  },
  selectedUserFilterOptionId: null,
  userFilterOptions: null,
};

const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: 'user-filters',
  initialState: initialStateFromUrl,
  reducers: {
    setShowForm(state, action: PayloadAction<boolean>) {
      state.showForm = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.form.name = action.payload;
    },
    setEmailOption(state, action: PayloadAction<BaseSelectOption>) {
      state.form.emailOption = action.payload;
    },
    setAllowFee(state, action: PayloadAction<boolean>) {
      state.form.allowFee = action.payload;
    },
    setUserFilterOptions(state, action: PayloadAction<UserFilter[]>) {
      state.userFilterOptions = action.payload;
    },
    setSelectedUserFilterId(state, action: PayloadAction<number | null>) {
      state.selectedUserFilterOptionId = action.payload;
    },
    resetForm(state) {
      state.form.name = '';
      state.form.emailOption = userFilterEmailOptions[0];
    },
  },
});

const stateSliceSelector = (state: RootState) => state.userFilters;

export const selectedUserFilterOptionSelector = createSelector(stateSliceSelector, (state) => {
  if (!state.selectedUserFilterOptionId) {
    return null;
  }
  return state.userFilterOptions?.find((option) => option.id === state.selectedUserFilterOptionId) || null;
});

export const {
  setShowForm,
  setName,
  setEmailOption,
  setAllowFee,
  setUserFilterOptions,
  setSelectedUserFilterId,
  resetForm,
} = slice.actions;
export const { reducer } = slice;
