import { configureStore } from '@reduxjs/toolkit';

import { reducer as commonReducer } from '../common/slice';
import { reducer as authReducer } from '../features/auth/slice';
import { reducer as contactReducer } from '../features/contact/slice';
import { setupDatadogListenerMiddleware } from '../features/datadog/listener-middlewares';
import { displayErrorForRejectedThunkMiddleware } from '../features/error-handling/middleware';
import { eventFeedListenerMiddleware } from '../features/event-feed/listener-middlewares';
import { reducer as eventFeedReducer } from '../features/event-feed/slice';
import { eventFilterListenerMiddleware } from '../features/event-filters/listener-middlewares';
import { reducer as eventFiltersReducer } from '../features/event-filters/slice';
import { setupFaviconListenerMiddleware } from '../features/favicon/listener-middlewares';
import { reducer as locationReducer } from '../features/location/slice';
import { emissionLayerApi } from '../features/map-layers/hooks';
import { reducer as mapLayersReducer } from '../features/map-layers/slice';
import { reducer as mapReducer, setMapInstance } from '../features/map/slice';
import { reducer as fleetReducer, setMapInstance as setFleetMapInstance } from '../features/my-fleet-view/slice';
import { reducer as pollingReducer } from '../features/polling/slice';
import { reducer as portPageReducer, setMapInstance as setPortMapInstance } from '../features/port-view/slice';
import { reducer as reportProblemReducer } from '../features/report-problem/slice';
import { reducer as switchMapStyleReducer } from '../features/switch-map-style/slice';
import { reducer as titleReducer } from '../features/title/slice';
import { userFilterListenerMiddleware } from '../features/user-filters/listener-middlewares';
import { reducer as userFiltersReducer } from '../features/user-filters/slice';
import { reducer as vesselDetailsReducer } from '../features/vessel-details-view/slice';
import { reducer as vesselMapDateSelectReducer } from '../features/vessel-map-date-select/slice';
import { reducer as vesselMapReducer, setMapInstance as setVesselMapInstance } from '../features/vessel-map/slice';
import { reducer as searchOptionsReducer } from '../features/vessel-search/slice';
import {
  aisBaseApi,
  ihsVesselDataBaseApi,
  installationBaseApi,
  iprApi,
  portBaseApi,
  vesselCsiBaseApi,
  vesselEventBaseApi,
} from './base/base-apis';

export const store = configureStore({
  reducer: {
    apiIhsVesselData: ihsVesselDataBaseApi.reducer,
    apiVesselEvent: vesselEventBaseApi.reducer,
    apiInstallation: installationBaseApi.reducer,
    apiAis: aisBaseApi.reducer,
    apiCsi: vesselCsiBaseApi.reducer,
    apiPort: portBaseApi.reducer,
    apiIpr: iprApi.reducer,
    common: commonReducer,
    auth: authReducer,
    eventFeed: eventFeedReducer,
    eventFilters: eventFiltersReducer,
    userFilters: userFiltersReducer,
    reportProblem: reportProblemReducer,
    map: mapReducer,
    myFleet: fleetReducer,
    portPage: portPageReducer,
    searchOptions: searchOptionsReducer,
    vesselMap: vesselMapReducer,
    vesselMapDateSelect: vesselMapDateSelectReducer,
    vesselDetails: vesselDetailsReducer,
    switchMapStyle: switchMapStyleReducer,
    location: locationReducer,
    mapLayers: mapLayersReducer,
    polling: pollingReducer,
    title: titleReducer,
    contact: contactReducer,
    [emissionLayerApi.reducerPath]: emissionLayerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // We're storing the MSALInstance in the state in order to be able to acquire API resource tokens before each request in base-apis.ts.
        // The MSAL instance is not serializable, so we'll need to ignore it.
        // Other non-serializable values that gets stored and ignored:
        // - The generated API queries and mutations also dispatch non-serializable values in the meta property.
        // - Reference to the mapbox map instance, it's needed in order to use the map's imperative methods such as "flyTo".
        // Note: this is against the Redux best practices (non-serializable values).
        // However we will not serialize and de-serialize this specific state so it's ok, the other redux state should still be serializable.
        ignoredPaths: [
          'auth.msalInstance',
          'meta.baseQueryMeta',
          'map.mapInstance',
          'vesselMap.mapInstance',
          'portPage.mapInstance',
          'myFleet.mapInstance',
        ],
        ignoredActionPaths: ['payload.msalInstance', 'meta.baseQueryMeta'],
        ignoredActions: [
          setMapInstance.type,
          setVesselMapInstance.type,
          setPortMapInstance.type,
          setFleetMapInstance.type,
        ],
      },
    })
      .concat(
        ihsVesselDataBaseApi.middleware,
        vesselEventBaseApi.middleware,
        installationBaseApi.middleware,
        aisBaseApi.middleware,
        portBaseApi.middleware,
        vesselCsiBaseApi.middleware,
        displayErrorForRejectedThunkMiddleware,
        emissionLayerApi.middleware,
        iprApi.middleware
      )
      .prepend(
        setupFaviconListenerMiddleware.middleware,
        setupDatadogListenerMiddleware.middleware,
        eventFeedListenerMiddleware.middleware,
        eventFilterListenerMiddleware.middleware,
        userFilterListenerMiddleware.middleware
      ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
