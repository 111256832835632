import React from 'react';

import { IconDropdown } from '../../common/components/IconDropdown';
import { MapIcon } from '../../common/components/icons/MapIcon';
import { useAppDispatch } from '../../common/hooks';
import { MapStyle } from '../../common/types';
import { storeConfig } from '../../common/utils';
import { setSelectedPortMapStyle, setSelectedVesselMapStyle } from './slice';

type Props = {
  className?: string;
  originPage?: 'vessel' | 'port';
};

export const SwitchMapStyle: React.FC<Props> = ({ className, originPage }) => {
  const dispatch = useAppDispatch();

  const onChangeMapStyleHandler = (value: MapStyle) => {
    const mapStyle: MapStyle = value;
    if (originPage === 'port') {
      dispatch(setSelectedPortMapStyle(mapStyle));
      storeConfig({ portPageMapStyleName: mapStyle });
    } else if (originPage === 'vessel') {
      dispatch(setSelectedVesselMapStyle(mapStyle));
      storeConfig({ vesselPageMapStyleName: mapStyle });
    }
  };

  return (
    <div>
      <div className={`SwitchMapStyle ${className ? className : ''}`}>
        <IconDropdown
          listClassName="w-44"
          title={'Switch map style'}
          icon={<MapIcon />}
          items={
            <div className="cursor-pointer">
              <div
                className="text-sm p-2 rounded hover:bg-secondary z-10"
                onClick={() => onChangeMapStyleHandler('default')}
              >
                Default map
              </div>
              <div
                className="text-sm p-2 rounded hover:bg-secondary"
                onClick={() => onChangeMapStyleHandler('navigational')}
              >
                Navigational map
              </div>
              <div className="text-sm p-2 rounded hover:bg-secondary" onClick={() => onChangeMapStyleHandler('street')}>
                Satellite street map
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
