import { MapRef } from 'react-map-gl';
import { BoxCoordinates, Coordinates } from './slice';
import { AccountInfo } from '@azure/msal-browser';

export const zoomMapOverMarkers = (markers: number[][], mapInstance: MapRef | null) => {
  if (!!!markers.length) return;
  let minLat = markers[0][1] || 0;
  let maxLat = markers[0][1] || 0;
  let minLon = markers[0][0] || 0;
  let maxLon = markers[0][0] || 0;
  for (const marker of markers) {
    minLat = Math.min(marker[1] as number, minLat);
    minLon = Math.min(marker[0] as number, minLon);
    maxLat = Math.max(marker[1] as number, maxLat);
    maxLon = Math.max(marker[0] as number, maxLon);
  }
  const offset = 0.5;
  mapInstance?.fitBounds(
    [
      [minLon - offset, minLat - offset],
      [maxLon + offset, maxLat + offset],
    ],
    { speed: 2 }
  );
  return;
};

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1);
};

type AreaSearchUrlParams = {
  latitude?: number;
  longitude?: number;
  maxLatitude?: number;
  maxLongitude?: number;
  minLatitude?: number;
  minLongitude?: number;
  updatePolygon?: string;
};

export const getCoordinatesFromURL = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<AreaSearchUrlParams> = Object.fromEntries(urlSearchParams.entries());
  if (params.latitude && params.longitude) {
    return { latitude: Number(params.latitude), longitude: Number(params.longitude) } as Coordinates;
  }
  return undefined;
};

export const getBoxCoordinatesFromURL = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<AreaSearchUrlParams> = Object.fromEntries(urlSearchParams.entries());
  if (params.maxLatitude && params.maxLongitude && params.minLatitude && params.minLongitude) {
    return {
      maxLat: Number(params.maxLatitude),
      maxLon: Number(params.maxLongitude),
      minLat: Number(params.minLatitude),
      minLon: Number(params.minLongitude),
    } as BoxCoordinates;
  }
  return undefined;
};

export const getPolygonUpdateState = (user: AccountInfo | null, polygonEditors: string[] | undefined) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<AreaSearchUrlParams> = Object.fromEntries(urlSearchParams.entries());
  return (
    params.updatePolygon === 'true' && !!user?.username && !!polygonEditors && !!polygonEditors.includes(user.username)
  );
};

export const checkForCoordinatesInURL = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<AreaSearchUrlParams> = Object.fromEntries(urlSearchParams.entries());
  if (
    (params.latitude && params.longitude) ||
    (params.maxLatitude && params.maxLongitude && params.minLatitude && params.minLongitude)
  )
    return true;
  return false;
};
