import React from 'react';
import { Layer, Source } from 'react-map-gl';

import { useAppSelector } from '../../common/hooks';
import { emissionLayerProps, getEmissionSourceProps } from './utils';

type Props = {};

export const EmissionLayer: React.FC<Props> = () => {
  const { MAPBOX_EMISSION_LAYER_URL } = useAppSelector((state) => state.common.config!);
  const { emissionLayer } = useAppSelector((state) => state.mapLayers);
  const { config } = useAppSelector((state) => state.common);

  if (!emissionLayer.enabled) {
    return null;
  }

  return (
    <Source
      {...getEmissionSourceProps(
        `${MAPBOX_EMISSION_LAYER_URL}/emission-tiles/{z}/{x}/{y}?period=yesterday${
          config?.VERSION ? `&version=${config.VERSION}` : ''
        }`
      )}
    >
      <Layer {...emissionLayerProps} />
    </Source>
  );
};
