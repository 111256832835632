type Props = {
  className?: string;
};

export const InternalUseText: React.FC<Props> = ({ className }) => (
  <div className={`InternalUseText ${className ? className : ''}`}>
    <h1 className="text-sm">INTERNAL USE ONLY</h1>
    <p className="text-xs">
      This portal shows information from external and internal data sources. Please hold all data seen in confidence and
      do not disclose, use, copy, export, publish or summarize any internal data, except if necessary to carry out your
      assigned responsibilities as a Wärtsilä employee.
    </p>
  </div>
);
