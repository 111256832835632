import React from 'react';

import { Spinner } from '../../../../common/components/Spinner';
import { Casualties } from '../../../../store/apis/vessel-event-api';
import { SelectedEvent } from '../../slice';

import { CasualtyItem } from './CasualtyItem';

type Props = {
  casualties?: Casualties[];
  isFetching: boolean;
  selectedEvent: SelectedEvent;
};

export const CasualtyList: React.FC<Props> = ({ casualties, isFetching, selectedEvent }) => {
  return (
    <div className="CasualtyList">
      {isFetching ? (
        <div className="flex items-center justify-center h-full">
          <Spinner className="my-10 h-6 w-6" />
        </div>
      ) : (
        casualties?.map((casualty) => {
          const selected = casualty.id === selectedEvent?.id && selectedEvent?.type === 'casualty';
          return <CasualtyItem key={casualty.id} casualty={casualty} selected={selected} />;
        })
      )}
    </div>
  );
};
