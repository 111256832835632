import React from 'react';

import { UserIcon } from '../../../common/components/icons/UserIcon';
import { useAppSelector } from '../../../common/hooks';
import { currentUserSelector } from '../../auth/slice';

type Props = {
  className?: string;
};

export const CurrentUser: React.FC<Props> = ({ className }) => {
  const user = useAppSelector(currentUserSelector);

  if (!user) {
    return null;
  }

  return (
    <div className={`User flex items-center ${className ? className : ''}`}>
      <UserIcon className="w-6 h-6 mr-1" />
      {user.name}
    </div>
  );
};
