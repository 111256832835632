import { vesselEventBaseApi as api } from '../base/base-apis';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAnomalies: build.query<GetAnomaliesApiResponse, GetAnomaliesApiArg>({
      query: (queryArg) => ({
        url: `/anomalies`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          imo_number: queryArg.imoNumber,
          mmsi_number: queryArg.mmsiNumber,
          ext_vessel_id: queryArg.extVesselId,
          start_datetime: queryArg.startDatetime,
          end_datetime: queryArg.endDatetime,
          anomaly_sinuosity: queryArg.anomalySinuosity,
          anomaly_confidence: queryArg.anomalyConfidence,
          confidence_threshold: queryArg.confidenceThreshold,
          distance_coast_km: queryArg.distanceCoastKm,
          speed_change: queryArg.speedChange,
          destination_change: queryArg.destinationChange,
          status_change: queryArg.statusChange,
          not_under_command: queryArg.notUnderCommand,
          involved_tug_mmsi: queryArg.involvedTugMmsi,
          drifting: queryArg.drifting,
          waiting_for_orders: queryArg.waitingForOrders,
          waiting_for_port: queryArg.waitingForPort,
          insert_datetime: queryArg.insertDatetime,
          update_datetime: queryArg.updateDatetime,
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          vessel_name: queryArg.vesselName,
          vessel_type_code: queryArg.vesselTypeCode,
          vessel_type_name: queryArg.vesselTypeName,
          installation_id: queryArg.installationId,
          products: queryArg.products,
          customers: queryArg.customers,
          employees: queryArg.employees,
          location: queryArg.location,
          destination: queryArg.destination,
          duration: queryArg.duration,
          casualty_id: queryArg.casualtyId,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
          and: queryArg.and,
        },
      }),
    }),
    getCasualties: build.query<GetCasualtiesApiResponse, GetCasualtiesApiArg>({
      query: (queryArg) => ({
        url: `/casualties`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          imo_number: queryArg.imoNumber,
          mmsi_number: queryArg.mmsiNumber,
          ext_vessel_id: queryArg.extVesselId,
          casualty_date: queryArg.casualtyDate,
          casualty_date_qualified: queryArg.casualtyDateQualified,
          loss_date: queryArg.lossDate,
          update_date: queryArg.updateDate,
          casualty_causes: queryArg.casualtyCauses,
          casualty_source: queryArg.casualtySource,
          casualty_source_id: queryArg.casualtySourceId,
          casualty_description: queryArg.casualtyDescription,
          post_incident_description: queryArg.postIncidentDescription,
          pollution_description: queryArg.pollutionDescription,
          cargo_descripion: queryArg.cargoDescripion,
          fatality_description: queryArg.fatalityDescription,
          origin: queryArg.origin,
          destination: queryArg.destination,
          insert_datetime: queryArg.insertDatetime,
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          vessel_name: queryArg.vesselName,
          vessel_type_code: queryArg.vesselTypeCode,
          vessel_type_name: queryArg.vesselTypeName,
          installation_id: queryArg.installationId,
          products: queryArg.products,
          customers: queryArg.customers,
          employees: queryArg.employees,
          location: queryArg.location,
          crm_link: queryArg.crmLink,
          anomaly_id: queryArg.anomalyId,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
          and: queryArg.and,
        },
      }),
    }),
    getDrydockings: build.query<GetDrydockingsApiResponse, GetDrydockingsApiArg>({
      query: (queryArg) => ({
        url: `/drydockings`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          imo_number: queryArg.imoNumber,
          mmsi_number: queryArg.mmsiNumber,
          ext_vessel_id: queryArg.extVesselId,
          start_datetime: queryArg.startDatetime,
          end_datetime: queryArg.endDatetime,
          update_datetime: queryArg.updateDatetime,
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          vessel_name: queryArg.vesselName,
          vessel_type_code: queryArg.vesselTypeCode,
          vessel_type_name: queryArg.vesselTypeName,
          installation_id: queryArg.installationId,
          products: queryArg.products,
          customers: queryArg.customers,
          employees: queryArg.employees,
          location: queryArg.location,
          destination: queryArg.destination,
          build_year: queryArg.buildYear,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
          and: queryArg.and,
        },
      }),
    }),
    getLatestEvents: build.query<GetLatestEventsApiResponse, GetLatestEventsApiArg>({
      query: (queryArg) => ({
        url: `/latest_events`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          event_datetime: queryArg.eventDatetime,
          imo_number: queryArg.imoNumber,
          vessel_name: queryArg.vesselName,
          installation_id: queryArg.installationId,
          event_category: queryArg.eventCategory,
          event_type: queryArg.eventType,
          url: queryArg.url,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getVesselChangesIhs: build.query<GetVesselChangesIhsApiResponse, GetVesselChangesIhsApiArg>({
      query: (queryArg) => ({
        url: `/vessel_changes_ihs`,
        headers: { Range: queryArg.range, 'Range-Unit': queryArg['Range-Unit'], Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          imo_number: queryArg.imoNumber,
          valid_from: queryArg.validFrom,
          field: queryArg.field,
          old_value: queryArg.oldValue,
          new_value: queryArg.newValue,
          insert_datetime: queryArg.insertDatetime,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetAnomaliesApiResponse = /** status 200 OK */ Anomalies[] | /** status 206 Partial Content */ string;
export type GetAnomaliesApiArg = {
  /** Format - integer. */
  id?: string;
  /** Format - integer. */
  imoNumber?: string;
  /** Format - integer. */
  mmsiNumber?: string;
  /** Format - integer. */
  extVesselId?: string;
  /** Format - timestamp without time zone. */
  startDatetime?: string;
  /** Format - timestamp without time zone. */
  endDatetime?: string;
  /** Format - double precision. */
  anomalySinuosity?: string;
  /** Format - double precision. */
  anomalyConfidence?: string;
  /** Format - double precision. */
  confidenceThreshold?: string;
  /** Format - integer. */
  distanceCoastKm?: string;
  /** Format - numeric. */
  speedChange?: string;
  /** Format - character varying. */
  destinationChange?: string;
  statusChange?: string;
  notUnderCommand?: string;
  /** Format - integer. */
  involvedTugMmsi?: string;
  drifting?: string;
  waitingForOrders?: string;
  waitingForPort?: string;
  insertDatetime?: string;
  /** Format - timestamp without time zone. */
  updateDatetime?: string;
  /** Format - double precision. */
  latitude?: string;
  /** Format - double precision. */
  longitude?: string;
  /** Format - character varying. */
  vesselName?: string;
  /** Format - character varying. */
  vesselTypeCode?: string;
  /** Format - character varying. */
  vesselTypeName?: string;
  /** Format - integer. */
  installationId?: string;
  /** Format - ARRAY. */
  products?: string;
  /** Format - ARRAY. */
  customers?: string;
  /** Format - ARRAY. */
  employees?: string;
  /** Format - character. */
  location?: string;
  /** Format - character. */
  destination?: string;
  /** Format - interval. */
  duration?: string;
  /** Format - character. */
  casualtyId?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
  /** Format - character varying. */
  and?: string;
};
export type GetCasualtiesApiResponse = /** status 200 OK */ Casualties[] | /** status 206 Partial Content */ undefined;
export type GetCasualtiesApiArg = {
  /** Format - integer. */
  id?: string;
  /** Format - integer. */
  imoNumber?: string;
  /** Format - integer. */
  mmsiNumber?: string;
  /** Format - integer. */
  extVesselId?: string;
  /** Format - date (as full-date in RFC3339). */
  casualtyDate?: string;
  /** Format - character varying. */
  casualtyDateQualified?: string;
  /** Format - date (as full-date in RFC3339). */
  lossDate?: string;
  /** Format - date (as full-date in RFC3339). */
  updateDate?: string;
  /** Format - character varying. */
  casualtyCauses?: string;
  /** Format - character varying. */
  casualtySource?: string;
  /** Format - character varying. */
  casualtySourceId?: string;
  /** Format - character varying. */
  casualtyDescription?: string;
  /** Format - character varying. */
  postIncidentDescription?: string;
  /** Format - character varying. */
  pollutionDescription?: string;
  /** Format - character varying. */
  cargoDescripion?: string;
  /** Format - character varying. */
  fatalityDescription?: string;
  /** Format - character varying. */
  origin?: string;
  /** Format - character varying. */
  destination?: string;
  /** Format - timestamp without time zone. */
  insertDatetime?: string;
  /** Format - double precision. */
  latitude?: string;
  /** Format - double precision. */
  longitude?: string;
  /** Format - character varying. */
  vesselName?: string;
  /** Format - character varying. */
  vesselTypeCode?: string;
  /** Format - character varying. */
  vesselTypeName?: string;
  /** Format - integer. */
  installationId?: string;
  /** Format - ARRAY. */
  products?: string;
  /** Format - ARRAY. */
  customers?: string;
  /** Format - ARRAY. */
  employees?: string;
  /** Format - text. */
  location?: string;
  crmLink?: string;
  anomalyId?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
  /** Format - character varying. */
  and?: string;
};
export type GetDrydockingsApiResponse =
  /** status 200 OK */
  Drydockings[] | /** status 206 Partial Content */ undefined;
export type GetDrydockingsApiArg = {
  id?: string;
  imoNumber?: string;
  mmsiNumber?: string;
  extVesselId?: string;
  startDatetime?: string;
  endDatetime?: string;
  updateDatetime?: string;
  latitude?: string;
  longitude?: string;
  vesselName?: string;
  vesselTypeCode?: string;
  vesselTypeName?: string;
  installationId?: string;
  products?: string;
  customers?: string;
  employees?: string;
  location?: string;
  destination?: string;
  buildYear?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
  /** Format - character varying. */
  and?: string;
};
export type GetLatestEventsApiResponse =
  /** status 200 OK */
  LatestEvents[] | /** status 206 Partial Content */ undefined;
export type GetLatestEventsApiArg = {
  eventDatetime?: string;
  imoNumber?: string;
  vesselName?: string;
  installationId?: string;
  eventCategory?: string;
  eventType?: string;
  url?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type GetVesselChangesIhsApiResponse =
  /** status 200 OK */
  VesselChangesIhs[] | /** status 206 Partial Content */ undefined;
export type GetVesselChangesIhsApiArg = {
  id?: string;
  imoNumber?: string;
  validFrom?: string;
  field?: string;
  oldValue?: string;
  newValue?: string;
  insertDatetime?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  'Range-Unit'?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: 'count=none';
};
export type Anomalies = {
  id?: number;
  imo_number?: number;
  mmsi_number?: number;
  ext_vessel_id?: number;
  start_datetime?: string;
  end_datetime?: string;
  anomaly_sinuosity?: number;
  anomaly_confidence?: number;
  confidence_threshold?: number;
  distance_coast_km?: number;
  speed_change?: number;
  destination_change?: string;
  status_change?: string;
  not_under_command?: number;
  involved_tug_mmsi?: number;
  drifting?: number;
  waiting_for_orders?: number;
  waiting_for_port?: number;
  insert_datetime?: string;
  update_datetime?: string;
  latitude?: number;
  longitude?: number;
  vessel_name?: string;
  vessel_type_code?: string;
  vessel_type_name?: string;
  installation_id?: number;
  products?: string;
  customers?: string;
  employees?: string;
  location?: string;
  destination?: string;
  duration?: string;
  casualty_id?: string;
};
export type Casualties = {
  id?: number;
  imo_number?: number;
  mmsi_number?: number;
  ext_vessel_id?: number;
  casualty_date?: string;
  casualty_date_qualified?: string;
  loss_date?: string;
  update_date?: string;
  casualty_causes?: string;
  casualty_source?: string;
  casualty_source_id?: string;
  casualty_description?: string;
  post_incident_description?: string;
  pollution_description?: string;
  cargo_descripion?: string;
  fatality_description?: string;
  origin?: string;
  destination?: string;
  insert_datetime?: string;
  latitude?: number;
  longitude?: number;
  vessel_name?: string;
  vessel_type_code?: string;
  vessel_type_name?: string;
  installation_id?: number;
  products?: string;
  customers?: string;
  employees?: string;
  location?: string;
  crm_link?: string;
  anomaly_id?: string;
};
export type Drydockings = {
  id?: number;
  imo_number?: number;
  mmsi_number?: number;
  ext_vessel_id?: number;
  start_datetime?: string;
  end_datetime?: string;
  update_datetime?: string;
  latitude?: number;
  longitude?: number;
  vessel_name?: string;
  vessel_type_code?: string;
  vessel_type_name?: string;
  installation_id?: number;
  products?: string;
  customers?: string;
  employees?: string;
  location?: string;
  destination?: string;
  build_year?: number;
};
export type LatestEvents = {
  event_datetime?: string;
  imo_number?: number;
  vessel_name?: string;
  installation_id?: number;
  event_category?: string;
  event_type?: string;
  url?: string;
};
export type VesselChangesIhs = {
  id?: number;
  imo_number?: number;
  valid_from?: string;
  field?: string;
  old_value?: string;
  new_value?: string;
  insert_datetime?: string;
};
export const {
  useGetAnomaliesQuery,
  useGetCasualtiesQuery,
  useGetDrydockingsQuery,
  useGetLatestEventsQuery,
  useGetVesselChangesIhsQuery,
} = injectedRtkApi;
