import { createListenerMiddleware } from '@reduxjs/toolkit';

import { getEventTypeFromEventOptionValue } from '../../common/utils';
import { RootState } from '../../store/store';
import { setSelectedTab } from './slice';

export const eventFeedListenerMiddleware = createListenerMiddleware();

eventFeedListenerMiddleware.startListening({
  predicate: (_, currentState, previousState) => {
    const currentSelectedEvent = (currentState as RootState).eventFilters.selectedEventOption;
    const previousSelectedEvent = (previousState as RootState).eventFilters.selectedEventOption;
    return currentSelectedEvent !== previousSelectedEvent;
  },
  effect: async (_, listenerApi) => {
    const selectedEvent = (listenerApi.getState() as RootState).eventFilters.selectedEventOption?.value;
    if (!selectedEvent) {
      return;
    }

    const type = getEventTypeFromEventOptionValue(selectedEvent);
    listenerApi.dispatch(setSelectedTab(type));
  },
});
