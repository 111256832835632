import React from 'react';
import { useDispatch } from 'react-redux';

import { IconDropdown } from '../../common/components/IconDropdown';
import { CollectionIcon } from '../../common/components/icons/CollectionIcon';
import { toggleAisQualityLayerEnabled, toggleEmissionLayerEnabled } from './slice';

type Props = {
  className?: string;
};

export const LayersMenuCompact: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  return (
    <div className={`LayersMenuCompact text-white ${className ? className : ''}`}>
      <IconDropdown
        title="Apply map layer"
        icon={<CollectionIcon />}
        listClassName="w-40"
        items={
          <>
            <div
              className="text-sm p-2 rounded hover:bg-secondary cursor-pointer"
              onClick={() => dispatch(toggleEmissionLayerEnabled())}
            >
              Emission layer
            </div>
            <div
              className="text-sm p-2 rounded hover:bg-secondary cursor-pointer"
              onClick={() => dispatch(toggleAisQualityLayerEnabled())}
            >
              AIS Data Quality layer
            </div>
          </>
        }
      />
    </div>
  );
};
