/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { PortStatus, portVesselFilterSelectOptions, setPortVesselFilter } from '../slice';
import { useGetVesselsInArea, useGetVesselsInBox } from '../hooks';
import { PortVesselItem } from './PortVesselItem';
import { VesselPosition } from '../../../store/apis/ais-api';
import { Select } from '../../../common/components/Select';
import { Spinner } from '../../../common/components/Spinner';

export const LocationVessels: React.FC = () => {
  const [vesselsInArea, vesselsInAreaIsLoading] = useGetVesselsInArea();
  const [vesselsInBox, vesselsInBoxIsLoading] = useGetVesselsInBox();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.portPage);

  const vesselList: VesselPosition[] | undefined = state.locationCoordinates ? vesselsInArea : vesselsInBox;

  const sortVessels = (list: VesselPosition[] | undefined) => {
    if (!list) return;
    const sortedList = [...list];
    sortedList.sort((a, b) => {
      if (!a.positions?.[0].timestamp) return -1;
      if (!b.positions?.[0].timestamp) return 1;
      return new Date(b.positions?.[0].timestamp).valueOf() - new Date(a.positions?.[0].timestamp).valueOf();
    });
    return sortedList;
  };

  const isSelected = (identifier: number | undefined) => {
    if (!identifier) return false;
    if (identifier === state.selected) return true;
    return false;
  };

  return (
    <div className="LocationVessels w-full h-full flex flex-col">
      <p className="text-xl">Vessels ({state.locationCoordinates ? vesselsInArea?.length : vesselsInBox?.length})</p>
      <div>
        <div>
          <Select
            value={state.portVesselFilter}
            options={portVesselFilterSelectOptions}
            onChange={(value) => dispatch(setPortVesselFilter(value))}
            className="my-2"
            placeholder="Filter by fleet"
          />
        </div>
      </div>
      {(vesselsInAreaIsLoading || vesselsInBoxIsLoading) && (
        <div className="bg-primary/90 rounded-[4px] flex items-center justify-center p-10">
          <Spinner className="w-8 h-8" />
        </div>
      )}
      <div className="overflow-y-auto">
        {sortVessels(vesselList)?.map((vessel) => {
          const selected = isSelected(vessel.imo ? vessel.imo : vessel.mmsi);
          return (
            <div
              className={`flex ${selected ? 'bg-secondary' : 'bg-primary'}`}
              key={vessel.mmsi}
              data-event={`port-${vessel.imo || vessel.mmsi}`}
            >
              <PortVesselItem vessel={vessel} status={PortStatus.InPort} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
