/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';

import { Spinner } from '../../common/components/Spinner';
import { useAppDispatch, useAppSelector, useGetFilteredEvents } from '../../common/hooks';
import { Anomalies, Casualties, Drydockings, useGetVesselChangesIhsQuery } from '../../store/apis/vessel-event-api';
import { AnomalyFilters } from './components/anomaly-components/AnomalyFilters';
import { AnomalyList } from './components/anomaly-components/AnomalyList';
import { CasualtyFilters } from './components/casualty-components/CasualtyFilters';
import { CasualtyList } from './components/casualty-components/CasualtyList';
import { DryDockingFilters } from './components/drydocking-components/DryDockingFilters';
import { DryDockingList } from './components/drydocking-components/DryDockingList';
import { VesselEventList } from './components/VesselEventList';
import { EventType, setSelectedTab } from './slice';
import { useGetPortEventsPortCallsQuery } from '../../store/apis/ais-api';
import { PortCallList } from './components/port-call-components/PortCallList';

type Props = {
  className?: string;
  pollingEnabled?: boolean;
};

export const EventFeed: React.FC<Props> = ({ className, pollingEnabled }) => {
  const imo = useAppSelector((state) => state.eventFilters.selectedImo);
  const { anomalies, casualties, drydockings } = useGetFilteredEvents();
  const dateState = useAppSelector((state) => state.vesselMapDateSelect);
  const portData = useGetPortEventsPortCallsQuery(
    { imo: imo || undefined, from: dateState.startDate, to: dateState.endDate, limit: 100 },
    { skip: !imo }
  );
  const ihsEventsQuery = useGetVesselChangesIhsQuery({ imoNumber: `eq.${imo}` }, { skip: !imo });

  let events: { isFetching: boolean; eventsData: any[] } = { isFetching: true, eventsData: [] };
  if (anomalies.isSuccess && casualties.isSuccess && drydockings.isSuccess && events.isFetching) {
    events.eventsData = [
      ...(casualties.currentData ? casualties.currentData : []),
      ...(anomalies.currentData ? anomalies.currentData : []),
      ...(drydockings.currentData ? drydockings.currentData : []),
      ...(ihsEventsQuery.currentData ? ihsEventsQuery.currentData : []),
    ];
    events.isFetching = false;
  }

  const dispatch = useAppDispatch();
  const { selectedTab, selectedEvent, showFilters } = useAppSelector((state) => state.eventFeed);

  const renderTabLink = (tab: EventType, title: string, isLoading: boolean, eventCount?: number) => (
    <a
      className={`tab text-sm md:text-base text-white whitespace-nowrap ${
        selectedTab === tab ? 'tab-active !border-orange-500' : ''
      } `}
      onClick={() => dispatch(setSelectedTab(tab))}
    >
      <span className="flex flex-nowrap items-center">
        {title}
        {isLoading ? (
          <Spinner className="ml-1 h-3 w-3" />
        ) : (
          <span className="ml-1 text-orange-400 text-sm">{eventCount}</span>
        )}
      </span>
    </a>
  );

  const renderChronologicalFeedLink = (isLoading: boolean, eventCount?: number) => (
    <a className={`tab tab-active !border-orange-500 text-sm md:text-base text-white whitespace-nowrap`}>
      <span className="flex flex-nowrap items-center">
        {'Events'}
        {isLoading ? (
          <Spinner className="ml-1 h-3 w-3" />
        ) : (
          <span className="ml-1 text-orange-400 text-sm">{eventCount}</span>
        )}
      </span>
    </a>
  );

  const renderTabContent = () => {
    if (selectedTab === 'anomaly') {
      const isFetching = pollingEnabled ? !anomalies.currentData && anomalies.isFetching : anomalies.isFetching;
      return (
        <AnomalyList
          isFetching={isFetching}
          anomalies={anomalies.currentData as Anomalies[] | undefined}
          selectedEvent={selectedEvent}
        />
      );
    } else if (selectedTab === 'casualty') {
      const isFetching = pollingEnabled ? !casualties.currentData && casualties.isFetching : casualties.isFetching;
      return (
        <CasualtyList
          isFetching={isFetching}
          casualties={casualties.currentData as Casualties[] | undefined}
          selectedEvent={selectedEvent}
        />
      );
    } else if (selectedTab === 'dry-dock') {
      const isFetching = pollingEnabled ? !drydockings.currentData && drydockings.isFetching : drydockings.isFetching;
      return (
        <DryDockingList
          isFetching={isFetching}
          dryDockings={drydockings.currentData as Drydockings[] | undefined}
          selectedEvent={selectedEvent}
        />
      );
    }
  };

  const styles = useMemo(
    () => ({
      tabContentContainer: {
        height: 'calc(100% - 48px)',
      },
    }),
    []
  );

  return (
    <div
      className={`EventFeed pt-2 bg-primary/90 text-white w-full h-full flex flex-col ${className ? className : ''}`}
    >
      {showFilters ? (
        <div className="tabs tabs-bordered flex flex-nowrap justify-center overflow-auto shadow-md py-2">
          {renderTabLink('anomaly', 'Anomalies', anomalies.isFetching, anomalies.currentData?.length)}
          {renderTabLink('casualty', 'Casualties', casualties.isFetching, casualties.currentData?.length)}
          {renderTabLink('dry-dock', 'Dry Docking', drydockings.isFetching, drydockings.currentData?.length)}
        </div>
      ) : (
        <div className="tabs tabs-bordered flex flex-nowrap overflow-auto shadow-md py-1 max-w-fit">
          {renderTabLink('chronological', 'Events', events.isFetching, events?.eventsData.length)}
          {renderTabLink('port-calls', 'Port Calls', portData.isFetching, portData.currentData?.results?.length)}
        </div>
      )}
      <div>
        {showFilters && selectedTab === 'anomaly' && <AnomalyFilters className="p-2 border-b border-b-neutral" />}
        {showFilters && selectedTab === 'casualty' && <CasualtyFilters className="p-2 border-b border-b-neutral" />}
        {showFilters && selectedTab === 'dry-dock' && <DryDockingFilters className="p-2 border-b border-b-neutral" />}
      </div>
      <div className="overflow-auto h-full" style={styles.tabContentContainer}>
        {showFilters ? (
          renderTabContent()
        ) : selectedTab === 'port-calls' ? (
          <PortCallList
            isFetching={portData.isFetching}
            portCalls={portData.currentData?.results}
            selectedEvent={selectedEvent}
            isError={portData.isError}
            error={portData.error}
          />
        ) : (
          <VesselEventList isFetching={events.isFetching} events={events.eventsData} selectedEvent={selectedEvent} />
        )}
      </div>
    </div>
  );
};
