import React from 'react';

type Props = {
  className?: string;
};

export const Camera: React.FC<Props> = ({ className = 'h-5 w-5' }) => (
  <svg id="bounding-box-48x48-export-me" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className}>
    <path d="m41,40.01H7c-1.65,0-3-1.35-3-3v-20c0-1.65,1.35-3,3-3h6.6s5.7-5.72,5.7-5.72c.19-.19.44-.29.71-.29h0l8,.04c.26,0,.52.11.7.29l5.71,5.69h6.58c1.65,0,3,1.35,3,3v20c0,1.65-1.35,3-3,3ZM7,16.01c-.55,0-1,.45-1,1v20c0,.55.45,1,1,1h34c.55,0,1-.45,1-1v-20c0-.55-.45-1-1-1h-7c-.26,0-.52-.11-.71-.29l-5.71-5.69-7.17-.03-5.69,5.72c-.19.19-.44.29-.71.29h-7.01Z" />
    <path d="m24,34.01c-3.86,0-7-3.14-7-7s3.14-7,7-7,7,3.14,7,7-3.14,7-7,7Zm0-12c-2.76,0-5,2.24-5,5s2.24,5,5,5,5-2.24,5-5-2.24-5-5-5Z" />
  </svg>
);
