import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from '../utils';

type Props = {
  value: string;
  transformInput?: (value: string) => string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  useDebounce?: boolean;
};

export const TextInput: React.FC<Props> = ({
  value,
  transformInput,
  onChange,
  className,
  placeholder,
  useDebounce,
}) => {
  const [input, setInput] = useState(value);

  const debouncedOnChangeHandler = useMemo(() => debounce(onChange, 500), [onChange]);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInput(transformInput ? transformInput(value) : value);
      useDebounce ? debouncedOnChangeHandler(value) : onChange(value);
    },
    [transformInput, useDebounce, debouncedOnChangeHandler, onChange]
  );

  // We need to update the input text if this component's value gets updated in the store from another component.
  useEffect(() => {
    setInput(value);
  }, [value]);

  return (
    <input
      type="text"
      value={useDebounce ? input : value}
      onChange={onChangeHandler}
      className={`
      input-secondary border-[1px] border-secondary pl-3 text-[14px] !h-[38px] w-full text-primary-content 
      bg-primary rounded-[4px] focus:!outline-none placeholder-[#808080]
      ${className ? className : ''}`}
      placeholder={placeholder}
    />
  );
};
