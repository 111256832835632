import React from 'react';

import { Spinner } from '../../../common/components/Spinner';
import { ChevronDown } from '../../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../../common/components/icons/ChevronUp';
import { useGetVesselCompaniesQuery, VesselCompanies } from '../../../store/apis/ihs-vessel-data-api';
import { useGetDetailedShipdataQuery } from '../hooks';
import { getCompanyRoleNames, groupVesselCompanies } from '../utils';
import { countryMap } from '../../../common/countryMapping';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { setVesselDetailsState } from '../slice';

type Props = {
  imo: string;
  className?: string;
};

export const RelatedCompanies: React.FC<Props> = ({ imo, className }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.vesselDetails);
  const isOpen = state.isRelatedCompaniesOpen;

  const companiesQuery = useGetVesselCompaniesQuery({
    lrimoshipno: `eq.${imo}`,
    select: 'role_code,role_name,owcode,fullcompanyname,country_code',
  });
  const shipDataQuery = useGetDetailedShipdataQuery(imo);

  const groupedCompanies = companiesQuery.currentData
    ? groupVesselCompanies(companiesQuery.currentData as VesselCompanies[])
    : null;

  return (
    <div className={`RelatedCompanies ${className ? className : ''}`}>
      <div className="flex flex-row items-center justify-between border-b border-secondary pb-1 mb-2">
        <div className="flex flex-row items-center">
          <div onClick={() => dispatch(setVesselDetailsState({ ...state, isRelatedCompaniesOpen: !isOpen }))}>
            {isOpen ? (
              <ChevronUp className="h-5 w-5 mr-2 cursor-pointer" />
            ) : (
              <ChevronDown className="h-5 w-5 mr-2 cursor-pointer" />
            )}
          </div>
          <span
            className="text-lg cursor-pointer"
            onClick={() => dispatch(setVesselDetailsState({ ...state, isRelatedCompaniesOpen: !isOpen }))}
          >
            Related companies
          </span>
        </div>
        <div className="bg-secondary rounded py-1 px-2 text-xs">
          Source: <span className="text-cyan-600">IHS Sea-web</span>
        </div>
      </div>
      {isOpen ? (
        <>
          {companiesQuery.isFetching ? (
            <Spinner className="h-6 w-6" />
          ) : (
            <ul>
              {groupedCompanies?.map((group) => (
                <li key={group.owcode} className="mb-2 ml-2">
                  {group.country_code && (
                    <span
                      className={`fi fi-${group.country_code.toLowerCase()}`}
                      title={countryMap[group.country_code as keyof typeof countryMap]}
                    />
                  )}{' '}
                  <span>
                    {group.fullcompanyname} <span className="text-stone-400">[{getCompanyRoleNames(group)}]</span>
                  </span>
                </li>
              ))}
              <li className="mb-2 ml-2">
                {shipDataQuery.currentData ? shipDataQuery.currentData.classificationsociety : 'No data available'}{' '}
                <span className="text-stone-400">{shipDataQuery.currentData ? '[Classification Society]' : ''}</span>
              </li>
            </ul>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
