import React from 'react';

import { Spinner } from '../../../../common/components/Spinner';
import { Drydockings } from '../../../../store/apis/vessel-event-api';
import { SelectedEvent } from '../../slice';
import { DryDockingItem } from './DryDockingItem';

type Props = {
  dryDockings?: Drydockings[];
  isFetching: boolean;
  selectedEvent: SelectedEvent;
};

export const DryDockingList: React.FC<Props> = ({ dryDockings, isFetching, selectedEvent }) => (
  <div className="DryDockingList">
    {isFetching ? (
      <div className="flex items-center justify-center h-full">
        <Spinner className="my-10 h-6 w-6" />
      </div>
    ) : (
      dryDockings?.map((dryDocking) => {
        const selected = dryDocking.id === selectedEvent?.id && selectedEvent?.type === 'dry-dock';
        return <DryDockingItem key={dryDocking.id} dryDocking={dryDocking} selected={selected} />;
      })
    )}
  </div>
);
