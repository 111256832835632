import { useEffect } from 'react';

import { useAppSelector } from '../../common/hooks';
import { addUsageLogInterceptorToGlobalFetch } from './utils';

export const useUsageLogInterceptorToGlobalFetch = () => {
  const config = useAppSelector((state) => state.common.config);
  const msalInstance = useAppSelector((state) => state.auth.msalInstance);

  useEffect(() => {
    if (!config || !msalInstance) return;
    addUsageLogInterceptorToGlobalFetch(config, msalInstance);
  }, [config, msalInstance]);
};
