/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Select } from '../../../common/components/Select';
import { Spinner } from '../../../common/components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { VesselPosition } from '../../../store/apis/ais-api';
import { useGetVesselsArrivingToPort, useGetVesselsAtPort, useGetVesselsDepartingFromPort } from '../hooks';
import { PortStatus, portVesselFilterSelectOptions, setPortVesselFilter, setSelectedTab } from '../slice';
import { zoomMapOverMarkers } from '../utils';
import { PortVesselItem } from './PortVesselItem';

type Props = {};

export const PortVessels: React.FC<Props> = () => {
  const [vesselsInPortQuery, inPortLoading] = useGetVesselsAtPort();
  const [vesselsArrivingQuery, arrivalsLoading] = useGetVesselsArrivingToPort();
  const [vesselsDepartingQuery, departuresLoading] = useGetVesselsDepartingFromPort();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.portPage);

  const sortVessels = (list: VesselPosition[] | undefined, state: PortStatus) => {
    if (!list) return;
    const sortedList = [...list];
    if (state === PortStatus.Arriving) {
      sortedList.sort((a, b) => {
        if (!a.positions?.[0].voyage?.eta) return -1;
        if (!b.positions?.[0].voyage?.eta) return 1;
        return new Date(a.positions?.[0].voyage?.eta).valueOf() - new Date(b.positions?.[0].voyage?.eta).valueOf();
      });
    } else {
      sortedList.sort((a, b) => {
        if (!a.positions?.[0].timestamp) return -1;
        if (!b.positions?.[0].timestamp) return 1;
        return new Date(b.positions?.[0].timestamp).valueOf() - new Date(a.positions?.[0].timestamp).valueOf();
      });
    }
    return sortedList;
  };

  const isSelected = (identifier: number | undefined) => {
    if (!identifier) return false;
    if (identifier === state.selected) return true;
    return false;
  };

  const onTabClick = (tab: PortStatus) => {
    const vessels =
      (tab === PortStatus.InPort
        ? vesselsInPortQuery
        : tab === PortStatus.Arriving
          ? vesselsArrivingQuery
          : vesselsDepartingQuery) || [];
    zoomMapOverMarkers(
      vessels.map((vessel) => [vessel.positions?.[0].longitude as number, vessel.positions?.[0].latitude as number]),
      state.mapInstance
    );
    dispatch(setSelectedTab(tab));
  };

  return (
    <div className="PortVessels w-full h-full flex flex-col min-h-[35rem] lg:min-h-[unset]">
      <p className="text-xl">Vessels</p>
      <div>
        <div>
          <Select
            value={state.portVesselFilter}
            options={portVesselFilterSelectOptions}
            onChange={(value) => dispatch(setPortVesselFilter(value))}
            className="my-2"
            placeholder="Filter by fleet"
          />
        </div>
      </div>
      <div className="tabs tabs-bordered">
        <a
          className={`tab text-sm md:text-base text-white whitespace-nowrap ${
            state.selectedTab === PortStatus.InPort ? 'tab-active !border-orange-500' : ''
          } `}
          onClick={() => onTabClick(PortStatus.InPort)}
        >
          <span className="flex flex-nowrap items-center">
            In Port
            {inPortLoading ? (
              <Spinner className="ml-1 h-3 w-3" />
            ) : (
              <span className="ml-1 text-orange-400 text-sm">{vesselsInPortQuery?.length}</span>
            )}
          </span>
        </a>
        <a
          className={`tab text-sm md:text-base text-white whitespace-nowrap ${
            state.selectedTab === PortStatus.Arriving ? 'tab-active !border-orange-500' : ''
          } `}
          onClick={() => onTabClick(PortStatus.Arriving)}
        >
          <span className="flex flex-nowrap items-center">
            Arrivals
            {arrivalsLoading ? (
              <Spinner className="ml-1 h-3 w-3" />
            ) : (
              <span className="ml-1 text-orange-400 text-sm">{vesselsArrivingQuery?.length}</span>
            )}
          </span>
        </a>
        <a
          className={`tab text-sm md:text-base text-white whitespace-nowrap ${
            state.selectedTab === PortStatus.Departing ? 'tab-active !border-orange-500' : ''
          } `}
          onClick={() => onTabClick(PortStatus.Departing)}
        >
          <span className="flex flex-nowrap items-center">
            Departures
            {departuresLoading ? (
              <Spinner className="ml-1 h-3 w-3" />
            ) : (
              <span className="ml-1 text-orange-400 text-sm">{vesselsDepartingQuery?.length}</span>
            )}
          </span>
        </a>
      </div>
      <div hidden={state.selectedTab !== PortStatus.InPort} className="overflow-y-auto">
        {sortVessels(vesselsInPortQuery, PortStatus.InPort)?.map((vessel) => {
          const selected = isSelected(vessel.imo ? vessel.imo : vessel.mmsi);
          return (
            <div
              className={`flex ${selected ? 'bg-secondary' : 'bg-primary'}`}
              key={vessel.mmsi}
              data-event={`port-${vessel.imo || vessel.mmsi}`}
            >
              <PortVesselItem vessel={vessel} status={PortStatus.InPort} />
            </div>
          );
        })}
      </div>
      <div hidden={state.selectedTab !== PortStatus.Arriving} className="overflow-y-auto">
        {sortVessels(vesselsArrivingQuery, PortStatus.Arriving)?.map((vessel) => {
          const selected = isSelected(vessel.imo ? vessel.imo : vessel.mmsi);
          return (
            <div
              className={`flex ${selected ? 'bg-secondary' : 'bg-primary'}`}
              key={vessel.mmsi}
              data-event={`port-${vessel.imo || vessel.mmsi}`}
            >
              <PortVesselItem vessel={vessel} status={PortStatus.Arriving} />
            </div>
          );
        })}
      </div>
      <div hidden={state.selectedTab !== PortStatus.Departing} className="overflow-y-auto">
        {sortVessels(vesselsDepartingQuery, PortStatus.Departing)?.map((vessel) => {
          const selected = isSelected(vessel.imo ? vessel.imo : vessel.mmsi);
          return (
            <div
              className={`flex ${selected ? 'bg-secondary' : 'bg-primary'}`}
              key={vessel.mmsi}
              data-event={`port-${vessel.imo || vessel.mmsi}`}
            >
              <PortVesselItem vessel={vessel} status={PortStatus.Departing} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
