import React from 'react';

import { Select } from '../../../../common/components/Select';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { setState } from '../../../event-filters/slice';

type Props = {
  className?: string;
};

export const DryDockingFilters: React.FC<Props> = ({ className }) => {
  const state = useAppSelector((state) => state.eventFilters);
  const dispatch = useAppDispatch();

  return (
    <div className={`DryDockingFilters ${className ? className : ''}`}>
      <Select
        value={state.selectedDrydockingStatusOption}
        options={state.drydockingStatusOptions}
        onChange={(value) => dispatch(setState({ ...state, selectedDrydockingStatusOption: value }))}
        className="mb-2"
        placeholder="Filter by status"
      />
    </div>
  );
};
