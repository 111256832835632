import React from 'react';

import { IconDropdown } from '../../common/components/IconDropdown';
import { DownloadIcon } from '../../common/components/icons/Download';
import { useGetDetailedShipdataQuery, useGetVesselPositionsForSelectedDatesQuery } from '../vessel-details-view/hooks';
import { useAppSelector } from '../../common/hooks';
import { formatPositionData } from './utils';

type Props = {
  className?: string;
  imo?: string;
};

const downloadCsvFile = (data: any, headers: string[], filename: string) => {
  const csv = data.map((row: any) => row.join(',')).join('\n');
  const blob = new Blob([headers.join(',') + '\n' + csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const DownloadPositions: React.FC<Props> = ({ className, imo }) => {
  const { startDate, endDate } = useAppSelector((state) => state.vesselMapDateSelect);
  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(Number(imo));
  const shipDataQuery = useGetDetailedShipdataQuery(Number(imo));

  const downloadPositions = () => {
    if (positionsQuery.currentData) {
      const positions = positionsQuery.currentData.results;
      const headers = [
        'WPT No.',
        'LAT',
        '',
        '',
        'LON',
        '',
        '',
        'PORT[NM]',
        'STBD[NM]',
        'Arr.Rad[NM]',
        'Speed[kn]',
        'Sail(RL/GC)',
        'ROT[deg/min]',
        'Turn Rad [NM]',
        'Wind Dir[deg]',
        'Time Zone',
        'Time',
        'Name',
      ];
      const filename = `${shipDataQuery.currentData?.shipname} ${startDate} - ${endDate}`;
      downloadCsvFile(formatPositionData(positions?.[0].positions), headers, filename);
    }
  };

  return (
    <div>
      <div className={`DownloadPositions ${className ? className : ''}`}>
        <IconDropdown
          listClassName="w-48"
          icon={<DownloadIcon />}
          items={
            <div className="cursor-pointer ">
              <div className="text-sm p-2 rounded hover:bg-secondary z-10 flex" onClick={() => downloadPositions()}>
                Download route as CSV
                <DownloadIcon className="ml-2 w-4" />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
