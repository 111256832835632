import toast from 'react-hot-toast';

import { ExclamationCircleIcon } from '../../common/components/icons/ExclamationCircleIcon';

export const createRequestErrorToast = (url: string, message: string, status?: number) => {
  toast(
    <div>
      <div className="flex items-center flex-wrap">
        <h1 className="text-xl font-bold mr-2">Request failed</h1>
        <ExclamationCircleIcon className="h6 w-6 text-error" />
      </div>
      <h2 className="font-semibold mt-2">URL</h2>
      <p className="break-all text-stone-400">{decodeURIComponent(url)}</p>
      <h2 className="font-semibold mt-2">Error ({status})</h2>
      <p className="text-stone-400">{message}</p>
    </div>,
    { style: { background: '#0f172b', border: '1px solid #08435f', color: '#fff' }, duration: 8000 }
  );
};

export const createAsyncActionErrorToast = (message: string) => {
  toast(
    <div>
      <div className="flex items-center flex-wrap">
        <h1 className="text-xl font-bold mr-2">Asynchronous action failed</h1>
        <ExclamationCircleIcon className="h6 w-6 text-error" />
      </div>
      <h2 className="font-semibold mt-2">Error</h2>
      <p>{message}</p>
    </div>
  );
};
