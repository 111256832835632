import React from 'react';

import { Spinner } from '../../../common/components/Spinner';
import { ChevronDown } from '../../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../../common/components/icons/ChevronUp';
import { useInstallationsInstallationIdPartnersGetQuery } from '../../../store/apis/installation-api';
import { getInstallationPartnerRoleNames } from '../utils';
import { setVesselDetailsState } from '../slice';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';

type Props = {
  installationId?: number;
  className?: string;
};

export const InstallationPartners: React.FC<Props> = ({ installationId, className }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.vesselDetails);
  const isOpen = state.isInstallationPartnersOpen;

  const partnersQuery = useInstallationsInstallationIdPartnersGetQuery(
    { installationId: installationId?.toString() as string },
    { skip: !installationId }
  );

  if (!installationId) {
    return null;
  }

  const hiddenRoleCodes = ['PE'];
  const unknownPartnerId = '21500';
  const partners = partnersQuery.currentData?.filter(
    (partner) => partner.type?.code && !hiddenRoleCodes.includes(partner.type.code) && partner.id !== unknownPartnerId
  );

  return (
    <div className={`InstallationPartners ${className ? className : ''}`}>
      <div className="flex flex-row items-center justify-between border-b border-secondary pb-1 mb-2">
        <div className="flex flex-row items-center">
          <div onClick={() => dispatch(setVesselDetailsState({ ...state, isInstallationPartnersOpen: !isOpen }))}>
            {isOpen ? (
              <ChevronUp className="h-5 w-5 mr-2 cursor-pointer" />
            ) : (
              <ChevronDown className="h-5 w-5 mr-2 cursor-pointer" />
            )}
          </div>
          <span
            className="text-lg cursor-pointer"
            onClick={() => dispatch(setVesselDetailsState({ ...state, isInstallationPartnersOpen: !isOpen }))}
          >
            Installation partners
          </span>
        </div>
        <div className="bg-secondary rounded py-1 px-2 text-xs">
          Source:{' '}
          <span className="text-accent">
            <a
              className="hover:underline"
              target="_blank"
              href={`https://cib.data.wartsila.com/installations/${installationId}`}
              rel="noreferrer"
              title="Link to CIB"
            >
              Wärtsilä CIB
            </a>
          </span>
        </div>
      </div>
      {isOpen ? (
        <>
          {partnersQuery.isFetching && <Spinner className="h-6 w-6" />}
          {!partnersQuery.isFetching && !!partners?.length && (
            <ul>
              {partners.map((partner) => (
                <li key={partner.id} className="mb-2">
                  <a
                    className="hover:underline"
                    href={`https://cib.data.wartsila.com/customers/${partner.id}`}
                    target="_blank"
                    rel="noreferrer"
                    title="Link to partner in CIB"
                  >
                    {partner.id}
                  </a>{' '}
                  {partner.name} <span className="text-stone-400">[{getInstallationPartnerRoleNames(partner)}]</span>
                </li>
              ))}
            </ul>
          )}
          {!partnersQuery.isFetching && !partners?.length && <div className="text-stone-400">No partners found</div>}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
