import { useEffect } from 'react';

import { useAppDispatch } from '../../common/hooks';
import { useGetUserFiltersUserFiltersGetQuery, UserFilter } from '../../store/apis/csi-api';
import { setUserFilterOptions } from './slice';

export const useSetupUserFilterOptions = () => {
  const dispatch = useAppDispatch();
  const query = useGetUserFiltersUserFiltersGetQuery();

  useEffect(() => {
    if (query.isLoading) {
      return;
    }

    dispatch(setUserFilterOptions(query.data as UserFilter[]));
  }, [dispatch, query.data, query.isLoading]);
};
