import React from 'react';

import { ClockIcon } from '../../../../common/components/icons/ClockIcon';
import { VesselChangesIhs } from '../../../../store/apis/vessel-event-api';

type Props = {
  event: VesselChangesIhs;
};

const ihsChangeText = (event: VesselChangesIhs) => {
  if (event.field === 'ship_name')
    return (
      <p className="text-sm w-10/12">
        Vessel's name changed from <b>{event.old_value}</b> to <b>{event.new_value}</b>
      </p>
    );
  if (event.new_value && event.old_value) {
    // Extracts name and ID from string formatted as "name (id)"
    const [oldName, oldId] = event.old_value.match(/(.*)\s\((.*)\)/)?.slice(1) ?? [];
    const [newName, newId] = event.new_value.match(/(.*)\s\((.*)\)/)?.slice(1) ?? [];

    if (oldId === newId)
      return (
        <p className="text-sm w-10/12">
          {convertFieldName(event.field ?? 'unknown field')} was renamed from <b>{oldName}</b> to <b>{newName}</b>
        </p>
      );
  }
  return (
    <p className="text-sm w-10/12">
      {convertFieldName(event.field ?? 'unknown field')} changed
      <br />
      from <b>{event.old_value}</b>
      <br />
      to <b>{event.new_value}</b>
    </p>
  );
};

const convertFieldName = (field: string) => {
  const splitWords = field.split('_');
  return splitWords
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export const IhsEventItem: React.FC<Props> = ({ event }) => (
  <div className={'IhsEventItem border-b border-neutral py-3 p-2 text-[#f2f2f2] flex flex-row'} key={event.id}>
    <div className="flex-1 flex flex-col">
      <div className="text-gray-400 flex flex-wrap items-center mb-2">
        <ClockIcon className="h-4 w-4" />
        <span className="ml-1">{event.valid_from}</span>
        <span className="text-gray-400 text-sm capitalize pl-1">| IHS Sea-Web change</span>
      </div>
      {ihsChangeText(event)}
    </div>
    <div className="flex flex-col">
      <img alt={`IHS Change marker`} src={'/assets/images/markers/ihs_change.svg'} className="mb-1 mx-3 h-8 w-8"></img>
    </div>
  </div>
);
