import { useEffect } from 'react';

import { useAppDispatch } from '../../common/hooks';
import { useIsUserAFleetUserFleetUserGetQuery } from '../../store/apis/csi-api';
import { fleetOptions } from './filter-options';
import { FleetOption, setFleetOptions } from './slice';

export const useSetupMyFleetOption = () => {
  const dispatch = useAppDispatch();
  const query = useIsUserAFleetUserFleetUserGetQuery({});

  useEffect(() => {
    if (query.isLoading) {
      return;
    }

    if (!query.data?.user_is_a_fleet_user) {
      return;
    }

    const myFleetOption: FleetOption = { label: 'My fleet', value: 'my-fleet' };
    dispatch(setFleetOptions([...fleetOptions, myFleetOption]));
  }, [dispatch, query.data?.user_is_a_fleet_user, query.isLoading]);
};
