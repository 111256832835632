import dayjs from 'dayjs';

import { ServiceNotifications } from '../../store/apis/csi-api';

export type BannerSeverity = 'critical' | 'warning' | 'message' | 'positive';

export type Banner = {
  text: string;
  severity: string;
};

const filterBanners = (banner: ServiceNotifications, environment: 'local' | 'dev' | 'test' | 'prod') => {
  const now = dayjs();
  if (!banner.environment.includes(environment)) return false;
  if (
    (!banner.from_timestamp || dayjs(banner.from_timestamp) < now) &&
    (!banner.to_timestamp || dayjs(banner.to_timestamp) > now)
  )
    return true;
  return false;
};

export const getBannersForEnvironment = (
  data: ServiceNotifications[],
  environment: 'local' | 'dev' | 'test' | 'prod'
) => {
  return data
    .filter((banner) => filterBanners(banner, environment))
    .map((banner) => ({ text: banner.message, severity: banner.severity }));
};
