import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getStateFromUrl } from './utils';

type Endpoint = 'VESSEL_EVENT' | 'VESSEL_CSI' | 'IHS_VESSEL_DATA' | 'AIS' | 'AIS_DEV' | 'INSTALLATION' | 'PORT' | 'IPR';

type APIResource = {
  APP_ID: string;
  BASE_URL: string;
};

export type Config = {
  ENVIRONMENT: 'local' | 'dev' | 'test' | 'prod';
  VERSION: string;
  CLIENT_ID: string;
  TENANT_ID: string;
  MAPBOX_ACCESS_TOKEN: string;
  MAPBOX_STYLE: string;
  MAPBOX_STREET_STYLE: string;
  MAPBOX_EMISSION_LAYER_URL: string;
  ENDPOINTS: Record<Endpoint, APIResource>;
  DATADOG_RUM_ENABLED: boolean;
  DATADOG_RUM_APPLICATION_ID: string;
  DATADOG_RUM_CLIENT_TOKEN: string;
  DATADOG_PROXY_HOST: string;
  POLYGON_EDITORS_LIST: string[];
};

export type CommonState = {
  config: Config | null;
  useDevAisApi: boolean;
};

const sliceName = 'common';

export const fetchConfig = createAsyncThunk<Config>(`${sliceName}/fetchConfig`, async () => {
  const res = await fetch('/config.json');
  const config = await res.json();
  return config;
});

const initialState: CommonState = { config: null, useDevAisApi: false };
const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: sliceName,
  initialState: initialStateFromUrl,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled.type, (state, action: PayloadAction<Config>) => {
      state.config = action.payload;
    });
  },
});

export const { reducer } = slice;
