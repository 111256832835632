import { UserFleet } from '../../store/apis/csi-api';
import { Shipdata } from '../../store/apis/ihs-vessel-data-api';
import { MyFleetState } from './slice';

export const filterSearchResults = (results: Shipdata[], manualFleet: UserFleet[] | undefined) =>
  results.filter((v) => !manualFleet?.find(({ vessel_name }) => v.shipname === vessel_name));

type MyFleetUrlParams = {
  person?: string;
};

export const getStateFromUrl = (initialState: MyFleetState): MyFleetState => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<MyFleetUrlParams> = Object.fromEntries(urlSearchParams.entries());

  if (params.person) {
    return { ...initialState, otherFleetUser: params.person };
  }

  return initialState;
};
