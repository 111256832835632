import React from 'react';

import { Select } from '../../../../common/components/Select';
import { TextInput } from '../../../../common/components/TextInput';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { setState } from '../../../event-filters/slice';

type Props = {
  className?: string;
};

export const CasualtyFilters: React.FC<Props> = ({ className }) => {
  const state = useAppSelector((state) => state.eventFilters);
  const dispatch = useAppDispatch();

  return (
    <div className={`CasualtyFilters ${className ? className : ''}`}>
      <Select
        value={state.selectedCauseOptions}
        options={state.causeOptions}
        onChange={(value) => dispatch(setState({ ...state, selectedCauseOptions: value }))}
        isClearable
        className="mb-2"
        placeholder="Filter by cause"
        isMulti
      />
      <Select
        value={state.selectedCrmLeadOptions}
        options={state.crmLeadOptions}
        onChange={(value) => dispatch(setState({ ...state, selectedCrmLeadOptions: value }))}
        isClearable
        className="mb-2"
        placeholder="Filter by CRM lead status"
        isMulti
      />
      <TextInput
        value={state.selectedDescriptionOption}
        transformInput={(value) => {
          return value.replace(/^,+|(,)+|[^a-zA-Z0-9,]+/g, '$1');
        }}
        onChange={(value) => dispatch(setState({ ...state, selectedDescriptionOption: value }))}
        placeholder="Filter by words in description (e.g. bearing,covid)"
        className="mb-2"
        useDebounce
      />
    </div>
  );
};
