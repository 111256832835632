import { useEffect } from 'react';

import { Spinner } from '../../../common/components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useGetFilteredVesselLocations, useGetMyFleet } from '../hooks';
import { setSelectedTab, TabOption } from '../slice';
import { MyFleetVesselItem } from './MyFleetVesselItem';

export const MyFleetVessels: React.FC = () => {
  const state = useAppSelector((state) => state.myFleet);
  const dispatch = useAppDispatch();

  const fleetQuery = useGetMyFleet();
  const vesselPositionQuery = useGetFilteredVesselLocations();

  useEffect(() => {
    if (fleetQuery.currentData) fleetQuery.refetch();
  }, []);

  const renderTabLink = (tab: TabOption, title: string, isLoading: boolean, eventCount?: number) => (
    <a
      className={`tab text-sm md:text-base text-white whitespace-nowrap ${
        state.selectedTab === tab ? 'tab-active !border-orange-500' : ''
      } `}
      onClick={() => dispatch(setSelectedTab(tab))}
    >
      <span className="flex flex-nowrap items-center">
        {title}
        <span className="ml-1 text-orange-400 text-sm">{eventCount}</span>
      </span>
    </a>
  );

  return (
    <div className="MyFleetVessels h-full min-w-[30rem] flex flex-col">
      <h1 className="text-2xl text-primary-content mt-2">My fleet</h1>
      <div className="tabs tabs-bordered shadow-md py-2 flex">
        {!!state.myAutomaticFleet?.length &&
          renderTabLink('automatic', 'Automatic', fleetQuery.isFetching, state.myAutomaticFleet?.length)}
        {!!state.myManualFleet?.length &&
          renderTabLink('manual', 'Manual', fleetQuery.isFetching, state.myManualFleet?.length)}
      </div>
      {fleetQuery.isLoading ? (
        <Spinner className="h-10 w-10 mx-auto"></Spinner>
      ) : (
        <div className=" overflow-y-scroll">
          {(state.selectedTab === 'manual'
            ? state.myManualFleet?.slice(state.manualFleetPage * 100, (state.manualFleetPage + 1) * 100)
            : state.selectedTab === 'automatic'
              ? state.myAutomaticFleet
              : []
          )?.map((vessel) => (
            <MyFleetVesselItem
              vessel={vessel}
              vesselLocation={vesselPositionQuery.currentData?.find(
                (vesselPosition) => vesselPosition.imo === vessel.imo_number
              )}
              refetchFunction={fleetQuery.refetch}
              key={vessel.imo_number}
            />
          ))}
        </div>
      )}
    </div>
  );
};
