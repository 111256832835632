import React from 'react';

import { useUsageLogInterceptorToGlobalFetch } from './hooks';

type Props = {
  children?: React.ReactNode;
};

export const UsageLogWrapper: React.FC<Props> = ({ children }) => {
  useUsageLogInterceptorToGlobalFetch();
  return <>{children}</>;
};
