import dayjs from 'dayjs';

import { parseUrlTimeRange } from '../../common/utils';
import { VesselMapDateSelectState } from './slice';

export type VesselMapDateSelectUrlParams = {
  from?: string;
  to?: string;
  time?: string;
};

export const getStateFromUrl = (initialState: VesselMapDateSelectState): VesselMapDateSelectState => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<VesselMapDateSelectUrlParams> = Object.fromEntries(urlSearchParams.entries());

  const state = { ...initialState };

  if (params.time) {
    const { amount, unit } = parseUrlTimeRange(params.time) || {};
    const selectedTimeRange = initialState.timeRangeOptions.find(
      (item) => item.value.amount.toString() === amount && item.value.unit === unit
    );

    state.selectedTimeRangeOption = selectedTimeRange || null;

    if (amount && unit) {
      state.startDate = dayjs().subtract(Number(amount), unit).toISOString();
      state.endDate = dayjs().toISOString();
    }
  }

  if (params.from && params.to) {
    state.startDate = params.from;
    state.endDate = params.to;
  }

  return state;
};
