import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

type Props = {
  to: string;
  className?: string;
  children: React.ReactNode;
};

export const Link: React.FC<Props> = ({ to, className, children }) => {
  const location = useLocation();
  const active = location.pathname === to;

  return (
    <RouterLink
      to={to}
      className={`Link flex items-center h-full text-base p-2 px-4 hover:bg-primary cursor-pointer whitespace-nowrap ${
        active ? 'font-semibold' : ''
      } ${className ? className : ''}`}
    >
      {children}
    </RouterLink>
  );
};
