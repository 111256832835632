import { useCallback, useEffect } from 'react';

import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { acquireTokenForApiResourceSilent } from '../../common/utils';
import { RootState } from '../../store/store';
import { setAisAccessToken } from './slice';

type EmissionLegendOption = {
  interval: string;
  color: string;
};

const emissionLayerBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const baseUrl = (api.getState() as RootState).common.config!.MAPBOX_EMISSION_LAYER_URL;
  const rawBaseQuery = fetchBaseQuery({ baseUrl });
  return rawBaseQuery(args, api, extraOptions);
};

export const emissionLayerApi = createApi({
  reducerPath: 'emissionLayerApi',
  baseQuery: emissionLayerBaseQuery,
  endpoints: (builder) => ({
    getLegendOptions: builder.query<EmissionLegendOption[], string | undefined>({
      query: (queryString) => `legend-options${queryString ? queryString : ''}`,
    }),
  }),
});

export const useSetupAisAccessToken = () => {
  const dispatch = useAppDispatch();
  const msalInstance = useAppSelector((state) => state.auth.msalInstance!);
  const config = useAppSelector((state) => state.common.config!);
  const setupAisAccessToken = async () => {
    const token = await acquireTokenForApiResourceSilent(msalInstance, config.ENDPOINTS.AIS.APP_ID);
    dispatch(setAisAccessToken(token));
  };
  useEffect(() => {
    setupAisAccessToken();
  }, []);
};

export const useGetTransformAisTileRequestCallback = () => {
  useSetupAisAccessToken();
  const aisAccessToken = useAppSelector((state) => state.mapLayers.aisAccessToken!);
  const config = useAppSelector((state) => state.common.config!);

  const transformAisTileRequestCallback = useCallback(
    (url: string, resourceType: string) => {
      if (resourceType === 'Tile' && url.startsWith(config.ENDPOINTS.AIS.BASE_URL)) {
        return {
          url,
          headers: { Authorization: `Bearer ${aisAccessToken}` },
        };
      }
      return { url };
    },
    [config, aisAccessToken]
  );

  return transformAisTileRequestCallback;
};
