import { IPublicClientApplication } from '@azure/msal-browser';

import { Config } from '../../common/slice';
import { acquireTokenForApiResourceRedirect, acquireTokenForApiResourceSilent } from '../../common/utils';
import { UsageLogEntry } from '../../store/apis/csi-api';

const USAGE_LOG_ENDPOINT = '/usage-log';

const createUsageLog = async (
  config: Config,
  msalInstance: IPublicClientApplication,
  url: string,
  durationMs: number
) => {
  const usageLogEntry: UsageLogEntry = {
    url,
    duration_milliseconds: durationMs,
  };
  const appId = config.ENDPOINTS.VESSEL_CSI.APP_ID;
  let token: string | null = null;
  try {
    token = await acquireTokenForApiResourceSilent(msalInstance, appId);
  } catch {
    await acquireTokenForApiResourceRedirect(msalInstance, appId);
  }

  const endpoint = `${config.ENDPOINTS.VESSEL_CSI.BASE_URL}${USAGE_LOG_ENDPOINT}`;

  fetch(endpoint, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify(usageLogEntry),
  });
};

const shouldCreateUsageLogForUrl = (url: string, config: Config) => {
  if (url.endsWith(USAGE_LOG_ENDPOINT)) {
    return false;
  }

  for (const [_, resource] of Object.entries(config.ENDPOINTS)) {
    if (url.startsWith(resource.BASE_URL)) {
      return true;
    }
  }

  return false;
};

export const addUsageLogInterceptorToGlobalFetch = (appConfig: Config, msalInstance: IPublicClientApplication) => {
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    const startTimestamp = new Date().getTime();

    const [resource, config] = args;
    const response = await originalFetch(resource, config);

    const url = (resource as any)?.url as string | undefined;
    if (url && shouldCreateUsageLogForUrl(url, appConfig)) {
      const durationMs = new Date().getTime() - startTimestamp;
      createUsageLog(appConfig, msalInstance, url, durationMs);
    }

    return response;
  };
};
