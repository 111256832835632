import { useState } from 'react';
import toast from 'react-hot-toast';

import { CloseIcon } from '../../common/components/icons/CloseIcon';
import { ThumbsDownIcon } from '../../common/components/icons/ThumbsDownIcon';
import { ThumbsUpIcon } from '../../common/components/icons/ThumbsUpIcon';
import { Spinner } from '../../common/components/Spinner';
import { TextAreaInput } from '../../common/components/TextAreaInput';
import {
  UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiArg,
  useGetAnomalyRatingsAnomalyRatingsGetQuery,
  useUpdateAnomalyRatingAnomalyRatingsAnomalyIdPutMutation,
} from '../../store/apis/csi-api';

enum AnomalyRatingValue {
  Negative = 0,
  Positive = 1,
}

type Props = {
  anomalyId: number;
  className?: string;
};

export const AnomalyRating: React.FC<Props> = ({ anomalyId, className }) => {
  const [createAnomalyRating, createAnomalyRatingResult] = useUpdateAnomalyRatingAnomalyRatingsAnomalyIdPutMutation();
  const ratingsQuery = useGetAnomalyRatingsAnomalyRatingsGetQuery({});
  const [comment, setComment] = useState('');
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const currentAnomalyRating = ratingsQuery.currentData?.find((rating) => rating.anomaly_id === anomalyId);

  const sendRatingHandler = async (vote: AnomalyRatingValue, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.stopPropagation();

    if (createAnomalyRatingResult.isLoading) {
      return;
    }

    // Undo vote if vote was already set to the same value previously
    const newVote = currentAnomalyRating?.vote === vote ? undefined : vote;

    const anomalyRating: UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiArg = {
      anomalyId: anomalyId,
      anomalyRatingUpdate: { vote: newVote },
    };

    try {
      await createAnomalyRating(anomalyRating).unwrap();
      ratingsQuery.refetch();

      if (newVote !== undefined) {
        setShowThankYouMessage(true);
      }
    } catch (error) {
      setShowThankYouMessage(true);
      toast.error('Could not submit rating, please try again.');
    }
  };

  const isFeedbackOpenHandler = (value: boolean, e: React.MouseEvent<any, MouseEvent>) => {
    e?.stopPropagation();
    setIsFeedbackOpen(value);
  };

  const setThankYouMessageHandler = (value: boolean, e: React.MouseEvent<any, MouseEvent>) => {
    e?.stopPropagation();
    setShowThankYouMessage(value);
  };

  const sendCommentHandler = async (e: React.MouseEvent<any, MouseEvent>) => {
    e?.stopPropagation();

    if (createAnomalyRatingResult.isLoading) {
      return;
    }

    const anomalyRating: UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiArg = {
      anomalyId: anomalyId,
      anomalyRatingUpdate: { vote: currentAnomalyRating?.vote, comment: comment },
    };

    try {
      await createAnomalyRating(anomalyRating).unwrap();
      setIsFeedbackOpen(false);
      setShowThankYouMessage(false);
      ratingsQuery.refetch();
    } catch (error) {
      toast.error('Could not submit comment, please try again.');
    }
  };

  return (
    <div id="anomaly-rating" className={`AnomalyRating ${className ? className : ''}`}>
      {showThankYouMessage ? (
        <>
          <div className="flex items-center">
            Thank you!
            <span
              className="text-cyan-600 hover:underline cursor-pointer mx-2"
              onClick={(e) => isFeedbackOpenHandler(true, e)}
            >
              Give more feedback
            </span>
            <span onClick={(e) => setThankYouMessageHandler(false, e)} className="text-gray-300 cursor-pointer">
              <CloseIcon className="h5 w-5 hover:text-accent" />
            </span>
          </div>
          {isFeedbackOpen && (
            <div>
              <TextAreaInput className="mt-1" value={comment} onChange={setComment} placeholder="Message" rows={3} />
              <div className="mt-2">
                <button
                  className="btn btn-sm btn-secondary mr-2 disabled:text-gray-500"
                  onClick={(e) => sendCommentHandler}
                  disabled={!comment.length}
                >
                  Send {createAnomalyRatingResult.isLoading && <Spinner className="ml-2" />}
                </button>
                <button className="btn btn-sm" onClick={(e) => isFeedbackOpenHandler(false, e)}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center">
          <div
            title="This anomaly was helpful/interesting"
            onClick={(e) => sendRatingHandler(AnomalyRatingValue.Positive, e)}
          >
            <ThumbsUpIcon
              className={`h5 w-5 mr-1 hover:stroke-black hover:fill-success hover:cursor-pointer ${
                currentAnomalyRating?.vote === AnomalyRatingValue.Positive ? 'fill-success stroke-black' : ''
              }`}
            />
          </div>
          <div title="This is not an anomaly!" onClick={(e) => sendRatingHandler(AnomalyRatingValue.Negative, e)}>
            <ThumbsDownIcon
              className={`h5 w-5 mr-1 hover:stroke-black hover:fill-red-500 hover:cursor-pointer ${
                currentAnomalyRating?.vote === AnomalyRatingValue.Negative ? 'fill-red-500 stroke-black' : ''
              }`}
            />
          </div>
          {createAnomalyRatingResult.isLoading && <Spinner className="ml-1" />}
        </div>
      )}
    </div>
  );
};
