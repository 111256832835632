import { useCallback } from 'react';

type Props = {
  value: string | null;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  rows?: number;
};

export const TextAreaInput: React.FC<Props> = ({ value, onChange, className, placeholder, rows }) => {
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );
  return (
    <textarea
      value={value || ''}
      onChange={onChangeHandler}
      onClick={(e) => e.stopPropagation()}
      rows={rows}
      className={`
      input-secondary border-[1px] border-secondary pl-3 text-[14px] w-full text-primary-content 
      bg-primary rounded-[4px] focus:!outline-none placeholder-[#808080] py-2
      ${className ? className : ''}`}
      placeholder={placeholder}
    />
  );
};
