import React from 'react';
import { Layer, Source } from 'react-map-gl';

import { useAppSelector } from '../../common/hooks';
import { aisQualityLayerProps, getAisQualitySourceProps, getDatesForAISCall } from './utils';

export const AisQualityLayer: React.FC = () => {
  const aisBaseUrl = useAppSelector((state) => state.common.config!.ENDPOINTS.AIS.BASE_URL);
  const { aisQualityLayer } = useAppSelector((state) => state.mapLayers);
  const { config } = useAppSelector((state) => state.common);
  const timeRangeStart = useAppSelector((state) => state.vesselMapDateSelect.startDate);
  const [year, month] = getDatesForAISCall(timeRangeStart);

  if (!aisQualityLayer.enabled) {
    return null;
  }

  return (
    <Source
      {...getAisQualitySourceProps(
        `${aisBaseUrl}/aggregations/quality-tiles/{z}/{x}/{y}?month=${month}&year=${year}${
          config?.VERSION ? `&version=${config.VERSION}` : ''
        }`
      )}
    >
      <Layer {...aisQualityLayerProps} />
    </Source>
  );
};
