import React from 'react';

import { useAppDispatch } from '../../common/hooks';
import { setShowForm as setShowContactForm } from '../contact/slice';
import { setShowForm as setShowReportForm } from '../report-problem/slice';

export const Footer: React.FC = () => {
  const dispatch = useAppDispatch();
  const year = new Date().getFullYear();
  return (
    <footer className="Footer bg-secondary text-secondary-content flex items-center justify-between py-1 px-3 flex-col lg:flex-row">
      <div className="flex-grow basis-0 text-xs tracking-wide">
        <a
          className="mr-2 lg:mr-3 hover:underline"
          href="https://confluence.devops.wartsila.com/x/4gFlBw"
          target="_blank"
          rel="noreferrer"
        >
          Help
        </a>
        <a
          className="mx-2 lg:mx-3 hover:underline"
          href="https://web.yammer.com/main/org/wartsila.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNjAwOTY1MyJ9"
          target="_blank"
          rel="noreferrer"
        >
          Yammer
        </a>
        <a
          className="lg:mx-3 hover:underline"
          href="https://csi.feedback.wartsila.com"
          target="_blank"
          rel="noreferrer"
          title="Send us your improvement ideas through the ideation tool"
        >
          Improvement ideas
        </a>
        <button
          className="btn btn-xs btn-info btn-outline border-none"
          onClick={() => dispatch(setShowContactForm(true))}
        >
          Contact us
        </button>
        <button className="btn btn-xs btn-error ml-1" onClick={() => dispatch(setShowReportForm(true))}>
          Report a problem
        </button>
      </div>
      <div className="mt-3 lg:mt-0 text-xs text-gray-400">© {year} Wärtsilä</div>
      <div className="flex-grow basis-0"></div>
    </footer>
  );
};
