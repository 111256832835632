import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Spinner } from '../../../common/components/Spinner';
import { useAppDispatch, useAppSelector, useGetFilteredEvents } from '../../../common/hooks';
import { Port } from '../../../store/apis/ais-api';
import { Anomalies } from '../../../store/apis/vessel-event-api';
import { setSelectedEvent } from '../../event-feed/slice';
import { setDatesAndNullUrl, setSelectedTimeRangeOption } from '../../vessel-map-date-select/slice';
import { setSelectedPositionTimestamp } from '../../vessel-map/slice';
import { getCalculatedHeadingForPosition, isAnomalyPosition } from '../../vessel-map/utils';
import {
  useGetSelectedPosition,
  useGetVesselPositionsForSelectedDatesQuery,
  useTriggerCallbackWhenSelectedDatesChange,
} from '../hooks';
import { PortInformation } from '../slice';
import { getCourse, getDraught, getHeading, getLastPositionSourceName, getRateOfTurn, getSpeed } from '../utils';

const textColorClass = 'text-accent-content';

const renderStatus = (value: string, description: string, className?: string) => (
  <div
    title={description === 'SPEED' ? 'Speed over ground' : undefined}
    className={`flex flex-col items-center ${className ? className : ''}`}
  >
    <span className={`${textColorClass} text-sm lg:text-xl`}>{value}</span>
    <span className="text-sm">{description}</span>
  </div>
);

const renderPort = (title: string, port: Port, portInfo: PortInformation | undefined) => (
  <div className="flex">
    {title}
    <span className={`mx-2 fi fi-${port.country_code?.toLowerCase()}`} title={port.country_name} />
    {(portInfo && !portInfo.unlocode) || !port.unlocode ? (
      <span className="text-gray-400">
        {port.name}
        {port.unlocode && ` (${port.unlocode})`}
      </span>
    ) : (
      <Link to={`/port/${port.unlocode}`} className="hover:text-info" title="See port information">
        {port.name} ({port.unlocode})
      </Link>
    )}
  </div>
);

const renderLabel = (title: string) => (
  <span className="bg-secondary rounded py-1 px-1.5 text-xs shrink whitespace-nowrap h-fit mr-2 mb-1">{title}</span>
);

const renderEtaAndDestination = (lastKnownDestination: string | undefined, lastKnownEta: string | undefined) => {
  if (!lastKnownDestination || !lastKnownEta) {
    return (
      <div className="flex sm:flex-row">
        {renderLabel('Destination')}
        <span className="text-gray-400">No recent data available</span>
      </div>
    );
  } else {
    return (
      <>
        <div className="flex sm:flex-row">
          {renderLabel('Destination')}
          <span className={textColorClass}>{lastKnownDestination}</span>
        </div>
        <div className="flex sm:flex-row">
          {renderLabel('ETA')}
          <span className={textColorClass}>{dayjs.utc(lastKnownEta).format('YYYY-MM-DD HH:mm')} UTC</span>
        </div>
      </>
    );
  }
};

type Props = {
  imo: string;
  className?: string;
};

export const LastPosition: React.FC<Props> = ({ imo, className }) => {
  const dispatch = useAppDispatch();
  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(Number(imo));
  useTriggerCallbackWhenSelectedDatesChange(positionsQuery.refetch);
  const [lastKnownEta, setLastKnownEta] = useState<string | undefined>(undefined);
  const [lastKnownDestination, setLastKnownDestination] = useState<string | undefined>(undefined);

  const vesselPosition = positionsQuery.currentData?.results?.length ? positionsQuery.currentData.results[0] : null;
  const lastPosition = vesselPosition?.positions?.[0];

  const selectedPosition = useGetSelectedPosition();
  const position = selectedPosition || lastPosition;

  const { nextPort, currentPort, lastPort } = useAppSelector((state) => state.vesselDetails);
  const { anomalies } = useGetFilteredEvents();
  const isAnomalyMarker =
    position?.timestamp &&
    anomalies.currentData &&
    isAnomalyPosition(position.timestamp, anomalies.currentData as Anomalies[]);

  let imgSrc = isAnomalyMarker
    ? '/assets/images/positions/position-direction-anomaly.svg'
    : '/assets/images/positions/position-direction.svg';

  let heading: number | null | undefined = position?.heading;
  const positions = positionsQuery.currentData?.results?.[0]?.positions;
  if (position && positions && (heading === undefined || heading === null)) {
    heading = getCalculatedHeadingForPosition(position, positions);
    if (heading) {
      imgSrc = isAnomalyMarker
        ? '/assets/images/positions/position-direction-anomaly-gray.svg'
        : '/assets/images/positions/position-direction-gray.svg';
    } else {
      imgSrc = isAnomalyMarker
        ? '/assets/images/positions/position-no-direction-anomaly.svg'
        : '/assets/images/positions/position-no-direction.svg';
    }
  }

  useEffect(() => {
    if (!position?.voyage?.eta || !position?.voyage?.destination) {
      //Find last known positions that have voyage data (eta & destination)
      const lastKnownPositionsWithDetails = positions?.filter(({ voyage }) => voyage?.eta && voyage?.destination);

      //Find last known position that is before & closest to the currently selected position & less than 3h ago (648000000ms)
      const positionsWithDetails = lastKnownPositionsWithDetails?.filter(
        ({ timestamp }) =>
          dayjs(timestamp).isBefore(dayjs(position?.timestamp)) &&
          new Date(position!.timestamp!).valueOf() - new Date(timestamp!).valueOf() < 648000000
      );
      const lastKnownPositionWithDetails =
        positionsWithDetails?.length &&
        positionsWithDetails.reduce((posA, posB) =>
          dayjs(posB.timestamp).isAfter(dayjs(posA!.timestamp)) ? posB : posA
        );

      if (
        lastKnownPositionWithDetails &&
        lastKnownPositionWithDetails.voyage?.eta &&
        lastKnownPositionWithDetails.voyage?.destination &&
        !dayjs(lastKnownPositionWithDetails.voyage?.eta).isBefore(dayjs(position?.timestamp))
      ) {
        setLastKnownEta(lastKnownPositionWithDetails.voyage?.eta);
        setLastKnownDestination(lastKnownPositionWithDetails.voyage?.destination);
      }
    } else if (!dayjs(position?.voyage?.eta).isBefore(dayjs(position.timestamp))) {
      setLastKnownEta(position?.voyage?.eta);
      setLastKnownDestination(position?.voyage?.destination);
    }
  }, [position]);

  const onGoToCurrentLocationHandler = async () => {
    const currentDate = dayjs(new Date()).format('YYYY-MM-DD');

    await dispatch(setSelectedEvent(null));
    dispatch(setSelectedTimeRangeOption(null));
    dispatch(setSelectedPositionTimestamp(null));
    dispatch(
      setDatesAndNullUrl({
        startDate: currentDate,
        endDate: currentDate,
      })
    );
  };

  return (
    <div className={`LastPosition ${className ? className : ''}`}>
      <div className="flex items-center justify-between border-b border-secondary mb-2">
        <span className="text-lg flex items-center">
          {position && (
            <img alt="marker" src={imgSrc} className="w-[18px] mr-1" style={{ transform: `rotate(${heading}deg)` }} />
          )}
          Position
          {position && (
            <span
              className="ml-1 underline decoration-dotted"
              title={`${dayjs.utc(position?.timestamp).format('YYYY-MM-DD HH:mm:ss')} UTC`}
            >
              {dayjs.utc(position?.timestamp).fromNow()}
            </span>
          )}
          {positionsQuery.isFetching && <Spinner className="ml-2 h-3 w-3" />}
        </span>
        <div className="bg-secondary rounded py-1 px-2 text-xs mb-1">
          Source:{' '}
          <span className={textColorClass}>
            <a
              className="hover:underline"
              target="_blank"
              href={`https://www.marinetraffic.com/en/ais/details/ships/imo:${imo}
            `}
              title="Link to vessel in MarineTraffic"
              rel="noreferrer"
            >
              MarineTraffic
            </a>
          </span>
        </div>
      </div>
      {positionsQuery.isFetching && !position && <Spinner className="h-6 w-6" />}
      {!positionsQuery.isFetching && !position && (
        <div>
          <span className="text-stone-400">No vessel positions available for the selected time period or event. </span>
          <span className="cursor-pointer hover:text-info" onClick={onGoToCurrentLocationHandler}>
            Show last known position.
          </span>
        </div>
      )}
      {!positionsQuery.isLoading && position && (
        <>
          <div className="flex mt-2 sm:justify-between mb-3 sm:w-fit xl:w-full">
            {renderStatus(getSpeed(position), 'SPEED')}
            {renderStatus(getDraught(position), 'DRAUGHT', 'pl-3')}
            {renderStatus(getHeading(position), 'HEADING', 'pl-3')}
            {renderStatus(getCourse(position), 'COURSE', 'pl-3')}
            {renderStatus(getRateOfTurn(position), 'RATE OF TURN', 'pl-3')}
          </div>
          <div className="flex flex-nowrap flex-row items-baseline">
            {renderLabel('Received via')}
            <div className="flex flex-wrap">
              <span className="text-accent-content">{getLastPositionSourceName(position)}</span>
              <span className="mx-1">at</span>
              <span className={textColorClass}>{`${dayjs
                .utc(position?.timestamp)
                .format('YYYY-MM-DD HH:mm:ss')} UTC`}</span>
            </div>
          </div>
          <div className="flex flex-nowrap flex-row items-baseline">
            {renderLabel('Status')}
            <div className="flex flex-wrap">
              <span className={textColorClass}>{position.status_desc || 'N/A'}</span>
              <span className={'mx-1'}>at</span>
              <span className={textColorClass}>
                {position?.latitude?.toFixed(4)} {position?.longitude?.toFixed(4)}
              </span>
            </div>
          </div>
          <div>{renderEtaAndDestination(lastKnownDestination, lastKnownEta)}</div>
          <div className="flex flex-col mt-2">
            {vesselPosition?.ports?.last_port && renderPort('Last port:', vesselPosition.ports.last_port, lastPort)}
            {vesselPosition?.ports?.current_port &&
              renderPort('Current port:', vesselPosition.ports.current_port, currentPort)}
            {vesselPosition?.ports?.next_port && renderPort('Next port:', vesselPosition.ports.next_port, nextPort)}
          </div>
        </>
      )}
    </div>
  );
};
