import React from 'react';

import { useAppSelector } from '../../common/hooks';
import { themeColors } from '../../common/utils';

export const NonProdIndicator: React.FC = () => {
  const { ENVIRONMENT } = useAppSelector((state) => state.common.config!);

  if (['test'].includes(ENVIRONMENT)) {
    return (
      <div>
        <div
          className={`h-6 flex flex-row justify-center items-center text-primary text-sm`}
          style={{ backgroundColor: themeColors.error, color: 'white', height: '3rem' }}
        >
          <span>
            This is the {ENVIRONMENT} environment of CSI, please make sure to use{' '}
            <a href="https://csi.wartsila.com" className="hover:underline">
              csi.wartsila.com
            </a>{' '}
            unless you need to test new functionality
          </span>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
