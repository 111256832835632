import { vesselCsiBaseApi as api } from '../base/base-apis';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createIssueIssuesPost: build.mutation<CreateIssueIssuesPostApiResponse, CreateIssueIssuesPostApiArg>({
      query: (queryArg) => ({ url: `/issues`, method: 'POST', body: queryArg.issueRequest }),
    }),
    getUserFiltersUserFiltersGet: build.query<
      GetUserFiltersUserFiltersGetApiResponse,
      GetUserFiltersUserFiltersGetApiArg
    >({
      query: () => ({ url: `/user-filters` }),
    }),
    createUserFilterUserFiltersPost: build.mutation<
      CreateUserFilterUserFiltersPostApiResponse,
      CreateUserFilterUserFiltersPostApiArg
    >({
      query: (queryArg) => ({ url: `/user-filters`, method: 'POST', body: queryArg.userFilter }),
    }),
    getUserFilterUserFiltersFilterIdGet: build.query<
      GetUserFilterUserFiltersFilterIdGetApiResponse,
      GetUserFilterUserFiltersFilterIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/user-filters/${queryArg.filterId}` }),
    }),
    updateUserFilterUserFiltersFilterIdPut: build.mutation<
      UpdateUserFilterUserFiltersFilterIdPutApiResponse,
      UpdateUserFilterUserFiltersFilterIdPutApiArg
    >({
      query: (queryArg) => ({ url: `/user-filters/${queryArg.filterId}`, method: 'PUT', body: queryArg.userFilter }),
    }),
    deleteUserFilterUserFiltersFilterIdDelete: build.mutation<
      DeleteUserFilterUserFiltersFilterIdDeleteApiResponse,
      DeleteUserFilterUserFiltersFilterIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/user-filters/${queryArg.filterId}`, method: 'DELETE' }),
    }),
    isUserAFleetUserFleetUserGet: build.query<
      IsUserAFleetUserFleetUserGetApiResponse,
      IsUserAFleetUserFleetUserGetApiArg
    >({
      query: (queryArg) => ({ url: `/fleet-user`, params: { person: queryArg.person } }),
    }),
    getUserFleetFleetGet: build.query<GetUserFleetFleetGetApiResponse, GetUserFleetFleetGetApiArg>({
      query: (queryArg) => ({ url: `/fleet`, params: { has_fleet: queryArg.hasFleet, person: queryArg.person } }),
    }),
    createVesselInFleetFleetPost: build.mutation<
      CreateVesselInFleetFleetPostApiResponse,
      CreateVesselInFleetFleetPostApiArg
    >({
      query: (queryArg) => ({ url: `/fleet`, method: 'POST', params: { imo_number: queryArg.imoNumber } }),
    }),
    getVesselInFleetFleetImoNumberGet: build.query<
      GetVesselInFleetFleetImoNumberGetApiResponse,
      GetVesselInFleetFleetImoNumberGetApiArg
    >({
      query: (queryArg) => ({ url: `/fleet/${queryArg.imoNumber}` }),
    }),
    deleteVesselFromFleetFleetImoNumberDelete: build.mutation<
      DeleteVesselFromFleetFleetImoNumberDeleteApiResponse,
      DeleteVesselFromFleetFleetImoNumberDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/fleet/${queryArg.imoNumber}`, method: 'DELETE' }),
    }),
    createUsageLogEntryUsageLogPost: build.mutation<
      CreateUsageLogEntryUsageLogPostApiResponse,
      CreateUsageLogEntryUsageLogPostApiArg
    >({
      query: (queryArg) => ({ url: `/usage-log`, method: 'POST', body: queryArg.usageLogEntry }),
    }),
    updateAnomalyRatingAnomalyRatingsAnomalyIdPut: build.mutation<
      UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiResponse,
      UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/anomaly-ratings/${queryArg.anomalyId}`,
        method: 'PUT',
        body: queryArg.anomalyRatingUpdate,
      }),
    }),
    getAnomalyRatingsAnomalyRatingsGet: build.query<
      GetAnomalyRatingsAnomalyRatingsGetApiResponse,
      GetAnomalyRatingsAnomalyRatingsGetApiArg
    >({
      query: (queryArg) => ({ url: `/anomaly-ratings`, params: { imo: queryArg.imo } }),
    }),
    getServiceNotificationsServiceNotificationsGet: build.query<
      GetServiceNotificationsServiceNotificationsGetApiResponse,
      GetServiceNotificationsServiceNotificationsGetApiArg
    >({
      query: () => ({ url: `/service-notifications` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type CreateIssueIssuesPostApiResponse = /** status 201 Successful Response */ IssueResponse;
export type CreateIssueIssuesPostApiArg = {
  issueRequest: IssueRequest;
};
export type GetUserFiltersUserFiltersGetApiResponse = /** status 200 Successful Response */ UserFilter[];
export type GetUserFiltersUserFiltersGetApiArg = void;
export type CreateUserFilterUserFiltersPostApiResponse = /** status 201 Successful Response */ UserFilter;
export type CreateUserFilterUserFiltersPostApiArg = {
  userFilter: UserFilter;
};
export type GetUserFilterUserFiltersFilterIdGetApiResponse = /** status 200 Successful Response */ UserFilter;
export type GetUserFilterUserFiltersFilterIdGetApiArg = {
  filterId: number;
};
export type UpdateUserFilterUserFiltersFilterIdPutApiResponse = /** status 201 Successful Response */ any;
export type UpdateUserFilterUserFiltersFilterIdPutApiArg = {
  filterId: number;
  userFilter: UserFilter;
};
export type DeleteUserFilterUserFiltersFilterIdDeleteApiResponse = /** status 201 Successful Response */ any;
export type DeleteUserFilterUserFiltersFilterIdDeleteApiArg = {
  filterId: number;
};
export type IsUserAFleetUserFleetUserGetApiResponse = /** status 200 Successful Response */ FleetUser;
export type IsUserAFleetUserFleetUserGetApiArg = {
  person?: string;
};
export type GetUserFleetFleetGetApiResponse = /** status 200 Successful Response */ UserFleet[];
export type GetUserFleetFleetGetApiArg = {
  hasFleet: boolean;
  person?: string;
};
export type CreateVesselInFleetFleetPostApiResponse = /** status 201 Successful Response */ any;
export type CreateVesselInFleetFleetPostApiArg = {
  imoNumber: number;
};
export type GetVesselInFleetFleetImoNumberGetApiResponse = /** status 200 Successful Response */ FleetVessel;
export type GetVesselInFleetFleetImoNumberGetApiArg = {
  imoNumber: number;
};
export type DeleteVesselFromFleetFleetImoNumberDeleteApiResponse = /** status 201 Successful Response */ any;
export type DeleteVesselFromFleetFleetImoNumberDeleteApiArg = {
  imoNumber: number;
};
export type CreateUsageLogEntryUsageLogPostApiResponse = /** status 201 Successful Response */ any;
export type CreateUsageLogEntryUsageLogPostApiArg = {
  usageLogEntry: UsageLogEntry;
};
export type UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiResponse = /** status 201 Successful Response */ any;
export type UpdateAnomalyRatingAnomalyRatingsAnomalyIdPutApiArg = {
  anomalyId: number;
  anomalyRatingUpdate: AnomalyRatingUpdate;
};
export type GetAnomalyRatingsAnomalyRatingsGetApiResponse = /** status 200 Successful Response */ AnomalyRating[];
export type GetAnomalyRatingsAnomalyRatingsGetApiArg = {
  imo?: number;
};
export type GetServiceNotificationsServiceNotificationsGetApiResponse =
  /** status 200 Successful Response */ ServiceNotifications[];
export type GetServiceNotificationsServiceNotificationsGetApiArg = void;
export type IssueResponse = {
  id: string;
  link: string;
};
export type Error = {
  message: string;
};
export type IssueRequest = {
  text: string;
  url: string;
  summary?: string;
};
export type AlertSchedule = 'hourly' | 'instant' | 'daily' | 'weekly' | 'monthly';
export type NotificationType = 'email';
export type EventType = 'casualties' | 'anomalies' | 'dry_docking';
export type Fleet = 'global' | 'imo' | 'wartsila' | 'my_fleet';
export type FilterSettings = {
  timeframe?: string;
  event_types?: EventType[];
  fleet?: Fleet;
  vessel_types?: string[];
  customers?: string[];
  products?: string[];
  locations?: string[];
  anomaly_characteristics?: string[];
  anomaly_confidence_min?: number;
  anomaly_confidence_max?: number;
  casualty_causes?: string[];
  casualty_crm_leads?: string[];
  casualty_description?: string[];
};
export type UserFilter = {
  id?: number;
  name: string;
  alert_schedule?: AlertSchedule;
  notification_type?: NotificationType;
  settings: FilterSettings;
};
export type FleetUser = {
  user_is_a_fleet_user: boolean;
};
export type FleetVesselType = 'automatic' | 'manual';
export type UserFleet = {
  installation_id?: number;
  imo_number: number;
  vessel_name: string;
  type_name: string;
  status_name: string;
  fleet_type: FleetVesselType;
};
export type FleetVessel = {
  vessel_in_fleet: boolean;
  fleet_type?: FleetVesselType;
};
export type UsageLogEntry = {
  url: string;
  duration_milliseconds: number;
};
export type AnomalyRatingUpdate = {
  vote?: number;
  comment?: string;
};
export type AnomalyRating = {
  anomaly_id: number;
  vote?: number;
  comment?: string;
};
export type ServiceNotifications = {
  from_timestamp?: string;
  to_timestamp?: string;
  severity: string;
  message: string;
  environment: string[];
};
export const {
  useCreateIssueIssuesPostMutation,
  useGetUserFiltersUserFiltersGetQuery,
  useCreateUserFilterUserFiltersPostMutation,
  useGetUserFilterUserFiltersFilterIdGetQuery,
  useUpdateUserFilterUserFiltersFilterIdPutMutation,
  useDeleteUserFilterUserFiltersFilterIdDeleteMutation,
  useIsUserAFleetUserFleetUserGetQuery,
  useGetUserFleetFleetGetQuery,
  useCreateVesselInFleetFleetPostMutation,
  useGetVesselInFleetFleetImoNumberGetQuery,
  useDeleteVesselFromFleetFleetImoNumberDeleteMutation,
  useCreateUsageLogEntryUsageLogPostMutation,
  useUpdateAnomalyRatingAnomalyRatingsAnomalyIdPutMutation,
  useGetAnomalyRatingsAnomalyRatingsGetQuery,
  useGetServiceNotificationsServiceNotificationsGetQuery,
} = injectedRtkApi;
