import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleIcon } from '../../common/components/icons/QuestionCircleIcon';
import { Camera } from '../../common/components/icons/Camera';

import { Select } from '../../common/components/Select';
import { countryMap } from '../../common/countryMapping';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { debounce } from '../../common/utils';
import { Shipdata } from '../../store/apis/ihs-vessel-data-api';
import { Places } from '../../store/apis/port-api';
import { setSelectedEvent } from '../event-feed/slice';
import { useSearchPortsQuery } from '../port-view/hooks';
import { getSearchCookies } from './slice';
import { useSearchVesselsQuery } from './hooks';
import { Link } from '../header/components/Link';

type Props = {
  className?: string;
};

export const VesselSearch: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState<string>('');
  const vesselSearchQuery = useSearchVesselsQuery(input);
  const portSearchQuery = useSearchPortsQuery(input);

  useEffect(() => {
    dispatch(getSearchCookies());
  }, []);

  const state = useAppSelector((state) => state.searchOptions);

  const searchResultAmount = (data: Shipdata[] | Places[] | undefined, type: string) => {
    const renderType = <span className="text-stone-200">{type}</span>;

    if (!data) return;
    else if (data.length === 10) return <span>{renderType} | Showing top 10 matching results</span>;
    else if (data.length === 1) return <span>{renderType} | Showing 1 matching result</span>;
    else
      return (
        <span>
          {renderType} | Showing {data.length} matching results
        </span>
      );
  };

  const groupedOptions = [
    {
      label: searchResultAmount(vesselSearchQuery.currentData as Shipdata[], 'Ships'),
      options: (vesselSearchQuery.currentData as Shipdata[]) || state.cookies || [],
    },
    {
      label: searchResultAmount(portSearchQuery.currentData as Places[], 'Ports'),
      options: (portSearchQuery.currentData as Places[]) || [],
    },
  ];

  const onInputChangeHandler = debounce((input: string) => {
    setInput(input);
  }, 500);

  const onChangeHandler = (value: Shipdata | Places) => {
    dispatch(setSelectedEvent(null));
    if ((value as Shipdata)?.lrimoshipno) navigate(`/vessel/${(value as Shipdata).lrimoshipno}`);
    else navigate(`/port/${(value as Places).id}`);
  };

  const getOptionLabel = (option: Shipdata | Places) => {
    if ((option as Shipdata)?.lrimoshipno) {
      const vessel = option as Shipdata;
      return `${vessel.shipname} (${vessel.shiptypelevel4}, ${vessel.yearofbuild}, IMO ${vessel.lrimoshipno})`;
    } else {
      const place = option as Places;
      return `${place.name} (${place.unlocode}) ${countryMap[place.country_code as keyof typeof countryMap]}`;
    }
  };

  return (
    <div className="Search flex items-start items-center">
      <div
        className="pl-8 md:pl-12 lg:pl-16 flex items-center h-full bg-dark overflow-auto mr-2"
        title="Search vessels with photo"
      >
        <Link to="/ipr" className="pr-2 pl-2">
          <Camera className="h-6 w-6 fill-white" />
        </Link>
      </div>
      <div className="object-contain">
        <Select
          value={null}
          options={groupedOptions}
          getOptionLabel={getOptionLabel}
          onInputChange={onInputChangeHandler}
          onChange={onChangeHandler}
          isClearable
          isLoading={vesselSearchQuery.isFetching || portSearchQuery.isFetching}
          className={className}
          placeholder="Search for vessel or port"
          noOptionsMessage={() => (input ? `No results found for ${input}` : 'Start typing a vessel or a port name')}
          menuPortalTarget={document.body}
        />
      </div>
      <div
        title="Search vessels with name or IMO number,&#10;Search port with name or UN/LOCODE"
        className="mr-5 ml-1 lg:order-2 order-1"
      >
        <QuestionCircleIcon className="h-4 w-4 fill-slate-400 hover:fill-slate-200" />
      </div>
    </div>
  );
};
