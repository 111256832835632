import { MapRef } from 'react-map-gl';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserFleet } from '../../store/apis/csi-api';
import { getStateFromUrl } from './utils';

export type Coordinates = {
  longitude: number;
  latitude: number;
};

export type TabOption = 'manual' | 'automatic';

export type MyFleetState = {
  mapInstance: MapRef | null;
  selected?: number;
  hasMyFleet?: boolean;
  myAutomaticFleet?: UserFleet[];
  myManualFleet?: UserFleet[];
  automaticFleetPage: number;
  manualFleetPage: number;
  selectedTab?: TabOption;
  otherFleetUser?: string;
};
const initialState: MyFleetState = {
  mapInstance: null,
  automaticFleetPage: 0,
  manualFleetPage: 0,
};
const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: 'eventFilters',
  initialState: initialStateFromUrl,
  reducers: {
    setMapInstance(state, action: PayloadAction<MyFleetState['mapInstance']>) {
      state.mapInstance = action.payload;
    },
    setSelectedVessel(state, action: PayloadAction<MyFleetState['selected']>) {
      state.selected = action.payload;
    },
    setHasMyFleet(state, action: PayloadAction<MyFleetState['hasMyFleet']>) {
      state.hasMyFleet = action.payload;
    },
    setMyAutomaticFleet(state, action: PayloadAction<MyFleetState['myAutomaticFleet']>) {
      state.myAutomaticFleet = action.payload;
    },
    setMyManualFleet(state, action: PayloadAction<MyFleetState['myManualFleet']>) {
      state.myManualFleet = action.payload;
    },
    setSelectedTab(state, action: PayloadAction<MyFleetState['selectedTab']>) {
      state.selectedTab = action.payload;
    },
    setManualFleetPage(state, action: PayloadAction<MyFleetState['manualFleetPage']>) {
      state.manualFleetPage = action.payload;
    },
    setAutomaticFleetPage(state, action: PayloadAction<MyFleetState['automaticFleetPage']>) {
      state.automaticFleetPage = action.payload;
    },
  },
});

export const {
  setMapInstance,
  setSelectedVessel,
  setHasMyFleet,
  setMyManualFleet,
  setMyAutomaticFleet,
  setSelectedTab,
  setManualFleetPage,
  setAutomaticFleetPage,
} = slice.actions;
export const { reducer } = slice;
