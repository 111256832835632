import React from 'react';

import { Casualties } from '../../../../store/apis/vessel-event-api';
import { getCasualtyCauses, getDifferenceInTimeForCasualtyText, getSourceText } from '../../utils';
import { EventAttribute } from '../EventAttribute';
import { ListItem } from '../ListItem';

type Props = {
  casualty: Casualties;
  selected: boolean;
};

type CRMLead = {
  status: string;
  url: string;
  type: string;
};

const crmLeadLink = (crm_lead: CRMLead | null | string) => {
  if (!crm_lead || typeof crm_lead === 'string') return;
  const color =
    crm_lead.status === 'Rejected'
      ? 'bg-[#a53333]'
      : crm_lead.status === 'In Progress'
        ? 'bg-cyan-600/75'
        : crm_lead.status === 'Confirmed'
          ? 'bg-[#448044]'
          : 'bg-gray-500';
  return (
    <div className={color + ' rounded-md px-2 w-fit my-2'}>
      <a className="link-hover" title="Requires Salesforce user licence" target="_blank" href={crm_lead.url}>
        {crm_lead.status === 'Unknown' ? 'CRM lead' : `CRM lead: ${crm_lead.status}`}
      </a>
    </div>
  );
};

export const CasualtyItem: React.FC<Props> = ({ casualty, selected }) => (
  <ListItem
    key={casualty.id}
    item={casualty}
    type="casualty"
    date={casualty.casualty_date}
    className={selected ? 'bg-secondary/60' : ''}
  >
    <>
      {casualty.crm_link && crmLeadLink(casualty.crm_link)}
      <div className="py-1 mb-1">
        {getCasualtyCauses(casualty).map((cause) => (
          <EventAttribute key={cause}>{cause}</EventAttribute>
        ))}
        {casualty.anomaly_id && (
          <EventAttribute>
            <a
              className="link font-medium hover:text-accent no-underline"
              href={`/vessel/${casualty.imo_number}/anomalies/${casualty.anomaly_id}`}
            >
              {getDifferenceInTimeForCasualtyText(casualty)}
            </a>
          </EventAttribute>
        )}
      </div>

      <p>
        {casualty.casualty_description} <span className="text-gray-400">{getSourceText(casualty)}</span>
      </p>
    </>
  </ListItem>
);
