import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown } from '../../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../../common/components/icons/ChevronUp';
import { Select } from '../../../common/components/Select';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { debounce } from '../../../common/utils';
import { Places } from '../../../store/apis/port-api';
import { setShowUserLocation } from '../../location/slice';
import { useSearchPortsQuery } from '../hooks';
import { setIsSearchOpen, setLocationCoordinates, setLocationBoxCoordinates } from '../slice';

type Props = {};

export const PortSearch: React.FC<Props> = () => {
  const state = useAppSelector((state) => state.portPage);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState<string>('');
  const searchQuery = useSearchPortsQuery(input);

  const options = (searchQuery.currentData as Places[]) || [];

  const onInputChangeHandler = debounce((input: string) => {
    setInput(input);
  }, 500);

  const onChangeHandler = (value: Places) => {
    dispatch(setLocationCoordinates(undefined));
    dispatch(setLocationBoxCoordinates(undefined));
    dispatch(setShowUserLocation(false));
    navigate(`/port/${value.id}`);
  };

  const getOptionLabel = (option: Places) => `${option.name} (${option.unlocode})`;
  return (
    <div className="PortSearch">
      <div
        className="flex items-center border-b border-secondary"
        onClick={() => dispatch(setIsSearchOpen(!state.isSearchOpen))}
      >
        <span className="cursor-pointer mr-2">{state.isSearchOpen ? <ChevronUp /> : <ChevronDown />}</span>
        <span className="text-lg">Port Search</span>
      </div>
      {state.isSearchOpen && (
        <div className="my-2">
          <Select
            value={null}
            options={options}
            getOptionLabel={getOptionLabel}
            onInputChange={onInputChangeHandler}
            onChange={onChangeHandler}
            isClearable
            isLoading={searchQuery.isFetching}
            className="w-full mr-2 order-2 lg:order-1"
            placeholder="Find port by name, code or id"
            noOptionsMessage={() => (input ? `No port found for "${input}"` : 'Search by port name, code or id')}
            menuPortalTarget={document.body}
          />
        </div>
      )}
    </div>
  );
};
