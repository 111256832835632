import { MapRef } from 'react-map-gl';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getStateFromUrl } from './utils';

export type VesselMapState = {
  selectedPositionTimestamp: string | null;
  mapInstance: MapRef | null;
  showVesselOutline: boolean;
  sourceMarkers: boolean;
};

const initialState: VesselMapState = {
  selectedPositionTimestamp: null,
  mapInstance: null,
  showVesselOutline: false,
  sourceMarkers: false,
};
const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: 'vesselMap',
  initialState: initialStateFromUrl,
  reducers: {
    setSelectedPositionTimestamp(state, action: PayloadAction<VesselMapState['selectedPositionTimestamp']>) {
      state.selectedPositionTimestamp = action.payload;
    },
    setMapInstance(state, action: PayloadAction<VesselMapState['mapInstance']>) {
      state.mapInstance = action.payload;
    },
  },
});

export const { setSelectedPositionTimestamp, setMapInstance } = slice.actions;
export const { reducer } = slice;
