import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { defaultStyleConfig } from '../../common/components/Select';
import { addSearchParamsToUrl, formatTimeRangeOptionForUrl, parseUrlTimeRange } from '../../common/utils';
import { AlertSchedule, EventType, Fleet, UserFilter } from '../../store/apis/csi-api';
import { ConfidenceOption, EventFiltersState, EventOption, FleetOption, VesselTypeOption } from './slice';

type FilterUrlParams = {
  customer?: string;
  time?: string;
  event?: string;
  fleet?: string;
  location?: string;
  product?: string;
  type?: string;
  characteristic?: string;
  conf_min?: string;
  conf_max?: string;
  cause?: string;
  crmLead?: string;
  desc?: string;
  drydock_status?: string;
};

export const setFilterStateToUrl = (state: EventFiltersState) => {
  const {
    selectedCustomerOption,
    selectedTimeRangeOption,
    selectedEventOption,
    selectedFleetOption,
    selectedLocationOption,
    selectedProductOptions,
    selectedVesselTypeOptions,
    selectedCharacteristicOptions,
    selectedConfidenceMin,
    selectedConfidenceMax,
    selectedCauseOptions,
    selectedCrmLeadOptions,
    selectedDescriptionOption,
    selectedDrydockingStatusOption,
  } = state;

  const filterParams: FilterUrlParams = {
    customer: selectedCustomerOption || undefined,
    location: selectedLocationOption || undefined,
    time: !selectedTimeRangeOption?.isDefault ? formatTimeRangeOptionForUrl(selectedTimeRangeOption) : undefined,
    event: selectedEventOption?.value,
    fleet: !selectedFleetOption?.isDefault ? selectedFleetOption?.value?.replace('-fleet', '') : undefined,
    product: selectedProductOptions.map((item) => item.value).join(',') || undefined,
    type: selectedVesselTypeOptions.map((item) => item.value).join(',') || undefined,
    characteristic: selectedCharacteristicOptions?.map((item) => item.urlValue).join(',') || undefined,
    conf_min: !selectedConfidenceMin?.isDefaultMin ? selectedConfidenceMin?.urlValue : undefined,
    conf_max: !selectedConfidenceMax?.isDefaultMax ? selectedConfidenceMax?.urlValue : undefined,
    cause: selectedCauseOptions.map((item) => item.value).join(',') || undefined,
    crmLead: selectedCrmLeadOptions.map((item) => item.urlValue).join(',') || undefined,
    desc: selectedDescriptionOption || undefined,
    drydock_status: selectedDrydockingStatusOption?.urlValue || undefined,
  };

  const urlParams = new URLSearchParams();
  Object.entries(filterParams).forEach(([key, value]) => {
    if (!value) return;
    urlParams.set(key, value);
  });

  addSearchParamsToUrl(urlParams);
};

export const getStateFromUrl = (initialState: EventFiltersState): EventFiltersState => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<FilterUrlParams> = Object.fromEntries(urlSearchParams.entries());

  if (!Object.entries(params)?.length) {
    return initialState;
  }

  const filter: EventFiltersState = {
    ...initialState,
    selectedCustomerOption: params.customer || initialState.selectedCustomerOption,
    selectedLocationOption: params.location || initialState.selectedLocationOption,
    selectedEventOption:
      initialState.eventOptions.find((item) => item.value === params.event) || initialState.selectedEventOption,
    selectedDescriptionOption: params.desc || initialState.selectedDescriptionOption,
  };

  if (params.fleet) {
    let fleetOption = initialState.fleetOptions.find((item) => item.value === `${params.fleet}-fleet`);
    if (params.fleet === 'my') fleetOption = { label: 'My fleet', value: 'my-fleet' };
    if (fleetOption) {
      filter.selectedFleetOption = fleetOption;
    } else {
      // Check if fleet filter is set to a custom employee value "<first-name>.<last-name>"
      if (params.fleet.split('.')?.length > 1) {
        filter.selectedFleetOption = {
          label: `Fleet (${params.fleet})`,
          value: params.fleet as any,
        };
      }
    }
  }

  if (params.time) {
    const { amount, unit } = parseUrlTimeRange(params.time) || {};
    let selectedTimeRange = initialState.timeRangeOptions.find(
      (item) => item.value.amount.toString() === amount && item.value.unit === unit
    );
    if (selectedTimeRange) {
      if (!filter.selectedCustomerOption && selectedTimeRange.conditional) {
        selectedTimeRange = { label: 'Past 1 month', value: { amount: 1, unit: 'months' } };
      }
      filter.selectedTimeRangeOption = selectedTimeRange;
    }
  }

  if (params.product) {
    const productValues = params.product.split(',');
    const selectedProducts = initialState.productOptions.filter((item) => productValues.includes(item.value));
    filter.selectedProductOptions = selectedProducts;
  }

  if (params.type) {
    const typeValues = params.type.split(',');
    const selectedTypes = initialState.vesselTypeOptions.filter((item) => typeValues.includes(item.value));
    filter.selectedVesselTypeOptions = selectedTypes;
  }

  if (params.characteristic) {
    const labelValues = params.characteristic.split(',');
    const selectedCharacteristic = initialState.characteristicOptions.filter((item) =>
      labelValues.includes(item.urlValue)
    );
    filter.selectedCharacteristicOptions = selectedCharacteristic;
  }

  if (params.conf_min) {
    const selectedConfMin =
      initialState.confidenceOptions.find((item) => item.urlValue === params.conf_min) ||
      (initialState.confidenceOptions.find((item) => item.isDefaultMin) as ConfidenceOption);
    filter.selectedConfidenceMin = selectedConfMin;
  }

  if (params.conf_max) {
    const selectedConfMax =
      initialState.confidenceOptions.find((item) => item.urlValue === params.conf_max) ||
      (initialState.confidenceOptions.find((item) => item.isDefaultMax) as ConfidenceOption);
    filter.selectedConfidenceMax = selectedConfMax;
  }

  if (params.cause) {
    const causeValues = params.cause.split(',');
    const selectedCauses = initialState.causeOptions.filter((item) => causeValues.includes(item.value));
    filter.selectedCauseOptions = selectedCauses;
  }

  if (params.crmLead) {
    const crmValues = params.crmLead.split(',');
    const selectedCrmLeads = initialState.crmLeadOptions.filter((item) => crmValues.includes(item.urlValue));
    filter.selectedCrmLeadOptions = selectedCrmLeads;
  }

  if (params.drydock_status) {
    const labelValues = params.drydock_status;
    const selectedDrydockingStatusOption = initialState.drydockingStatusOptions.find((item) => {
      if (item.isDefault) return false;
      return labelValues.includes(item.urlValue);
    });
    filter.selectedDrydockingStatusOption = selectedDrydockingStatusOption || null;
  }

  return filter;
};

export const buildFilterFromUserFilter = (
  userFilter: UserFilter,
  initialState: EventFiltersState
): EventFiltersState => {
  const filter = { ...initialState };
  const {
    timeframe,
    event_types,
    fleet,
    vessel_types,
    products,
    customers,
    locations,
    anomaly_characteristics,
    anomaly_confidence_min,
    anomaly_confidence_max,
    casualty_causes,
    casualty_crm_leads,
    casualty_description,
  } = userFilter.settings;

  if (timeframe) {
    const { amount, unit } = parseUrlTimeRange(timeframe) || {};
    filter.selectedTimeRangeOption =
      filter.timeRangeOptions.find(
        (option) => option.value.amount.toString() === amount && option.value.unit === unit
      ) || null;
  }

  if (event_types?.length) {
    let eventOption: EventOption | null = null;

    switch (event_types[0]) {
      case 'anomalies':
        eventOption = filter.eventOptions.find((option) => option.value === 'anomalies') || null;
        break;

      case 'casualties':
        eventOption = filter.eventOptions.find((option) => option.value === 'casualties') || null;
        break;

      case 'dry_docking':
        eventOption = filter.eventOptions.find((option) => option.value === 'dry-docking') || null;
        break;
    }

    filter.selectedEventOption = eventOption;
  }

  if (fleet) {
    let fleetOption: FleetOption | null = null;
    switch (fleet) {
      case 'global':
        fleetOption = filter.fleetOptions.find((option) => option.value === 'global-fleet') || null;
        break;
      case 'imo':
        fleetOption = filter.fleetOptions.find((option) => option.value === 'imo-fleet') || null;
        break;
      case 'wartsila':
        fleetOption = filter.fleetOptions.find((option) => option.value === 'wartsila-fleet') || null;
        break;
      case 'my_fleet':
        fleetOption = filter.fleetOptions.find((option) => option.value === 'my-fleet') || null;
        break;
    }
    filter.selectedFleetOption = fleetOption;
  }

  if (vessel_types) {
    filter.selectedVesselTypeOptions = filter.vesselTypeOptions.filter((option) => vessel_types.includes(option.value));
  }

  if (products) {
    filter.selectedProductOptions = filter.productOptions.filter((option) => products.includes(option.value));
  }

  if (customers) {
    filter.selectedCustomerOption = customers.join(',');
  }

  if (locations) {
    filter.selectedLocationOption = locations.join(',');
  }

  // Event specific filters

  if (anomaly_characteristics) {
    filter.selectedCharacteristicOptions = filter.characteristicOptions.filter((option) =>
      anomaly_characteristics.includes(option.value)
    );
  }

  if (anomaly_confidence_min) {
    filter.selectedConfidenceMin =
      filter.confidenceOptions.find((option) => option.value === anomaly_confidence_min) ||
      (filter.confidenceOptions.find((option) => option.isDefaultMin) as ConfidenceOption);
  }

  if (anomaly_confidence_max) {
    filter.selectedConfidenceMax =
      filter.confidenceOptions.find((option) => option.value === anomaly_confidence_max) ||
      (filter.confidenceOptions.find((option) => option.isDefaultMax) as ConfidenceOption);
  }

  if (casualty_causes) {
    filter.selectedCauseOptions = filter.causeOptions.filter((option) => casualty_causes.includes(option.value));
  }

  if (casualty_crm_leads) {
    filter.selectedCrmLeadOptions = filter.crmLeadOptions.filter((option) => casualty_crm_leads.includes(option.value));
  }

  if (casualty_description) {
    filter.selectedDescriptionOption = casualty_description.join(',');
  }

  return filter;
};

export const buildUserFilter = (
  name: string,
  alertSchedule: AlertSchedule,
  filterState: EventFiltersState,
  id?: number
): UserFilter => {
  const userFilter: UserFilter = {
    name,
    settings: {},
  };

  if (id) {
    userFilter.id = id;
  }

  if (alertSchedule) {
    userFilter.notification_type = 'email';
    userFilter.alert_schedule = alertSchedule;
  }

  const {
    selectedTimeRangeOption,
    selectedEventOption,
    selectedFleetOption,
    selectedVesselTypeOptions,
    selectedProductOptions,
    selectedCustomerOption,
    selectedLocationOption,
    selectedCharacteristicOptions,
    selectedConfidenceMin,
    selectedConfidenceMax,
    selectedCauseOptions,
    selectedCrmLeadOptions,
    selectedDescriptionOption,
  } = filterState;

  if (selectedTimeRangeOption) {
    userFilter.settings.timeframe = formatTimeRangeOptionForUrl(selectedTimeRangeOption);
  }

  if (selectedEventOption) {
    if (selectedEventOption.value !== 'port-calls') {
      let eventType: EventType | null = null;
      switch (selectedEventOption.value) {
        case 'anomalies':
          eventType = 'anomalies';
          break;
        case 'casualties':
          eventType = 'casualties';
          break;
        case 'dry-docking':
          eventType = 'dry_docking';
          break;
      }
      userFilter.settings.event_types = [eventType];
    }
  }

  if (selectedFleetOption) {
    let fleet: Fleet | null = null;
    switch (selectedFleetOption.value) {
      case 'global-fleet':
        fleet = 'global';
        break;
      case 'imo-fleet':
        fleet = 'imo';
        break;
      case 'wartsila-fleet':
        fleet = 'wartsila';
        break;
      case 'my-fleet':
        fleet = 'my_fleet';
        break;
    }
    userFilter.settings.fleet = fleet;
  }

  if (selectedVesselTypeOptions?.length) {
    userFilter.settings.vessel_types = selectedVesselTypeOptions.map((option) => option.value);
  }

  if (selectedProductOptions?.length) {
    userFilter.settings.products = selectedProductOptions.map((option) => option.value);
  }

  if (selectedCustomerOption) {
    userFilter.settings.customers = selectedCustomerOption.split(',');
  }

  if (selectedLocationOption) {
    userFilter.settings.locations = selectedLocationOption.split(',');
  }

  // Event specific filters

  if (selectedCharacteristicOptions) {
    userFilter.settings.anomaly_characteristics = selectedCharacteristicOptions.map((option) => option.value);
  }

  if (selectedConfidenceMin) {
    userFilter.settings.anomaly_confidence_min = selectedConfidenceMin.value;
  }

  if (selectedConfidenceMax) {
    userFilter.settings.anomaly_confidence_max = selectedConfidenceMax.value;
  }

  if (selectedCauseOptions) {
    userFilter.settings.casualty_causes = selectedCauseOptions.map((option) => option.value);
  }

  if (selectedCrmLeadOptions) {
    userFilter.settings.casualty_crm_leads = selectedCrmLeadOptions.map((option) => option.value);
  }

  if (selectedDescriptionOption) {
    userFilter.settings.casualty_description = selectedDescriptionOption.split(',');
  }

  return userFilter;
};

export const vesselTypeStyleConfig: StylesConfig = {
  ...defaultStyleConfig,
  option: (provided, state) => {
    const option = state.data as VesselTypeOption;
    const style: CSSObjectWithLabel = {
      paddingLeft: `${option.level * 10}px`,
    };

    if (defaultStyleConfig.option) {
      return {
        ...defaultStyleConfig.option(provided, state),
        ...style,
      };
    }

    return style;
  },
};

export const filteredTimeOptions = (state: EventFiltersState) => {
  if (state.selectedCustomerOption) return state.timeRangeOptions;
  return state.timeRangeOptions.filter((option) => !option.conditional);
};

export const filteredVesselTypeOptions = (state: EventFiltersState) => {
  const { selectedVesselTypeOptions, vesselTypeOptions } = state;
  return vesselTypeOptions.filter((v) => !selectedVesselTypeOptions.some((filter) => v.value.includes(filter.value)));
};
