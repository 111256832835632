import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export type TitleState = {
  title: string;
};

const initialState: TitleState = { title: 'Continuous Ship Intelligence' };

export const setTitleAndUpdate = createAsyncThunk<void, string | undefined>(
  `title/setAndUpdateTitle`,
  async (title) => {
    document.title = title ? `CSI | ${title}` : ' CSI | Continuous Ship Intelligence';
  }
);

const slice = createSlice({
  name: 'title',
  initialState,
  reducers: {},
});

export const { reducer } = slice;
