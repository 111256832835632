import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapStyle } from '../../common/types';
import { getStateFromLocalStorage } from './utils';

export type SwitchMapStyleState = {
  selectedVesselMapStyle: MapStyle;
  selectedPortMapStyle: MapStyle;
};

const initialState: SwitchMapStyleState = { selectedVesselMapStyle: 'default', selectedPortMapStyle: 'default' };
const initialStateFromLocalStorage = getStateFromLocalStorage(initialState);

const slice = createSlice({
  name: 'switchMapStyle',
  initialState: initialStateFromLocalStorage,
  reducers: {
    setSelectedVesselMapStyle(state, action: PayloadAction<SwitchMapStyleState['selectedVesselMapStyle']>) {
      state.selectedVesselMapStyle = action.payload;
    },
    setSelectedPortMapStyle(state, action: PayloadAction<SwitchMapStyleState['selectedPortMapStyle']>) {
      state.selectedPortMapStyle = action.payload;
    },
  },
});

export const { setSelectedVesselMapStyle, setSelectedPortMapStyle } = slice.actions;
export const { reducer } = slice;
