import React from 'react';

import { Spinner } from '../../../common/components/Spinner';
import { ChevronDown } from '../../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../../common/components/icons/ChevronUp';
import {
  Equipment as InstallationEquipment,
  useInstallationsInstallationIdEquipmentsGetQuery,
} from '../../../store/apis/installation-api';
import { groupEquipment } from '../utils';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { setVesselDetailsState } from '../slice';

type Props = {
  installationId?: number;
  className?: string;
};

export const Equipment: React.FC<Props> = ({ installationId, className }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.vesselDetails);
  const isOpen = state.isEquipmentOpen;

  const equipmentQuery = useInstallationsInstallationIdEquipmentsGetQuery(
    { installationId: installationId?.toString() as string },
    { skip: !installationId }
  );

  if (!installationId) {
    return null;
  }

  const equipment = equipmentQuery.currentData as InstallationEquipment[] | undefined;
  const groupedEquipment = groupEquipment(equipment || []);
  const isFetching = equipmentQuery.isFetching;

  return (
    <div className={`Equipment ${className ? className : ''}`}>
      <div className="flex flex-row items-center justify-between border-b border-secondary pb-1 mb-2">
        <div className="flex flex-row items-center">
          <div onClick={() => dispatch(setVesselDetailsState({ ...state, isEquipmentOpen: !isOpen }))}>
            {isOpen ? (
              <ChevronUp className="h-5 w-5 mr-2 cursor-pointer" />
            ) : (
              <ChevronDown className="h-5 w-5 mr-2 cursor-pointer" />
            )}
          </div>
          <span
            className="text-lg cursor-pointer"
            onClick={() => dispatch(setVesselDetailsState({ ...state, isEquipmentOpen: !isOpen }))}
          >
            Equipment
          </span>
        </div>
        <div className="bg-secondary rounded py-1 px-2 text-xs">
          Source:{' '}
          <span className="text-accent">
            <a
              className="hover:underline"
              target="_blank"
              href={`https://cib.data.wartsila.com/installations/${installationId}`}
              rel="noreferrer"
              title="Link to CIB"
            >
              Wärtsilä CIB
            </a>
          </span>
        </div>
      </div>
      {isOpen ? (
        <>
          {isFetching && <Spinner className="h-6 w-6" />}
          {!isFetching && !groupedEquipment.length && <div className="text-stone-400">No equipment found</div>}
          {!!groupedEquipment.length && (
            <div>
              {groupedEquipment.map((eq) => (
                <div key={eq.name} className="flex justify-between">
                  <span className="flex-1">{eq.name}</span>
                  <div className="flex-1 flex flex-col">
                    {eq.equipmentGroup.map((group, index) => (
                      <span key={index} className="text-right">
                        {group.amount}x {group.equipment.productReferenceType?.code}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
