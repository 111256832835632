import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Layer = {
  enabled: boolean;
};

export type State = {
  emissionLayer: Layer;
  aisQualityLayer: Layer;
  menuIsOpen: boolean;
  legendIsOpen: boolean;
  aisAccessToken: string | null;
};

const initialState: State = {
  emissionLayer: {
    enabled: false,
  },
  aisQualityLayer: {
    enabled: false,
  },
  menuIsOpen: true,
  legendIsOpen: true,
  aisAccessToken: null,
};

const slice = createSlice({
  name: 'mapLayers',
  initialState,
  reducers: {
    toggleEmissionLayerEnabled(state) {
      state.emissionLayer.enabled = !state.emissionLayer.enabled;
    },
    toggleAisQualityLayerEnabled(state) {
      state.aisQualityLayer.enabled = !state.aisQualityLayer.enabled;
    },
    toggleMenuIsOpen(state) {
      state.menuIsOpen = !state.menuIsOpen;
    },
    toggleLegendIsOpen(state) {
      state.legendIsOpen = !state.legendIsOpen;
    },
    setAisAccessToken(state, action: PayloadAction<State['aisAccessToken']>) {
      state.aisAccessToken = action.payload;
    },
  },
});

export const {
  toggleEmissionLayerEnabled,
  toggleAisQualityLayerEnabled,
  toggleMenuIsOpen,
  toggleLegendIsOpen,
  setAisAccessToken,
} = slice.actions;
export const { reducer } = slice;
