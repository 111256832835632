import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSelectOption, TimeRangeOption } from '../../common/types';
import { RootState } from '../../store/store';
import {
  causeOptions,
  characteristicOptions,
  confidenceOptions,
  crmLeadOptions,
  drydockingStatusOptions,
  eventOptions,
  fleetOptions,
  productFilters,
  timeRangeOptions,
  vesselTypeOptions,
} from './filter-options';
import { getStateFromUrl } from './utils';

export type FleetOption = Omit<BaseSelectOption, 'value'> & {
  value: 'global-fleet' | 'imo-fleet' | 'wartsila-fleet' | 'my-fleet';
  isDefault?: boolean;
};

export type EventOptionValue = 'anomalies' | 'casualties' | 'dry-docking' | 'port-calls';

export type EventOption = Omit<BaseSelectOption, 'value'> & {
  value: EventOptionValue;
};

export type VesselTypeOption = Omit<BaseSelectOption, 'value'> & {
  value: string;
  level: number;
};

export type ProductOption = {
  value: string;
  label: string;
  count?: number;
  type?: string;
};

export type CharacteristicOption = {
  label: string;
  value: string;
  urlValue: string;
  isDefault?: boolean;
};

export type ConfidenceOption = {
  label: string;
  /** This value is only used by the range slider. */
  value: number;
  urlValue: string;
  minQuery: string | null;
  maxQuery: string | null;
  isDefaultMin?: boolean;
  isDefaultMax?: boolean;
};

export type CRMLeadOption = {
  value: 'Rejected' | 'Unknown' | 'In Progress' | 'Pending' | 'Confirmed';
  label: string;
  urlValue: string;
};

export type CauseOption = BaseSelectOption;

export type EventFiltersState = {
  isOpen: boolean;
  timeRangeOptions: TimeRangeOption[];
  selectedTimeRangeOption: TimeRangeOption | null;
  fleetOptions: FleetOption[];
  selectedFleetOption: FleetOption | null;
  eventOptions: EventOption[];
  selectedEventOption: EventOption | null;
  vesselTypeOptions: VesselTypeOption[];
  selectedVesselTypeOptions: VesselTypeOption[];
  productOptions: ProductOption[];
  selectedProductOptions: ProductOption[];
  selectedCustomerOption: string;
  selectedLocationOption: string;
  // Anomaly feed specific filters
  characteristicOptions: CharacteristicOption[];
  selectedCharacteristicOptions: CharacteristicOption[] | null;
  confidenceOptions: ConfidenceOption[];
  selectedConfidenceMin: ConfidenceOption;
  selectedConfidenceMax: ConfidenceOption;
  // Casualty feed specific filters
  causeOptions: CauseOption[];
  selectedCauseOptions: CauseOption[];
  crmLeadOptions: CRMLeadOption[];
  selectedCrmLeadOptions: CRMLeadOption[];
  selectedDescriptionOption: string;
  // Vessel details page specific filters
  selectedImo: number | null;
  drydockingStatusOptions: CharacteristicOption[];
  selectedDrydockingStatusOption: CharacteristicOption | null;
};

export const initialState: EventFiltersState = {
  isOpen: true,
  timeRangeOptions,
  selectedTimeRangeOption: timeRangeOptions.find((option) => option.isDefault) || null,
  fleetOptions,
  selectedFleetOption: fleetOptions.find((option) => option.isDefault) || fleetOptions[0],
  eventOptions,
  selectedEventOption: null,
  vesselTypeOptions,
  selectedVesselTypeOptions: [],
  productOptions: productFilters,
  selectedProductOptions: [],
  selectedCustomerOption: '',
  selectedLocationOption: '',
  // Anomaly feed specific filters
  characteristicOptions: characteristicOptions,
  selectedCharacteristicOptions: [],
  confidenceOptions: confidenceOptions,
  selectedConfidenceMin: confidenceOptions.find((item) => item.isDefaultMin) as ConfidenceOption,
  selectedConfidenceMax: confidenceOptions.find((item) => item.isDefaultMax) as ConfidenceOption,
  // Casualty feed specific filters
  causeOptions,
  selectedCauseOptions: [],
  crmLeadOptions,
  selectedCrmLeadOptions: [],
  selectedDescriptionOption: '',
  // Vessel details page specific filters
  selectedImo: null,
  drydockingStatusOptions: drydockingStatusOptions,
  selectedDrydockingStatusOption: drydockingStatusOptions[0],
};

const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: 'eventFilters',
  initialState: initialStateFromUrl,
  reducers: {
    toggleShowEventFilters(state) {
      state.isOpen = !state.isOpen;
    },
    setState(_, action: PayloadAction<EventFiltersState>) {
      if (!action.payload.selectedCustomerOption && action.payload.selectedTimeRangeOption?.conditional) {
        action.payload.selectedTimeRangeOption = { label: 'Past 1 month', value: { amount: 1, unit: 'months' } };
      }
      return action.payload;
    },
    setFleetOptions(state, action: PayloadAction<FleetOption[]>) {
      state.fleetOptions = action.payload;
    },
    resetState() {
      return initialState;
    },
    resetStateFromUrl(state) {
      const test = { ...initialStateFromUrl } as EventFiltersState;
      test.fleetOptions = state.fleetOptions;
      return test;
    },
  },
});

export const { toggleShowEventFilters, setState, setFleetOptions, resetState, resetStateFromUrl } = slice.actions;
export const { reducer } = slice;

const eventFiltersSelector = (state: RootState) => state.eventFilters;

export const filterValuesAsJsonSelector = createSelector(eventFiltersSelector, (state) => {
  const { isOpen, ...filters } = state;
  return JSON.stringify(filters);
});
